const initialCards = [
  {
    id: "0",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Agate",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/agate/interiors/absolut_gres_agate_12679_825776_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/agate/tovars/absolut_gres_agate_103750_668232_300x0.jpg"),
      require("../images/catalog/absolut_gres/agate/tovars/absolut_gres_agate_106802_774882_300x0.jpg"),
    ],
  },

  {
    id: "3",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Alba",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/alba/interiors/absolut_gres_alba_14589_454811_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/alba/tovars/absolut_gres_alba_112165_639576_300x0.jpg"),
    ],
  },
  {
    id: "5",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Almond wood",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/almond_wood/interiors/absolut_gres_almond_wood_17005_95319_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/almond_wood/tovars/absolut_gres_almond_wood_122766_166185_300x0.jpg"),
      require("../images/catalog/absolut_gres/almond_wood/tovars/absolut_gres_almond_wood_122767_657106_300x0.jpg"),
      require("../images/catalog/absolut_gres/almond_wood/tovars/absolut_gres_almond_wood_122768_636972_300x0.jpg"),
    ],
  },

  {
    id: "9",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Ambassador interiors",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/ambassador/interiors/absolut_gres_ambassador_14580_353460_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/ambassador/tovars/absolut_gres_ambassador_112156_847849_300x0.jpg"),
    ],
  },

  {
    id: "11",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Andrea creama",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/andrea_creama/interiors/absolut_gres_andrea_creama_16482_287438_0x500.jpg"),
      require("../images/catalog/absolut_gres/andrea_creama/interiors/absolut_gres_andrea_creama_16482_359377_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/andrea_creama/tovars/absolut_gres_andrea_creama_120976_434155_300x0.jpg"),
      require("../images/catalog/absolut_gres/andrea_creama/tovars/absolut_gres_andrea_creama_120986_923157_300x0.jpg"),
    ],
  },

  {
    id: "15",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Antique grey",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/antique_grey/interiors/absolut_gres_antique_grey_12661_570759_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/antique_grey/tovars/absolut_gres_antique_grey_103731_91518_300x0.jpg"),
    ],
  },

  {
    id: "17",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Antique roug",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/antique_rough/interiors/absolut_gres_antique_rough_12660_621462_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/antique_rough/tovars/absolut_gres_antique_rough_103730_401996_300x0.jpg"),
    ],
  },

  {
    id: "19",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Antiqu onix natural",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/antiqu_onix_natural/interiors/absolut_gres_antiqu_onix_natural_15277_316562_0x500.jpg"),
      require("../images/catalog/absolut_gres/antiqu_onix_natural/interiors/absolut_gres_antiqu_onix_natural_15277_378517_0x300.jpg"),
      require("../images/catalog/absolut_gres/antiqu_onix_natural/interiors/absolut_gres_antiqu_onix_natural_15277_521866_0x300.jpg"),
      require("../images/catalog/absolut_gres/antiqu_onix_natural/interiors/absolut_gres_antiqu_onix_natural_15277_805714_0x300.jpg"),
      require("../images/catalog/absolut_gres/antiqu_onix_natural/interiors/absolut_gres_antiqu_onix_natural_15277_991946_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/antiqu_onix_natural/tovars/absolut_gres_antiqu_onix_natural_109179_258871_300x0.jpg"),
    ],
  },

  {
    id: "25",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Apollo white",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/apollo_white/interiors/absolut_gres_apollo_white_12687_542930_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/apollo_white/tovars/absolut_gres_apollo_white_103758_594605_300x0.jpg"),
      require("../images/catalog/absolut_gres/apollo_white/tovars/absolut_gres_apollo_white_106805_602870_300x0.jpg"),
    ],
  },
  {
    id: "755",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Aquafina natural",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/aquafina_natural/interiors/absolut_gres_aquafina_natural_14575_115506_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/aquafina_natural/tovars/absolut_gres_aquafina_natural_109164_53651_300x0.jpg"),
      require("../images/catalog/absolut_gres/aquafina_natural/tovars/absolut_gres_aquafina_natural_109176_163729_300x0.jpg"),
    ],
  },
  {
    id: "27",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Aquarelle grey",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/aquarelle_grey/interiors/absolut_gres_aquarelle_grey_16481_98602_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/aquarelle_grey/tovars/absolut_gres_aquarelle_grey_120974_554002_300x0.jpg"),
      require("../images/catalog/absolut_gres/aquarelle_grey/tovars/absolut_gres_aquarelle_grey_120983_514857_300x0.jpg"),
    ],
  },
  {
    id: "758",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Armani bianco",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/armani_bianco/interiors/absolut_gres_armani_bianco_16438_429290_0x300.jpg"),
      require("../images/catalog/absolut_gres/armani_bianco/interiors/absolut_gres_armani_bianco_16438_983310_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/armani_bianco/tovars/absolut_gres_armani_bianco_120967_79856_300x0.jpg"),
      require("../images/catalog/absolut_gres/armani_bianco/tovars/absolut_gres_armani_bianco_120984_870379_300x0.jpg"),
    ],
  },
  {
    id: "29",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Armani black",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/armani_black/interiors/absolut_gres_armani_black_16480_393626_0x300.jpg"),
      require("../images/catalog/absolut_gres/armani_black/interiors/absolut_gres_armani_black_16480_586205_0x500.jpg"),
      require("../images/catalog/absolut_gres/armani_black/interiors/absolut_gres_armani_black_16480_74829_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/armani_black/tovars/absolut_gres_armani_black_120972_411176_300x0.jpg"),
      require("../images/catalog/absolut_gres/armani_black/tovars/absolut_gres_armani_black_120990_425393_300x0.jpg"),
    ],
  },
  {
    id: "40",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Armani brown",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/armani_brown/interiors/absolut_gres_armani_brown_16478_469830_0x300.jpg"),
      require("../images/catalog/absolut_gres/armani_brown/interiors/absolut_gres_armani_brown_16478_469830_0x500.jpg"),
      require("../images/catalog/absolut_gres/armani_brown/interiors/absolut_gres_armani_brown_16478_61254_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/armani_brown/tovars/absolut_gres_armani_brown_120971_612639_300x0.jpg"),
      require("../images/catalog/absolut_gres/armani_brown/tovars/absolut_gres_armani_brown_120989_670861_300x0.jpg"),
    ],
  },
  {
    id: "752",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Armani gris",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/armani_gris/interiors/absolut_gres_armani_gris_12677_882953_0x300.jpg"),
      require("../images/catalog/absolut_gres/armani_gris/interiors/absolut_gres_armani_gris_12677_882953_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/armani_gris/tovars/absolut_gres_armani_gris_103748_397324_300x0.jpg"),
      require("../images/catalog/absolut_gres/armani_gris/tovars/absolut_gres_armani_gris_106801_201053_300x0.jpg"),
      require("../images/catalog/absolut_gres/armani_gris/tovars/absolut_gres_armani_gris_109159_63456_300x0.jpg"),
    ],
  },
  {
    id: "753",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Armani silver",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/armani_silver/interiors/absolut_gres_armani_silver_14578_53331_0x300.jpg"),
      require("../images/catalog/absolut_gres/armani_silver/interiors/absolut_gres_armani_silver_14578_53331_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/armani_silver/tovars/absolut_gres_armani_silver_109170_135588_300x0.jpg"),
      require("../images/catalog/absolut_gres/armani_silver/tovars/absolut_gres_armani_silver_109182_374430_300x0.jpg"),
    ],
  },
  {
    id: "770",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Aroma wood",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/aroma_wood/interiors/absolut_gres_aroma_wood_17011_661330_0x300.jpg"),
      require("../images/catalog/absolut_gres/aroma_wood/interiors/absolut_gres_aroma_wood_17011_661330_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/aroma_wood/tovars/absolut_gres_aroma_wood_122769_305374_300x0.jpg"),
      require("../images/catalog/absolut_gres/aroma_wood/tovars/absolut_gres_aroma_wood_122770_109769_300x0.jpg"),
      require("../images/catalog/absolut_gres/aroma_wood/tovars/absolut_gres_aroma_wood_122771_717082_300x0.jpg"),
      require("../images/catalog/absolut_gres/aroma_wood/tovars/absolut_gres_aroma_wood_122772_974539_300x0.jpg"),
      require("../images/catalog/absolut_gres/aroma_wood/tovars/absolut_gres_aroma_wood_122773_65616_300x0.jpg"),
      require("../images/catalog/absolut_gres/aroma_wood/tovars/absolut_gres_aroma_wood_122774_577996_300x0.jpg"),
      require("../images/catalog/absolut_gres/aroma_wood/tovars/absolut_gres_aroma_wood_122775_430289_300x0.jpg"),
    ],
  },
  {
    id: "754",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Aspire gold",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/aspire_gold/interiors/absolut_gres_aspire_gold_16461_557988_0x300.jpg"),
      require("../images/catalog/absolut_gres/aspire_gold/interiors/absolut_gres_aspire_gold_16461_557988_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/aspire_gold/tovars/absolut_gres_aspire_gold_120978_21263_300x0.jpg"),
      require("../images/catalog/absolut_gres/aspire_gold/tovars/absolut_gres_aspire_gold_120981_459112_300x0.jpg"),
    ],
  },
  {
    id: "26",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Aura orointeriors",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/aura_oro/interiors/absolut_gres_aura_oro_14573_292853_0x300.jpg"),
      require("../images/catalog/absolut_gres/aura_oro/interiors/absolut_gres_aura_oro_14573_292853_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/aura_oro/tovars/absolut_gres_aura_oro_109163_437987_300x0.jpg"),
      require("../images/catalog/absolut_gres/aura_oro/tovars/absolut_gres_aura_oro_109175_148584_300x0.jpg"),
    ],
  },
  {
    id: "757",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Azur",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/azur/interiors/absolut_gres_azur_14585_507874_0x300.jpg"),
      require("../images/catalog/absolut_gres/azur/interiors/absolut_gres_azur_14585_507874_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/azur/tovars/absolut_gres_azur_112161_464113_300x0.jpg"),
    ],
  },
  {
    id: "28",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Barma brown",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/barma_brown/interiors/absolut_gres_barma_brown_12705_23590_0x300.jpg"),
      require("../images/catalog/absolut_gres/barma_brown/interiors/absolut_gres_barma_brown_12705_23590_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/barma_brown/tovars/absolut_gres_barma_brown_103780_101619_300x0.jpg"),
    ],
  },
  {
    id: "759",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Barma cafe",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/barma_cafe/interiors/absolut_gres_barma_cafe_12704_399490_0x300.jpg"),
      require("../images/catalog/absolut_gres/barma_cafe/interiors/absolut_gres_barma_cafe_12704_399490_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/barma_cafe/tovars/absolut_gres_barma_cafe_103779_62173_300x0.jpg"),
    ],
  },
  {
    id: "30",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Barma teak",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/barma_teak/interiors/absolut_gres_barma_teak_12706_469753_0x300.jpg"),
      require("../images/catalog/absolut_gres/barma_teak/interiors/absolut_gres_barma_teak_12706_469753_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/barma_teak/tovars/absolut_gres_barma_teak_103781_84345_300x0.jpg"),
    ],
  },
  {
    id: "31",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Beton",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/beton/interiors/absolut_gres_novaya_kollekciya__17722_17722_345892.jpg"),
      require("../images/catalog/absolut_gres/beton/interiors/absolut_gres_novaya_kollekciya__17722_17722_543689.jpg"),
      require("../images/catalog/absolut_gres/beton/interiors/absolut_gres_novaya_kollekciya__17722_17722_612931.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/beton/tovars/absolut_gres_beton_124840_545918_300x0.jpg"),
    ],
  },
  {
    id: "32",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Bianco dorado",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/bianco_dorado/interiors/absolut_gres_bianco_dorado_16470_476209_0x300.jpg"),
      require("../images/catalog/absolut_gres/bianco_dorado/interiors/absolut_gres_bianco_dorado_16470_872628_0x300.jpg"),
      require("../images/catalog/absolut_gres/bianco_dorado/interiors/absolut_gres_bianco_dorado_16470_872628_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/bianco_dorado/tovars/absolut_gres_bianco_dorado_120963_908896_300x0.jpg"),
    ],
  },
  {
    id: "33",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Black marquina",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/black_marquina/interiors/absolut_gres_black_marquina_12692_766007_0x300.jpg"),
      require("../images/catalog/absolut_gres/black_marquina/interiors/absolut_gres_black_marquina_12692_766007_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/black_marquina/tovars/absolut_gres_black_marquina_103763_13330_300x0.jpg"),
    ],
  },
  {
    id: "34",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Blue jasto",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/blue_jasto/interiors/absolut_gres_blue_jasto_12693_205361_0x300.jpg"),
      require("../images/catalog/absolut_gres/blue_jasto/interiors/absolut_gres_blue_jasto_12693_205361_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/blue_jasto/tovars/absolut_gres_blue_jasto_103764_165574_300x0.jpg"),
    ],
  },
  {
    id: "35",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Blue jasto light",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/blue_jasto_light/interiors/absolut_gres_blue_jasto_light_12686_913650_0x300.jpg"),
      require("../images/catalog/absolut_gres/blue_jasto_light/interiors/absolut_gres_blue_jasto_light_12686_913650_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/blue_jasto_light/tovars/absolut_gres_blue_jasto_light_103757_711242_300x0.jpg"),
    ],
  },
  {
    id: "36",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Borgini gold",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/borgini_gold/interiors/absolut_gres_borgini_gold_12666_253571_0x300.jpg"),
      require("../images/catalog/absolut_gres/borgini_gold/interiors/absolut_gres_borgini_gold_12666_253571_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/borgini_gold/tovars/absolut_gres_borgini_gold_103737_955784_300x0.jpg"),
    ],
  },
  {
    id: "37",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Boston dark",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/boston_dark/interiors/absolut_gres_boston_dark_17723_416257_0x300.jpg"),
      require("../images/catalog/absolut_gres/boston_dark/interiors/absolut_gres_boston_dark_17723_725731_0x300.jpg"),
      require("../images/catalog/absolut_gres/boston_dark/interiors/absolut_gres_boston_dark_17723_813601_0x300.jpg"),
      require("../images/catalog/absolut_gres/boston_dark/interiors/absolut_gres_boston_dark_17723_895416_0x300.jpg"),
      require("../images/catalog/absolut_gres/boston_dark/interiors/absolut_gres_boston_dark_17723_895416_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/boston_dark/tovars/absolut_gres_boston_dark_124841_843575_300x0.jpg"),
    ],
  },
  {
    id: "38",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Boston light",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/boston_light/interiors/absolut_gres_boston_light_17724_260803_0x300.jpg"),
      require("../images/catalog/absolut_gres/boston_light/interiors/absolut_gres_boston_light_17724_520011_0x300.jpg"),
      require("../images/catalog/absolut_gres/boston_light/interiors/absolut_gres_boston_light_17724_641625_0x300.jpg"),
      require("../images/catalog/absolut_gres/boston_light/interiors/absolut_gres_boston_light_17724_752067_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/boston_light/tovars/absolut_gres_boston_light_124842_913845_300x0.jpg"),
    ],
  },
  {
    id: "41",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Breccia natural",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/breccia_natural/interiors/absolut_gres_breccia_natural_14576_829194_0x300.jpg"),
      require("../images/catalog/absolut_gres/breccia_natural/interiors/absolut_gres_breccia_natural_14576_829194_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/breccia_natural/tovars/absolut_gres_breccia_natural_109166_780263_300x0.jpg"),
      require("../images/catalog/absolut_gres/breccia_natural/tovars/absolut_gres_breccia_natural_109178_859514_300x0.jpg"),
    ],
  },
  {
    id: "42",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Breccia white",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/breccia_white/interiors/absolut_gres_breccia_white_15264_75999_0x300.jpg"),
      require("../images/catalog/absolut_gres/breccia_white/interiors/absolut_gres_breccia_white_15264_75999_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/breccia_white/tovars/absolut_gres_breccia_white_109167_346030_300x0.jpg"),
    ],
  },
  {
    id: "43",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Calacatta bronze",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/calacatta_bronze/interiors/absolut_gres_calacatta_bronze_13403_637778_0x300.jpg"),
      require("../images/catalog/absolut_gres/calacatta_bronze/interiors/absolut_gres_calacatta_bronze_13403_637778_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/calacatta_bronze/tovars/absolut_gres_calacatta_bronze_103724_606144_300x0.jpg"),
      require("../images/catalog/absolut_gres/calacatta_bronze/tovars/absolut_gres_calacatta_bronze_106807_24941_300x0.jpg"),
      require("../images/catalog/absolut_gres/calacatta_bronze/tovars/absolut_gres_calacatta_bronze_109157_155380_300x0.jpg"),
    ],
  },
  {
    id: "44",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Calacatta gold glazed",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/calacatta_gold_glazed/interiors/absolut_gres_calacatta_gold_glazed_12673_255910_0x300.jpg"),
      require("../images/catalog/absolut_gres/calacatta_gold_glazed/interiors/absolut_gres_calacatta_gold_glazed_12673_255910_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/calacatta_gold_glazed/tovars/absolut_gres_calacatta_gold_glazed_103744_605153_300x0.jpg"),
    ],
  },
  {
    id: "45",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Calacatta gold matt",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/calacatta_gold_matt/interiors/absolut_gres_calacatta_gold_matt_12675_415065_0x300.jpg"),
      require("../images/catalog/absolut_gres/calacatta_gold_matt/interiors/absolut_gres_calacatta_gold_matt_12675_415065_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/calacatta_gold_matt/tovars/absolut_gres_calacatta_gold_matt_103746_599427_300x0.jpg"),
    ],
  },
  {
    id: "46",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Calacatta grey glazed",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/calacatta_grey_glazed/interiors/absolut_gres_calacatta_grey_glazed_12672_170599_0x300.jpg"),
      require("../images/catalog/absolut_gres/calacatta_grey_glazed/interiors/absolut_gres_calacatta_grey_glazed_12672_170599_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/calacatta_grey_glazed/tovars/absolut_gres_calacatta_grey_glazed_103743_261084_300x0.jpg"),
    ],
  },
  {
    id: "47",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Calacatta grey matt",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/calacatta_grey_matt/interiors/absolut_gres_calacatta_grey_matt_12674_408089_0x300.jpg"),
      require("../images/catalog/absolut_gres/calacatta_grey_matt/interiors/absolut_gres_calacatta_grey_matt_12674_408089_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/calacatta_grey_matt/tovars/absolut_gres_calacatta_grey_matt_103745_694564_300x0.jpg"),
    ],
  },
  {
    id: "48",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Calacatta sun",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/calacatta_sun/interiors/absolut_gres_calacatta_sun_13404_831195_0x300.jpg"),
      require("../images/catalog/absolut_gres/calacatta_sun/interiors/absolut_gres_calacatta_sun_13404_831195_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/calacatta_sun/tovars/absolut_gres_calacatta_sun_106808_534181_300x0.jpg"),
      require("../images/catalog/absolut_gres/calacatta_sun/tovars/absolut_gres_calacatta_sun_109158_864063_300x0.jpg"),
      require("../images/catalog/absolut_gres/calacatta_sun/tovars/absolut_gres_calacatta_sun_115828_211307_300x0.jpg"),
    ],
  },
  {
    id: "49",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Canoli",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/canoli/interiors/absolut_gres_canoli_16463_581965_0x300.jpg"),
      require("../images/catalog/absolut_gres/canoli/interiors/absolut_gres_canoli_16463_581965_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/canoli/tovars/absolut_gres_canoli_120968_353205_300x0.jpg"),
      require("../images/catalog/absolut_gres/canoli/tovars/absolut_gres_canoli_120985_337963_300x0.jpg"),
    ],
  },
  {
    id: "50",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Carbon",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/carbon/interiors/absolut_gres_carbon_14590_810987_0x300.jpg"),
      require("../images/catalog/absolut_gres/carbon/interiors/absolut_gres_carbon_14590_810987_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/carbon/tovars/absolut_gres_carbon_112166_339881_300x0.jpg"),
    ],
  },
  {
    id: "51",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Carrara bianco",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/carrara_bianco/interiors/absolut_gres_carrara_bianco_14588_420105_0x300.jpg"),
      require("../images/catalog/absolut_gres/carrara_bianco/interiors/absolut_gres_carrara_bianco_14588_420105_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/carrara_bianco/tovars/absolut_gres_carrara_bianco_112164_164370_300x0.jpg"),
    ],
  },
  {
    id: "52",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Carrara classic",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/carrara_classic/interiors/absolut_gres_carrara_classic_12655_562792_0x300.jpg"),
      require("../images/catalog/absolut_gres/carrara_classic/interiors/absolut_gres_carrara_classic_12655_562792_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/carrara_classic/tovars/absolut_gres_carrara_classic_103725_621063_300x0.jpg"),
      require("../images/catalog/absolut_gres/carrara_classic/tovars/absolut_gres_carrara_classic_106815_686910_300x0.jpg"),
      require("../images/catalog/absolut_gres/carrara_classic/tovars/absolut_gres_carrara_classic_120790_684550_300x0.jpg"),
    ],
  },
  {
    id: "53",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Cemento dark",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/cemento_dark/interiors/absolut_gres_cemento_dark_17725_174489_0x300.jpg"),
      require("../images/catalog/absolut_gres/cemento_dark/interiors/absolut_gres_cemento_dark_17725_388573_0x300.jpg"),
      require("../images/catalog/absolut_gres/cemento_dark/interiors/absolut_gres_cemento_dark_17725_388573_0x500.jpg"),
      require("../images/catalog/absolut_gres/cemento_dark/interiors/absolut_gres_cemento_dark_17725_475631_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/cemento_dark/tovars/absolut_gres_cemento_dark_124843_54007_300x0.jpg"),
    ],
  },
  {
    id: "54",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Cemento light",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/cemento_light/interiors/absolut_gres_cemento_light_17726_225002_0x300.jpg"),
      require("../images/catalog/absolut_gres/cemento_light/interiors/absolut_gres_cemento_light_17726_225002_0x500.jpg"),
      require("../images/catalog/absolut_gres/cemento_light/interiors/absolut_gres_cemento_light_17726_453453_0x300.jpg"),
      require("../images/catalog/absolut_gres/cemento_light/interiors/absolut_gres_cemento_light_17726_493570_0x300.jpg"),
      require("../images/catalog/absolut_gres/cemento_light/interiors/absolut_gres_cemento_light_17726_820162_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/cemento_light/tovars/absolut_gres_cemento_light_124844_71468_300x0.jpg"),
    ],
  },
  {
    id: "55",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Cloudy beige",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/cloudy_beige/interiors/absolut_gres_cloudy_beige_12662_781040_0x300.jpg"),
      require("../images/catalog/absolut_gres/cloudy_beige/interiors/absolut_gres_cloudy_beige_12662_781040_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/cloudy_beige/tovars/absolut_gres_cloudy_beige_103732_462156_300x0.jpg"),
    ],
  },
  {
    id: "56",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Cloudy rosa",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/cloudy_rosa/interiors/absolut_gres_cloudy_rosa_12663_851273_0x300.jpg"),
      require("../images/catalog/absolut_gres/cloudy_rosa/interiors/absolut_gres_cloudy_rosa_12663_851273_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/cloudy_rosa/tovars/absolut_gres_cloudy_rosa_103733_938187_300x0.jpg"),
    ],
  },
  {
    id: "57",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Colonial_bianco",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/colonial_bianco/interiors/absolut_gres_colonial_bianco_16475_898352_0x300.jpg"),
      require("../images/catalog/absolut_gres/colonial_bianco/interiors/absolut_gres_colonial_bianco_16475_898352_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/colonial_bianco/tovars/absolut_gres_colonial_bianco_120975_461208_300x0.jpg"),
      require("../images/catalog/absolut_gres/colonial_bianco/tovars/absolut_gres_colonial_bianco_120980_137150_300x0.jpg"),
    ],
  },
  {
    id: "58",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Diana_beige",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/diana_beige/interiors/absolut_gres_diana_beige_12642_393667_0x300.jpg"),
      require("../images/catalog/absolut_gres/diana_beige/interiors/absolut_gres_diana_beige_12642_393667_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/diana_beige/tovars/absolut_gres_diana_beige_103706_136798_300x0.jpg"),
    ],
  },
  {
    id: "59",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Docato_pine",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/docato_pine/interiors/absolut_gres_docato_pine_12695_740119_0x300.jpg"),
      require("../images/catalog/absolut_gres/docato_pine/interiors/absolut_gres_docato_pine_12695_740119_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/docato_pine/tovars/absolut_gres_docato_pine_103770_916853_300x0.jpg"),
    ],
  },
  {
    id: "60",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Dove_onix",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/dove_onix/interiors/absolut_gres_dove_onix_12658_123849_0x300.jpg"),
      require("../images/catalog/absolut_gres/dove_onix/interiors/absolut_gres_dove_onix_12658_351732_0x300.jpg"),
      require("../images/catalog/absolut_gres/dove_onix/interiors/absolut_gres_dove_onix_12658_351732_0x500.jpg"),
      require("../images/catalog/absolut_gres/dove_onix/interiors/absolut_gres_dove_onix_12658_415935_0x300.jpg"),
      require("../images/catalog/absolut_gres/dove_onix/interiors/absolut_gres_dove_onix_12658_550053_0x300.jpg"),
      require("../images/catalog/absolut_gres/dove_onix/interiors/absolut_gres_dove_onix_12658_578425_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/dove_onix/tovars/absolut_gres_dove_onix_103728_372812_300x0.jpg"),
      require("../images/catalog/absolut_gres/dove_onix/tovars/absolut_gres_dove_onix_120960_304754_300x0.jpg"),
    ],
  },
  {
    id: "62",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Downtown",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/downtown/interiors/absolut_gres_downtown_15269_414956_0x300.jpg"),
      require("../images/catalog/absolut_gres/downtown/interiors/absolut_gres_downtown_15269_414956_0x500.jpg"),
      require("../images/catalog/absolut_gres/downtown/interiors/absolut_gres_downtown_15269_663296_0x300.jpg"),
      require("../images/catalog/absolut_gres/downtown/interiors/absolut_gres_downtown_15269_78833_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/downtown/tovars/absolut_gres_downtown_112183_401140_300x0.jpg"),
    ],
  },
  {
    id: "61",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Forest_essenze",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/forest_essenze/interiors/absolut_gres_forest_essenze_17012_214762_0x300.jpg"),
      require("../images/catalog/absolut_gres/forest_essenze/interiors/absolut_gres_forest_essenze_17012_214762_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/forest_essenze/tovars/absolut_gres_forest_essenze_122776_843311_300x0.jpg"),
      require("../images/catalog/absolut_gres/forest_essenze/tovars/absolut_gres_forest_essenze_122777_465289_300x0.jpg"),
    ],
  },
  {
    id: "63",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Gia",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/gia/interiors/absolut_gres_gia_16462_253634_0x300.jpg"),
      require("../images/catalog/absolut_gres/gia/interiors/absolut_gres_gia_16462_253634_0x500.jpg"),
      require("../images/catalog/absolut_gres/gia/interiors/absolut_gres_gia_16462_326939_0x300.jpg"),
      require("../images/catalog/absolut_gres/gia/interiors/absolut_gres_gia_16462_34761_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/gia/tovars/absolut_gres_gia_120969_134679_300x0.jpg"),
      require("../images/catalog/absolut_gres/gia/tovars/absolut_gres_gia_120988_363107_300x0.jpg"),
    ],
  },
  {
    id: "64",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Gloria",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/gloria/interiors/absolut_gres_gloria_15272_244623_0x300.jpg"),
      require("../images/catalog/absolut_gres/gloria/interiors/absolut_gres_gloria_15272_24943_0x300.jpg"),
      require("../images/catalog/absolut_gres/gloria/interiors/absolut_gres_gloria_15272_24943_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/gloria/tovars/absolut_gres_gloria_112187_998571_300x0.jpg"),
    ],
  },
  {
    id: "65",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Gold_statuario",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/gold_statuario/interiors/absolut_gres_gold_statuario_12668_619172_0x300.jpg"),
      require("../images/catalog/absolut_gres/gold_statuario/interiors/absolut_gres_gold_statuario_12668_619172_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/gold_statuario/tovars/absolut_gres_gold_statuario_103739_942655_300x0.jpg"),
    ],
  },
  {
    id: "66",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Grapfit_black",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/grapfit_black/interiors/absolut_gres_grapfit_black_12708_437839_0x300.jpg"),
      require("../images/catalog/absolut_gres/grapfit_black/interiors/absolut_gres_grapfit_black_12708_437839_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/grapfit_black/tovars/absolut_gres_grapfit_black_103783_590197_300x0.jpg"),
    ],
  },
  {
    id: "67",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Grapfit_white",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/grapfit_white/interiors/absolut_gres_grapfit_white_12707_996134_0x300.jpg"),
      require("../images/catalog/absolut_gres/grapfit_white/interiors/absolut_gres_grapfit_white_12707_996134_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/grapfit_white/tovars/absolut_gres_grapfit_white_103782_720407_300x0.jpg"),
    ],
  },
  {
    id: "68",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Italy_brown",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/italy_brown/interiors/absolut_gres_italy_brown_12702_956819_0x300.jpg"),
      require("../images/catalog/absolut_gres/italy_brown/interiors/absolut_gres_italy_brown_12702_956819_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/italy_brown/tovars/absolut_gres_italy_brown_103777_704295_300x0.jpg"),
    ],
  },
  {
    id: "70",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Italy_choco",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/italy_choco/interiors/absolut_gres_italy_choco_12701_364574_0x300.jpg"),
      require("../images/catalog/absolut_gres/italy_choco/interiors/absolut_gres_italy_choco_12701_364574_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/italy_choco/tovars/absolut_gres_italy_choco_103776_604590_300x0.jpg"),
    ],
  },
  {
    id: "71",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Italy_gris",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/italy_gris/interiors/absolut_gres_italy_gris_12699_141042_0x300.jpg"),
      require("../images/catalog/absolut_gres/italy_gris/interiors/absolut_gres_italy_gris_12699_141042_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/italy_gris/tovars/absolut_gres_italy_gris_103774_19730_300x0.jpg"),
    ],
  },
  {
    id: "72",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Italy_natural",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/italy_natural/interiors/absolut_gres_italy_natural_12700_731448_0x300.jpg"),
      require("../images/catalog/absolut_gres/italy_natural/interiors/absolut_gres_italy_natural_12700_731448_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/italy_natural/tovars/absolut_gres_italy_natural_103775_964749_300x0.jpg"),
    ],
  },
  {
    id: "73",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Juno_dark_grey",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/juno_dark_grey/interiors/absolut_gres_juno_dark_grey_15266_717276_0x300.jpg"),
      require("../images/catalog/absolut_gres/juno_dark_grey/interiors/absolut_gres_juno_dark_grey_15266_852498_0x300.jpg"),
      require("../images/catalog/absolut_gres/juno_dark_grey/interiors/absolut_gres_juno_dark_grey_15266_852498_0x500.jpg"),
      require("../images/catalog/absolut_gres/juno_dark_grey/interiors/absolut_gres_juno_dark_grey_15266_971996_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/juno_dark_grey/tovars/absolut_gres_juno_dark_grey_112180_407520_300x0.jpg"),
    ],
  },
  {
    id: "74",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Juno_light_grey",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/juno_light_grey/interiors/absolut_gres_juno_light_grey_15267_685382_0x300.jpg"),
      require("../images/catalog/absolut_gres/juno_light_grey/interiors/absolut_gres_juno_light_grey_15267_716151_0x300.jpg"),
      require("../images/catalog/absolut_gres/juno_light_grey/interiors/absolut_gres_juno_light_grey_15267_797083_0x300.jpg"),
      require("../images/catalog/absolut_gres/juno_light_grey/interiors/absolut_gres_juno_light_grey_15267_797083_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/juno_light_grey/tovars/absolut_gres_juno_light_grey_112181_69557_300x0.jpg"),
    ],
  },
  {
    id: "75",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Lipe_gris",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/lipe_gris/interiors/absolut_gres_lipe_gris_12696_246241_0x300.jpg"),
      require("../images/catalog/absolut_gres/lipe_gris/interiors/absolut_gres_lipe_gris_12696_246241_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/lipe_gris/tovars/absolut_gres_lipe_gris_103771_975928_300x0.jpg"),
    ],
  },
  {
    id: "76",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Marbo_crema",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/marbo_crema/interiors/absolut_gres_marbo_crema_12665_423296_0x300.jpg"),
      require("../images/catalog/absolut_gres/marbo_crema/interiors/absolut_gres_marbo_crema_12665_423296_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/marbo_crema/tovars/absolut_gres_marbo_crema_103736_829490_300x0.jpg"),
    ],
  },
  {
    id: "77",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Modena_white",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/modena_white/interiors/absolut_gres_modena_white_15271_469488_0x300.jpg"),
      require("../images/catalog/absolut_gres/modena_white/interiors/absolut_gres_modena_white_15271_743720_0x300.jpg"),
      require("../images/catalog/absolut_gres/modena_white/interiors/absolut_gres_modena_white_15271_743720_0x500.jpg"),
      require("../images/catalog/absolut_gres/modena_white/interiors/absolut_gres_modena_white_15271_990638_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/modena_white/tovars/absolut_gres_modena_white_112186_764276_300x0.jpg"),
    ],
  },
  {
    id: "78",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Montana_light_grey",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/montana_light_grey/interiors/absolut_gres_montana_light_grey_15275_22414_0x300.jpg"),
      require("../images/catalog/absolut_gres/montana_light_grey/interiors/absolut_gres_montana_light_grey_15275_523379_0x300.jpg"),
      require("../images/catalog/absolut_gres/montana_light_grey/interiors/absolut_gres_montana_light_grey_15275_523379_0x500.jpg"),
      require("../images/catalog/absolut_gres/montana_light_grey/interiors/absolut_gres_montana_light_grey_15275_728935_0x300.jpg"),
      require("../images/catalog/absolut_gres/montana_light_grey/interiors/absolut_gres_montana_light_grey_15275_830767_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/montana_light_grey/tovars/absolut_gres_montana_light_grey_112190_842173_300x0.jpg"),
    ],
  },
  {
    id: "79",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Monte_grey",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/monte_grey/interiors/absolut_gres_monte_grey_15268_257065_0x300.jpg"),
      require("../images/catalog/absolut_gres/monte_grey/interiors/absolut_gres_monte_grey_15268_329249_0x300.jpg"),
      require("../images/catalog/absolut_gres/monte_grey/interiors/absolut_gres_monte_grey_15268_329249_0x500.jpg"),
      require("../images/catalog/absolut_gres/monte_grey/interiors/absolut_gres_monte_grey_15268_615843_0x300.jpg"),
      require("../images/catalog/absolut_gres/monte_grey/interiors/absolut_gres_monte_grey_15268_747891_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/monte_grey/tovars/absolut_gres_monte_grey_112182_285782_300x0.jpg"),
    ],
  },
  {
    id: "80",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Narzo_crema",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/narzo_crema/interiors/absolut_gres_narzo_crema_12703_903767_0x300.jpg"),
      require("../images/catalog/absolut_gres/narzo_crema/interiors/absolut_gres_narzo_crema_12703_903767_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/narzo_crema/tovars/absolut_gres_narzo_crema_103778_564177_300x0.jpg"),
    ],
  },
  {
    id: "81",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Novaya_kollekciya",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/novaya_kollekciya__17722/interiors/absolut_gres_novaya_kollekciya__17722_17722_345892_0x300.jpg"),
      require("../images/catalog/absolut_gres/novaya_kollekciya__17722/interiors/absolut_gres_novaya_kollekciya__17722_17722_543689_0x300.jpg"),
      require("../images/catalog/absolut_gres/novaya_kollekciya__17722/interiors/absolut_gres_novaya_kollekciya__17722_17722_543689_0x500.jpg"),
      require("../images/catalog/absolut_gres/novaya_kollekciya__17722/interiors/absolut_gres_novaya_kollekciya__17722_17722_612931_0x300.jpg"),
    ],
  },
  {
    id: "82",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Portland",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/portland/interiors/absolut_gres_portland_15276_581433_0x300.jpg"),
      require("../images/catalog/absolut_gres/portland/interiors/absolut_gres_portland_15276_599286_0x300.jpg"),
      require("../images/catalog/absolut_gres/portland/interiors/absolut_gres_portland_15276_599286_0x500.jpg"),
      require("../images/catalog/absolut_gres/portland/interiors/absolut_gres_portland_15276_833718_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/portland/tovars/absolut_gres_portland_112191_109038_300x0.jpg"),
    ],
  },
  {
    id: "83",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Regal carara",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/regal carara/interiors/absolut_gres_regal_carara_12643_467208.jpg"),
      require("../images/catalog/absolut_gres/regal carara/interiors/absolut_gres_regal_carara_12643_613765.jpg"),
      require("../images/catalog/absolut_gres/regal carara/interiors/absolut_gres_regal_carara_12643_692927.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/regal carara/tovars/absolut_gres_regal_carara_103707_305500_500x0.jpg"),
      require("../images/catalog/absolut_gres/regal carara/tovars/absolut_gres_regal_carara_120955_418299_500x0.jpg"),
    ],
  },
  {
    id: "84",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Royal_brown",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/royal_brown/interiors/absolut_gres_royal_brown_12697_848494_0x300.jpg"),
      require("../images/catalog/absolut_gres/royal_brown/interiors/absolut_gres_royal_brown_12697_848494_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/royal_brown/tovars/absolut_gres_royal_brown_103772_713664_300x0.jpg"),
    ],
  },
  {
    id: "85",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Sena_grey",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/sena_grey/interiors/absolut_gres_sena_grey_15274_55275_0x300.jpg"),
      require("../images/catalog/absolut_gres/sena_grey/interiors/absolut_gres_sena_grey_15274_910662_0x300.jpg"),
      require("../images/catalog/absolut_gres/sena_grey/interiors/absolut_gres_sena_grey_15274_910662_0x500.jpg"),
      require("../images/catalog/absolut_gres/sena_grey/interiors/absolut_gres_sena_grey_15274_946414_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/sena_grey/tovars/absolut_gres_sena_grey_112189_258266_300x0.jpg"),
    ],
  },
  {
    id: "86",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Sahara Beige",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/sahara beige/interiors/absolut_gres_sahara_beige_12664_267257.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/sahara beige/tovars/absolut_gres_sahara_beige_103734_805493_500x0.jpg"),
      require("../images/catalog/absolut_gres/sahara beige/tovars/absolut_gres_sahara_beige_103735_942382_500x0.jpg"),
    ],
  },
  {
    id: "87",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "Urban_wood",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/urban_wood/interiors/absolut_gres_urban_wood_17013_803007_0x300.jpg"),
      require("../images/catalog/absolut_gres/urban_wood/interiors/absolut_gres_urban_wood_17013_803007_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/urban_wood/tovars/absolut_gres_urban_wood_122793_329576_300x0.jpg"),
    ],
  },
  {
    id: "88",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "wenge_cinnamon",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/wenge_cinnamon/interiors/absolut_gres_wenge_cinnamon_12698_155184_0x300.jpg"),
      require("../images/catalog/absolut_gres/wenge_cinnamon/interiors/absolut_gres_wenge_cinnamon_12698_155184_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/wenge_cinnamon/tovars/absolut_gres_wenge_cinnamon_103773_999436_300x0.jpg"),
    ],
  },
  {
    id: "89",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "wildwood",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/wildwood/interiors/absolut_gres_wildwood_17014_612136_0x300.jpg"),
      require("../images/catalog/absolut_gres/wildwood/interiors/absolut_gres_wildwood_17014_612136_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/wildwood/tovars/absolut_gres_wildwood_122778_957155_300x0.jpg"),
      require("../images/catalog/absolut_gres/wildwood/tovars/absolut_gres_wildwood_122779_839087_300x0.jpg"),
      require("../images/catalog/absolut_gres/wildwood/tovars/absolut_gres_wildwood_122780_149612_300x0.jpg"),
      require("../images/catalog/absolut_gres/wildwood/tovars/absolut_gres_wildwood_122781_728068_300x0.jpg"),
      require("../images/catalog/absolut_gres/wildwood/tovars/absolut_gres_wildwood_122782_407049_300x0.jpg"),
      require("../images/catalog/absolut_gres/wildwood/tovars/absolut_gres_wildwood_122783_389379_300x0.jpg"),
      require("../images/catalog/absolut_gres/wildwood/tovars/absolut_gres_wildwood_122784_862462_300x0.jpg"),
      require("../images/catalog/absolut_gres/wildwood/tovars/absolut_gres_wildwood_122785_505436_300x0.jpg"),
    ],
  },
  {
    id: "90",
    Сountry: "Индия",
    Name: "Absolut Gres",
    Collection: "woodstyle",
    Category: "Keramogranit",
    Size: "60x120",
    interiors: [
      require("../images/catalog/absolut_gres/woodstyle/interiors/absolut_gres_woodstyle_17015_427727_0x300.jpg"),
      require("../images/catalog/absolut_gres/woodstyle/interiors/absolut_gres_woodstyle_17015_427727_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/absolut_gres/woodstyle/tovars/absolut_gres_woodstyle_122786_42617_300x0.jpg"),
      require("../images/catalog/absolut_gres/woodstyle/tovars/absolut_gres_woodstyle_122787_256183_300x0.jpg"),
      require("../images/catalog/absolut_gres/woodstyle/tovars/absolut_gres_woodstyle_122788_394909_300x0.jpg"),
      require("../images/catalog/absolut_gres/woodstyle/tovars/absolut_gres_woodstyle_122789_163987_300x0.jpg"),
      require("../images/catalog/absolut_gres/woodstyle/tovars/absolut_gres_woodstyle_122790_737775_300x0.jpg"),
      require("../images/catalog/absolut_gres/woodstyle/tovars/absolut_gres_woodstyle_122791_884789_300x0.jpg"),
      require("../images/catalog/absolut_gres/woodstyle/tovars/absolut_gres_woodstyle_122792_287149_300x0.jpg"),
    ],
  },
  {
    id: "91",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24.9x50", "41.8x41.8"],
    Name: "Alma Ceramica",
    Collection: "Adelia",
    interiors: [
      require("../images/catalog/alma_ceramica/adelia/interiors/alma_ceramica_adelia_8556_915652_0x300.jpg"),
      require("../images/catalog/alma_ceramica/adelia/interiors/alma_ceramica_adelia_8556_915652_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/adelia/tovars/alma_ceramica_adelia_73575_247182_300x0.jpg"),
      require("../images/catalog/alma_ceramica/adelia/tovars/alma_ceramica_adelia_73576_969005_300x0.jpg"),
      require("../images/catalog/alma_ceramica/adelia/tovars/alma_ceramica_adelia_73577_265060_300x0.jpg"),
      require("../images/catalog/alma_ceramica/adelia/tovars/alma_ceramica_adelia_73578_469343_300x0.jpg"),
      require("../images/catalog/alma_ceramica/adelia/tovars/alma_ceramica_adelia_73579_483180_300x0.jpg"),
    ],
  },
  {
    id: "92",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41.8x41.8"],
    Name: "Alma Ceramica",
    Collection: "Ailand",
    interiors: [
      require("../images/catalog/alma_ceramica/ailand/interiors/alma_ceramica_ailand_4587_817647_0x300.jpg"),
      require("../images/catalog/alma_ceramica/ailand/interiors/alma_ceramica_ailand_4587_817647_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33579_421382_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33580_886889_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33582_40848_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33583_396720_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33584_754822_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33585_745191_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33586_250793_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33587_222054_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33588_698441_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33589_198886_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33590_152109_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ailand/tovars/alma_ceramica_ailand_33591_254170_300x0.jpg"),
    ],
  },
  {
    id: "93",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41.8x41.8"],
    Name: "Alma Ceramica",
    Collection: "Alaris",
    interiors: [
      require("../images/catalog/alma_ceramica/alaris/interiors/alma_ceramica_alaris_10227_929177_0x300.jpg"),
      require("../images/catalog/alma_ceramica/alaris/interiors/alma_ceramica_alaris_10227_929177_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/alaris/tovars/alma_ceramica_alaris_87052_281690_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alaris/tovars/alma_ceramica_alaris_87053_523490_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alaris/tovars/alma_ceramica_alaris_87054_310252_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alaris/tovars/alma_ceramica_alaris_87055_10782_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alaris/tovars/alma_ceramica_alaris_87057_367171_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alaris/tovars/alma_ceramica_alaris_87058_789081_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alaris/tovars/alma_ceramica_alaris_87059_751637_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alaris/tovars/alma_ceramica_alaris_87060_943723_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alaris/tovars/alma_ceramica_alaris_87061_206133_300x0.jpg"),
    ],
  },
  {
    id: "94",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Allurе",
    interiors: [
      require("../images/catalog/alma_ceramica/allure/interiors/alma_ceramica_allure_11673_818109_0x300.jpg"),
      require("../images/catalog/alma_ceramica/allure/interiors/alma_ceramica_allure_11673_818109_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/allure/tovars/alma_ceramica_allure_96092_131216_300x0.jpg"),
      require("../images/catalog/alma_ceramica/allure/tovars/alma_ceramica_allure_96093_976318_300x0.jpg"),
      require("../images/catalog/alma_ceramica/allure/tovars/alma_ceramica_allure_96094_681047_300x0.jpg"),
      require("../images/catalog/alma_ceramica/allure/tovars/alma_ceramica_allure_96095_201941_300x0.jpg"),
    ],
  },
  {
    id: "95",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41.8x41.8"],
    Name: "Alma Ceramica",
    Collection: "Almond",
    interiors: [
      require("../images/catalog/alma_ceramica/almond/interiors/alma_ceramica_almond_14686_197392_0x300.jpg"),
      require("../images/catalog/alma_ceramica/almond/interiors/alma_ceramica_almond_14686_560997_0x300.jpg"),
      require("../images/catalog/alma_ceramica/almond/interiors/alma_ceramica_almond_14686_578217_0x300.jpg"),
      require("../images/catalog/alma_ceramica/almond/interiors/alma_ceramica_almond_14686_578217_0x500.jpg"),
      require("../images/catalog/alma_ceramica/almond/interiors/alma_ceramica_almond_14686_647939_0x300.jpg"),
      require("../images/catalog/alma_ceramica/almond/interiors/alma_ceramica_almond_14686_830498_0x300.jpg"),
      require("../images/catalog/alma_ceramica/almond/interiors/alma_ceramica_almond_14686_919417_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/almond/tovars/alma_ceramica_almond_112419_817892_300x0.jpg"),
      require("../images/catalog/alma_ceramica/almond/tovars/alma_ceramica_almond_112420_618598_300x0.jpg"),
      require("../images/catalog/alma_ceramica/almond/tovars/alma_ceramica_almond_112421_512549_300x0.jpg"),
      require("../images/catalog/alma_ceramica/almond/tovars/alma_ceramica_almond_112422_272077_300x0.jpg"),
      require("../images/catalog/alma_ceramica/almond/tovars/alma_ceramica_almond_112423_648774_300x0.jpg"),
      require("../images/catalog/alma_ceramica/almond/tovars/alma_ceramica_almond_112424_466618_300x0.jpg"),
    ],
  },
  {
    id: "96",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Alpina",
    interiors: [
      require("../images/catalog/alma_ceramica/alpina/interiors/alma_ceramica_alpina_14687_51573_0x300.jpg"),
      require("../images/catalog/alma_ceramica/alpina/interiors/alma_ceramica_alpina_14687_692512_0x300.jpg"),
      require("../images/catalog/alma_ceramica/alpina/interiors/alma_ceramica_alpina_14687_692512_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/alpina/tovars/alma_ceramica_alpina_112425_869610_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alpina/tovars/alma_ceramica_alpina_112426_334394_300x0.jpg"),
    ],
  },
  {
    id: "97",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41.8x41.8"],
    Name: "Alma Ceramica",
    Collection: "Alta",
    interiors: [
      require("../images/catalog/alma_ceramica/alta/interiors/alma_ceramica_alta_5066_496228_0x300.jpg"),
      require("../images/catalog/alma_ceramica/alta/interiors/alma_ceramica_alta_5066_496228_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/alta/tovars/alma_ceramica_alta_38413_462182_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alta/tovars/alma_ceramica_alta_38415_671486_300x0.jpg"),
    ],
  },
  {
    id: "98",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41.8x41.8"],
    Name: "Alma Ceramica",
    Collection: "Alva",
    interiors: [
      require("../images/catalog/alma_ceramica/alva/interiors/alma_ceramica_alva_10228_16963_0x300.jpg"),
      require("../images/catalog/alma_ceramica/alva/interiors/alma_ceramica_alva_10228_16963_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/alva/tovars/alma_ceramica_alva_87062_49237_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alva/tovars/alma_ceramica_alva_87063_21052_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alva/tovars/alma_ceramica_alva_87064_570827_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alva/tovars/alma_ceramica_alva_87065_640249_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alva/tovars/alma_ceramica_alva_87066_831353_300x0.jpg"),
      require("../images/catalog/alma_ceramica/alva/tovars/alma_ceramica_alva_87067_896938_300x0.jpg"),
    ],
  },
  {
    id: "99",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Amsterdam",
    interiors: [
      require("../images/catalog/alma_ceramica/amsterdam/interiors/alma_ceramica_amsterdam_15692_222991_0x300.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/interiors/alma_ceramica_amsterdam_15692_259964_0x300.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/interiors/alma_ceramica_amsterdam_15692_259964_0x500.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/interiors/alma_ceramica_amsterdam_15692_363337_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_116537_223492_300x0.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_116538_323468_300x0.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_116539_226845_300x0.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_116540_42522_300x0.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_116541_228495_300x0.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_118185_185706_300x0.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_118186_724678_300x0.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_118187_734025_300x0.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_118188_376681_300x0.jpg"),
      require("../images/catalog/alma_ceramica/amsterdam/tovars/alma_ceramica_amsterdam_118189_753042_300x0.jpg"),
    ],
  },
  {
    id: "100",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Andora",
    interiors: [
      require("../images/catalog/alma_ceramica/andora/interiors/alma_ceramica_andora_14688_133242_0x300.jpg"),
      require("../images/catalog/alma_ceramica/andora/interiors/alma_ceramica_andora_14688_243223_0x300.jpg"),
      require("../images/catalog/alma_ceramica/andora/interiors/alma_ceramica_andora_14688_243223_0x500.jpg"),
      require("../images/catalog/alma_ceramica/andora/interiors/alma_ceramica_andora_14688_516222_0x300.jpg"),
      require("../images/catalog/alma_ceramica/andora/interiors/alma_ceramica_andora_14688_765122_0x300.jpg"),
      require("../images/catalog/alma_ceramica/andora/interiors/alma_ceramica_andora_14688_872949_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/andora/tovars/alma_ceramica_andora_112427_833281_300x0.jpg"),
      require("../images/catalog/alma_ceramica/andora/tovars/alma_ceramica_andora_112428_713464_300x0.jpg"),
      require("../images/catalog/alma_ceramica/andora/tovars/alma_ceramica_andora_112431_492347_300x0.jpg"),
    ],
  },
  {
    id: "101",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Angara",
    interiors: [
      require("../images/catalog/alma_ceramica/angara/interiors/alma_ceramica_angara_15693_572325_0x300.jpg"),
      require("../images/catalog/alma_ceramica/angara/interiors/alma_ceramica_angara_15693_622561_0x300.jpg"),
      require("../images/catalog/alma_ceramica/angara/interiors/alma_ceramica_angara_15693_732752_0x300.jpg"),
      require("../images/catalog/alma_ceramica/angara/interiors/alma_ceramica_angara_15693_732752_0x500.jpg"),
      require("../images/catalog/alma_ceramica/angara/interiors/alma_ceramica_angara_15693_82557_0x300.jpg"),
      require("../images/catalog/alma_ceramica/angara/interiors/alma_ceramica_angara_15693_931124_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/angara/tovars/alma_ceramica_angara_116548_386539_300x0.jpg"),
      require("../images/catalog/alma_ceramica/angara/tovars/alma_ceramica_angara_116550_848594_300x0.jpg"),
      require("../images/catalog/alma_ceramica/angara/tovars/alma_ceramica_angara_116551_978587_300x0.jpg"),
      require("../images/catalog/alma_ceramica/angara/tovars/alma_ceramica_angara_116552_595368_300x0.jpg"),
      require("../images/catalog/alma_ceramica/angara/tovars/alma_ceramica_angara_116553_18872_300x0.jpg"),
      require("../images/catalog/alma_ceramica/angara/tovars/alma_ceramica_angara_116886_614872_300x0.jpg"),
    ],
  },
  {
    id: "102",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Antik",
    interiors: [
      require("../images/catalog/alma_ceramica/antik/interiors/alma_ceramica_antik_9509_901127_0x300.jpg"),
      require("../images/catalog/alma_ceramica/antik/interiors/alma_ceramica_antik_9509_901127_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/antik/tovars/alma_ceramica_antik_82317_138728_300x0.jpg"),
    ],
  },
  {
    id: "103",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Arabesco",
    interiors: [
      require("../images/catalog/alma_ceramica/arabesco/interiors/alma_ceramica_arabesco_13506_434957_0x300.jpg"),
      require("../images/catalog/alma_ceramica/arabesco/interiors/alma_ceramica_arabesco_13506_434957_0x500.jpg"),
      require("../images/catalog/alma_ceramica/arabesco/interiors/alma_ceramica_arabesco_13506_504863_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/arabesco/tovars/alma_ceramica_arabesco_107328_260831_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arabesco/tovars/alma_ceramica_arabesco_115792_111219_300x0.jpg"),
    ],
  },
  {
    id: "104",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Arabica",
    interiors: [
      require("../images/catalog/alma_ceramica/arabica/interiors/alma_ceramica_arabica_4601_778747_0x300.jpg"),
      require("../images/catalog/alma_ceramica/arabica/interiors/alma_ceramica_arabica_4601_778747_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/arabica/tovars/alma_ceramica_arabica_33729_327589_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arabica/tovars/alma_ceramica_arabica_33730_569656_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arabica/tovars/alma_ceramica_arabica_33731_513342_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arabica/tovars/alma_ceramica_arabica_33732_358438_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arabica/tovars/alma_ceramica_arabica_33733_956378_300x0.jpg"),
    ],
  },
  {
    id: "105",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "60x60", "57x57"],
    Name: "Alma Ceramica",
    Collection: "Arcadia",
    interiors: [
      require("../images/catalog/alma_ceramica/arcadia/interiors/alma_ceramica_arcadia_11674_233328_0x300.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/interiors/alma_ceramica_arcadia_11674_233328_0x500.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/interiors/alma_ceramica_arcadia_11674_279928_0x300.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/interiors/alma_ceramica_arcadia_11674_35126_0x300.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/interiors/alma_ceramica_arcadia_11674_986301_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/arcadia/tovars/alma_ceramica_arcadia_107329_592875_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/tovars/alma_ceramica_arcadia_107330_538971_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/tovars/alma_ceramica_arcadia_115793_954369_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/tovars/alma_ceramica_arcadia_118609_143545_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/tovars/alma_ceramica_arcadia_118610_484079_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/tovars/alma_ceramica_arcadia_96100_247185_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/tovars/alma_ceramica_arcadia_96101_884865_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/tovars/alma_ceramica_arcadia_96102_376131_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arcadia/tovars/alma_ceramica_arcadia_96103_226202_300x0.jpg"),
    ],
  },
  {
    id: "106",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "20x90"],
    Name: "Alma Ceramica",
    Collection: "Arteo",
    interiors: [
      require("../images/catalog/alma_ceramica/arteo/interiors/alma_ceramica_arteo_15311_543102_0x300.jpg"),
      require("../images/catalog/alma_ceramica/arteo/interiors/alma_ceramica_arteo_15311_543102_0x500.jpg"),
      require("../images/catalog/alma_ceramica/arteo/interiors/alma_ceramica_arteo_15311_811151_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/arteo/tovars/alma_ceramica_arteo_115054_991091_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arteo/tovars/alma_ceramica_arteo_115055_681644_300x0.jpg"),
      require("../images/catalog/alma_ceramica/arteo/tovars/alma_ceramica_arteo_115056_396504_300x0.jpg"),
    ],
  },
  {
    id: "107",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Ashton",
    interiors: [
      require("../images/catalog/alma_ceramica/ashton/interiors/alma_ceramica_ashton_11620_227518_0x300.jpg"),
      require("../images/catalog/alma_ceramica/ashton/interiors/alma_ceramica_ashton_11620_570110_0x300.jpg"),
      require("../images/catalog/alma_ceramica/ashton/interiors/alma_ceramica_ashton_11620_570110_0x500.jpg"),
      require("../images/catalog/alma_ceramica/ashton/interiors/alma_ceramica_ashton_11620_655412_0x300.jpg"),
      require("../images/catalog/alma_ceramica/ashton/interiors/alma_ceramica_ashton_11620_718560_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/ashton/tovars/alma_ceramica_ashton_95618_638988_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ashton/tovars/alma_ceramica_ashton_95619_863765_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ashton/tovars/alma_ceramica_ashton_95620_705989_300x0.jpg"),
      require("../images/catalog/alma_ceramica/ashton/tovars/alma_ceramica_ashton_95621_12227_300x0.jpg"),
    ],
  },
  {
    id: "108",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Asteria",
    interiors: [
      require("../images/catalog/alma_ceramica/asteria/interiors/alma_ceramica_asteria_6487_345606_0x300.jpg"),
      require("../images/catalog/alma_ceramica/asteria/interiors/alma_ceramica_asteria_6487_345606_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/asteria/tovars/alma_ceramica_asteria_53593_706975_300x0.jpg"),
      require("../images/catalog/alma_ceramica/asteria/tovars/alma_ceramica_asteria_53594_597187_300x0.jpg"),
      require("../images/catalog/alma_ceramica/asteria/tovars/alma_ceramica_asteria_53595_271147_300x0.jpg"),
      require("../images/catalog/alma_ceramica/asteria/tovars/alma_ceramica_asteria_53598_25083_300x0.jpg"),
    ],
  },
  {
    id: "109",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Aveiro",
    interiors: [
      require("../images/catalog/alma_ceramica/aveiro/interiors/alma_ceramica_aveiro_8562_790200_0x300.jpg"),
      require("../images/catalog/alma_ceramica/aveiro/interiors/alma_ceramica_aveiro_8562_790200_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/aveiro/tovars/alma_ceramica_aveiro_73597_273227_300x0.jpg"),
      require("../images/catalog/alma_ceramica/aveiro/tovars/alma_ceramica_aveiro_73598_825960_300x0.jpg"),
    ],
  },
  {
    id: "110",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Bali",
    interiors: [
      require("../images/catalog/alma_ceramica/bali/interiors/alma_ceramica_bali_15908_383926_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bali/interiors/alma_ceramica_bali_15908_383926_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bali/tovars/alma_ceramica_bali_118177_505733_300x0.jpg"),
    ],
  },
  {
    id: "111",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57"],
    Name: "Alma Ceramica",
    Collection: "Baltia",
    interiors: [
      require("../images/catalog/alma_ceramica/baltia/interiors/alma_ceramica_baltia_13507_739357_0x300.jpg"),
      require("../images/catalog/alma_ceramica/baltia/interiors/alma_ceramica_baltia_13507_739357_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/baltia/tovars/alma_ceramica_baltia_107331_657393_300x0.jpg"),
    ],
  },
  {
    id: "112",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Bardiglio",
    interiors: [
      require("../images/catalog/alma_ceramica/bardiglio/interiors/alma_ceramica_bardiglio_14817_890717_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bardiglio/interiors/alma_ceramica_bardiglio_14817_890717_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bardiglio/tovars/alma_ceramica_bardiglio_113060_931739_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bardiglio/tovars/alma_ceramica_bardiglio_113061_312843_300x0.jpg"),
    ],
  },
  {
    id: "113",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Basalto",
    interiors: [
      require("../images/catalog/alma_ceramica/basalto/interiors/alma_ceramica_basalto_13508_144928_0x300.jpg"),
      require("../images/catalog/alma_ceramica/basalto/interiors/alma_ceramica_basalto_13508_171209_0x300.jpg"),
      require("../images/catalog/alma_ceramica/basalto/interiors/alma_ceramica_basalto_13508_171209_0x500.jpg"),
      require("../images/catalog/alma_ceramica/basalto/interiors/alma_ceramica_basalto_13508_275797_0x300.jpg"),
      require("../images/catalog/alma_ceramica/basalto/interiors/alma_ceramica_basalto_13508_480601_0x300.jpg"),
      require("../images/catalog/alma_ceramica/basalto/interiors/alma_ceramica_basalto_13508_491805_0x300.jpg"),
      require("../images/catalog/alma_ceramica/basalto/interiors/alma_ceramica_basalto_13508_61971_0x300.jpg"),
      require("../images/catalog/alma_ceramica/basalto/interiors/alma_ceramica_basalto_13508_830919_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/basalto/tovars/alma_ceramica_basalto_107332_917145_300x0.jpg"),
      require("../images/catalog/alma_ceramica/basalto/tovars/alma_ceramica_basalto_107333_762284_300x0.jpg"),
      require("../images/catalog/alma_ceramica/basalto/tovars/alma_ceramica_basalto_107334_870534_300x0.jpg"),
      require("../images/catalog/alma_ceramica/basalto/tovars/alma_ceramica_basalto_107335_223392_300x0.jpg"),
      require("../images/catalog/alma_ceramica/basalto/tovars/alma_ceramica_basalto_115794_25444_300x0.jpg"),
      require("../images/catalog/alma_ceramica/basalto/tovars/alma_ceramica_basalto_115795_742267_300x0.jpg"),
      require("../images/catalog/alma_ceramica/basalto/tovars/alma_ceramica_basalto_115796_600194_300x0.jpg"),
      require("../images/catalog/alma_ceramica/basalto/tovars/alma_ceramica_basalto_115797_86287_300x0.jpg"),
    ],
  },
  {
    id: "114",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Bellagio",
    interiors: [
      require("../images/catalog/alma_ceramica/bellagio/interiors/alma_ceramica_bellagio_15533_185699_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bellagio/interiors/alma_ceramica_bellagio_15533_185699_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bellagio/tovars/alma_ceramica_bellagio_115798_641258_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bellagio/tovars/alma_ceramica_bellagio_118597_579230_300x0.jpg"),
    ],
  },
  {
    id: "115",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Bergamo",
    interiors: [
      require("../images/catalog/alma_ceramica/bergamo/interiors/alma_ceramica_bergamo_11675_836103_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bergamo/interiors/alma_ceramica_bergamo_11675_964211_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bergamo/interiors/alma_ceramica_bergamo_11675_964211_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bergamo/tovars/alma_ceramica_bergamo_96104_967743_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bergamo/tovars/alma_ceramica_bergamo_96105_948436_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bergamo/tovars/alma_ceramica_bergamo_96106_609276_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bergamo/tovars/alma_ceramica_bergamo_96107_745519_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bergamo/tovars/alma_ceramica_bergamo_96108_631387_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bergamo/tovars/alma_ceramica_bergamo_96109_637171_300x0.jpg"),
    ],
  },
  {
    id: "116",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60", "60x120"],
    Name: "Alma Ceramica",
    Collection: "Berlin",
    interiors: [
      require("../images/catalog/alma_ceramica/berlin/interiors/alma_ceramica_berlin_15694_154023_0x300.jpg"),
      require("../images/catalog/alma_ceramica/berlin/interiors/alma_ceramica_berlin_15694_236180_0x300.jpg"),
      require("../images/catalog/alma_ceramica/berlin/interiors/alma_ceramica_berlin_15694_311741_0x300.jpg"),
      require("../images/catalog/alma_ceramica/berlin/interiors/alma_ceramica_berlin_15694_311741_0x500.jpg"),
      require("../images/catalog/alma_ceramica/berlin/interiors/alma_ceramica_berlin_15694_323635_0x300.jpg"),
      require("../images/catalog/alma_ceramica/berlin/interiors/alma_ceramica_berlin_15694_506442_0x300.jpg"),
      require("../images/catalog/alma_ceramica/berlin/interiors/alma_ceramica_berlin_15694_694726_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_116554_540301_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_116555_799607_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_116556_595427_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_116557_460374_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_116558_222164_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_116559_116109_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_116560_129326_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_118611_816927_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_118612_490769_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_118613_575226_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_118614_34906_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_118615_810290_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_118616_355589_300x0.jpg"),
      require("../images/catalog/alma_ceramica/berlin/tovars/alma_ceramica_berlin_118617_221096_300x0.jpg"),
    ],
  },
  {
    id: "117",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Bianco_chiara",
    interiors: [
      require("../images/catalog/alma_ceramica/bianco_chiara/interiors/alma_ceramica_bianco_chiara_13509_218366_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bianco_chiara/interiors/alma_ceramica_bianco_chiara_13509_218366_0x500.jpg"),
      require("../images/catalog/alma_ceramica/bianco_chiara/interiors/alma_ceramica_bianco_chiara_13509_961826_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bianco_chiara/tovars/alma_ceramica_bianco_chiara_107336_156559_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bianco_chiara/tovars/alma_ceramica_bianco_chiara_115799_692518_300x0.jpg"),
    ],
  },
  {
    id: "118",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "57x57"],
    Name: "Alma Ceramica",
    Collection: "Bilbao",
    interiors: [
      require("../images/catalog/alma_ceramica/bilbao/interiors/alma_ceramica_bilbao_14299_54204_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bilbao/interiors/alma_ceramica_bilbao_14299_550514_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bilbao/interiors/alma_ceramica_bilbao_14299_727119_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bilbao/interiors/alma_ceramica_bilbao_14299_727119_0x500.jpg"),
      require("../images/catalog/alma_ceramica/bilbao/interiors/alma_ceramica_bilbao_14299_908329_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bilbao/tovars/alma_ceramica_bilbao_111085_744543_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bilbao/tovars/alma_ceramica_bilbao_111086_213852_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bilbao/tovars/alma_ceramica_bilbao_111087_583319_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bilbao/tovars/alma_ceramica_bilbao_111088_310972_300x0.jpg"),
    ],
  },
  {
    id: "119",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Blare",
    interiors: [
      require("../images/catalog/alma_ceramica/blare/interiors/alma_ceramica_blare_8563_310299_0x300.jpg"),
      require("../images/catalog/alma_ceramica/blare/interiors/alma_ceramica_blare_8563_310299_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/blare/tovars/alma_ceramica_blare_73604_756102_300x0.jpg"),
      require("../images/catalog/alma_ceramica/blare/tovars/alma_ceramica_blare_73606_856169_300x0.jpg"),
    ],
  },
  {
    id: "120",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Bolle",
    interiors: [
      require("../images/catalog/alma_ceramica/bolle/interiors/alma_ceramica_bolle_10232_899637_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bolle/interiors/alma_ceramica_bolle_10232_899637_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bolle/tovars/alma_ceramica_bolle_87090_925455_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bolle/tovars/alma_ceramica_bolle_87091_209618_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bolle/tovars/alma_ceramica_bolle_87092_194124_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bolle/tovars/alma_ceramica_bolle_87093_869178_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bolle/tovars/alma_ceramica_bolle_87094_405752_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bolle/tovars/alma_ceramica_bolle_87096_233362_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bolle/tovars/alma_ceramica_bolle_87097_880232_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bolle/tovars/alma_ceramica_bolle_87098_966581_300x0.jpg"),
    ],
  },
  {
    id: "121",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Bonita",
    interiors: [
      require("../images/catalog/alma_ceramica/bonita/interiors/alma_ceramica_bonita_9279_689626_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bonita/interiors/alma_ceramica_bonita_9279_689626_0x500.jpg"),
      require("../images/catalog/alma_ceramica/bonita/interiors/alma_ceramica_bonita_9279_938964_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bonita/tovars/alma_ceramica_bonita_79560_253992_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bonita/tovars/alma_ceramica_bonita_79561_658438_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bonita/tovars/alma_ceramica_bonita_79562_652568_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bonita/tovars/alma_ceramica_bonita_79576_803997_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bonita/tovars/alma_ceramica_bonita_79577_792655_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bonita/tovars/alma_ceramica_bonita_79578_280283_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bonita/tovars/alma_ceramica_bonita_79579_195453_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bonita/tovars/alma_ceramica_bonita_79580_280861_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bonita/tovars/alma_ceramica_bonita_79581_649843_300x0.jpg"),
    ],
  },
  {
    id: "122",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60", "60x120"],
    Name: "Alma Ceramica",
    Collection: "Bottichino",
    interiors: [
      require("../images/catalog/alma_ceramica/bottichino/interiors/alma_ceramica_bottichino_14818_93782_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bottichino/interiors/alma_ceramica_bottichino_14818_93782_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bottichino/tovars/alma_ceramica_bottichino_113062_936674_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bottichino/tovars/alma_ceramica_bottichino_113063_978022_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bottichino/tovars/alma_ceramica_bottichino_113064_729876_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bottichino/tovars/alma_ceramica_bottichino_118618_868176_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bottichino/tovars/alma_ceramica_bottichino_118619_320253_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bottichino/tovars/alma_ceramica_bottichino_118620_156113_300x0.jpg"),
    ],
  },
  {
    id: "123",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Boutique",
    interiors: [
      require("../images/catalog/alma_ceramica/boutique/interiors/alma_ceramica_boutique_11676_855413_0x300.jpg"),
      require("../images/catalog/alma_ceramica/boutique/interiors/alma_ceramica_boutique_11676_855413_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/boutique/tovars/alma_ceramica_boutique_96110_646398_300x0.jpg"),
      require("../images/catalog/alma_ceramica/boutique/tovars/alma_ceramica_boutique_96111_537583_300x0.jpg"),
      require("../images/catalog/alma_ceramica/boutique/tovars/alma_ceramica_boutique_96112_214134_300x0.jpg"),
      require("../images/catalog/alma_ceramica/boutique/tovars/alma_ceramica_boutique_96113_187235_300x0.jpg"),
    ],
  },
  {
    id: "124",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Brazil",
    interiors: [
      require("../images/catalog/alma_ceramica/brazil/interiors/alma_ceramica_brazil_13510_290168_0x300.jpg"),
      require("../images/catalog/alma_ceramica/brazil/interiors/alma_ceramica_brazil_13510_574663_0x300.jpg"),
      require("../images/catalog/alma_ceramica/brazil/interiors/alma_ceramica_brazil_13510_574663_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/brazil/tovars/alma_ceramica_brazil_107337_460422_300x0.jpg"),
      require("../images/catalog/alma_ceramica/brazil/tovars/alma_ceramica_brazil_107338_741746_300x0.jpg"),
    ],
  },
  {
    id: "125",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "60x60", "20x90"],
    Name: "Alma Ceramica",
    Collection: "Brenta",
    interiors: [
      require("../images/catalog/alma_ceramica/brenta/interiors/alma_ceramica_brenta_11677_647391_0x300.jpg"),
      require("../images/catalog/alma_ceramica/brenta/interiors/alma_ceramica_brenta_11677_647391_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/brenta/tovars/alma_ceramica_brenta_96115_916189_300x0.jpg"),
      require("../images/catalog/alma_ceramica/brenta/tovars/alma_ceramica_brenta_96116_647149_300x0.jpg"),
      require("../images/catalog/alma_ceramica/brenta/tovars/alma_ceramica_brenta_96117_348558_300x0.jpg"),
    ],
  },
  {
    id: "126",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Bruce",
    interiors: [
      require("../images/catalog/alma_ceramica/bruce/interiors/alma_ceramica_bruce_11621_260855_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bruce/interiors/alma_ceramica_bruce_11621_717756_0x300.jpg"),
      require("../images/catalog/alma_ceramica/bruce/interiors/alma_ceramica_bruce_11621_717756_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/bruce/tovars/alma_ceramica_bruce_95622_905197_300x0.jpg"),
      require("../images/catalog/alma_ceramica/bruce/tovars/alma_ceramica_bruce_95623_258521_300x0.jpg"),
    ],
  },
  {
    id: "127",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Calacatta_oro",
    interiors: [
      require("../images/catalog/alma_ceramica/calacatta_oro/interiors/alma_ceramica_calacatta_oro_15534_399829_0x300.jpg"),
      require("../images/catalog/alma_ceramica/calacatta_oro/interiors/alma_ceramica_calacatta_oro_15534_399829_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/calacatta_oro/tovars/alma_ceramica_calacatta_oro_115800_848964_300x0.jpg"),
      require("../images/catalog/alma_ceramica/calacatta_oro/tovars/alma_ceramica_calacatta_oro_118596_35444_300x0.jpg"),
    ],
  },
  {
    id: "128",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Capriani",
    interiors: [
      require("../images/catalog/alma_ceramica/capriani/interiors/alma_ceramica_capriani_15535_618944_0x300.jpg"),
      require("../images/catalog/alma_ceramica/capriani/interiors/alma_ceramica_capriani_15535_618944_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/capriani/tovars/alma_ceramica_capriani_115801_639189_300x0.jpg"),
      require("../images/catalog/alma_ceramica/capriani/tovars/alma_ceramica_capriani_118598_327960_300x0.jpg"),
    ],
  },
  {
    id: "129",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60", "60x120"],
    Name: "Alma Ceramica",
    Collection: "Carrara",
    interiors: [
      require("../images/catalog/alma_ceramica/carrara/interiors/alma_ceramica_carrara_8211_373321_0x300.jpg"),
      require("../images/catalog/alma_ceramica/carrara/interiors/alma_ceramica_carrara_8211_373321_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/carrara/tovars/alma_ceramica_carrara_118621_670792_300x0.jpg"),
      require("../images/catalog/alma_ceramica/carrara/tovars/alma_ceramica_carrara_70983_977547_300x0.jpg"),
    ],
  },
  {
    id: "130",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Cemento",
    interiors: [
      require("../images/catalog/alma_ceramica/cemento/interiors/alma_ceramica_cemento_13511_480889_0x300.jpg"),
      require("../images/catalog/alma_ceramica/cemento/interiors/alma_ceramica_cemento_13511_480889_0x500.jpg"),
      require("../images/catalog/alma_ceramica/cemento/interiors/alma_ceramica_cemento_13511_528774_0x300.jpg"),
      require("../images/catalog/alma_ceramica/cemento/interiors/alma_ceramica_cemento_13511_626646_0x300.jpg"),
      require("../images/catalog/alma_ceramica/cemento/interiors/alma_ceramica_cemento_13511_877278_0x300.jpg"),
      require("../images/catalog/alma_ceramica/cemento/interiors/alma_ceramica_cemento_13511_929309_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/cemento/tovars/alma_ceramica_cemento_107339_777068_300x0.jpg"),
      require("../images/catalog/alma_ceramica/cemento/tovars/alma_ceramica_cemento_107340_51160_300x0.jpg"),
      require("../images/catalog/alma_ceramica/cemento/tovars/alma_ceramica_cemento_107341_40684_300x0.jpg"),
      require("../images/catalog/alma_ceramica/cemento/tovars/alma_ceramica_cemento_107342_782950_300x0.jpg"),
      require("../images/catalog/alma_ceramica/cemento/tovars/alma_ceramica_cemento_115802_333558_300x0.jpg"),
      require("../images/catalog/alma_ceramica/cemento/tovars/alma_ceramica_cemento_115803_172560_300x0.jpg"),
      require("../images/catalog/alma_ceramica/cemento/tovars/alma_ceramica_cemento_115804_515077_300x0.jpg"),
    ],
  },
  {
    id: "131",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Chester",
    interiors: [
      require("../images/catalog/alma_ceramica/chester/interiors/alma_ceramica_chester_14689_235399_0x300.jpg"),
      require("../images/catalog/alma_ceramica/chester/interiors/alma_ceramica_chester_14689_302609_0x300.jpg"),
      require("../images/catalog/alma_ceramica/chester/interiors/alma_ceramica_chester_14689_302609_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/chester/tovars/alma_ceramica_chester_112432_969649_300x0.jpg"),
      require("../images/catalog/alma_ceramica/chester/tovars/alma_ceramica_chester_112433_849612_300x0.jpg"),
    ],
  },
  {
    id: "132",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "57x57"],
    Name: "Alma Ceramica",
    Collection: "Corsica",
    interiors: [
      require("../images/catalog/alma_ceramica/corsica/interiors/alma_ceramica_corsica_14115_841654_0x300.jpg"),
      require("../images/catalog/alma_ceramica/corsica/interiors/alma_ceramica_corsica_14115_842211_0x300.jpg"),
      require("../images/catalog/alma_ceramica/corsica/interiors/alma_ceramica_corsica_14115_933905_0x300.jpg"),
      require("../images/catalog/alma_ceramica/corsica/interiors/alma_ceramica_corsica_14115_933905_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/corsica/tovars/alma_ceramica_corsica_110393_804265_300x0.jpg"),
      require("../images/catalog/alma_ceramica/corsica/tovars/alma_ceramica_corsica_110394_696251_300x0.jpg"),
      require("../images/catalog/alma_ceramica/corsica/tovars/alma_ceramica_corsica_110398_793715_300x0.jpg"),
    ],
  },
  {
    id: "133",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Costa_rica",
    interiors: [
      require("../images/catalog/alma_ceramica/costa_rica/interiors/alma_ceramica_costa_rica_15312_215705_0x300.jpg"),
      require("../images/catalog/alma_ceramica/costa_rica/interiors/alma_ceramica_costa_rica_15312_269392_0x300.jpg"),
      require("../images/catalog/alma_ceramica/costa_rica/interiors/alma_ceramica_costa_rica_15312_269392_0x500.jpg"),
      require("../images/catalog/alma_ceramica/costa_rica/interiors/alma_ceramica_costa_rica_15312_474474_0x300.jpg"),
      require("../images/catalog/alma_ceramica/costa_rica/interiors/alma_ceramica_costa_rica_15312_575401_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/costa_rica/tovars/alma_ceramica_costa_rica_115057_17924_300x0.jpg"),
      require("../images/catalog/alma_ceramica/costa_rica/tovars/alma_ceramica_costa_rica_115058_162834_300x0.jpg"),
      require("../images/catalog/alma_ceramica/costa_rica/tovars/alma_ceramica_costa_rica_115059_109316_300x0.jpg"),
      require("../images/catalog/alma_ceramica/costa_rica/tovars/alma_ceramica_costa_rica_115060_867405_300x0.jpg"),
      require("../images/catalog/alma_ceramica/costa_rica/tovars/alma_ceramica_costa_rica_115061_706149_300x0.jpg"),
      require("../images/catalog/alma_ceramica/costa_rica/tovars/alma_ceramica_costa_rica_115062_215318_300x0.jpg"),
    ],
  },
  {
    id: "134",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Country",
    interiors: [
      require("../images/catalog/alma_ceramica/country/interiors/alma_ceramica_country_14819_894098_0x300.jpg"),
      require("../images/catalog/alma_ceramica/country/interiors/alma_ceramica_country_14819_894098_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/country/tovars/alma_ceramica_country_113065_368290_300x0.jpg"),
      require("../images/catalog/alma_ceramica/country/tovars/alma_ceramica_country_113066_627683_300x0.jpg"),
    ],
  },
  {
    id: "135",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Crema_marfil",
    interiors: [
      require("../images/catalog/alma_ceramica/crema_marfil/interiors/alma_ceramica_crema_marfil_15695_508506_0x300.jpg"),
      require("../images/catalog/alma_ceramica/crema_marfil/interiors/alma_ceramica_crema_marfil_15695_508506_0x500.jpg"),
      require("../images/catalog/alma_ceramica/crema_marfil/interiors/alma_ceramica_crema_marfil_15695_870030_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/crema_marfil/tovars/alma_ceramica_crema_marfil_116561_655904_300x0.jpg"),
      require("../images/catalog/alma_ceramica/crema_marfil/tovars/alma_ceramica_crema_marfil_116562_333534_300x0.jpg"),
    ],
  },
  {
    id: "136",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Daino_reale",
    interiors: [
      require("../images/catalog/alma_ceramica/daino_reale/interiors/alma_ceramica_daino_reale_14820_745062_0x300.jpg"),
      require("../images/catalog/alma_ceramica/daino_reale/interiors/alma_ceramica_daino_reale_14820_745062_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/daino_reale/tovars/alma_ceramica_daino_reale_113067_676337_300x0.jpg"),
    ],
  },
  {
    id: "137",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "57x57"],
    Name: "Alma Ceramica",
    Collection: "Dakota",
    interiors: [
      require("../images/catalog/alma_ceramica/dakota/interiors/alma_ceramica_dakota_14116_525652_0x300.jpg"),
      require("../images/catalog/alma_ceramica/dakota/interiors/alma_ceramica_dakota_14116_525652_0x500.jpg"),
      require("../images/catalog/alma_ceramica/dakota/interiors/alma_ceramica_dakota_14116_896096_0x300.jpg"),
      require("../images/catalog/alma_ceramica/dakota/interiors/alma_ceramica_dakota_14116_964800_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/dakota/tovars/alma_ceramica_dakota_110400_762533_300x0.jpg"),
      require("../images/catalog/alma_ceramica/dakota/tovars/alma_ceramica_dakota_110401_637975_300x0.jpg"),
    ],
  },
  {
    id: "890",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "60x60", "60x120"],
    Name: "Alma Ceramica",
    Collection: "Dea",
    interiors: [
      require("../images/catalog/alma_ceramica/dea/interiors/alma_ceramica_dea_11678_467406_0x300.jpg"),
      require("../images/catalog/alma_ceramica/dea/interiors/alma_ceramica_dea_11678_467406_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/dea/tovars/alma_ceramica_dea_118622_574458_300x0.jpg"),
      require("../images/catalog/alma_ceramica/dea/tovars/alma_ceramica_dea_96118_851375_300x0.jpg"),
      require("../images/catalog/alma_ceramica/dea/tovars/alma_ceramica_dea_96119_880017_300x0.jpg"),
      require("../images/catalog/alma_ceramica/dea/tovars/alma_ceramica_dea_96120_752603_300x0.jpg"),
      require("../images/catalog/alma_ceramica/dea/tovars/alma_ceramica_dea_96121_183188_300x0.jpg"),
    ],
  },
  {
    id: "138",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Del_mare",
    interiors: [
      require("../images/catalog/alma_ceramica/del_mare/interiors/alma_ceramica_del_mare_4395_657697_0x300.jpg"),
      require("../images/catalog/alma_ceramica/del_mare/interiors/alma_ceramica_del_mare_4395_901451_0x300.jpg"),
      require("../images/catalog/alma_ceramica/del_mare/interiors/alma_ceramica_del_mare_4395_901451_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/del_mare/tovars/alma_ceramica_del_mare_31410_867389_300x0.jpg"),
      require("../images/catalog/alma_ceramica/del_mare/tovars/alma_ceramica_del_mare_31411_150447_300x0.jpg"),
      require("../images/catalog/alma_ceramica/del_mare/tovars/alma_ceramica_del_mare_31412_667557_300x0.jpg"),
      require("../images/catalog/alma_ceramica/del_mare/tovars/alma_ceramica_del_mare_31415_75697_300x0.jpg"),
      require("../images/catalog/alma_ceramica/del_mare/tovars/alma_ceramica_del_mare_31418_848808_300x0.jpg"),
      require("../images/catalog/alma_ceramica/del_mare/tovars/alma_ceramica_del_mare_54259_617970_300x0.jpg"),
    ],
  },
  {
    id: "139",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["30x90", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Denver",
    interiors: [
      require("../images/catalog/alma_ceramica/denver/interiors/alma_ceramica_denver_11679_519360_0x300.jpg"),
      require("../images/catalog/alma_ceramica/denver/interiors/alma_ceramica_denver_11679_67311_0x300.jpg"),
      require("../images/catalog/alma_ceramica/denver/interiors/alma_ceramica_denver_11679_67311_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/denver/tovars/alma_ceramica_denver_96122_403038_300x0.jpg"),
      require("../images/catalog/alma_ceramica/denver/tovars/alma_ceramica_denver_96123_873821_300x0.jpg"),
      require("../images/catalog/alma_ceramica/denver/tovars/alma_ceramica_denver_96124_159506_300x0.jpg"),
      require("../images/catalog/alma_ceramica/denver/tovars/alma_ceramica_denver_96125_84085_300x0.jpg"),
      require("../images/catalog/alma_ceramica/denver/tovars/alma_ceramica_denver_96126_75763_300x0.jpg"),
      require("../images/catalog/alma_ceramica/denver/tovars/alma_ceramica_denver_96127_583618_300x0.jpg"),
      require("../images/catalog/alma_ceramica/denver/tovars/alma_ceramica_denver_96128_528687_300x0.jpg"),
      require("../images/catalog/alma_ceramica/denver/tovars/alma_ceramica_denver_96129_554022_300x0.jpg"),
    ],
  },
  {
    id: "140",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Dublin",
    interiors: [
      require("../images/catalog/alma_ceramica/dublin/interiors/alma_ceramica_dublin_11680_653927_0x300.jpg"),
      require("../images/catalog/alma_ceramica/dublin/interiors/alma_ceramica_dublin_11680_653927_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/dublin/tovars/alma_ceramica_dublin_96130_536557_300x0.jpg"),
      require("../images/catalog/alma_ceramica/dublin/tovars/alma_ceramica_dublin_96131_765874_300x0.jpg"),
      require("../images/catalog/alma_ceramica/dublin/tovars/alma_ceramica_dublin_96132_438887_300x0.jpg"),
    ],
  },
  {
    id: "141",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["30x90", "57x57"],
    Name: "Alma Ceramica",
    Collection: "Elsa",
    interiors: [
      require("../images/catalog/alma_ceramica/elsa/interiors/alma_ceramica_elsa_10240_375706_0x300.jpg"),
      require("../images/catalog/alma_ceramica/elsa/interiors/alma_ceramica_elsa_10240_375706_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/elsa/tovars/alma_ceramica_elsa_87153_716944_300x0.jpg"),
      require("../images/catalog/alma_ceramica/elsa/tovars/alma_ceramica_elsa_87155_619046_300x0.jpg"),
      require("../images/catalog/alma_ceramica/elsa/tovars/alma_ceramica_elsa_87156_276063_300x0.jpg"),
      require("../images/catalog/alma_ceramica/elsa/tovars/alma_ceramica_elsa_87157_594318_300x0.jpg"),
      require("../images/catalog/alma_ceramica/elsa/tovars/alma_ceramica_elsa_87158_562969_300x0.jpg"),
    ],
  },
  {
    id: "142",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Esta",
    interiors: [
      require("../images/catalog/alma_ceramica/esta/interiors/alma_ceramica_esta_4419_604602_0x300.jpg"),
      require("../images/catalog/alma_ceramica/esta/interiors/alma_ceramica_esta_4419_604602_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/esta/tovars/alma_ceramica_esta_31420_394894_300x0.jpg"),
      require("../images/catalog/alma_ceramica/esta/tovars/alma_ceramica_esta_31422_952957_300x0.jpg"),
      require("../images/catalog/alma_ceramica/esta/tovars/alma_ceramica_esta_31687_926165_300x0.jpg"),
      require("../images/catalog/alma_ceramica/esta/tovars/alma_ceramica_esta_31695_321059_300x0.jpg"),
      require("../images/catalog/alma_ceramica/esta/tovars/alma_ceramica_esta_65832_656166_300x0.jpg"),
    ],
  },
  {
    id: "143",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Evolution",
    interiors: [
      require("../images/catalog/alma_ceramica/evolution/interiors/alma_ceramica_evolution_14821_992828.jpg"),
      require("../images/catalog/alma_ceramica/evolution/interiors/alma_ceramica_evolution_14821_26835.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/evolution/tovars/alma_ceramica_evolution_113068_817157_500x0.jpg"),
      require("../images/catalog/alma_ceramica/evolution/tovars/alma_ceramica_evolution_113069_717311_500x0.jpg"),
      require("../images/catalog/alma_ceramica/evolution/tovars/alma_ceramica_evolution_113070_473165_500x0.jpg"),
      require("../images/catalog/alma_ceramica/evolution/tovars/alma_ceramica_evolution_113071_237003_500x0.jpg"),
      require("../images/catalog/alma_ceramica/evolution/tovars/alma_ceramica_evolution_113072_51924_500x0.jpg"),
      require("../images/catalog/alma_ceramica/evolution/tovars/alma_ceramica_evolution_113073_374557_500x0.jpg"),
      require("../images/catalog/alma_ceramica/evolution/tovars/alma_ceramica_evolution_113074_774011_500x0.jpg"),
      require("../images/catalog/alma_ceramica/evolution/tovars/alma_ceramica_evolution_113075_34659_500x0.jpg"),
      require("../images/catalog/alma_ceramica/evolution/tovars/alma_ceramica_evolution_113076_739302_500x0.jpg"),
    ],
  },
  {
    id: "144",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Extra_calacatta",
    interiors: [
      require("../images/catalog/alma_ceramica/extra_calacatta/interiors/alma_ceramica_extra_calacatta_13513_40483.jpg"),
      require("../images/catalog/alma_ceramica/extra_calacatta/interiors/alma_ceramica_extra_calacatta_13513_931903.jpg"),
      require("../images/catalog/alma_ceramica/extra_calacatta/interiors/alma_ceramica_extra_calacatta_13513_934430.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/extra_calacatta/tovars/alma_ceramica_extra_calacatta_107345_778542_500x0.jpg"),
      require("../images/catalog/alma_ceramica/extra_calacatta/tovars/alma_ceramica_extra_calacatta_115807_854533_500x0.jpg"),
    ],
  },
  {
    id: "145",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Fluid_art",
    interiors: [
      require("../images/catalog/alma_ceramica/fluid_art/interiors/alma_ceramica_fluid_art_11681_213920_0x300.jpg"),
      require("../images/catalog/alma_ceramica/fluid_art/interiors/alma_ceramica_fluid_art_11681_213920_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/fluid_art/tovars/alma_ceramica_fluid_art_96133_902690_300x0.jpg"),
      require("../images/catalog/alma_ceramica/fluid_art/tovars/alma_ceramica_fluid_art_96134_982077_300x0.jpg"),
      require("../images/catalog/alma_ceramica/fluid_art/tovars/alma_ceramica_fluid_art_96135_406630_300x0.jpg"),
    ],
  },
  {
    id: "146",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["30x90", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Greys",
    interiors: [
      require("../images/catalog/alma_ceramica/greys/interiors/alma_ceramica_greys_4594_710276_0x300.jpg"),
      require("../images/catalog/alma_ceramica/greys/interiors/alma_ceramica_greys_4594_710276_0x500.jpg"),
      require("../images/catalog/alma_ceramica/greys/interiors/alma_ceramica_greys_4594_898153_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/greys/tovars/alma_ceramica_greys_33676_567457_300x0.jpg"),
      require("../images/catalog/alma_ceramica/greys/tovars/alma_ceramica_greys_33678_977868_300x0.jpg"),
      require("../images/catalog/alma_ceramica/greys/tovars/alma_ceramica_greys_33681_425089_300x0.jpg"),
    ],
  },
  {
    id: "147",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Grigio",
    interiors: [
      require("../images/catalog/alma_ceramica/grigio/interiors/alma_ceramica_grigio_10233_655271_0x300.jpg"),
      require("../images/catalog/alma_ceramica/grigio/interiors/alma_ceramica_grigio_10233_655271_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87099_863613_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87100_711585_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87101_863519_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87102_391381_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87103_383112_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87104_852189_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87105_407979_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87106_21789_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87107_527003_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87108_713454_300x0.jpg"),
      require("../images/catalog/alma_ceramica/grigio/tovars/alma_ceramica_grigio_87109_756841_300x0.jpg"),
    ],
  },
  {
    id: "148",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Harisma",
    interiors: [
      require("../images/catalog/alma_ceramica/harisma/interiors/alma_ceramica_harisma_6493_424907_0x300.jpg"),
      require("../images/catalog/alma_ceramica/harisma/interiors/alma_ceramica_harisma_6493_424907_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/harisma/tovars/alma_ceramica_harisma_53643_909224_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harisma/tovars/alma_ceramica_harisma_53644_59412_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harisma/tovars/alma_ceramica_harisma_53645_306753_300x0.jpg"),
    ],
  },
  {
    id: "149",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["30x90", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Harvi",
    interiors: [
      require("../images/catalog/alma_ceramica/harvi/interiors/alma_ceramica_harvi_10241_926509_0x300.jpg"),
      require("../images/catalog/alma_ceramica/harvi/interiors/alma_ceramica_harvi_10241_926509_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87159_309467_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87160_515871_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87161_644955_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87162_765744_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87163_695455_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87164_146694_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87165_178384_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87166_443321_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87167_58971_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87168_23512_300x0.jpg"),
      require("../images/catalog/alma_ceramica/harvi/tovars/alma_ceramica_harvi_87169_116642_300x0.jpg"),
    ],
  },
  {
    id: "150",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["30x90", "20x90"],
    Name: "Alma Ceramica",
    Collection: "Iceberg",
    interiors: [
      require("../images/catalog/alma_ceramica/iceberg/interiors/alma_ceramica_iceberg_15911_389453_0x300.jpg"),
      require("../images/catalog/alma_ceramica/iceberg/interiors/alma_ceramica_iceberg_15911_486065_0x300.jpg"),
      require("../images/catalog/alma_ceramica/iceberg/interiors/alma_ceramica_iceberg_15911_486065_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/iceberg/tovars/alma_ceramica_iceberg_118198_168454_300x0.jpg"),
      require("../images/catalog/alma_ceramica/iceberg/tovars/alma_ceramica_iceberg_118199_312221_300x0.jpg"),
    ],
  },
  {
    id: "151",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Irma",
    interiors: [
      require("../images/catalog/alma_ceramica/irma/interiors/alma_ceramica_irma_10245_137763_0x300.jpg"),
      require("../images/catalog/alma_ceramica/irma/interiors/alma_ceramica_irma_10245_137763_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87186_443863_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87187_478916_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87188_991703_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87189_996833_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87190_778384_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87191_507575_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87192_641789_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87194_203030_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87195_778485_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87196_712515_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87197_636353_300x0.jpg"),
      require("../images/catalog/alma_ceramica/irma/tovars/alma_ceramica_irma_87198_827457_300x0.jpg"),
    ],
  },
  {
    id: "152",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Ironwood",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/alma_ceramica/ironwood/tovars/alma_ceramica_ironwood_118181_499172_300x0.jpg"),
    ],
  },
  {
    id: "153",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Jemchug",
    interiors: [
      require("../images/catalog/alma_ceramica/jemchug/interiors/alma_ceramica_jemchug_4603_974480_0x300.jpg"),
      require("../images/catalog/alma_ceramica/jemchug/interiors/alma_ceramica_jemchug_4603_974480_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/jemchug/tovars/alma_ceramica_jemchug_33741_396059_300x0.jpg"),
      require("../images/catalog/alma_ceramica/jemchug/tovars/alma_ceramica_jemchug_33742_622330_300x0.jpg"),
      require("../images/catalog/alma_ceramica/jemchug/tovars/alma_ceramica_jemchug_33747_46242_300x0.jpg"),
    ],
  },
  {
    id: "154",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Jungle",
    interiors: [
      require("../images/catalog/alma_ceramica/jungle/interiors/alma_ceramica_jungle_10229_598626_0x300.jpg"),
      require("../images/catalog/alma_ceramica/jungle/interiors/alma_ceramica_jungle_10229_598626_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/jungle/tovars/alma_ceramica_jungle_87069_671526_300x0.jpg"),
      require("../images/catalog/alma_ceramica/jungle/tovars/alma_ceramica_jungle_87070_988670_300x0.jpg"),
      require("../images/catalog/alma_ceramica/jungle/tovars/alma_ceramica_jungle_87073_674813_300x0.jpg"),
    ],
  },
  {
    id: "155",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["30x90", "60x60", "60x120"],
    Name: "Alma Ceramica",
    Collection: "Laredo",
    interiors: [
      require("../images/catalog/alma_ceramica/laredo/interiors/alma_ceramica_laredo_11682_803446_0x300.jpg"),
      require("../images/catalog/alma_ceramica/laredo/interiors/alma_ceramica_laredo_11682_803446_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/laredo/tovars/alma_ceramica_laredo_118623_45245_300x0.jpg"),
      require("../images/catalog/alma_ceramica/laredo/tovars/alma_ceramica_laredo_96136_851126_300x0.jpg"),
      require("../images/catalog/alma_ceramica/laredo/tovars/alma_ceramica_laredo_96137_591353_300x0.jpg"),
      require("../images/catalog/alma_ceramica/laredo/tovars/alma_ceramica_laredo_96138_152149_300x0.jpg"),
      require("../images/catalog/alma_ceramica/laredo/tovars/alma_ceramica_laredo_96139_482513_300x0.jpg"),
    ],
  },
  {
    id: "156",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "57x57"],
    Name: "Alma Ceramica",
    Collection: "Laura",
    interiors: [
      require("../images/catalog/alma_ceramica/laura/interiors/alma_ceramica_laura_14117_213715_0x300.jpg"),
      require("../images/catalog/alma_ceramica/laura/interiors/alma_ceramica_laura_14117_449613_0x300.jpg"),
      require("../images/catalog/alma_ceramica/laura/interiors/alma_ceramica_laura_14117_449613_0x500.jpg"),
      require("../images/catalog/alma_ceramica/laura/interiors/alma_ceramica_laura_14117_732666_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/laura/tovars/alma_ceramica_laura_110403_380686_300x0.jpg"),
    ],
  },
  {
    id: "157",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "20x90"],
    Name: "Alma Ceramica",
    Collection: "Lazio",
    interiors: [
      require("../images/catalog/alma_ceramica/lazio/interiors/alma_ceramica_lazio_11683_945065_0x300.jpg"),
      require("../images/catalog/alma_ceramica/lazio/interiors/alma_ceramica_lazio_11683_945065_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/lazio/tovars/alma_ceramica_lazio_96140_228524_300x0.jpg"),
      require("../images/catalog/alma_ceramica/lazio/tovars/alma_ceramica_lazio_96141_788549_300x0.jpg"),
      require("../images/catalog/alma_ceramica/lazio/tovars/alma_ceramica_lazio_96142_20097_300x0.jpg"),
      require("../images/catalog/alma_ceramica/lazio/tovars/alma_ceramica_lazio_96143_432660_300x0.jpg"),
    ],
  },
  {
    id: "158",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Lea",
    interiors: [
      require("../images/catalog/alma_ceramica/lea/interiors/alma_ceramica_lea_11189_768736_0x300.jpg"),
      require("../images/catalog/alma_ceramica/lea/interiors/alma_ceramica_lea_11189_965106_0x300.jpg"),
      require("../images/catalog/alma_ceramica/lea/interiors/alma_ceramica_lea_11189_965106_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/lea/tovars/alma_ceramica_lea_93743_349135_300x0.jpg"),
    ],
  },
  {
    id: "159",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Linden",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/alma_ceramica/linden/tovars/alma_ceramica_linden_95625_993046_300x0.jpg"),
    ],
  },
  {
    id: "160",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["30x90"],
    Name: "Alma Ceramica",
    Collection: "Luxor",
    interiors: [
      require("../images/catalog/alma_ceramica/luxor/interiors/alma_ceramica_luxor_10242_75757_0x300.jpg"),
      require("../images/catalog/alma_ceramica/luxor/interiors/alma_ceramica_luxor_10242_75757_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/luxor/tovars/alma_ceramica_luxor_87170_170868_300x0.jpg"),
      require("../images/catalog/alma_ceramica/luxor/tovars/alma_ceramica_luxor_87171_52765_300x0.jpg"),
      require("../images/catalog/alma_ceramica/luxor/tovars/alma_ceramica_luxor_87172_649143_300x0.jpg"),
      require("../images/catalog/alma_ceramica/luxor/tovars/alma_ceramica_luxor_87173_678518_300x0.jpg"),
    ],
  },
  {
    id: "161",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Madison",
    interiors: [
      require("../images/catalog/alma_ceramica/madison/interiors/alma_ceramica_madison_11188_291724_0x300.jpg"),
      require("../images/catalog/alma_ceramica/madison/interiors/alma_ceramica_madison_11188_291724_0x500.jpg"),
      require("../images/catalog/alma_ceramica/madison/interiors/alma_ceramica_madison_11188_495735_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/madison/tovars/alma_ceramica_madison_93737_484968_300x0.jpg"),
      require("../images/catalog/alma_ceramica/madison/tovars/alma_ceramica_madison_93738_17587_300x0.jpg"),
      require("../images/catalog/alma_ceramica/madison/tovars/alma_ceramica_madison_93739_818082_300x0.jpg"),
    ],
  },
  {
    id: "162",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Madrid",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/alma_ceramica/madrid/tovars/alma_ceramica_madrid_107352_394162_300x0.jpg"),
    ],
  },
  {
    id: "163",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["30x90", "60x60", "60x120"],
    Name: "Alma Ceramica",
    Collection: "Magic",
    interiors: [
      require("../images/catalog/alma_ceramica/magic/interiors/alma_ceramica_magic_11684_36017_0x300.jpg"),
      require("../images/catalog/alma_ceramica/magic/interiors/alma_ceramica_magic_11684_723591_0x300.jpg"),
      require("../images/catalog/alma_ceramica/magic/interiors/alma_ceramica_magic_11684_723591_0x500.jpg"),
      require("../images/catalog/alma_ceramica/magic/interiors/alma_ceramica_magic_11684_826460_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_118624_813682_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_118625_270634_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_96144_965786_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_96145_960853_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_96146_348849_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_96147_612935_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_96148_309412_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_96149_200225_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_96150_492953_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_96151_62016_300x0.jpg"),
      require("../images/catalog/alma_ceramica/magic/tovars/alma_ceramica_magic_96152_373414_300x0.jpg"),
    ],
  },
  {
    id: "164",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Marbella",
    interiors: [
      require("../images/catalog/alma_ceramica/marbella/interiors/alma_ceramica_marbella_9271_417881_0x300.jpg"),
      require("../images/catalog/alma_ceramica/marbella/interiors/alma_ceramica_marbella_9271_570337_0x300.jpg"),
      require("../images/catalog/alma_ceramica/marbella/interiors/alma_ceramica_marbella_9271_570337_0x500.jpg"),
      require("../images/catalog/alma_ceramica/marbella/interiors/alma_ceramica_marbella_9271_853927_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/marbella/tovars/alma_ceramica_marbella_79485_393011_300x0.jpg"),
      require("../images/catalog/alma_ceramica/marbella/tovars/alma_ceramica_marbella_79486_449743_300x0.jpg"),
      require("../images/catalog/alma_ceramica/marbella/tovars/alma_ceramica_marbella_79487_471330_300x0.jpg"),
      require("../images/catalog/alma_ceramica/marbella/tovars/alma_ceramica_marbella_79488_223192_300x0.jpg"),
      require("../images/catalog/alma_ceramica/marbella/tovars/alma_ceramica_marbella_79489_585765_300x0.jpg"),
      require("../images/catalog/alma_ceramica/marbella/tovars/alma_ceramica_marbella_79495_905825_300x0.jpg"),
      require("../images/catalog/alma_ceramica/marbella/tovars/alma_ceramica_marbella_79497_983991_300x0.jpg"),
      require("../images/catalog/alma_ceramica/marbella/tovars/alma_ceramica_marbella_79498_696510_300x0.jpg"),
    ],
  },
  {
    id: "165",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Marrone",
    interiors: [
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_523767_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_56474_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_780134_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_929530_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_929530_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/marrone/tovars/alma_ceramica_marrone_79294_830181_300x0.jpg"),
      require("../images/catalog/alma_ceramica/marrone/tovars/alma_ceramica_marrone_79292_828260.jpg"),
      require("../images/catalog/alma_ceramica/marrone/tovars/alma_ceramica_marrone_79293_426662.jpg"),
    ],
  },
  {
    id: "166",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "57x57"],
    Name: "Alma Ceramica",
    Collection: "Mars",
    interiors: [
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_523767_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_56474_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_780134_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_929530_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mars/interiors/alma_ceramica_mars_15315_929530_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/mars/tovars/alma_ceramica_mars_115065_132088_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mars/tovars/alma_ceramica_mars_115066_587841_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mars/tovars/alma_ceramica_mars_115067_93584_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mars/tovars/alma_ceramica_mars_115068_933523_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mars/tovars/alma_ceramica_mars_115069_453383_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mars/tovars/alma_ceramica_mars_115070_176863_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mars/tovars/alma_ceramica_mars_115071_435742_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mars/tovars/alma_ceramica_mars_115072_339812_300x0.jpg"),
    ],
  },
  {
    id: "167",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "20x90"],
    Name: "Alma Ceramica",
    Collection: "Mattone",
    interiors: [
      require("../images/catalog/alma_ceramica/mattone/interiors/alma_ceramica_mattone_11685_108015_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mattone/interiors/alma_ceramica_mattone_11685_108015_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/mattone/tovars/alma_ceramica_mattone_96153_885993_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mattone/tovars/alma_ceramica_mattone_96154_925838_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mattone/tovars/alma_ceramica_mattone_96155_522922_300x0.jpg"),
    ],
  },
  {
    id: "168",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Medis",
    interiors: [
      require("../images/catalog/alma_ceramica/medis/interiors/alma_ceramica_medis_10230_774298_0x300.jpg"),
      require("../images/catalog/alma_ceramica/medis/interiors/alma_ceramica_medis_10230_774298_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/medis/tovars/alma_ceramica_medis_87074_315473_300x0.jpg"),
      require("../images/catalog/alma_ceramica/medis/tovars/alma_ceramica_medis_87075_745050_300x0.jpg"),
      require("../images/catalog/alma_ceramica/medis/tovars/alma_ceramica_medis_87076_360369_300x0.jpg"),
      require("../images/catalog/alma_ceramica/medis/tovars/alma_ceramica_medis_87079_240468_300x0.jpg"),
      require("../images/catalog/alma_ceramica/medis/tovars/alma_ceramica_medis_87080_286561_300x0.jpg"),
      require("../images/catalog/alma_ceramica/medis/tovars/alma_ceramica_medis_87081_54178_300x0.jpg"),
      require("../images/catalog/alma_ceramica/medis/tovars/alma_ceramica_medis_87082_148061_300x0.jpg"),
    ],
  },
  {
    id: "169",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Melange",
    interiors: [
      require("../images/catalog/alma_ceramica/melange/interiors/alma_ceramica_melange_8572_60647_0x300.jpg"),
      require("../images/catalog/alma_ceramica/melange/interiors/alma_ceramica_melange_8572_60647_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/melange/tovars/alma_ceramica_melange_73648_640789_300x0.jpg"),
      require("../images/catalog/alma_ceramica/melange/tovars/alma_ceramica_melange_73649_608380_300x0.jpg"),
      require("../images/catalog/alma_ceramica/melange/tovars/alma_ceramica_melange_73650_156620_300x0.jpg"),
      require("../images/catalog/alma_ceramica/melange/tovars/alma_ceramica_melange_73653_148494_300x0.jpg"),
    ],
  },
  {
    id: "170",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "57x57"],
    Name: "Alma Ceramica",
    Collection: "Mercury",
    interiors: [
      require("../images/catalog/alma_ceramica/mercury/interiors/alma_ceramica_mercury_14118_145708_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mercury/interiors/alma_ceramica_mercury_14118_846140_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mercury/interiors/alma_ceramica_mercury_14118_846140_0x500.jpg"),
      require("../images/catalog/alma_ceramica/mercury/interiors/alma_ceramica_mercury_14118_984638_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/mercury/tovars/alma_ceramica_mercury_110404_674833_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mercury/tovars/alma_ceramica_mercury_110405_583758_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mercury/tovars/alma_ceramica_mercury_110406_362556_300x0.jpg"),
    ],
  },
  {
    id: "171",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["57x57", "57x114"],
    Name: "Alma Ceramica",
    Collection: "Mirage",
    interiors: [
      require("../images/catalog/alma_ceramica/mirage/interiors/alma_ceramica_mirage_13521_253648.jpg"),
      require("../images/catalog/alma_ceramica/mirage/interiors/alma_ceramica_mirage_13521_983280.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/mirage/tovars/alma_ceramica_mirage_107356_355560_300x0.jpg"),
    ],
  },
  {
    id: "172",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "20x90"],
    Name: "Alma Ceramica",
    Collection: "Mirt",
    interiors: [
      require("../images/catalog/alma_ceramica/mirt/interiors/alma_ceramica_mirt_11686_259652_0x300.jpg"),
      require("../images/catalog/alma_ceramica/mirt/interiors/alma_ceramica_mirt_11686_259652_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/mirt/tovars/alma_ceramica_mirt_96156_960080_300x0.jpg"),
      require("../images/catalog/alma_ceramica/mirt/tovars/alma_ceramica_mirt_96157_991603_300x0.jpg"),
    ],
  },
  {
    id: "173",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Modena",
    interiors: [
      require("../images/catalog/alma_ceramica/modena/interiors/alma_ceramica_modena_9272_473683_0x300.jpg"),
      require("../images/catalog/alma_ceramica/modena/interiors/alma_ceramica_modena_9272_569302_0x300.jpg"),
      require("../images/catalog/alma_ceramica/modena/interiors/alma_ceramica_modena_9272_569302_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/modena/tovars/alma_ceramica_modena_79502_560003_300x0.jpg"),
      require("../images/catalog/alma_ceramica/modena/tovars/alma_ceramica_modena_79506_627715_300x0.jpg"),
    ],
  },
  {
    id: "174",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Morana",
    interiors: [
      require("../images/catalog/alma_ceramica/morana/interiors/alma_ceramica_morana_8597_645664_0x300.jpg"),
      require("../images/catalog/alma_ceramica/morana/interiors/alma_ceramica_morana_8597_645664_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/morana/tovars/alma_ceramica_morana_73800_62439_300x0.jpg"),
    ],
  },

  {
    id: "175",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "57x57"],
    Name: "Alma Ceramica",
    Collection: "Nativa",
    interiors: [
      require("../images/catalog/alma_ceramica/nativa/interiors/alma_ceramica_nativa_15316_175525_0x300.jpg"),
      require("../images/catalog/alma_ceramica/nativa/interiors/alma_ceramica_nativa_15316_782080_0x300.jpg"),
      require("../images/catalog/alma_ceramica/nativa/interiors/alma_ceramica_nativa_15316_782080_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/nativa/tovars/alma_ceramica_nativa_115073_335736_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nativa/tovars/alma_ceramica_nativa_115074_822350_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nativa/tovars/alma_ceramica_nativa_115075_884213_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nativa/tovars/alma_ceramica_nativa_115076_537131_300x0.jpg"),
    ],
  },
  {
    id: "176",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "60x60", "60x120"],
    Name: "Alma Ceramica",
    Collection: "Nevada",
    interiors: [
      require("../images/catalog/alma_ceramica/nevada/interiors/alma_ceramica_nevada_11687_256587_0x300.jpg"),
      require("../images/catalog/alma_ceramica/nevada/interiors/alma_ceramica_nevada_11687_321450_0x300.jpg"),
      require("../images/catalog/alma_ceramica/nevada/interiors/alma_ceramica_nevada_11687_402216_0x300.jpg"),
      require("../images/catalog/alma_ceramica/nevada/interiors/alma_ceramica_nevada_11687_402216_0x500.jpg"),
      require("../images/catalog/alma_ceramica/nevada/interiors/alma_ceramica_nevada_11687_691525_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_107357_655625_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_115814_953171_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_118628_839865_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_118629_947352_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_96158_106540_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_96159_488767_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_96160_545625_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_96161_633099_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_96162_254641_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_96163_974514_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_96164_535789_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_96165_236718_300x0.jpg"),
      require("../images/catalog/alma_ceramica/nevada/tovars/alma_ceramica_nevada_96166_381145_300x0.jpg"),
    ],
  },
  {
    id: "177",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60", "60x60"],
    Name: "Alma Ceramica",
    Collection: "New_york",
    interiors: [
      require("../images/catalog/alma_ceramica/new_york/interiors/alma_ceramica_new_york_11688_13507_0x300.jpg"),
      require("../images/catalog/alma_ceramica/new_york/interiors/alma_ceramica_new_york_11688_13507_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/new_york/tovars/alma_ceramica_new_york_96167_386916_300x0.jpg"),
      require("../images/catalog/alma_ceramica/new_york/tovars/alma_ceramica_new_york_96168_818072_300x0.jpg"),
      require("../images/catalog/alma_ceramica/new_york/tovars/alma_ceramica_new_york_96169_523295_300x0.jpg"),
    ],
  },
  {
    id: "178",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "20x90"],
    Name: "Alma Ceramica",
    Collection: "North",
    interiors: [
      require("../images/catalog/alma_ceramica/north/interiors/alma_ceramica_north_11689_543079_0x300.jpg"),
      require("../images/catalog/alma_ceramica/north/interiors/alma_ceramica_north_11689_543079_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/north/tovars/alma_ceramica_north_96170_859430_300x0.jpg"),
      require("../images/catalog/alma_ceramica/north/tovars/alma_ceramica_north_96171_46597_300x0.jpg"),
      require("../images/catalog/alma_ceramica/north/tovars/alma_ceramica_north_96172_311845_300x0.jpg"),
    ],
  },
  {
    id: "179",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Oasis",
    interiors: [
      require("../images/catalog/alma_ceramica/oasis/interiors/alma_ceramica_oasis_11690_539127_0x300.jpg"),
      require("../images/catalog/alma_ceramica/oasis/interiors/alma_ceramica_oasis_11690_539127_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/oasis/tovars/alma_ceramica_oasis_96173_869529_300x0.jpg"),
      require("../images/catalog/alma_ceramica/oasis/tovars/alma_ceramica_oasis_96174_469258_300x0.jpg"),
      require("../images/catalog/alma_ceramica/oasis/tovars/alma_ceramica_oasis_96175_277631_300x0.jpg"),
      require("../images/catalog/alma_ceramica/oasis/tovars/alma_ceramica_oasis_96176_830383_300x0.jpg"),
    ],
  },
  {
    id: "180",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["30x90", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Origami",
    interiors: [
      require("../images/catalog/alma_ceramica/origami/interiors/alma_ceramica_origami_15912_524885_0x300.jpg"),
      require("../images/catalog/alma_ceramica/origami/interiors/alma_ceramica_origami_15912_539840_0x300.jpg"),
      require("../images/catalog/alma_ceramica/origami/interiors/alma_ceramica_origami_15912_539840_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/origami/tovars/alma_ceramica_origami_118200_739633_300x0.jpg"),
      require("../images/catalog/alma_ceramica/origami/tovars/alma_ceramica_origami_118201_178294_300x0.jpg"),
      require("../images/catalog/alma_ceramica/origami/tovars/alma_ceramica_origami_118202_928985_300x0.jpg"),
      require("../images/catalog/alma_ceramica/origami/tovars/alma_ceramica_origami_118203_260387_300x0.jpg"),
    ],
  },
  {
    id: "181",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Orlean",
    interiors: [
      require("../images/catalog/alma_ceramica/orlean/interiors/alma_ceramica_orlean_15697_145218.jpg"),
      require("../images/catalog/alma_ceramica/orlean/interiors/alma_ceramica_orlean_15697_222282.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/orlean/tovars/alma_ceramica_orlean_116565_509332_300x0.jpg"),
      require("../images/catalog/alma_ceramica/orlean/tovars/alma_ceramica_orlean_116564_755787.jpg"),
    ],
  },
  {
    id: "182",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Persa",
    interiors: [
      require("../images/catalog/alma_ceramica/persa/interiors/alma_ceramica_persa_11691_813170_0x300.jpg"),
      require("../images/catalog/alma_ceramica/persa/interiors/alma_ceramica_persa_11691_813170_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/persa/tovars/alma_ceramica_persa_96180_998336_300x0.jpg"),
    ],
  },
  {
    id: "183",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "60x60"],
    Name: "Alma Ceramica",
    Collection: "Piemonte",
    interiors: [
      require("../images/catalog/alma_ceramica/piemonte/interiors/alma_ceramica_piemonte_11692_990528_0x300.jpg"),
      require("../images/catalog/alma_ceramica/piemonte/interiors/alma_ceramica_piemonte_11692_990528_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/piemonte/tovars/alma_ceramica_piemonte_96181_373522_300x0.jpg"),
      require("../images/catalog/alma_ceramica/piemonte/tovars/alma_ceramica_piemonte_96182_191813_300x0.jpg"),
      require("../images/catalog/alma_ceramica/piemonte/tovars/alma_ceramica_piemonte_96183_371751_300x0.jpg"),
    ],
  },
  {
    id: "184",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["24,9x50", "41,8x41,8"],
    Name: "Alma Ceramica",
    Collection: "Plesso",
    interiors: [
      require("../images/catalog/alma_ceramica/plesso/interiors/alma_ceramica_plesso_4404_629889_0x300.jpg"),
      require("../images/catalog/alma_ceramica/plesso/interiors/alma_ceramica_plesso_4404_676388_0x300.jpg"),
      require("../images/catalog/alma_ceramica/plesso/interiors/alma_ceramica_plesso_4404_676388_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31513_121148_300x0.jpg"),
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31514_382629_300x0.jpg"),
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31515_946390_300x0.jpg"),
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31516_859074_300x0.jpg"),
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31517_182947_300x0.jpg"),
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31518_974860_300x0.jpg"),
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31525_924140_300x0.jpg"),
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31526_835393_300x0.jpg"),
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31527_731923_300x0.jpg"),
      require("../images/catalog/alma_ceramica/plesso/tovars/alma_ceramica_plesso_31529_277046_300x0.jpg"),
    ],
  },
  {
    id: "185",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Poli",
    interiors: [
      require("../images/catalog/alma_ceramica/poli/interiors/alma_ceramica_poli_4595_437065_0x300.jpg"),
      require("../images/catalog/alma_ceramica/poli/interiors/alma_ceramica_poli_4595_437065_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/poli/tovars/alma_ceramica_poli_33691_984288_300x0.jpg"),
    ],
  },
  {
    id: "186",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Relax",
    interiors: [
      require("../images/catalog/alma_ceramica/relax/interiors/alma_ceramica_relax_4408_41129_0x300.jpg"),
      require("../images/catalog/alma_ceramica/relax/interiors/alma_ceramica_relax_4408_41129_0x500.jpg"),
      require("../images/catalog/alma_ceramica/relax/interiors/alma_ceramica_relax_4408_647449_0x300.jpg"),
      require("../images/catalog/alma_ceramica/relax/interiors/alma_ceramica_relax_4408_665785_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/relax/tovars/alma_ceramica_relax_31563_158679_300x0.jpg"),
      require("../images/catalog/alma_ceramica/relax/tovars/alma_ceramica_relax_31564_125239_300x0.jpg"),
      require("../images/catalog/alma_ceramica/relax/tovars/alma_ceramica_relax_31565_238802_300x0.jpg"),
      require("../images/catalog/alma_ceramica/relax/tovars/alma_ceramica_relax_31566_198120_300x0.jpg"),
      require("../images/catalog/alma_ceramica/relax/tovars/alma_ceramica_relax_31572_652991_300x0.jpg"),
      require("../images/catalog/alma_ceramica/relax/tovars/alma_ceramica_relax_31575_34665_300x0.jpg"),
      require("../images/catalog/alma_ceramica/relax/tovars/alma_ceramica_relax_31576_762305_300x0.jpg"),
      require("../images/catalog/alma_ceramica/relax/tovars/alma_ceramica_relax_31577_967014_300x0.jpg"),
      require("../images/catalog/alma_ceramica/relax/tovars/alma_ceramica_relax_31581_266169_300x0.jpg"),
    ],
  },
  {
    id: "187",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Rialto",
    interiors: [
      require("../images/catalog/alma_ceramica/rialto/interiors/alma_ceramica_rialto_7909_39219.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/rialto/tovars/alma_ceramica_rialto_68132_237954_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rialto/tovars/alma_ceramica_rialto_68133_897749_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rialto/tovars/alma_ceramica_rialto_68134_213159_300x0.jpg"),
    ],
  },
  {
    id: "188",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Ricci",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/alma_ceramica/ricci/tovars/alma_ceramica_ricci_113109_694011_300x0.jpg"),
    ],
  },
  {
    id: "189",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Richard",
    interiors: [
      require("../images/catalog/alma_ceramica/richard/interiors/alma_ceramica_richard_14119_224461_0x300.jpg"),
      require("../images/catalog/alma_ceramica/richard/interiors/alma_ceramica_richard_14119_232587_0x300.jpg"),
      require("../images/catalog/alma_ceramica/richard/interiors/alma_ceramica_richard_14119_236045_0x300.jpg"),
      require("../images/catalog/alma_ceramica/richard/interiors/alma_ceramica_richard_14119_44538_0x300.jpg"),
      require("../images/catalog/alma_ceramica/richard/interiors/alma_ceramica_richard_14119_470148_0x300.jpg"),
      require("../images/catalog/alma_ceramica/richard/interiors/alma_ceramica_richard_14119_768165_0x300.jpg"),
      require("../images/catalog/alma_ceramica/richard/interiors/alma_ceramica_richard_14119_768165_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/richard/tovars/alma_ceramica_richard_110408_376442_300x0.jpg"),
      require("../images/catalog/alma_ceramica/richard/tovars/alma_ceramica_richard_110409_423120_300x0.jpg"),
      require("../images/catalog/alma_ceramica/richard/tovars/alma_ceramica_richard_110410_452621_300x0.jpg"),
      require("../images/catalog/alma_ceramica/richard/tovars/alma_ceramica_richard_110411_864484_300x0.jpg"),
      require("../images/catalog/alma_ceramica/richard/tovars/alma_ceramica_richard_110412_687502_300x0.jpg"),
      require("../images/catalog/alma_ceramica/richard/tovars/alma_ceramica_richard_110413_507189_300x0.jpg"),
    ],
  },
  {
    id: "190",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Riva",
    interiors: [
      require("../images/catalog/alma_ceramica/riva/interiors/alma_ceramica_riva_8606_922651_0x300.jpg"),
      require("../images/catalog/alma_ceramica/riva/interiors/alma_ceramica_riva_8606_922651_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/riva/tovars/alma_ceramica_riva_73852_837223_300x0.jpg"),
      require("../images/catalog/alma_ceramica/riva/tovars/alma_ceramica_riva_73853_741965_300x0.jpg"),
      require("../images/catalog/alma_ceramica/riva/tovars/alma_ceramica_riva_73855_495274_300x0.jpg"),
    ],
  },
  {
    id: "191",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Riviera",
    interiors: [
      require("../images/catalog/alma_ceramica/riviera/interiors/alma_ceramica_riviera_3479_189699_0x300.jpg"),
      require("../images/catalog/alma_ceramica/riviera/interiors/alma_ceramica_riviera_3479_189699_0x500.jpg"),
      require("../images/catalog/alma_ceramica/riviera/interiors/alma_ceramica_riviera_3479_517444_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/riviera/tovars/alma_ceramica_riviera_23065_192432_300x0.jpg"),
      require("../images/catalog/alma_ceramica/riviera/tovars/alma_ceramica_riviera_23066_284187_300x0.jpg"),
      require("../images/catalog/alma_ceramica/riviera/tovars/alma_ceramica_riviera_23067_417539_300x0.jpg"),
      require("../images/catalog/alma_ceramica/riviera/tovars/alma_ceramica_riviera_23080_255384_300x0.jpg"),
      require("../images/catalog/alma_ceramica/riviera/tovars/alma_ceramica_riviera_23081_913115_300x0.jpg"),
    ],
  },
  {
    id: "192",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Rivoli",
    interiors: [
      require("../images/catalog/alma_ceramica/rivoli/interiors/alma_ceramica_rivoli_10234_438946_0x300.jpg"),
      require("../images/catalog/alma_ceramica/rivoli/interiors/alma_ceramica_rivoli_10234_438946_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/rivoli/tovars/alma_ceramica_rivoli_87110_877374_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rivoli/tovars/alma_ceramica_rivoli_87111_943923_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rivoli/tovars/alma_ceramica_rivoli_87112_43402_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rivoli/tovars/alma_ceramica_rivoli_87113_921553_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rivoli/tovars/alma_ceramica_rivoli_87114_91985_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rivoli/tovars/alma_ceramica_rivoli_87115_466276_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rivoli/tovars/alma_ceramica_rivoli_87117_501739_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rivoli/tovars/alma_ceramica_rivoli_87118_412640_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rivoli/tovars/alma_ceramica_rivoli_87119_747865_300x0.jpg"),
    ],
  },
  {
    id: "193",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Rocko",
    interiors: [
      require("../images/catalog/alma_ceramica/rocko/interiors/alma_ceramica_rocko_15317_17699_0x300.jpg"),
      require("../images/catalog/alma_ceramica/rocko/interiors/alma_ceramica_rocko_15317_17699_0x500.jpg"),
      require("../images/catalog/alma_ceramica/rocko/interiors/alma_ceramica_rocko_15317_310356_0x300.jpg"),
      require("../images/catalog/alma_ceramica/rocko/interiors/alma_ceramica_rocko_15317_612464_0x300.jpg"),
      require("../images/catalog/alma_ceramica/rocko/interiors/alma_ceramica_rocko_15317_860819_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/rocko/tovars/alma_ceramica_rocko_115077_711164_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rocko/tovars/alma_ceramica_rocko_115078_772466_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rocko/tovars/alma_ceramica_rocko_115079_753505_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rocko/tovars/alma_ceramica_rocko_115080_608674_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rocko/tovars/alma_ceramica_rocko_115081_143958_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rocko/tovars/alma_ceramica_rocko_115082_908495_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rocko/tovars/alma_ceramica_rocko_115083_315388_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rocko/tovars/alma_ceramica_rocko_115084_830583_300x0.jpg"),
    ],
  },
  {
    id: "194",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Romano",
    interiors: [
      require("../images/catalog/alma_ceramica/romano/interiors/alma_ceramica_romano_4597_431156_0x300.jpg"),
      require("../images/catalog/alma_ceramica/romano/interiors/alma_ceramica_romano_4597_431156_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/romano/tovars/alma_ceramica_romano_33699_93714_300x0.jpg"),
      require("../images/catalog/alma_ceramica/romano/tovars/alma_ceramica_romano_33703_682767_300x0.jpg"),
    ],
  },
  {
    id: "195",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Roxana",
    interiors: [
      require("../images/catalog/alma_ceramica/roxana/interiors/alma_ceramica_roxana_8565_463610_0x300.jpg"),
      require("../images/catalog/alma_ceramica/roxana/interiors/alma_ceramica_roxana_8565_463610_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/roxana/tovars/alma_ceramica_roxana_73616_400444_300x0.jpg"),
      require("../images/catalog/alma_ceramica/roxana/tovars/alma_ceramica_roxana_73617_933949_300x0.jpg"),
      require("../images/catalog/alma_ceramica/roxana/tovars/alma_ceramica_roxana_73618_210859_300x0.jpg"),
      require("../images/catalog/alma_ceramica/roxana/tovars/alma_ceramica_roxana_73619_194005_300x0.jpg"),
      require("../images/catalog/alma_ceramica/roxana/tovars/alma_ceramica_roxana_73621_432949_300x0.jpg"),
      require("../images/catalog/alma_ceramica/roxana/tovars/alma_ceramica_roxana_73622_641950_300x0.jpg"),
    ],
  },
  {
    id: "196",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Rubi",
    interiors: [
      require("../images/catalog/alma_ceramica/rubi/interiors/alma_ceramica_rubi_9274_891645_0x300.jpg"),
      require("../images/catalog/alma_ceramica/rubi/interiors/alma_ceramica_rubi_9274_891645_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/rubi/tovars/alma_ceramica_rubi_79516_391455_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rubi/tovars/alma_ceramica_rubi_79517_488205_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rubi/tovars/alma_ceramica_rubi_79518_379558_300x0.jpg"),
      require("../images/catalog/alma_ceramica/rubi/tovars/alma_ceramica_rubi_79520_834487_300x0.jpg"),
    ],
  },
  {
    id: "197",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["60x60"],
    Name: "Alma Ceramica",
    Collection: "Salvia",
    interiors: [
      require("../images/catalog/alma_ceramica/salvia/interiors/alma_ceramica_salvia_10235_143025_0x300.jpg"),
      require("../images/catalog/alma_ceramica/salvia/interiors/alma_ceramica_salvia_10235_143025_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/salvia/tovars/alma_ceramica_salvia_87120_912276_300x0.jpg"),
      require("../images/catalog/alma_ceramica/salvia/tovars/alma_ceramica_salvia_87121_845127_300x0.jpg"),
      require("../images/catalog/alma_ceramica/salvia/tovars/alma_ceramica_salvia_87122_673321_300x0.jpg"),
      require("../images/catalog/alma_ceramica/salvia/tovars/alma_ceramica_salvia_87123_121895_300x0.jpg"),
      require("../images/catalog/alma_ceramica/salvia/tovars/alma_ceramica_salvia_87124_39251_300x0.jpg"),
      require("../images/catalog/alma_ceramica/salvia/tovars/alma_ceramica_salvia_87125_542499_300x0.jpg"),
    ],
  },
  {
    id: "198",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Sandal",
    interiors: [
      require("../images/catalog/alma_ceramica/sandal/interiors/alma_ceramica_sandal_11624_78730.jpg"),
      require("../images/catalog/alma_ceramica/sandal/interiors/alma_ceramica_sandal_11624_465973.jpg"),
      require("../images/catalog/alma_ceramica/sandal/interiors/alma_ceramica_sandal_11624_677218.jpg"),
      require("../images/catalog/alma_ceramica/sandal/interiors/alma_ceramica_sandal_11624_806022.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/sandal/tovars/alma_ceramica_sandal_95627_506308_300x0.jpg"),
      require("../images/catalog/alma_ceramica/sandal/tovars/alma_ceramica_sandal_95630_746197_300x0.jpg"),
    ],
  },
  {
    id: "199",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Scandi",
    interiors: [
      require("../images/catalog/alma_ceramica/scandi/interiors/alma_ceramica_scandi_10231_780989_0x300.jpg"),
      require("../images/catalog/alma_ceramica/scandi/interiors/alma_ceramica_scandi_10231_780989_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/scandi/tovars/alma_ceramica_scandi_87083_432872_300x0.jpg"),
      require("../images/catalog/alma_ceramica/scandi/tovars/alma_ceramica_scandi_87084_268028_300x0.jpg"),
      require("../images/catalog/alma_ceramica/scandi/tovars/alma_ceramica_scandi_87085_419753_300x0.jpg"),
      require("../images/catalog/alma_ceramica/scandi/tovars/alma_ceramica_scandi_87086_946363_300x0.jpg"),
      require("../images/catalog/alma_ceramica/scandi/tovars/alma_ceramica_scandi_87087_568282_300x0.jpg"),
      require("../images/catalog/alma_ceramica/scandi/tovars/alma_ceramica_scandi_87088_420536_300x0.jpg"),
      require("../images/catalog/alma_ceramica/scandi/tovars/alma_ceramica_scandi_87089_442486_300x0.jpg"),
    ],
  },
  {
    id: "200",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Sezal",
    interiors: [
      require("../images/catalog/alma_ceramica/sezal/interiors/alma_ceramica_sezal_4592_798507_0x300.jpg"),
      require("../images/catalog/alma_ceramica/sezal/interiors/alma_ceramica_sezal_4592_798507_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/sezal/tovars/alma_ceramica_sezal_33650_295156_300x0.jpg"),
      require("../images/catalog/alma_ceramica/sezal/tovars/alma_ceramica_sezal_33661_879433_300x0.jpg"),
      require("../images/catalog/alma_ceramica/sezal/tovars/alma_ceramica_sezal_33662_633023_300x0.jpg"),
    ],
  },
  {
    id: "201",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x90"],
    Name: "Alma Ceramica",
    Collection: "Sherwood",
    interiors: [
      require("../images/catalog/alma_ceramica/sherwood/interiors/alma_ceramica_sherwood_11625_629465.jpg"),
      require("../images/catalog/alma_ceramica/sherwood/interiors/alma_ceramica_sherwood_11625_629465.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/sherwood/tovars/alma_ceramica_sherwood_95632_819804_300x0.jpg"),
    ],
  },
  {
    id: "202",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Singapore",
    interiors: [
      require("../images/catalog/alma_ceramica/singapore/interiors/alma_ceramica_singapore_11693_429002_0x300.jpg"),
      require("../images/catalog/alma_ceramica/singapore/interiors/alma_ceramica_singapore_11693_429002_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/singapore/tovars/alma_ceramica_singapore_96184_259515_300x0.jpg"),
      require("../images/catalog/alma_ceramica/singapore/tovars/alma_ceramica_singapore_96185_117652_300x0.jpg"),
      require("../images/catalog/alma_ceramica/singapore/tovars/alma_ceramica_singapore_96186_884674_300x0.jpg"),
      require("../images/catalog/alma_ceramica/singapore/tovars/alma_ceramica_singapore_96187_219595_300x0.jpg"),
    ],
  },
  {
    id: "203",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Slash",
    interiors: [
      require("../images/catalog/alma_ceramica/slash/interiors/alma_ceramica_slash_11694_54446_0x300.jpg"),
      require("../images/catalog/alma_ceramica/slash/interiors/alma_ceramica_slash_11694_54446_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/slash/tovars/alma_ceramica_slash_96188_109255_300x0.jpg"),
      require("../images/catalog/alma_ceramica/slash/tovars/alma_ceramica_slash_96189_981216_300x0.jpg"),
    ],
  },
  {
    id: "204",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Slate_rock",
    interiors: [
      require("../images/catalog/alma_ceramica/slate_rock/interiors/alma_ceramica_slate_rock_11695_377996_0x300.jpg"),
      require("../images/catalog/alma_ceramica/slate_rock/interiors/alma_ceramica_slate_rock_11695_377996_0x500.jpg"),
      require("../images/catalog/alma_ceramica/slate_rock/interiors/alma_ceramica_slate_rock_11695_390107_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/slate_rock/tovars/alma_ceramica_slate_rock_96190_698364_300x0.jpg"),
      require("../images/catalog/alma_ceramica/slate_rock/tovars/alma_ceramica_slate_rock_96191_644882_300x0.jpg"),
      require("../images/catalog/alma_ceramica/slate_rock/tovars/alma_ceramica_slate_rock_96192_614315_300x0.jpg"),
      require("../images/catalog/alma_ceramica/slate_rock/tovars/alma_ceramica_slate_rock_96193_943006_300x0.jpg"),
      require("../images/catalog/alma_ceramica/slate_rock/tovars/alma_ceramica_slate_rock_96194_619396_300x0.jpg"),
      require("../images/catalog/alma_ceramica/slate_rock/tovars/alma_ceramica_slate_rock_96195_150104_300x0.jpg"),
      require("../images/catalog/alma_ceramica/slate_rock/tovars/alma_ceramica_slate_rock_96196_179725_300x0.jpg"),
      require("../images/catalog/alma_ceramica/slate_rock/tovars/alma_ceramica_slate_rock_96197_990542_300x0.jpg"),
    ],
  },
  {
    id: "205",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Soho",
    interiors: [
      require("../images/catalog/alma_ceramica/soho/interiors/alma_ceramica_soho_15910_97608.jpg"),
      require("../images/catalog/alma_ceramica/soho/interiors/alma_ceramica_soho_15910_221751.jpg"),
      require("../images/catalog/alma_ceramica/soho/interiors/alma_ceramica_soho_15910_727557.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/soho/tovars/alma_ceramica_soho_118182_343465_300x0.jpg"),
      require("../images/catalog/alma_ceramica/soho/tovars/alma_ceramica_soho_118183_34979.jpg"),
      require("../images/catalog/alma_ceramica/soho/tovars/alma_ceramica_soho_118184_993475.jpg"),
    ],
  },
  {
    id: "206",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Soul",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/alma_ceramica/soul/tovars/alma_ceramica_soul_107374_87986_300x0.jpg"),
      require("../images/catalog/alma_ceramica/soul/tovars/alma_ceramica_soul_107376_378684.jpg"),
      require("../images/catalog/alma_ceramica/soul/tovars/alma_ceramica_soul_115820_706389.jpg"),
      require("../images/catalog/alma_ceramica/soul/tovars/alma_ceramica_soul_107375_348872.jpg"),
    ],
  },
  {
    id: "207",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Style",
    interiors: [
      require("../images/catalog/alma_ceramica/style/interiors/alma_ceramica_style_11696_776810_0x300.jpg"),
      require("../images/catalog/alma_ceramica/style/interiors/alma_ceramica_style_11696_776810_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/style/tovars/alma_ceramica_style_96198_527021_300x0.jpg"),
      require("../images/catalog/alma_ceramica/style/tovars/alma_ceramica_style_96199_987799_300x0.jpg"),
      require("../images/catalog/alma_ceramica/style/tovars/alma_ceramica_style_96200_513838_300x0.jpg"),
    ],
  },
  {
    id: "208",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Sunshine",
    interiors: [
      require("../images/catalog/alma_ceramica/sunshine/interiors/alma_ceramica_sunshine_11697_408129_0x300.jpg"),
      require("../images/catalog/alma_ceramica/sunshine/interiors/alma_ceramica_sunshine_11697_408129_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/sunshine/tovars/alma_ceramica_sunshine_96201_386452_300x0.jpg"),
      require("../images/catalog/alma_ceramica/sunshine/tovars/alma_ceramica_sunshine_96202_34397_300x0.jpg"),
      require("../images/catalog/alma_ceramica/sunshine/tovars/alma_ceramica_sunshine_96203_815685_300x0.jpg"),
      require("../images/catalog/alma_ceramica/sunshine/tovars/alma_ceramica_sunshine_96204_255982_300x0.jpg"),
    ],
  },
  {
    id: "209",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Terrazzo",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/alma_ceramica/terrazzo/tovars/alma_ceramica_terrazzo_107380_589722_300x0.jpg"),
    ],
  },
  {
    id: "210",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Tokio",
    interiors: [
      require("../images/catalog/alma_ceramica/tokio/interiors/alma_ceramica_tokio_11698_208216_0x300.jpg"),
      require("../images/catalog/alma_ceramica/tokio/interiors/alma_ceramica_tokio_11698_208216_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/tokio/tovars/alma_ceramica_tokio_96205_493657_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tokio/tovars/alma_ceramica_tokio_96206_93316_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tokio/tovars/alma_ceramica_tokio_96207_86366_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tokio/tovars/alma_ceramica_tokio_96208_301766_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tokio/tovars/alma_ceramica_tokio_96209_963886_300x0.jpg"),
    ],
  },
  {
    id: "211",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Tori",
    interiors: [
      require("../images/catalog/alma_ceramica/tori/interiors/alma_ceramica_tori_9276_123218_0x300.jpg"),
      require("../images/catalog/alma_ceramica/tori/interiors/alma_ceramica_tori_9276_123218_0x500.jpg"),
      require("../images/catalog/alma_ceramica/tori/interiors/alma_ceramica_tori_9276_156000_0x300.jpg"),
      require("../images/catalog/alma_ceramica/tori/interiors/alma_ceramica_tori_9276_612295_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/tori/tovars/alma_ceramica_tori_79535_18675_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tori/tovars/alma_ceramica_tori_79537_283810_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tori/tovars/alma_ceramica_tori_79539_547225_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tori/tovars/alma_ceramica_tori_79541_461640_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tori/tovars/alma_ceramica_tori_79542_559652_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tori/tovars/alma_ceramica_tori_79543_868771_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tori/tovars/alma_ceramica_tori_79544_689147_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tori/tovars/alma_ceramica_tori_79545_324932_300x0.jpg"),
      require("../images/catalog/alma_ceramica/tori/tovars/alma_ceramica_tori_79546_896354_300x0.jpg"),
    ],
  },
  {
    id: "212",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Toscana",
    interiors: [
      require("../images/catalog/alma_ceramica/toscana/interiors/alma_ceramica_toscana_13532_124594.jpg"),
      require("../images/catalog/alma_ceramica/toscana/interiors/alma_ceramica_toscana_13532_516921.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/toscana/tovars/alma_ceramica_toscana_107382_321975_300x0.jpg"),
      require("../images/catalog/alma_ceramica/toscana/tovars/alma_ceramica_toscana_107383_973885_300x0.jpg"),
    ],
  },
  {
    id: "213",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Trevis",
    interiors: [
      require("../images/catalog/alma_ceramica/trevis/interiors/alma_ceramica_trevis_10236_682159_0x300.jpg"),
      require("../images/catalog/alma_ceramica/trevis/interiors/alma_ceramica_trevis_10236_682159_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/trevis/tovars/alma_ceramica_trevis_87126_517649_300x0.jpg"),
      require("../images/catalog/alma_ceramica/trevis/tovars/alma_ceramica_trevis_87127_262615_300x0.jpg"),
      require("../images/catalog/alma_ceramica/trevis/tovars/alma_ceramica_trevis_87128_422732_300x0.jpg"),
      require("../images/catalog/alma_ceramica/trevis/tovars/alma_ceramica_trevis_87129_484230_300x0.jpg"),
      require("../images/catalog/alma_ceramica/trevis/tovars/alma_ceramica_trevis_87131_134318_300x0.jpg"),
      require("../images/catalog/alma_ceramica/trevis/tovars/alma_ceramica_trevis_87132_347749_300x0.jpg"),
    ],
  },
  {
    id: "214",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Umberto",
    interiors: [
      require("../images/catalog/alma_ceramica/umberto/interiors/alma_ceramica_umberto_10243_230285_0x300.jpg"),
      require("../images/catalog/alma_ceramica/umberto/interiors/alma_ceramica_umberto_10243_230285_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/umberto/tovars/alma_ceramica_umberto_87174_305381_300x0.jpg"),
      require("../images/catalog/alma_ceramica/umberto/tovars/alma_ceramica_umberto_87175_71876_300x0.jpg"),
      require("../images/catalog/alma_ceramica/umberto/tovars/alma_ceramica_umberto_87176_162748_300x0.jpg"),
      require("../images/catalog/alma_ceramica/umberto/tovars/alma_ceramica_umberto_87177_421611_300x0.jpg"),
    ],
  },

  {
    id: "215",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Uno",
    interiors: [
      require("../images/catalog/alma_ceramica/uno/interiors/alma_ceramica_uno_11187_728611_0x300.jpg"),
      require("../images/catalog/alma_ceramica/uno/interiors/alma_ceramica_uno_11187_728611_0x500.jpg"),
      require("../images/catalog/alma_ceramica/uno/interiors/alma_ceramica_uno_11187_956042_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/uno/tovars/alma_ceramica_uno_93735_756305_300x0.jpg"),
    ],
  },
  {
    id: "216",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Urban",
    interiors: [
      require("../images/catalog/alma_ceramica/urban/interiors/alma_ceramica_uno_11187_728611.jpg"),
      require("../images/catalog/alma_ceramica/urban/interiors/alma_ceramica_uno_11187_956042.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/urban/tovars/alma_ceramica_urban_113123_108712_300x0.jpg"),
      require("../images/catalog/alma_ceramica/urban/tovars/alma_ceramica_urban_113125_902368_300x0.jpg"),
    ],
  },
  {
    id: "217",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Varadero",
    interiors: [
      require("../images/catalog/alma_ceramica/varadero/interiors/alma_ceramica_varadero_14120_53601_0x300.jpg"),
      require("../images/catalog/alma_ceramica/varadero/interiors/alma_ceramica_varadero_14120_53601_0x500.jpg"),
      require("../images/catalog/alma_ceramica/varadero/interiors/alma_ceramica_varadero_14120_597395_0x300.jpg"),
      require("../images/catalog/alma_ceramica/varadero/interiors/alma_ceramica_varadero_14120_889528_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/varadero/tovars/alma_ceramica_varadero_110414_638392_300x0.jpg"),
      require("../images/catalog/alma_ceramica/varadero/tovars/alma_ceramica_varadero_110415_641952_300x0.jpg"),
      require("../images/catalog/alma_ceramica/varadero/tovars/alma_ceramica_varadero_110500_502333_300x0.jpg"),
    ],
  },
  {
    id: "218",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Vegas",
    interiors: [
      require("../images/catalog/alma_ceramica/vegas/interiors/alma_ceramica_vegas_14121_104985_0x300.jpg"),
      require("../images/catalog/alma_ceramica/vegas/interiors/alma_ceramica_vegas_14121_279039_0x300.jpg"),
      require("../images/catalog/alma_ceramica/vegas/interiors/alma_ceramica_vegas_14121_279039_0x500.jpg"),
      require("../images/catalog/alma_ceramica/vegas/interiors/alma_ceramica_vegas_14121_376396_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/vegas/tovars/alma_ceramica_vegas_110416_493147_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vegas/tovars/alma_ceramica_vegas_110417_156542_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vegas/tovars/alma_ceramica_vegas_110418_666365_300x0.jpg"),
    ],
  },
  {
    id: "219",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Veliente",
    interiors: [
      require("../images/catalog/alma_ceramica/veliente/interiors/alma_ceramica_veliente_9282_948233_0x300.jpg"),
      require("../images/catalog/alma_ceramica/veliente/interiors/alma_ceramica_veliente_9282_948233_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/veliente/tovars/alma_ceramica_veliente_79597_29940_300x0.jpg"),
      require("../images/catalog/alma_ceramica/veliente/tovars/alma_ceramica_veliente_79598_289537_300x0.jpg"),
      require("../images/catalog/alma_ceramica/veliente/tovars/alma_ceramica_veliente_79599_203389_300x0.jpg"),
      require("../images/catalog/alma_ceramica/veliente/tovars/alma_ceramica_veliente_79600_303751_300x0.jpg"),
      require("../images/catalog/alma_ceramica/veliente/tovars/alma_ceramica_veliente_79601_714933_300x0.jpg"),
      require("../images/catalog/alma_ceramica/veliente/tovars/alma_ceramica_veliente_79602_740616_300x0.jpg"),
      require("../images/catalog/alma_ceramica/veliente/tovars/alma_ceramica_veliente_79603_718357_300x0.jpg"),
    ],
  },
  {
    id: "220",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Velvet",
    interiors: [
      require("../images/catalog/alma_ceramica/velvet/interiors/alma_ceramica_velvet_15913_810283_0x300.jpg"),
      require("../images/catalog/alma_ceramica/velvet/interiors/alma_ceramica_velvet_15913_892951_0x300.jpg"),
      require("../images/catalog/alma_ceramica/velvet/interiors/alma_ceramica_velvet_15913_892951_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/velvet/tovars/alma_ceramica_velvet_118204_411873_300x0.jpg"),
      require("../images/catalog/alma_ceramica/velvet/tovars/alma_ceramica_velvet_118205_871962_300x0.jpg"),
      require("../images/catalog/alma_ceramica/velvet/tovars/alma_ceramica_velvet_118206_214663_300x0.jpg"),
    ],
  },
  {
    id: "221",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Venezia",
    interiors: [
      require("../images/catalog/alma_ceramica/venezia/interiors/alma_ceramica_venezia_11186_56906_0x300.jpg"),
      require("../images/catalog/alma_ceramica/venezia/interiors/alma_ceramica_venezia_11186_650475_0x300.jpg"),
      require("../images/catalog/alma_ceramica/venezia/interiors/alma_ceramica_venezia_11186_650475_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/venezia/tovars/alma_ceramica_venezia_93730_277377_300x0.jpg"),
      require("../images/catalog/alma_ceramica/venezia/tovars/alma_ceramica_venezia_93731_511602_300x0.jpg"),
      require("../images/catalog/alma_ceramica/venezia/tovars/alma_ceramica_venezia_93732_477385_300x0.jpg"),
    ],
  },
  {
    id: "222",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Vialle",
    interiors: [
      require("../images/catalog/alma_ceramica/vialle/interiors/alma_ceramica_vialle_8613_536050_0x300.jpg"),
      require("../images/catalog/alma_ceramica/vialle/interiors/alma_ceramica_vialle_8613_536050_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/vialle/tovars/alma_ceramica_vialle_73877_480818_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vialle/tovars/alma_ceramica_vialle_73878_502281_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vialle/tovars/alma_ceramica_vialle_73879_960114_300x0.jpg"),
    ],
  },
  {
    id: "223",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Vilona",
    interiors: [
      require("../images/catalog/alma_ceramica/vilona/interiors/alma_ceramica_vilona_9277_36880_0x300.jpg"),
      require("../images/catalog/alma_ceramica/vilona/interiors/alma_ceramica_vilona_9277_36880_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/vilona/tovars/alma_ceramica_vilona_79553_941022_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vilona/tovars/alma_ceramica_vilona_79554_56807_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vilona/tovars/alma_ceramica_vilona_79555_386160_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vilona/tovars/alma_ceramica_vilona_79556_412353_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vilona/tovars/alma_ceramica_vilona_79557_270001_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vilona/tovars/alma_ceramica_vilona_79558_961927_300x0.jpg"),
      require("../images/catalog/alma_ceramica/vilona/tovars/alma_ceramica_vilona_79559_318179_300x0.jpg"),
    ],
  },
  {
    id: "224",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Vision",
    interiors: [
      require("../images/catalog/alma_ceramica/vision/interiors/alma_ceramica_vision_11699_56738_0x300.jpg"),
      require("../images/catalog/alma_ceramica/vision/interiors/alma_ceramica_vision_11699_56738_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/vision/tovars/alma_ceramica_vision_96210_216163_300x0.jpg"),
    ],
  },
  {
    id: "225",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Westwood",
    interiors: [
      require("../images/catalog/alma_ceramica/westwood/interiors/alma_ceramica_westwood_11626_204461.jpg"),
      require("../images/catalog/alma_ceramica/westwood/interiors/alma_ceramica_westwood_11626_293945.jpg"),
      require("../images/catalog/alma_ceramica/westwood/interiors/alma_ceramica_westwood_11626_465107.jpg"),
      require("../images/catalog/alma_ceramica/westwood/interiors/alma_ceramica_westwood_11626_937134.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/westwood/tovars/alma_ceramica_westwood_95633_978216_300x0.jpg"),
      require("../images/catalog/alma_ceramica/westwood/tovars/alma_ceramica_westwood_95634_583076_300x0.jpg"),
      require("../images/catalog/alma_ceramica/westwood/tovars/alma_ceramica_westwood_95635_85287_300x0.jpg"),
    ],
  },
  {
    id: "226",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Wizard",
    interiors: [
      require("../images/catalog/alma_ceramica/wizard/interiors/alma_ceramica_wizard_10244_557956_0x300.jpg"),
      require("../images/catalog/alma_ceramica/wizard/interiors/alma_ceramica_wizard_10244_557956_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/wizard/tovars/alma_ceramica_wizard_87178_196195_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wizard/tovars/alma_ceramica_wizard_87179_500499_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wizard/tovars/alma_ceramica_wizard_87180_919224_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wizard/tovars/alma_ceramica_wizard_87181_693628_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wizard/tovars/alma_ceramica_wizard_87182_690440_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wizard/tovars/alma_ceramica_wizard_87183_824816_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wizard/tovars/alma_ceramica_wizard_87184_212851_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wizard/tovars/alma_ceramica_wizard_87185_407384_300x0.jpg"),
    ],
  },
  {
    id: "227",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Alma Ceramica",
    Collection: "Wood",
    interiors: [
      require("../images/catalog/alma_ceramica/wood/interiors/alma_ceramica_wood_11176_234760_0x300.jpg"),
      require("../images/catalog/alma_ceramica/wood/interiors/alma_ceramica_wood_11176_234760_0x500.jpg"),
      require("../images/catalog/alma_ceramica/wood/interiors/alma_ceramica_wood_11176_706654_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/alma_ceramica/wood/tovars/alma_ceramica_wood_93671_102597_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wood/tovars/alma_ceramica_wood_93672_46575_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wood/tovars/alma_ceramica_wood_93673_38673_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wood/tovars/alma_ceramica_wood_93674_427936_300x0.jpg"),
      require("../images/catalog/alma_ceramica/wood/tovars/alma_ceramica_wood_93736_248780_300x0.jpg"),
    ],
  },

  {
    id: "228",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Alavesa",
    interiors: [
      require("../images/catalog/ametis_by_estima/alavesa/interiors/ametis_by_estima_alavesa_18706_116385_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/interiors/ametis_by_estima_alavesa_18706_116385_0x500.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/interiors/ametis_by_estima_alavesa_18706_424541_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/interiors/ametis_by_estima_alavesa_18706_987077_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129446_553919_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129447_352750_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129448_819090_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129449_155286_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129450_89494_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129451_111794_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129452_539236_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129453_30545_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129454_551965_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129455_506647_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129456_711989_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129457_964556_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129458_722448_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129459_213467_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129460_939178_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129461_896599_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129462_484066_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129463_607520_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129464_390283_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129465_553280_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129466_517815_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129467_13918_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129468_741171_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/alavesa/tovars/ametis_by_estima_alavesa_129469_578426_300x0.jpg"),
    ],
  },
  {
    id: "229",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Arti",
    interiors: [
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_177155_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_207595_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_262205_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_262205_0x500.jpg"),
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_263569_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_271817_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_285997_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_638364_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_661168_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/arti/interiors/ametis_by_estima_arti_13617_952881_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_107919_124128_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_107920_794780_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_107921_634227_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_107922_885787_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_107923_251572_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_107924_952344_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_129440_259716_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_129441_54704_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_129442_566409_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_129443_398810_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_129444_174336_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/arti/tovars/ametis_by_estima_arti_129445_884419_300x0.jpg"),
    ],
  },
  {
    id: "230",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Daintree",
    interiors: [
      require("../images/catalog/ametis_by_estima/daintree/interiors/ametis_by_estima_daintree_9836_241873_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/interiors/ametis_by_estima_daintree_9836_286705_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/interiors/ametis_by_estima_daintree_9836_286705_0x500.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/interiors/ametis_by_estima_daintree_9836_289062_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/interiors/ametis_by_estima_daintree_9836_304304_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/interiors/ametis_by_estima_daintree_9836_739053_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/interiors/ametis_by_estima_daintree_9836_751793_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84277_368333_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84280_996756_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84283_991869_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84286_602398_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84291_285218_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84292_173346_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84293_490158_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84294_814020_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84296_880612_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84297_626161_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84298_434274_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84299_35376_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84306_854654_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84307_278547_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84308_812094_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_84309_107428_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91375_386736_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91376_449849_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91377_811670_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91378_184833_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91380_770739_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91381_538240_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91382_861606_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91383_112380_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91385_569009_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91386_428490_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91387_591608_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_91388_174507_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_98334_968365_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_98335_281966_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_98336_710616_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/daintree/tovars/ametis_by_estima_daintree_98337_872015_300x0.jpg"),
    ],
  },
  {
    id: "231",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Intense",
    interiors: [
      require("../images/catalog/ametis_by_estima/intense/interiors/ametis_by_estima_intense_18707_358022_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/intense/interiors/ametis_by_estima_intense_18707_358022_0x500.jpg"),
      require("../images/catalog/ametis_by_estima/intense/interiors/ametis_by_estima_intense_18707_488891_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/intense/interiors/ametis_by_estima_intense_18707_610208_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/intense/tovars/ametis_by_estima_intense_129470_179565_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/intense/tovars/ametis_by_estima_intense_129471_913154_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/intense/tovars/ametis_by_estima_intense_129472_327061_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/intense/tovars/ametis_by_estima_intense_129473_72847_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/intense/tovars/ametis_by_estima_intense_129474_15132_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/intense/tovars/ametis_by_estima_intense_129475_868483_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/intense/tovars/ametis_by_estima_intense_129476_483909_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/intense/tovars/ametis_by_estima_intense_129477_964868_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/intense/tovars/ametis_by_estima_intense_129478_377696_300x0.jpg"),
    ],
  },
  {
    id: "232",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Kailas",
    interiors: [
      require("../images/catalog/ametis_by_estima/kailas/interiors/ametis_by_estima_kailas_9858_217066_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/interiors/ametis_by_estima_kailas_9858_40235_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/interiors/ametis_by_estima_kailas_9858_451309_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/interiors/ametis_by_estima_kailas_9858_566767_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/interiors/ametis_by_estima_kailas_9858_839057_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/interiors/ametis_by_estima_kailas_9858_839057_0x500.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/interiors/ametis_by_estima_kailas_9858_858112_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_119634_35185_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_119635_85324_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_119636_498627_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_119637_209169_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_119638_585488_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_119639_558239_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_119640_303979_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_119641_721216_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84472_20785_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84475_731184_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84478_569007_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84481_599473_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84482_218703_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84483_683170_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84484_612831_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84485_669798_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84486_928723_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84487_486091_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84488_479740_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84489_174889_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84490_204892_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84491_710551_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84492_836674_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_84493_580508_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91405_50788_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91406_863934_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91407_790539_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91408_900413_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91409_907068_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91410_584072_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91411_136862_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91412_670492_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91413_524646_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91414_654228_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91415_388216_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_91416_404728_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_94466_539993_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_94467_599069_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_94468_990009_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/kailas/tovars/ametis_by_estima_kailas_94469_605173_300x0.jpg"),
    ],
  },
  {
    id: "233",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Magmas",
    interiors: [
      require("../images/catalog/ametis_by_estima/magmas/interiors/ametis_by_estima_magmas_16292_761799_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/interiors/ametis_by_estima_magmas_16292_827523_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/interiors/ametis_by_estima_magmas_16292_827523_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120183_107406_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120184_617662_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120185_632685_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120186_557659_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120187_711069_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120188_724774_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120189_319535_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120190_103007_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120191_766463_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120192_892248_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120193_416026_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120194_133312_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120195_880575_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120196_700074_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120197_993671_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120198_693928_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120199_566101_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_120200_481616_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_129479_428044_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_129480_77302_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_129481_351641_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_129482_543839_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_129483_787333_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/magmas/tovars/ametis_by_estima_magmas_129484_569389_300x0.jpg"),
    ],
  },
  {
    id: "234",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Marmulla",
    interiors: [
      require("../images/catalog/ametis_by_estima/marmulla/interiors/ametis_by_estima_marmulla_9860_183478_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/interiors/ametis_by_estima_marmulla_9860_309381_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/interiors/ametis_by_estima_marmulla_9860_318710_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/interiors/ametis_by_estima_marmulla_9860_554209_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/interiors/ametis_by_estima_marmulla_9860_607904_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/interiors/ametis_by_estima_marmulla_9860_607904_0x500.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/interiors/ametis_by_estima_marmulla_9860_704117_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_119642_261945_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_119643_860667_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_119644_596785_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_119645_451973_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_119646_673583_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_119647_742898_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84531_304350_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84536_445015_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84541_93859_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84546_621975_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84547_437404_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84548_461685_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84549_917254_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84550_243255_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84551_767145_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84552_171658_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84553_522462_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84554_241919_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84555_161741_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84556_458783_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84557_199645_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84558_172528_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84559_189968_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84560_758654_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84561_762002_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84562_398673_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84563_441825_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84564_374834_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84565_68472_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84566_370548_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84567_850926_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84568_538213_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84569_535439_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84570_55819_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84571_248765_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84572_372113_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84573_626328_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_84574_543117_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91437_111215_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91438_433070_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91439_670721_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91440_293351_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91441_809807_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91442_120571_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91443_105022_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91444_984641_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91446_544976_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91447_865763_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91448_522882_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91449_406583_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91451_968144_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91452_97495_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91453_965593_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_91454_396635_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94470_963553_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94471_470598_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94472_827135_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94473_233996_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94474_917346_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94475_850696_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94476_795883_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94477_478740_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94478_211025_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94479_663710_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94480_405650_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_94481_562983_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_98339_384785_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_98340_470879_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_98341_373598_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/marmulla/tovars/ametis_by_estima_marmulla_98342_747421_300x0.jpg"),
    ],
  },
  {
    id: "235",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Oceanmist",
    interiors: [
      require("../images/catalog/ametis_by_estima/oceanmist/interiors/ametis_by_estima_oceanmist_16293_715344_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/interiors/ametis_by_estima_oceanmist_16293_786444_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/interiors/ametis_by_estima_oceanmist_16293_786444_0x500.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/interiors/ametis_by_estima_oceanmist_16293_911241_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120204_397102_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120205_951744_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120206_547885_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120207_521251_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120208_813233_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120209_543889_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120210_792308_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120211_875186_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120212_933149_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120213_107164_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120214_972594_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120215_550811_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120216_729850_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120217_530254_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120218_261881_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120219_454625_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120220_839790_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120221_354889_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120222_221089_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120223_732039_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120224_760916_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120225_344402_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120226_612615_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/oceanmist/tovars/ametis_by_estima_oceanmist_120227_460990_300x0.jpg"),
    ],
  },
  {
    id: "236",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Selection",
    interiors: [
      require("../images/catalog/ametis_by_estima/selection/interiors/ametis_by_estima_selection_12019_106857_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/selection/interiors/ametis_by_estima_selection_12019_185537_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/selection/interiors/ametis_by_estima_selection_12019_208154_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/selection/interiors/ametis_by_estima_selection_12019_309191_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/selection/interiors/ametis_by_estima_selection_12019_622500_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/selection/interiors/ametis_by_estima_selection_12019_637236_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/selection/interiors/ametis_by_estima_selection_12019_637236_0x500.jpg"),
      require("../images/catalog/ametis_by_estima/selection/interiors/ametis_by_estima_selection_12019_819700_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/selection/interiors/ametis_by_estima_selection_12019_999718_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98415_400382_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98416_77088_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98417_498543_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98418_819759_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98419_309830_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98420_740393_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98421_561377_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98422_737015_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98423_345963_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98424_491884_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98425_414333_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98426_722485_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98427_579647_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98428_603755_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98429_590574_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98430_696681_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98431_894665_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98432_325016_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98433_313864_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98434_660659_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98435_663233_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98436_14797_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98437_284593_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/selection/tovars/ametis_by_estima_selection_98438_307955_300x0.jpg"),
    ],
  },
  {
    id: "237",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Spectrum",
    interiors: [
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_179984_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_179984_0x500.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_186038_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_266605_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_288176_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_509023_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_615719_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_891332_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_938392_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/interiors/ametis_by_estima_spectrum_12018_970856_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_119663_894149_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_119664_992038_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_119665_565034_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_119666_969475_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_119667_490666_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_119668_764204_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_119669_554963_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_119670_48906_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98343_593598_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98344_392852_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98345_692730_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98346_760740_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98347_363917_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98348_250027_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98349_48518_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98350_798928_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98351_289497_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98352_364459_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98353_543020_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98354_243769_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98355_595172_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98356_875569_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98357_182939_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98358_419206_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98359_322241_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98360_204441_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98361_363720_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98362_198888_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98363_250134_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98364_780950_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98365_167253_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98366_522102_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98367_491566_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98368_39269_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98369_111486_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98370_866353_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98371_500150_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98372_475085_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98373_613774_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98374_93748_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98375_857938_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98376_306504_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98377_844489_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98378_221856_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98379_546533_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98380_883009_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98381_20784_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98382_826031_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98383_247468_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98384_553806_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98385_69800_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98386_832642_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98387_429375_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98388_242741_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98389_251848_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98390_741617_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98391_437183_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98392_605570_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98393_930507_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98394_677318_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98395_386520_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98396_97761_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98397_199420_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98398_868088_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98399_127032_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98400_300908_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98401_734442_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98402_617183_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98403_765994_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98404_348216_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98405_700932_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98406_623933_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98407_644722_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98408_545422_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98409_835790_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98410_191255_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98411_428431_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98412_846576_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98413_17286_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/spectrum/tovars/ametis_by_estima_spectrum_98414_665901_300x0.jpg"),
    ],
  },
  {
    id: "238",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Ametist by Estima",
    Collection: "Supreme",
    interiors: [
      require("../images/catalog/ametis_by_estima/supreme/interiors/ametis_by_estima_supreme_12020_108991_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/interiors/ametis_by_estima_supreme_12020_424141_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/interiors/ametis_by_estima_supreme_12020_473176_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/interiors/ametis_by_estima_supreme_12020_683209_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/interiors/ametis_by_estima_supreme_12020_761770_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/interiors/ametis_by_estima_supreme_12020_873009_0x300.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/interiors/ametis_by_estima_supreme_12020_873009_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_107915_790721_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_107916_847931_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_107917_773241_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_107918_432662_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112801_829987_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112802_627255_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112803_690904_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112804_583326_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112805_95722_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112806_290041_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112807_937034_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112808_997210_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112809_664632_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112810_651258_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112811_860079_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112812_713476_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112813_558393_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112814_642665_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_112815_291296_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_119657_546883_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_119658_849329_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_119659_683817_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_119660_111994_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_119661_818035_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_119662_539848_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98439_550220_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98440_120383_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98441_489212_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98442_968653_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98443_956961_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98444_496500_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98445_634555_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98446_357344_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98451_563589_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98452_133099_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98453_177103_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98454_863421_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98455_863493_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98456_728481_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98457_600436_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98458_209456_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98459_220366_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98460_14770_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98461_921943_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98462_790015_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98463_608527_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98464_512517_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98465_486696_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98466_503192_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98467_827534_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98468_790561_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98469_163852_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98470_490768_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98471_795359_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98472_438446_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98473_788724_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98474_345580_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98475_548831_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98476_277936_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98477_314234_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98478_505792_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98479_764437_300x0.jpg"),
      require("../images/catalog/ametis_by_estima/supreme/tovars/ametis_by_estima_supreme_98480_938790_300x0.jpg"),
    ],
  },

  {
    id: "239",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Acate",
    interiors: [
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_167402_0x300.jpg"),
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_300786_0x300.jpg"),
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_309981_0x300.jpg"),
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_415860_0x300.jpg"),
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_467540_0x300.jpg"),
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_467540_0x500.jpg"),
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_734870_0x300.jpg"),
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_819655_0x300.jpg"),
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_889664_0x300.jpg"),
      require("../images/catalog/azori/acate/interiors/azori_acate_17906_91414_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/acate/tovars/azori_acate_125355_998295_300x0.jpg"),
      require("../images/catalog/azori/acate/tovars/azori_acate_125356_587937_300x0.jpg"),
      require("../images/catalog/azori/acate/tovars/azori_acate_125357_807578_300x0.jpg"),
      require("../images/catalog/azori/acate/tovars/azori_acate_125358_704166_300x0.jpg"),
      require("../images/catalog/azori/acate/tovars/azori_acate_125359_585383_300x0.jpg"),
      require("../images/catalog/azori/acate/tovars/azori_acate_125360_719208_300x0.jpg"),
      require("../images/catalog/azori/acate/tovars/azori_acate_125361_883385_300x0.jpg"),
    ],
  },

  {
    id: "240",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Alpi",
    interiors: [
      require("../images/catalog/azori/alpi/interiors/azori_alpi_13431_157172_0x300.jpg"),
      require("../images/catalog/azori/alpi/interiors/azori_alpi_13431_157172_0x500.jpg"),
      require("../images/catalog/azori/alpi/interiors/azori_alpi_13431_53534_0x300.jpg"),
      require("../images/catalog/azori/alpi/interiors/azori_alpi_13431_755637_0x300.jpg"),
      require("../images/catalog/azori/alpi/interiors/azori_alpi_13431_845161_0x300.jpg"),
      require("../images/catalog/azori/alpi/interiors/azori_alpi_13431_854982_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/alpi/tovars/azori_alpi_106974_909940_300x0.jpg"),
      require("../images/catalog/azori/alpi/tovars/azori_alpi_106975_667279_300x0.jpg"),
      require("../images/catalog/azori/alpi/tovars/azori_alpi_106976_322498_300x0.jpg"),
      require("../images/catalog/azori/alpi/tovars/azori_alpi_106977_272096_300x0.jpg"),
      require("../images/catalog/azori/alpi/tovars/azori_alpi_108472_916218_300x0.jpg"),
      require("../images/catalog/azori/alpi/tovars/azori_alpi_108473_417184_300x0.jpg"),
    ],
  },
  {
    id: "241",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Amati",
    interiors: [
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_296512_0x300.jpg"),
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_461035_0x300.jpg"),
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_526886_0x300.jpg"),
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_562940_0x300.jpg"),
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_603149_0x300.jpg"),
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_645190_0x300.jpg"),
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_67190_0x300.jpg"),
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_768208_0x300.jpg"),
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_77013_0x300.jpg"),
      require("../images/catalog/azori/amati/interiors/azori_amati_4710_77013_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/amati/tovars/azori_amati_34832_284161_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_34833_453628_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_34834_890366_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_34835_453083_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_34836_759644_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_34838_668869_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_34840_23189_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_34841_358909_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_34842_606439_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_94330_766285_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_94331_24577_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_94332_830744_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_94333_230976_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_94334_32475_300x0.jpg"),
      require("../images/catalog/azori/amati/tovars/azori_amati_94335_349662_300x0.jpg"),
    ],
  },
  {
    id: "242",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Aapulia_oro",
    interiors: [
      require("../images/catalog/azori/apulia_oro/interiors/azori_apulia_oro_13725_495321_0x300.jpg"),
      require("../images/catalog/azori/apulia_oro/interiors/azori_apulia_oro_13725_495321_0x500.jpg"),
      require("../images/catalog/azori/apulia_oro/interiors/azori_apulia_oro_13725_594824_0x300.jpg"),
      require("../images/catalog/azori/apulia_oro/interiors/azori_apulia_oro_13725_905392_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/apulia_oro/tovars/azori_apulia_oro_108730_287300_300x0.jpg"),
      require("../images/catalog/azori/apulia_oro/tovars/azori_apulia_oro_108731_851888_300x0.jpg"),
      require("../images/catalog/azori/apulia_oro/tovars/azori_apulia_oro_108733_551975_300x0.jpg"),
      require("../images/catalog/azori/apulia_oro/tovars/azori_apulia_oro_108734_963325_300x0.jpg"),
      require("../images/catalog/azori/apulia_oro/tovars/azori_apulia_oro_108735_192674_300x0.jpg"),
      require("../images/catalog/azori/apulia_oro/tovars/azori_apulia_oro_112641_501894_300x0.jpg"),
    ],
  },
  {
    id: "243",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Artemest",
    interiors: [
      require("../images/catalog/azori/artemest/interiors/azori_artemest_17909_276933_0x300.jpg"),
      require("../images/catalog/azori/artemest/interiors/azori_artemest_17909_276933_0x500.jpg"),
      require("../images/catalog/azori/artemest/interiors/azori_artemest_17909_351162_0x300.jpg"),
      require("../images/catalog/azori/artemest/interiors/azori_artemest_17909_364372_0x300.jpg"),
      require("../images/catalog/azori/artemest/interiors/azori_artemest_17909_428808_0x300.jpg"),
      require("../images/catalog/azori/artemest/interiors/azori_artemest_17909_61000_0x300.jpg"),
      require("../images/catalog/azori/artemest/interiors/azori_artemest_17909_953327_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/artemest/tovars/azori_artemest_125389_904435_300x0.jpg"),
      require("../images/catalog/azori/artemest/tovars/azori_artemest_125390_845656_300x0.jpg"),
      require("../images/catalog/azori/artemest/tovars/azori_artemest_125391_49477_300x0.jpg"),
      require("../images/catalog/azori/artemest/tovars/azori_artemest_125392_185840_300x0.jpg"),
    ],
  },
  {
    id: "244",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Ascoli",
    interiors: [
      require("../images/catalog/azori/ascoli/interiors/azori_ascoli_9373_257932_0x300.jpg"),
      require("../images/catalog/azori/ascoli/interiors/azori_ascoli_9373_388063_0x300.jpg"),
      require("../images/catalog/azori/ascoli/interiors/azori_ascoli_9373_389633_0x300.jpg"),
      require("../images/catalog/azori/ascoli/interiors/azori_ascoli_9373_389633_0x500.jpg"),
      require("../images/catalog/azori/ascoli/interiors/azori_ascoli_9373_574778_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80599_632499_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80600_60351_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80601_549678_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80602_848550_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80603_219259_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80604_716306_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80605_878499_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80606_317117_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80607_695938_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80608_934026_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80611_239033_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_80612_967296_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_87300_182750_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_87301_366954_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_87302_668173_300x0.jpg"),
      require("../images/catalog/azori/ascoli/tovars/azori_ascoli_87303_86985_300x0.jpg"),
    ],
  },
  {
    id: "245",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Atlas",
    interiors: [
      require("../images/catalog/azori/atlas/interiors/azori_atlas_13430_100694_0x300.jpg"),
      require("../images/catalog/azori/atlas/interiors/azori_atlas_13430_339962_0x300.jpg"),
      require("../images/catalog/azori/atlas/interiors/azori_atlas_13430_339962_0x500.jpg"),
      require("../images/catalog/azori/atlas/interiors/azori_atlas_13430_862686_0x300.jpg"),
      require("../images/catalog/azori/atlas/interiors/azori_atlas_13430_989255_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/atlas/tovars/azori_atlas_106960_266779_300x0.jpg"),
      require("../images/catalog/azori/atlas/tovars/azori_atlas_106961_977859_300x0.jpg"),
      require("../images/catalog/azori/atlas/tovars/azori_atlas_106962_200977_300x0.jpg"),
      require("../images/catalog/azori/atlas/tovars/azori_atlas_106963_170489_300x0.jpg"),
      require("../images/catalog/azori/atlas/tovars/azori_atlas_106964_453950_300x0.jpg"),
      require("../images/catalog/azori/atlas/tovars/azori_atlas_106965_996616_300x0.jpg"),
      require("../images/catalog/azori/atlas/tovars/azori_atlas_106966_316102_300x0.jpg"),
    ],
  },
  {
    id: "246",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Aura",
    interiors: [
      require("../images/catalog/azori/aura/interiors/azori_aura_8125_404447_0x300.jpg"),
      require("../images/catalog/azori/aura/interiors/azori_aura_8125_526070_0x300.jpg"),
      require("../images/catalog/azori/aura/interiors/azori_aura_8125_526070_0x500.jpg"),
      require("../images/catalog/azori/aura/interiors/azori_aura_8125_794977_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/aura/tovars/azori_aura_70324_97244_300x0.jpg"),
      require("../images/catalog/azori/aura/tovars/azori_aura_70325_26193_300x0.jpg"),
      require("../images/catalog/azori/aura/tovars/azori_aura_70330_966878_300x0.jpg"),
      require("../images/catalog/azori/aura/tovars/azori_aura_70331_700637_300x0.jpg"),
      require("../images/catalog/azori/aura/tovars/azori_aura_70332_745566_300x0.jpg"),
      require("../images/catalog/azori/aura/tovars/azori_aura_70333_471144_300x0.jpg"),
      require("../images/catalog/azori/aura/tovars/azori_aura_80629_866719_300x0.jpg"),
      require("../images/catalog/azori/aura/tovars/azori_aura_80630_923366_300x0.jpg"),
    ],
  },
  {
    id: "247",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Azolla",
    interiors: [
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_110206_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_246403_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_592205_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_608943_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_74223_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_772764_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_772764_0x500.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_815891_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_820305_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_858920_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_88230_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_898568_0x300.jpg"),
      require("../images/catalog/azori/azolla/interiors/azori_azolla_10968_977982_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92284_795170_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92285_500057_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92286_658530_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92287_408436_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92288_449063_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92289_588509_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92290_980667_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92291_707693_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92292_713465_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92293_666566_300x0.jpg"),
      require("../images/catalog/azori/azolla/tovars/azori_azolla_92294_813307_300x0.jpg"),
    ],
  },
  {
    id: "248",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Bianco Venatino",
    interiors: [
      require("../images/catalog/azori/bianco_venatino/interiors/azori_bianco_venatino_18662_566695_0x300.jpg"),
      require("../images/catalog/azori/bianco_venatino/interiors/azori_bianco_venatino_18662_64108_0x300.jpg"),
      require("../images/catalog/azori/bianco_venatino/interiors/azori_bianco_venatino_18662_64197_0x300.jpg"),
      require("../images/catalog/azori/bianco_venatino/interiors/azori_bianco_venatino_18662_660381_0x300.jpg"),
      require("../images/catalog/azori/bianco_venatino/interiors/azori_bianco_venatino_18662_791966_0x300.jpg"),
      require("../images/catalog/azori/bianco_venatino/interiors/azori_bianco_venatino_18662_791966_0x500.jpg"),
      require("../images/catalog/azori/bianco_venatino/interiors/azori_bianco_venatino_18662_914022_0x300.jpg"),
      require("../images/catalog/azori/bianco_venatino/interiors/azori_bianco_venatino_18662_942867_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/bianco_venatino/tovars/azori_bianco_venatino_129227_718234_300x0.jpg"),
      require("../images/catalog/azori/bianco_venatino/tovars/azori_bianco_venatino_129228_911859_300x0.jpg"),
    ],
  },
  {
    id: "249",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Bricks",
    interiors: [
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_185589_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_185616_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_187293_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_191950_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_22294_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_340523_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_340523_0x500.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_359088_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_363760_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_515378_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_527924_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_600233_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_639880_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_68367_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_763143_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_832301_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_879757_0x300.jpg"),
      require("../images/catalog/azori/bricks/interiors/azori_bricks_17907_901005_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/bricks/tovars/azori_bricks_125379_839835_300x0.jpg"),
      require("../images/catalog/azori/bricks/tovars/azori_bricks_125380_619687_300x0.jpg"),
      require("../images/catalog/azori/bricks/tovars/azori_bricks_125381_383127_300x0.jpg"),
      require("../images/catalog/azori/bricks/tovars/azori_bricks_125382_478388_300x0.jpg"),
      require("../images/catalog/azori/bricks/tovars/azori_bricks_125383_428741_300x0.jpg"),
      require("../images/catalog/azori/bricks/tovars/azori_bricks_125384_168564_300x0.jpg"),
      require("../images/catalog/azori/bricks/tovars/azori_bricks_125385_435325_300x0.jpg"),
    ],
  },
  {
    id: "250",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Calacatta Ivori",
    interiors: [
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_143925_0x300.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_252733_0x300.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_416582_0x300.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_482345_0x300.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_640633_0x300.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_675886_0x300.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_785956_0x300.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_924212_0x300.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_926774_0x300.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_926774_0x500.jpg"),
      require("../images/catalog/azori/calacatta_ivori/interiors/azori_calacatta_ivori_15900_930022_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/calacatta_ivori/tovars/azori_calacatta_ivori_118136_869413_300x0.jpg"),
      require("../images/catalog/azori/calacatta_ivori/tovars/azori_calacatta_ivori_118137_141915_300x0.jpg"),
      require("../images/catalog/azori/calacatta_ivori/tovars/azori_calacatta_ivori_118138_845111_300x0.jpg"),
      require("../images/catalog/azori/calacatta_ivori/tovars/azori_calacatta_ivori_118139_233738_300x0.jpg"),
    ],
  },
  {
    id: "251",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Calacatta Opaco",
    interiors: [
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_286831_0x300.jpg"),
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_292270_0x300.jpg"),
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_327205_0x300.jpg"),
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_503705_0x300.jpg"),
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_617207_0x300.jpg"),
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_686728_0x300.jpg"),
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_900256_0x300.jpg"),
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_945842_0x300.jpg"),
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_99701_0x300.jpg"),
      require("../images/catalog/azori/calacatta_opaco/interiors/azori_calacatta_opaco_18646_99701_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/calacatta_opaco/tovars/azori_calacatta_opaco_129202_506072_300x0.jpg"),
      require("../images/catalog/azori/calacatta_opaco/tovars/azori_calacatta_opaco_129203_165709_300x0.jpg"),
      require("../images/catalog/azori/calacatta_opaco/tovars/azori_calacatta_opaco_129204_192907_300x0.jpg"),
    ],
  },
  {
    id: "252",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Calacatta Royal",
    interiors: [
      require("../images/catalog/azori/calacatta_royal/interiors/azori_calacatta_royal_10595_27318_0x300.jpg"),
      require("../images/catalog/azori/calacatta_royal/interiors/azori_calacatta_royal_10595_27318_0x500.jpg"),
      require("../images/catalog/azori/calacatta_royal/interiors/azori_calacatta_royal_10595_40862_0x300.jpg"),
      require("../images/catalog/azori/calacatta_royal/interiors/azori_calacatta_royal_10595_512249_0x300.jpg"),
      require("../images/catalog/azori/calacatta_royal/interiors/azori_calacatta_royal_10595_750466_0x300.jpg"),
      require("../images/catalog/azori/calacatta_royal/interiors/azori_calacatta_royal_10595_844840_0x300.jpg"),
      require("../images/catalog/azori/calacatta_royal/interiors/azori_calacatta_royal_10595_910406_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/calacatta_royal/tovars/azori_calacatta_royal_107008_855809_300x0.jpg"),
      require("../images/catalog/azori/calacatta_royal/tovars/azori_calacatta_royal_119945_754680_300x0.jpg"),
      require("../images/catalog/azori/calacatta_royal/tovars/azori_calacatta_royal_119946_798914_300x0.jpg"),
      require("../images/catalog/azori/calacatta_royal/tovars/azori_calacatta_royal_90027_514934_300x0.jpg"),
      require("../images/catalog/azori/calacatta_royal/tovars/azori_calacatta_royal_90028_315748_300x0.jpg"),
      require("../images/catalog/azori/calacatta_royal/tovars/azori_calacatta_royal_90029_559824_300x0.jpg"),
      require("../images/catalog/azori/calacatta_royal/tovars/azori_calacatta_royal_90030_648096_300x0.jpg"),
      require("../images/catalog/azori/calacatta_royal/tovars/azori_calacatta_royal_90031_817626_300x0.jpg"),
    ],
  },
  {
    id: "253",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Calypso",
    interiors: [
      require("../images/catalog/azori/calypso/interiors/azori_calypso_16252_269853_0x300.jpg"),
      require("../images/catalog/azori/calypso/interiors/azori_calypso_16252_269853_0x500.jpg"),
      require("../images/catalog/azori/calypso/interiors/azori_calypso_16252_574831_0x300.jpg"),
      require("../images/catalog/azori/calypso/interiors/azori_calypso_16252_889580_0x300.jpg"),
      require("../images/catalog/azori/calypso/interiors/azori_calypso_16252_919896_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/calypso/tovars/azori_calypso_119947_721168_300x0.jpg"),
      require("../images/catalog/azori/calypso/tovars/azori_calypso_119948_811556_300x0.jpg"),
      require("../images/catalog/azori/calypso/tovars/azori_calypso_119949_67239_300x0.jpg"),
      require("../images/catalog/azori/calypso/tovars/azori_calypso_119950_108556_300x0.jpg"),
      require("../images/catalog/azori/calypso/tovars/azori_calypso_119951_299730_300x0.jpg"),
    ],
  },
  {
    id: "254",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Carrara",
    interiors: [
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_193214_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_305230_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_316511_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_329399_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_357956_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_475889_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_486140_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_517815_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_592903_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_592903_0x500.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_798669_0x300.jpg"),
      require("../images/catalog/azori/carrara/interiors/azori_carrara_18644_945247_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/carrara/tovars/azori_carrara_129194_768454_300x0.jpg"),
      require("../images/catalog/azori/carrara/tovars/azori_carrara_129195_984284_300x0.jpg"),
      require("../images/catalog/azori/carrara/tovars/azori_carrara_129196_44997_300x0.jpg"),
    ],
  },
  {
    id: "255",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Defile",
    interiors: [
      require("../images/catalog/azori/defile/interiors/azori_defile_654_825145_0x300.jpg"),
      require("../images/catalog/azori/defile/interiors/azori_defile_654_825145_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/defile/tovars/azori_defile_5216_945883_300x0.jpg"),
      require("../images/catalog/azori/defile/tovars/azori_defile_5217_762415_300x0.jpg"),
      require("../images/catalog/azori/defile/tovars/azori_defile_5218_131989_300x0.jpg"),
      require("../images/catalog/azori/defile/tovars/azori_defile_5219_856861_300x0.jpg"),
      require("../images/catalog/azori/defile/tovars/azori_defile_5220_627905_300x0.jpg"),
      require("../images/catalog/azori/defile/tovars/azori_defile_87296_366830_300x0.jpg"),
    ],
  },
  {
    id: "256",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Desert",
    interiors: [
      require("../images/catalog/azori/desert/interiors/azori_desert_15501_274852_0x300.jpg"),
      require("../images/catalog/azori/desert/interiors/azori_desert_15501_299312_0x300.jpg"),
      require("../images/catalog/azori/desert/interiors/azori_desert_15501_410705_0x300.jpg"),
      require("../images/catalog/azori/desert/interiors/azori_desert_15501_45750_0x300.jpg"),
      require("../images/catalog/azori/desert/interiors/azori_desert_15501_604450_0x300.jpg"),
      require("../images/catalog/azori/desert/interiors/azori_desert_15501_642849_0x300.jpg"),
      require("../images/catalog/azori/desert/interiors/azori_desert_15501_810202_0x300.jpg"),
      require("../images/catalog/azori/desert/interiors/azori_desert_15501_826413_0x300.jpg"),
      require("../images/catalog/azori/desert/interiors/azori_desert_15501_826413_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/desert/tovars/azori_desert_115610_531531_300x0.jpg"),
      require("../images/catalog/azori/desert/tovars/azori_desert_115611_253679_300x0.jpg"),
      require("../images/catalog/azori/desert/tovars/azori_desert_115612_749667_300x0.jpg"),
      require("../images/catalog/azori/desert/tovars/azori_desert_115613_694166_300x0.jpg"),
      require("../images/catalog/azori/desert/tovars/azori_desert_115614_984023_300x0.jpg"),
      require("../images/catalog/azori/desert/tovars/azori_desert_118148_175088_300x0.jpg"),
      require("../images/catalog/azori/desert/tovars/azori_desert_118149_911807_300x0.jpg"),
    ],
  },
  {
    id: "257",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Devore",
    interiors: [
      require("../images/catalog/azori/devore/interiors/azori_devore_9375_111420_0x300.jpg"),
      require("../images/catalog/azori/devore/interiors/azori_devore_9375_344234_0x300.jpg"),
      require("../images/catalog/azori/devore/interiors/azori_devore_9375_614229_0x300.jpg"),
      require("../images/catalog/azori/devore/interiors/azori_devore_9375_614229_0x500.jpg"),
      require("../images/catalog/azori/devore/interiors/azori_devore_9375_623326_0x300.jpg"),
      require("../images/catalog/azori/devore/interiors/azori_devore_9375_807177_0x300.jpg"),
      require("../images/catalog/azori/devore/interiors/azori_devore_9375_851292_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/devore/tovars/azori_devore_80613_706821_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80614_782016_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80615_620300_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80616_882160_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80617_901300_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80618_995443_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80619_809144_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80620_192941_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80621_816716_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80622_436954_300x0.jpg"),
      require("../images/catalog/azori/devore/tovars/azori_devore_80623_283013_300x0.jpg"),
    ],
  },
  {
    id: "258",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Ebri",
    interiors: [
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_114848_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_743339_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_773873_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_863525_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_872504_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_880888_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_883028_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_905826_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_925850_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_954781_0x300.jpg"),
      require("../images/catalog/azori/ebri/interiors/azori_ebri_17911_954781_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125401_647138_300x0.jpg"),
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125402_300843_300x0.jpg"),
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125403_110924_300x0.jpg"),
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125404_732590_300x0.jpg"),
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125405_83593_300x0.jpg"),
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125406_884753_300x0.jpg"),
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125407_389456_300x0.jpg"),
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125408_471453_300x0.jpg"),
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125409_174580_300x0.jpg"),
      require("../images/catalog/azori/ebri/tovars/azori_ebri_125410_795265_300x0.jpg"),
    ],
  },
  {
    id: "259",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Eclipse",
    interiors: [
      require("../images/catalog/azori/eclipse/interiors/azori_eclipse_6519_297222_0x300.jpg"),
      require("../images/catalog/azori/eclipse/interiors/azori_eclipse_6519_360160_0x300.jpg"),
      require("../images/catalog/azori/eclipse/interiors/azori_eclipse_6519_360160_0x500.jpg"),
      require("../images/catalog/azori/eclipse/interiors/azori_eclipse_6519_829046_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_53989_538134_300x0.jpg"),
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_53991_96136_300x0.jpg"),
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_53992_832456_300x0.jpg"),
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_53994_937807_300x0.jpg"),
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_53996_327080_300x0.jpg"),
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_53997_759245_300x0.jpg"),
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_53998_84371_300x0.jpg"),
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_54001_972522_300x0.jpg"),
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_54942_596377_300x0.jpg"),
      require("../images/catalog/azori/eclipse/tovars/azori_eclipse_54945_832116_300x0.jpg"),
    ],
  },
  {
    id: "750",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Ehlara",
    interiors: [
      require("../images/catalog/azori/ehlara/interiors/azori_ehlara_4546_107753_0x300.jpg"),
      require("../images/catalog/azori/ehlara/interiors/azori_ehlara_4546_231518_0x300.jpg"),
      require("../images/catalog/azori/ehlara/interiors/azori_ehlara_4546_630204_0x300.jpg"),
      require("../images/catalog/azori/ehlara/interiors/azori_ehlara_4546_630204_0x500.jpg"),
      require("../images/catalog/azori/ehlara/interiors/azori_ehlara_4546_746083_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/ehlara/tovars/azori_ehlara_33245_76110_300x0.jpg"),
      require("../images/catalog/azori/ehlara/tovars/azori_ehlara_33247_734683_300x0.jpg"),
      require("../images/catalog/azori/ehlara/tovars/azori_ehlara_33248_124159_300x0.jpg"),
      require("../images/catalog/azori/ehlara/tovars/azori_ehlara_33250_462653_300x0.jpg"),
      require("../images/catalog/azori/ehlara/tovars/azori_ehlara_33252_316485_300x0.jpg"),
      require("../images/catalog/azori/ehlara/tovars/azori_ehlara_33254_143187_300x0.jpg"),
      require("../images/catalog/azori/ehlara/tovars/azori_ehlara_74666_960253_300x0.jpg"),
      require("../images/catalog/azori/ehlara/tovars/azori_ehlara_74667_615778_300x0.jpg"),
      require("../images/catalog/azori/ehlara/tovars/azori_ehlara_87297_596643_300x0.jpg"),
    ],
  },
  {
    id: "260",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Equadore",
    interiors: [
      require("../images/catalog/azori/equadore/interiors/azori_equadore_9376_607323_0x300.jpg"),
      require("../images/catalog/azori/equadore/interiors/azori_equadore_9376_607323_0x500.jpg"),
      require("../images/catalog/azori/equadore/interiors/azori_equadore_9376_633175_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/equadore/tovars/azori_equadore_80626_240585_300x0.jpg"),
      require("../images/catalog/azori/equadore/tovars/azori_equadore_80627_300865_300x0.jpg"),
      require("../images/catalog/azori/equadore/tovars/azori_equadore_80628_509367_300x0.jpg"),
    ],
  },
  {
    id: "261",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Fortezza",
    interiors: [
      require("../images/catalog/azori/fortezza/interiors/azori_fortezza_17905_123245_0x300.jpg"),
      require("../images/catalog/azori/fortezza/interiors/azori_fortezza_17905_123245_0x500.jpg"),
      require("../images/catalog/azori/fortezza/interiors/azori_fortezza_17905_225981_0x300.jpg"),
      require("../images/catalog/azori/fortezza/interiors/azori_fortezza_17905_232496_0x300.jpg"),
      require("../images/catalog/azori/fortezza/interiors/azori_fortezza_17905_750316_0x300.jpg"),
      require("../images/catalog/azori/fortezza/interiors/azori_fortezza_17905_815278_0x300.jpg"),
      require("../images/catalog/azori/fortezza/interiors/azori_fortezza_17905_910817_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/fortezza/tovars/azori_fortezza_125349_434903_300x0.jpg"),
      require("../images/catalog/azori/fortezza/tovars/azori_fortezza_125350_496433_300x0.jpg"),
      require("../images/catalog/azori/fortezza/tovars/azori_fortezza_125351_768083_300x0.jpg"),
      require("../images/catalog/azori/fortezza/tovars/azori_fortezza_125352_547304_300x0.jpg"),
      require("../images/catalog/azori/fortezza/tovars/azori_fortezza_125353_809706_300x0.jpg"),
      require("../images/catalog/azori/fortezza/tovars/azori_fortezza_125354_642251_300x0.jpg"),
    ],
  },
  {
    id: "262",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Global",
    interiors: [
      require("../images/catalog/azori/global/interiors/azori_global_9378_124984_0x300.jpg"),
      require("../images/catalog/azori/global/interiors/azori_global_9378_215396_0x300.jpg"),
      require("../images/catalog/azori/global/interiors/azori_global_9378_244132_0x300.jpg"),
      require("../images/catalog/azori/global/interiors/azori_global_9378_363314_0x300.jpg"),
      require("../images/catalog/azori/global/interiors/azori_global_9378_463647_0x300.jpg"),
      require("../images/catalog/azori/global/interiors/azori_global_9378_50685_0x300.jpg"),
      require("../images/catalog/azori/global/interiors/azori_global_9378_663425_0x300.jpg"),
      require("../images/catalog/azori/global/interiors/azori_global_9378_772810_0x300.jpg"),
      require("../images/catalog/azori/global/interiors/azori_global_9378_772810_0x500.jpg"),
      require("../images/catalog/azori/global/interiors/azori_global_9378_915107_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/global/tovars/azori_global_106971_599394_300x0.jpg"),
      require("../images/catalog/azori/global/tovars/azori_global_106972_525482_300x0.jpg"),
      require("../images/catalog/azori/global/tovars/azori_global_107016_357717_300x0.jpg"),
      require("../images/catalog/azori/global/tovars/azori_global_80636_771916_300x0.jpg"),
      require("../images/catalog/azori/global/tovars/azori_global_80637_768980_300x0.jpg"),
      require("../images/catalog/azori/global/tovars/azori_global_97574_938684_300x0.jpg"),
    ],
  },
  {
    id: "263",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Grazia",
    interiors: [
      require("../images/catalog/azori/grazia/interiors/azori_grazia_6145_173871_0x300.jpg"),
      require("../images/catalog/azori/grazia/interiors/azori_grazia_6145_173871_0x500.jpg"),
      require("../images/catalog/azori/grazia/interiors/azori_grazia_6145_917688_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/grazia/tovars/azori_grazia_23984_753421_300x0.jpg"),
      require("../images/catalog/azori/grazia/tovars/azori_grazia_23985_225629_300x0.jpg"),
      require("../images/catalog/azori/grazia/tovars/azori_grazia_50594_72766_300x0.jpg"),
      require("../images/catalog/azori/grazia/tovars/azori_grazia_50595_542969_300x0.jpg"),
      require("../images/catalog/azori/grazia/tovars/azori_grazia_50596_928125_300x0.jpg"),
      require("../images/catalog/azori/grazia/tovars/azori_grazia_50599_759215_300x0.jpg"),
      require("../images/catalog/azori/grazia/tovars/azori_grazia_50600_672101_300x0.jpg"),
      require("../images/catalog/azori/grazia/tovars/azori_grazia_50601_496815_300x0.jpg"),
      require("../images/catalog/azori/grazia/tovars/azori_grazia_50602_428483_300x0.jpg"),
    ],
  },
  {
    id: "264",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Grunge",
    interiors: [
      require("../images/catalog/azori/grunge/interiors/azori_grunge_10596_233777_0x300.jpg"),
      require("../images/catalog/azori/grunge/interiors/azori_grunge_10596_645051_0x300.jpg"),
      require("../images/catalog/azori/grunge/interiors/azori_grunge_10596_78248_0x300.jpg"),
      require("../images/catalog/azori/grunge/interiors/azori_grunge_10596_78248_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/grunge/tovars/azori_grunge_90032_921711_300x0.jpg"),
      require("../images/catalog/azori/grunge/tovars/azori_grunge_90033_797956_300x0.jpg"),
      require("../images/catalog/azori/grunge/tovars/azori_grunge_90034_431241_300x0.jpg"),
      require("../images/catalog/azori/grunge/tovars/azori_grunge_90035_809418_300x0.jpg"),
      require("../images/catalog/azori/grunge/tovars/azori_grunge_90036_601746_300x0.jpg"),
      require("../images/catalog/azori/grunge/tovars/azori_grunge_92283_772165_300x0.jpg"),
      require("../images/catalog/azori/grunge/tovars/azori_grunge_97573_508526_300x0.jpg"),
    ],
  },
  {
    id: "265",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Hygge",
    interiors: [
      require("../images/catalog/azori/hygge/interiors/azori_hygge_10969_168184_0x300.jpg"),
      require("../images/catalog/azori/hygge/interiors/azori_hygge_10969_325128_0x300.jpg"),
      require("../images/catalog/azori/hygge/interiors/azori_hygge_10969_398358_0x300.jpg"),
      require("../images/catalog/azori/hygge/interiors/azori_hygge_10969_448040_0x300.jpg"),
      require("../images/catalog/azori/hygge/interiors/azori_hygge_10969_592613_0x300.jpg"),
      require("../images/catalog/azori/hygge/interiors/azori_hygge_10969_592613_0x500.jpg"),
      require("../images/catalog/azori/hygge/interiors/azori_hygge_10969_653187_0x300.jpg"),
      require("../images/catalog/azori/hygge/interiors/azori_hygge_10969_814487_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/hygge/tovars/azori_hygge_107013_527309_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_107014_510635_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_107015_992851_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92295_525953_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92296_403675_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92297_402070_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92298_958706_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92299_565622_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92300_884802_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92301_565666_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92302_157806_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92303_837398_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92304_314395_300x0.jpg"),
      require("../images/catalog/azori/hygge/tovars/azori_hygge_92305_995228_300x0.jpg"),
    ],
  },
  {
    id: "266",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Incisio",
    interiors: [
      require("../images/catalog/azori/incisio/interiors/azori_incisio_17910_13161_0x300.jpg"),
      require("../images/catalog/azori/incisio/interiors/azori_incisio_17910_13161_0x500.jpg"),
      require("../images/catalog/azori/incisio/interiors/azori_incisio_17910_184161_0x300.jpg"),
      require("../images/catalog/azori/incisio/interiors/azori_incisio_17910_390631_0x300.jpg"),
      require("../images/catalog/azori/incisio/interiors/azori_incisio_17910_596442_0x300.jpg"),
      require("../images/catalog/azori/incisio/interiors/azori_incisio_17910_779192_0x300.jpg"),
      require("../images/catalog/azori/incisio/interiors/azori_incisio_17910_919657_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/incisio/tovars/azori_incisio_125393_150026_300x0.jpg"),
      require("../images/catalog/azori/incisio/tovars/azori_incisio_125394_681024_300x0.jpg"),
      require("../images/catalog/azori/incisio/tovars/azori_incisio_125395_389440_300x0.jpg"),
      require("../images/catalog/azori/incisio/tovars/azori_incisio_125396_97423_300x0.jpg"),
      require("../images/catalog/azori/incisio/tovars/azori_incisio_125397_731355_300x0.jpg"),
      require("../images/catalog/azori/incisio/tovars/azori_incisio_125398_964865_300x0.jpg"),
      require("../images/catalog/azori/incisio/tovars/azori_incisio_125399_894109_300x0.jpg"),
    ],
  },
  {
    id: "267",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Latila",
    interiors: [
      require("../images/catalog/azori/latila/interiors/azori_latila_13726_117750_0x300.jpg"),
      require("../images/catalog/azori/latila/interiors/azori_latila_13726_154800_0x300.jpg"),
      require("../images/catalog/azori/latila/interiors/azori_latila_13726_310033_0x300.jpg"),
      require("../images/catalog/azori/latila/interiors/azori_latila_13726_310033_0x500.jpg"),
      require("../images/catalog/azori/latila/interiors/azori_latila_13726_781880_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/latila/tovars/azori_latila_108743_243229_300x0.jpg"),
      require("../images/catalog/azori/latila/tovars/azori_latila_108744_430622_300x0.jpg"),
      require("../images/catalog/azori/latila/tovars/azori_latila_108745_885205_300x0.jpg"),
      require("../images/catalog/azori/latila/tovars/azori_latila_108746_733428_300x0.jpg"),
      require("../images/catalog/azori/latila/tovars/azori_latila_108747_286892_300x0.jpg"),
    ],
  },
  {
    id: "268",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Loft Silver Light m",
    interiors: [
      require("../images/catalog/azori/loft_silver_light_m/interiors/azori_loft_silver_light_m_18669_369164_0x300.jpg"),
      require("../images/catalog/azori/loft_silver_light_m/interiors/azori_loft_silver_light_m_18669_369164_0x500.jpg"),
      require("../images/catalog/azori/loft_silver_light_m/interiors/azori_loft_silver_light_m_18669_489629_0x300.jpg"),
      require("../images/catalog/azori/loft_silver_light_m/interiors/azori_loft_silver_light_m_18669_811238_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/loft_silver_light_m/tovars/azori_loft_silver_light_m_129239_256861_300x0.jpg"),
    ],
  },
  {
    id: "269",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Loft Silver m",
    interiors: [
      require("../images/catalog/azori/loft_silver_m/interiors/azori_loft_silver_m_18670_653464_0x300.jpg"),
      require("../images/catalog/azori/loft_silver_m/interiors/azori_loft_silver_m_18670_742500_0x300.jpg"),
      require("../images/catalog/azori/loft_silver_m/interiors/azori_loft_silver_m_18670_742500_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/loft_silver_m/tovars/azori_loft_silver_m_129240_347901_300x0.jpg"),
    ],
  },
  {
    id: "285",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Loft Smoky m",
    interiors: [
      require("../images/catalog/azori/loft_smoky_m/interiors/azori_loft_smoky_m_18667_340123_0x300.jpg"),
      require("../images/catalog/azori/loft_smoky_m/interiors/azori_loft_smoky_m_18667_502863_0x300.jpg"),
      require("../images/catalog/azori/loft_smoky_m/interiors/azori_loft_smoky_m_18667_502863_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/loft_smoky_m/tovars/azori_loft_smoky_m_129236_243480_300x0.jpg"),
    ],
  },
  {
    id: "286",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Lounge",
    interiors: [
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_134926_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_308139_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_362161_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_444738_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_44623_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_491863_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_534892_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_641730_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_663082_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_866663_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_866663_0x500.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_888342_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_969922_0x300.jpg"),
      require("../images/catalog/azori/lounge/interiors/azori_lounge_10970_985782_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/lounge/tovars/azori_lounge_119952_345448_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_119953_450317_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92306_493346_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92307_977526_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92308_333467_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92309_253216_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92310_574136_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92311_382820_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92312_378501_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92313_904495_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92314_154985_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92315_173671_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92316_404552_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92317_803517_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92318_572109_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92319_843617_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92320_392026_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92321_552776_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92322_551310_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92323_105491_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92324_219342_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92325_364617_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92326_621446_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92327_613018_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92328_756689_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92342_580152_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92343_178641_300x0.jpg"),
      require("../images/catalog/azori/lounge/tovars/azori_lounge_92344_641491_300x0.jpg"),
    ],
  },
  {
    id: "287",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Macbeth",
    interiors: [
      require("../images/catalog/azori/macbeth/interiors/azori_macbeth_8128_201211_0x300.jpg"),
      require("../images/catalog/azori/macbeth/interiors/azori_macbeth_8128_343018_0x300.jpg"),
      require("../images/catalog/azori/macbeth/interiors/azori_macbeth_8128_58506_0x300.jpg"),
      require("../images/catalog/azori/macbeth/interiors/azori_macbeth_8128_58506_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/macbeth/tovars/azori_macbeth_70353_889426_300x0.jpg"),
      require("../images/catalog/azori/macbeth/tovars/azori_macbeth_70354_716672_300x0.jpg"),
      require("../images/catalog/azori/macbeth/tovars/azori_macbeth_70355_117278_300x0.jpg"),
      require("../images/catalog/azori/macbeth/tovars/azori_macbeth_70357_468670_300x0.jpg"),
      require("../images/catalog/azori/macbeth/tovars/azori_macbeth_70358_993793_300x0.jpg"),
      require("../images/catalog/azori/macbeth/tovars/azori_macbeth_70361_11958_300x0.jpg"),
      require("../images/catalog/azori/macbeth/tovars/azori_macbeth_70362_986558_300x0.jpg"),
      require("../images/catalog/azori/macbeth/tovars/azori_macbeth_70364_28343_300x0.jpg"),
    ],
  },
  {
    id: "288",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Mallorca",
    interiors: [
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_186971_0x300.jpg"),
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_186971_0x500.jpg"),
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_204028_0x300.jpg"),
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_24527_0x300.jpg"),
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_369741_0x300.jpg"),
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_493113_0x300.jpg"),
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_563960_0x300.jpg"),
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_651300_0x300.jpg"),
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_698297_0x300.jpg"),
      require("../images/catalog/azori/mallorca/interiors/azori_mallorca_6604_786036_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_106967_886289_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_106968_779327_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_106969_708404_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_106970_200544_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_55035_625959_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_55036_240913_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_55037_87091_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_55038_366105_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_55039_511402_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_60127_258899_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_60128_242995_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_80653_483615_300x0.jpg"),
      require("../images/catalog/azori/mallorca/tovars/azori_mallorca_80654_813476_300x0.jpg"),
    ],
  },
  {
    id: "289",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Marmaro Gold",
    interiors: [
      require("../images/catalog/azori/marmaro_gold/interiors/azori_marmaro_gold_18656_285466_0x300.jpg"),
      require("../images/catalog/azori/marmaro_gold/interiors/azori_marmaro_gold_18656_309891_0x300.jpg"),
      require("../images/catalog/azori/marmaro_gold/interiors/azori_marmaro_gold_18656_356365_0x300.jpg"),
      require("../images/catalog/azori/marmaro_gold/interiors/azori_marmaro_gold_18656_356365_0x500.jpg"),
      require("../images/catalog/azori/marmaro_gold/interiors/azori_marmaro_gold_18656_66578_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/marmaro_gold/tovars/azori_marmaro_gold_129220_42279_300x0.jpg"),
      require("../images/catalog/azori/marmaro_gold/tovars/azori_marmaro_gold_129221_394115_300x0.jpg"),
    ],
  },
  {
    id: "290",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Nuvola",
    interiors: [
      require("../images/catalog/azori/nuvola/interiors/azori_nuvola_8129_543859_0x300.jpg"),
      require("../images/catalog/azori/nuvola/interiors/azori_nuvola_8129_574734_0x300.jpg"),
      require("../images/catalog/azori/nuvola/interiors/azori_nuvola_8129_574734_0x500.jpg"),
      require("../images/catalog/azori/nuvola/interiors/azori_nuvola_8129_862636_0x300.jpg"),
      require("../images/catalog/azori/nuvola/interiors/azori_nuvola_8129_988431_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/nuvola/tovars/azori_nuvola_70366_172532_300x0.jpg"),
      require("../images/catalog/azori/nuvola/tovars/azori_nuvola_70368_837667_300x0.jpg"),
      require("../images/catalog/azori/nuvola/tovars/azori_nuvola_70370_502608_300x0.jpg"),
      require("../images/catalog/azori/nuvola/tovars/azori_nuvola_70372_789438_300x0.jpg"),
      require("../images/catalog/azori/nuvola/tovars/azori_nuvola_70373_806488_300x0.jpg"),
      require("../images/catalog/azori/nuvola/tovars/azori_nuvola_70378_620029_300x0.jpg"),
    ],
  },
  {
    id: "291",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Onyx Crema",
    interiors: [
      require("../images/catalog/azori/onyx_crema/interiors/azori_onyx_crema_18653_190853_0x300.jpg"),
      require("../images/catalog/azori/onyx_crema/interiors/azori_onyx_crema_18653_193563_0x300.jpg"),
      require("../images/catalog/azori/onyx_crema/interiors/azori_onyx_crema_18653_415555_0x300.jpg"),
      require("../images/catalog/azori/onyx_crema/interiors/azori_onyx_crema_18653_415555_0x500.jpg"),
      require("../images/catalog/azori/onyx_crema/interiors/azori_onyx_crema_18653_931071_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/onyx_crema/tovars/azori_onyx_crema_129215_203159_300x0.jpg"),
      require("../images/catalog/azori/onyx_crema/tovars/azori_onyx_crema_129216_81721_300x0.jpg"),
    ],
  },
  {
    id: "292",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Onyx Ivory",
    interiors: [
      require("../images/catalog/azori/onyx_ivory/interiors/azori_onyx_ivory_18664_221064_0x300.jpg"),
      require("../images/catalog/azori/onyx_ivory/interiors/azori_onyx_ivory_18664_221064_0x500.jpg"),
      require("../images/catalog/azori/onyx_ivory/interiors/azori_onyx_ivory_18664_539601_0x300.jpg"),
      require("../images/catalog/azori/onyx_ivory/interiors/azori_onyx_ivory_18664_56922_0x300.jpg"),
      require("../images/catalog/azori/onyx_ivory/interiors/azori_onyx_ivory_18664_905578_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/onyx_ivory/tovars/azori_onyx_ivory_129230_60901_300x0.jpg"),
      require("../images/catalog/azori/onyx_ivory/tovars/azori_onyx_ivory_129231_81333_300x0.jpg"),
    ],
  },
  {
    id: "293",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Onyx Pearl",
    interiors: [
      require("../images/catalog/azori/onyx_pearl/interiors/azori_onyx_pearl_18666_478158_0x300.jpg"),
      require("../images/catalog/azori/onyx_pearl/interiors/azori_onyx_pearl_18666_736796_0x300.jpg"),
      require("../images/catalog/azori/onyx_pearl/interiors/azori_onyx_pearl_18666_778764_0x300.jpg"),
      require("../images/catalog/azori/onyx_pearl/interiors/azori_onyx_pearl_18666_782976_0x300.jpg"),
      require("../images/catalog/azori/onyx_pearl/interiors/azori_onyx_pearl_18666_782976_0x500.jpg"),
      require("../images/catalog/azori/onyx_pearl/interiors/azori_onyx_pearl_18666_982793_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/onyx_pearl/tovars/azori_onyx_pearl_129234_607446_300x0.jpg"),
      require("../images/catalog/azori/onyx_pearl/tovars/azori_onyx_pearl_129235_144063_300x0.jpg"),
    ],
  },
  {
    id: "294",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Onyx Smoky Sugar",
    interiors: [
      require("../images/catalog/azori/onyx_smoky_sugar/interiors/azori_onyx_smoky_sugar_18668_184046_0x300.jpg"),
      require("../images/catalog/azori/onyx_smoky_sugar/interiors/azori_onyx_smoky_sugar_18668_219413_0x300.jpg"),
      require("../images/catalog/azori/onyx_smoky_sugar/interiors/azori_onyx_smoky_sugar_18668_326937_0x300.jpg"),
      require("../images/catalog/azori/onyx_smoky_sugar/interiors/azori_onyx_smoky_sugar_18668_326937_0x500.jpg"),
      require("../images/catalog/azori/onyx_smoky_sugar/interiors/azori_onyx_smoky_sugar_18668_336223_0x300.jpg"),
      require("../images/catalog/azori/onyx_smoky_sugar/interiors/azori_onyx_smoky_sugar_18668_337904_0x300.jpg"),
      require("../images/catalog/azori/onyx_smoky_sugar/interiors/azori_onyx_smoky_sugar_18668_452448_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/onyx_smoky_sugar/tovars/azori_onyx_smoky_sugar_129237_876951_300x0.jpg"),
      require("../images/catalog/azori/onyx_smoky_sugar/tovars/azori_onyx_smoky_sugar_129238_31463_300x0.jpg"),
    ],
  },
  {
    id: "295",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Onyx Vanilla Sky",
    interiors: [
      require("../images/catalog/azori/onyx_vanilla_sky/interiors/azori_onyx_vanilla_sky_18652_199826_0x300.jpg"),
      require("../images/catalog/azori/onyx_vanilla_sky/interiors/azori_onyx_vanilla_sky_18652_330779_0x300.jpg"),
      require("../images/catalog/azori/onyx_vanilla_sky/interiors/azori_onyx_vanilla_sky_18652_427146_0x300.jpg"),
      require("../images/catalog/azori/onyx_vanilla_sky/interiors/azori_onyx_vanilla_sky_18652_427146_0x500.jpg"),
      require("../images/catalog/azori/onyx_vanilla_sky/interiors/azori_onyx_vanilla_sky_18652_42978_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/onyx_vanilla_sky/tovars/azori_onyx_vanilla_sky_129213_186760_300x0.jpg"),
      require("../images/catalog/azori/onyx_vanilla_sky/tovars/azori_onyx_vanilla_sky_129214_387583_300x0.jpg"),
    ],
  },
  {
    id: "296",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Opale",
    interiors: [
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_130363_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_217843_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_401067_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_442084_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_623018_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_67271_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_710779_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_746206_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_827093_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_841736_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_889036_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_921701_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_927406_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_99297_0x300.jpg"),
      require("../images/catalog/azori/opale/interiors/azori_opale_13728_99297_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/opale/tovars/azori_opale_108750_166264_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108751_417274_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108752_560860_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108753_697322_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108754_924279_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108755_628188_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108756_882231_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108757_266087_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108758_556026_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108759_206327_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108760_954741_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108761_833327_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108762_58216_300x0.jpg"),
      require("../images/catalog/azori/opale/tovars/azori_opale_108763_94380_300x0.jpg"),
    ],
  },
  {
    id: "297",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Palladio",
    interiors: [
      require("../images/catalog/azori/palladio/interiors/azori_palladio_15902_131275_0x300.jpg"),
      require("../images/catalog/azori/palladio/interiors/azori_palladio_15902_177701_0x300.jpg"),
      require("../images/catalog/azori/palladio/interiors/azori_palladio_15902_32189_0x300.jpg"),
      require("../images/catalog/azori/palladio/interiors/azori_palladio_15902_32189_0x500.jpg"),
      require("../images/catalog/azori/palladio/interiors/azori_palladio_15902_398455_0x300.jpg"),
      require("../images/catalog/azori/palladio/interiors/azori_palladio_15902_812283_0x300.jpg"),
      require("../images/catalog/azori/palladio/interiors/azori_palladio_15902_918732_0x300.jpg"),
      require("../images/catalog/azori/palladio/interiors/azori_palladio_15902_957226_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/palladio/tovars/azori_palladio_118143_562755_300x0.jpg"),
      require("../images/catalog/azori/palladio/tovars/azori_palladio_118144_254546_300x0.jpg"),
      require("../images/catalog/azori/palladio/tovars/azori_palladio_118145_221802_300x0.jpg"),
      require("../images/catalog/azori/palladio/tovars/azori_palladio_118146_617438_300x0.jpg"),
      require("../images/catalog/azori/palladio/tovars/azori_palladio_118147_300348_300x0.jpg"),
    ],
  },
  {
    id: "298",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Pandora",
    interiors: [
      require("../images/catalog/azori/pandora/interiors/azori_pandora_6520_307657_0x300.jpg"),
      require("../images/catalog/azori/pandora/interiors/azori_pandora_6520_307657_0x500.jpg"),
      require("../images/catalog/azori/pandora/interiors/azori_pandora_6520_354036_0x300.jpg"),
      require("../images/catalog/azori/pandora/interiors/azori_pandora_6520_390569_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/pandora/tovars/azori_pandora_107010_966401_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_107011_539894_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54947_985650_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54948_346462_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54949_916461_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54950_904270_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54951_394008_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54954_118356_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54955_159171_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54956_529923_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54957_549818_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54958_719774_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54960_392187_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54961_524607_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54962_930262_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_54963_74916_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_71597_859807_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_71598_215198_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_71599_759416_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_71600_282967_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_80638_132704_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_80639_650416_300x0.jpg"),
      require("../images/catalog/azori/pandora/tovars/azori_pandora_87306_825598_300x0.jpg"),
    ],
  },
  {
    id: "299",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Polar",
    interiors: [
      require("../images/catalog/azori/polar/interiors/azori_polar_15901_264650_0x300.jpg"),
      require("../images/catalog/azori/polar/interiors/azori_polar_15901_497958_0x300.jpg"),
      require("../images/catalog/azori/polar/interiors/azori_polar_15901_497958_0x500.jpg"),
      require("../images/catalog/azori/polar/interiors/azori_polar_15901_919092_0x300.jpg"),
      require("../images/catalog/azori/polar/interiors/azori_polar_15901_95140_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/polar/tovars/azori_polar_118140_326213_300x0.jpg"),
      require("../images/catalog/azori/polar/tovars/azori_polar_118141_443521_300x0.jpg"),
      require("../images/catalog/azori/polar/tovars/azori_polar_118142_722994_300x0.jpg"),
    ],
  },
  {
    id: "300",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Pulpis Dark",
    interiors: [
      require("../images/catalog/azori/pulpis_dark/interiors/azori_pulpis_dark_18654_247385_0x300.jpg"),
      require("../images/catalog/azori/pulpis_dark/interiors/azori_pulpis_dark_18654_281745_0x300.jpg"),
      require("../images/catalog/azori/pulpis_dark/interiors/azori_pulpis_dark_18654_361423_0x300.jpg"),
      require("../images/catalog/azori/pulpis_dark/interiors/azori_pulpis_dark_18654_610648_0x300.jpg"),
      require("../images/catalog/azori/pulpis_dark/interiors/azori_pulpis_dark_18654_610648_0x500.jpg"),
      require("../images/catalog/azori/pulpis_dark/interiors/azori_pulpis_dark_18654_682911_0x300.jpg"),
      require("../images/catalog/azori/pulpis_dark/interiors/azori_pulpis_dark_18654_968116_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/pulpis_dark/tovars/azori_pulpis_dark_129218_582839_300x0.jpg"),
    ],
  },
  {
    id: "301",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Pulpis Grey",
    interiors: [
      require("../images/catalog/azori/pulpis_grey/interiors/azori_pulpis_grey_18657_83594_0x300.jpg"),
      require("../images/catalog/azori/pulpis_grey/interiors/azori_pulpis_grey_18657_83594_0x500.jpg"),
      require("../images/catalog/azori/pulpis_grey/interiors/azori_pulpis_grey_18657_992861_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/pulpis_grey/tovars/azori_pulpis_grey_129222_839460_300x0.jpg"),
    ],
  },
  {
    id: "302",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Pulpis Light",
    interiors: [
      require("../images/catalog/azori/pulpis_light/interiors/azori_pulpis_light_18663_229561_0x300.jpg"),
      require("../images/catalog/azori/pulpis_light/interiors/azori_pulpis_light_18663_812005_0x300.jpg"),
      require("../images/catalog/azori/pulpis_light/interiors/azori_pulpis_light_18663_812005_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/pulpis_light/tovars/azori_pulpis_light_129229_584129_300x0.jpg"),
    ],
  },
  {
    id: "303",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Riviera",
    interiors: [
      require("../images/catalog/azori/riviera/interiors/azori_riviera_8130_621483_0x300.jpg"),
      require("../images/catalog/azori/riviera/interiors/azori_riviera_8130_950624_0x300.jpg"),
      require("../images/catalog/azori/riviera/interiors/azori_riviera_8130_950624_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/riviera/tovars/azori_riviera_70379_567255_300x0.jpg"),
      require("../images/catalog/azori/riviera/tovars/azori_riviera_70381_467868_300x0.jpg"),
      require("../images/catalog/azori/riviera/tovars/azori_riviera_70383_773763_300x0.jpg"),
      require("../images/catalog/azori/riviera/tovars/azori_riviera_70384_535947_300x0.jpg"),
      require("../images/catalog/azori/riviera/tovars/azori_riviera_70385_364526_300x0.jpg"),
      require("../images/catalog/azori/riviera/tovars/azori_riviera_70386_77030_300x0.jpg"),
      require("../images/catalog/azori/riviera/tovars/azori_riviera_70387_282304_300x0.jpg"),
      require("../images/catalog/azori/riviera/tovars/azori_riviera_87298_988543_300x0.jpg"),
    ],
  },
  {
    id: "304",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Romanico",
    interiors: [
      require("../images/catalog/azori/romanico/interiors/azori_romanico_13432_502643_0x300.jpg"),
      require("../images/catalog/azori/romanico/interiors/azori_romanico_13432_502643_0x500.jpg"),
      require("../images/catalog/azori/romanico/interiors/azori_romanico_13432_964051_0x300.jpg"),
      require("../images/catalog/azori/romanico/interiors/azori_romanico_13432_965219_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/romanico/tovars/azori_romanico_106978_547969_300x0.jpg"),
      require("../images/catalog/azori/romanico/tovars/azori_romanico_106979_803924_300x0.jpg"),
      require("../images/catalog/azori/romanico/tovars/azori_romanico_106980_684084_300x0.jpg"),
      require("../images/catalog/azori/romanico/tovars/azori_romanico_106981_588200_300x0.jpg"),
      require("../images/catalog/azori/romanico/tovars/azori_romanico_106982_560907_300x0.jpg"),
      require("../images/catalog/azori/romanico/tovars/azori_romanico_106983_970740_300x0.jpg"),
      require("../images/catalog/azori/romanico/tovars/azori_romanico_106984_503952_300x0.jpg"),
      require("../images/catalog/azori/romanico/tovars/azori_romanico_106985_830407_300x0.jpg"),
      require("../images/catalog/azori/romanico/tovars/azori_romanico_106986_291531_300x0.jpg"),
    ],
  },
  {
    id: "305",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Rosso Venato",
    interiors: [
      require("../images/catalog/azori/rosso_venato/interiors/azori_rosso_venato_17908_175499_0x300.jpg"),
      require("../images/catalog/azori/rosso_venato/interiors/azori_rosso_venato_17908_19360_0x300.jpg"),
      require("../images/catalog/azori/rosso_venato/interiors/azori_rosso_venato_17908_282248_0x300.jpg"),
      require("../images/catalog/azori/rosso_venato/interiors/azori_rosso_venato_17908_742620_0x300.jpg"),
      require("../images/catalog/azori/rosso_venato/interiors/azori_rosso_venato_17908_742620_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/rosso_venato/tovars/azori_rosso_venato_125386_595774_300x0.jpg"),
      require("../images/catalog/azori/rosso_venato/tovars/azori_rosso_venato_125387_706286_300x0.jpg"),
      require("../images/catalog/azori/rosso_venato/tovars/azori_rosso_venato_125388_584420_300x0.jpg"),
    ],
  },
  {
    id: "306",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Rustic",
    interiors: [
      require("../images/catalog/azori/rustic/interiors/azori_rustic_13434_149529_0x300.jpg"),
      require("../images/catalog/azori/rustic/interiors/azori_rustic_13434_153868_0x300.jpg"),
      require("../images/catalog/azori/rustic/interiors/azori_rustic_13434_264075_0x300.jpg"),
      require("../images/catalog/azori/rustic/interiors/azori_rustic_13434_278603_0x300.jpg"),
      require("../images/catalog/azori/rustic/interiors/azori_rustic_13434_451231_0x300.jpg"),
      require("../images/catalog/azori/rustic/interiors/azori_rustic_13434_451231_0x500.jpg"),
      require("../images/catalog/azori/rustic/interiors/azori_rustic_13434_589907_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/rustic/tovars/azori_rustic_107000_189963_300x0.jpg"),
      require("../images/catalog/azori/rustic/tovars/azori_rustic_107001_626602_300x0.jpg"),
      require("../images/catalog/azori/rustic/tovars/azori_rustic_107002_683772_300x0.jpg"),
      require("../images/catalog/azori/rustic/tovars/azori_rustic_107003_705447_300x0.jpg"),
      require("../images/catalog/azori/rustic/tovars/azori_rustic_107004_849977_300x0.jpg"),
      require("../images/catalog/azori/rustic/tovars/azori_rustic_107005_593712_300x0.jpg"),
      require("../images/catalog/azori/rustic/tovars/azori_rustic_107006_372726_300x0.jpg"),
      require("../images/catalog/azori/rustic/tovars/azori_rustic_107007_172476_300x0.jpg"),
    ],
  },
  {
    id: "307",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Scandi",
    interiors: [
      require("../images/catalog/azori/scandi/interiors/azori_scandi_13732_127760_0x300.jpg"),
      require("../images/catalog/azori/scandi/interiors/azori_scandi_13732_199453_0x300.jpg"),
      require("../images/catalog/azori/scandi/interiors/azori_scandi_13732_416290_0x300.jpg"),
      require("../images/catalog/azori/scandi/interiors/azori_scandi_13732_474173_0x300.jpg"),
      require("../images/catalog/azori/scandi/interiors/azori_scandi_13732_474173_0x500.jpg"),
      require("../images/catalog/azori/scandi/interiors/azori_scandi_13732_559684_0x300.jpg"),
      require("../images/catalog/azori/scandi/interiors/azori_scandi_13732_574883_0x300.jpg"),
      require("../images/catalog/azori/scandi/interiors/azori_scandi_13732_953762_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/scandi/tovars/azori_scandi_108765_21541_300x0.jpg"),
      require("../images/catalog/azori/scandi/tovars/azori_scandi_108766_277056_300x0.jpg"),
      require("../images/catalog/azori/scandi/tovars/azori_scandi_108767_441629_300x0.jpg"),
      require("../images/catalog/azori/scandi/tovars/azori_scandi_108768_350498_300x0.jpg"),
      require("../images/catalog/azori/scandi/tovars/azori_scandi_108769_433558_300x0.jpg"),
      require("../images/catalog/azori/scandi/tovars/azori_scandi_108770_935127_300x0.jpg"),
      require("../images/catalog/azori/scandi/tovars/azori_scandi_108771_789531_300x0.jpg"),
      require("../images/catalog/azori/scandi/tovars/azori_scandi_108772_261515_300x0.jpg"),
      require("../images/catalog/azori/scandi/tovars/azori_scandi_108773_948346_300x0.jpg"),
    ],
  },
  {
    Collection: "Sfumato",
    interiors: [
      require("../images/catalog/azori/sfumato/interiors/azori_sfumato_3595_189692_0x300.jpg"),
      require("../images/catalog/azori/sfumato/interiors/azori_sfumato_3595_525603_0x300.jpg"),
      require("../images/catalog/azori/sfumato/interiors/azori_sfumato_3595_726545_0x300.jpg"),
      require("../images/catalog/azori/sfumato/interiors/azori_sfumato_3595_726545_0x500.jpg"),
      require("../images/catalog/azori/sfumato/interiors/azori_sfumato_3595_847779_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24004_864129_300x0.jpg"),
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24005_951211_300x0.jpg"),
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24008_531769_300x0.jpg"),
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24010_762796_300x0.jpg"),
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24012_433188_300x0.jpg"),
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24014_255449_300x0.jpg"),
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24015_231540_300x0.jpg"),
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24018_455372_300x0.jpg"),
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24020_961764_300x0.jpg"),
      require("../images/catalog/azori/sfumato/tovars/azori_sfumato_24022_935669_300x0.jpg"),
    ],
  },
  {
    id: "308",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Shabby",
    interiors: [
      require("../images/catalog/azori/shabby/interiors/azori_shabby_9380_191544_0x300.jpg"),
      require("../images/catalog/azori/shabby/interiors/azori_shabby_9380_191544_0x500.jpg"),
      require("../images/catalog/azori/shabby/interiors/azori_shabby_9380_361153_0x300.jpg"),
      require("../images/catalog/azori/shabby/interiors/azori_shabby_9380_451294_0x300.jpg"),
      require("../images/catalog/azori/shabby/interiors/azori_shabby_9380_471755_0x300.jpg"),
      require("../images/catalog/azori/shabby/interiors/azori_shabby_9380_651562_0x300.jpg"),
      require("../images/catalog/azori/shabby/interiors/azori_shabby_9380_693511_0x300.jpg"),
      require("../images/catalog/azori/shabby/interiors/azori_shabby_9380_820625_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/shabby/tovars/azori_shabby_106973_233374_300x0.jpg"),
      require("../images/catalog/azori/shabby/tovars/azori_shabby_107009_910696_300x0.jpg"),
      require("../images/catalog/azori/shabby/tovars/azori_shabby_80647_86097_300x0.jpg"),
      require("../images/catalog/azori/shabby/tovars/azori_shabby_80648_818643_300x0.jpg"),
      require("../images/catalog/azori/shabby/tovars/azori_shabby_80649_584442_300x0.jpg"),
      require("../images/catalog/azori/shabby/tovars/azori_shabby_80650_537534_300x0.jpg"),
      require("../images/catalog/azori/shabby/tovars/azori_shabby_82315_679578_300x0.jpg"),
    ],
  },
  {
    id: "309",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Sonnet",
    interiors: [
      require("../images/catalog/azori/sonnet/interiors/azori_sonnet_13433_305181_0x300.jpg"),
      require("../images/catalog/azori/sonnet/interiors/azori_sonnet_13433_395877_0x300.jpg"),
      require("../images/catalog/azori/sonnet/interiors/azori_sonnet_13433_478038_0x300.jpg"),
      require("../images/catalog/azori/sonnet/interiors/azori_sonnet_13433_826737_0x300.jpg"),
      require("../images/catalog/azori/sonnet/interiors/azori_sonnet_13433_958117_0x300.jpg"),
      require("../images/catalog/azori/sonnet/interiors/azori_sonnet_13433_968861_0x300.jpg"),
      require("../images/catalog/azori/sonnet/interiors/azori_sonnet_13433_968861_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106987_491469_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106988_748186_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106989_127956_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106990_127454_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106991_14966_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106992_105815_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106993_318433_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106994_175456_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106995_549767_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106996_315049_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106997_481559_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106998_436057_300x0.jpg"),
      require("../images/catalog/azori/sonnet/tovars/azori_sonnet_106999_94376_300x0.jpg"),
    ],
  },
  {
    id: "310",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Starck",
    interiors: [
      require("../images/catalog/azori/starck/interiors/azori_starck_15502_232876_0x300.jpg"),
      require("../images/catalog/azori/starck/interiors/azori_starck_15502_402471_0x300.jpg"),
      require("../images/catalog/azori/starck/interiors/azori_starck_15502_403095_0x300.jpg"),
      require("../images/catalog/azori/starck/interiors/azori_starck_15502_579703_0x300.jpg"),
      require("../images/catalog/azori/starck/interiors/azori_starck_15502_789963_0x300.jpg"),
      require("../images/catalog/azori/starck/interiors/azori_starck_15502_789963_0x500.jpg"),
      require("../images/catalog/azori/starck/interiors/azori_starck_15502_85722_0x300.jpg"),
      require("../images/catalog/azori/starck/interiors/azori_starck_15502_918537_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/starck/tovars/azori_starck_115615_89404_300x0.jpg"),
      require("../images/catalog/azori/starck/tovars/azori_starck_115616_78240_300x0.jpg"),
      require("../images/catalog/azori/starck/tovars/azori_starck_115617_853397_300x0.jpg"),
      require("../images/catalog/azori/starck/tovars/azori_starck_115618_844769_300x0.jpg"),
      require("../images/catalog/azori/starck/tovars/azori_starck_115619_133168_300x0.jpg"),
      require("../images/catalog/azori/starck/tovars/azori_starck_115620_220139_300x0.jpg"),
    ],
  },
  {
    id: "311",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Vela",
    interiors: [
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_123321_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_434626_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_481856_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_493432_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_519127_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_545006_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_645703_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_645703_0x500.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_697013_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_722122_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_736787_0x300.jpg"),
      require("../images/catalog/azori/vela/interiors/azori_vela_8916_829906_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/vela/tovars/azori_vela_76160_928762_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76161_143389_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76162_119549_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76164_90835_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76165_782545_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76166_290417_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76167_724898_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76168_994111_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76169_151844_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76170_960935_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76171_290812_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76172_638913_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76173_204395_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76174_325130_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76175_615819_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76176_137226_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76177_645810_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76178_273499_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76179_594651_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76180_236075_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76181_117305_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76182_919919_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76183_126724_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76184_817265_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76185_54017_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76186_165732_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76187_839842_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_76188_347713_300x0.jpg"),
      require("../images/catalog/azori/vela/tovars/azori_vela_87299_371342_300x0.jpg"),
    ],
  },
  {
    id: "312",
    Сountry: "Россия",
    Category: "Керамическая плика",
    Size: ["20x60"],
    Name: "Azori",
    Collection: "Veneziano",
    interiors: [
      require("../images/catalog/azori/veneziano/interiors/azori_veneziano_15503_179782_0x300.jpg"),
      require("../images/catalog/azori/veneziano/interiors/azori_veneziano_15503_355053_0x300.jpg"),
      require("../images/catalog/azori/veneziano/interiors/azori_veneziano_15503_439029_0x300.jpg"),
      require("../images/catalog/azori/veneziano/interiors/azori_veneziano_15503_529710_0x300.jpg"),
      require("../images/catalog/azori/veneziano/interiors/azori_veneziano_15503_605286_0x300.jpg"),
      require("../images/catalog/azori/veneziano/interiors/azori_veneziano_15503_639180_0x300.jpg"),
      require("../images/catalog/azori/veneziano/interiors/azori_veneziano_15503_723231_0x300.jpg"),
      require("../images/catalog/azori/veneziano/interiors/azori_veneziano_15503_723231_0x500.jpg"),
      require("../images/catalog/azori/veneziano/interiors/azori_veneziano_15503_838065_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/azori/veneziano/tovars/azori_veneziano_115621_543049_300x0.jpg"),
      require("../images/catalog/azori/veneziano/tovars/azori_veneziano_115622_692679_300x0.jpg"),
      require("../images/catalog/azori/veneziano/tovars/azori_veneziano_115623_648193_300x0.jpg"),
      require("../images/catalog/azori/veneziano/tovars/azori_veneziano_115624_267943_300x0.jpg"),
      require("../images/catalog/azori/veneziano/tovars/azori_veneziano_115625_456497_300x0.jpg"),
      require("../images/catalog/azori/veneziano/tovars/azori_veneziano_115626_711834_300x0.jpg"),
    ],
  },

  {
    id: "313",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Afion",
    interiors: [
      require("../images/catalog/bluezone/afion/interiors/bluezone_afion_11440_674277_0x300.jpg"),
      require("../images/catalog/bluezone/afion/interiors/bluezone_afion_11440_856398_0x300.jpg"),
      require("../images/catalog/bluezone/afion/interiors/bluezone_afion_11440_856398_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/afion/tovars/bluezone_afion_94985_424169_300x0.jpg"),
      require("../images/catalog/bluezone/afion/tovars/bluezone_afion_94986_570296_300x0.jpg"),
      require("../images/catalog/bluezone/afion/tovars/bluezone_afion_94987_919939_300x0.jpg"),
    ],
  },
  {
    id: "314",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Aagyaro",
    interiors: [
      require("../images/catalog/bluezone/agyaro/interiors/bluezone_agyaro_11441_228455_0x300.jpg"),
      require("../images/catalog/bluezone/agyaro/interiors/bluezone_agyaro_11441_228455_0x500.jpg"),
      require("../images/catalog/bluezone/agyaro/interiors/bluezone_agyaro_11441_315088_0x300.jpg"),
      require("../images/catalog/bluezone/agyaro/interiors/bluezone_agyaro_11441_899397_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/agyaro/tovars/bluezone_agyaro_94988_941004_300x0.jpg"),
      require("../images/catalog/bluezone/agyaro/tovars/bluezone_agyaro_94989_889880_300x0.jpg"),
      require("../images/catalog/bluezone/agyaro/tovars/bluezone_agyaro_94990_459535_300x0.jpg"),
    ],
  },
  {
    id: "315",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Alabaster",
    interiors: [
      require("../images/catalog/bluezone/alabaster/interiors/bluezone_alabaster_12240_677468_0x300.jpg"),
      require("../images/catalog/bluezone/alabaster/interiors/bluezone_alabaster_12240_677468_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/alabaster/tovars/bluezone_alabaster_100746_84699_300x0.jpg"),
      require("../images/catalog/bluezone/alabaster/tovars/bluezone_alabaster_100747_384049_300x0.jpg"),
      require("../images/catalog/bluezone/alabaster/tovars/bluezone_alabaster_100748_459013_300x0.jpg"),
      require("../images/catalog/bluezone/alabaster/tovars/bluezone_alabaster_100749_422781_300x0.jpg"),
    ],
  },
  {
    id: "316",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Antique_satvario",
    interiors: [
      require("../images/catalog/bluezone/antique_satvario/interiors/bluezone_antique_satvario_16391_651825_0x300.jpg"),
      require("../images/catalog/bluezone/antique_satvario/interiors/bluezone_antique_satvario_16391_651825_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/antique_satvario/tovars/bluezone_antique_satvario_120847_323582_300x0.jpg"),
    ],
  },
  {
    id: "317",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Arizona",
    interiors: [
      require("../images/catalog/bluezone/arizona/interiors/bluezone_arizona_16392_543093_0x300.jpg"),
      require("../images/catalog/bluezone/arizona/interiors/bluezone_arizona_16392_543093_0x500.jpg"),
      require("../images/catalog/bluezone/arizona/interiors/bluezone_arizona_16392_864110_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/arizona/tovars/bluezone_arizona_120848_533151_300x0.jpg"),
    ],
  },
  {
    id: "318",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Armeo",
    interiors: [
      require("../images/catalog/bluezone/armeo/interiors/bluezone_armeo_12241_793337_0x300.jpg"),
      require("../images/catalog/bluezone/armeo/interiors/bluezone_armeo_12241_793337_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/armeo/tovars/bluezone_armeo_100750_685087_300x0.jpg"),
    ],
  },
  {
    id: "319",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Atlanta",
    interiors: [
      require("../images/catalog/bluezone/atlanta/interiors/bluezone_atlanta_12242_850822_0x300.jpg"),
      require("../images/catalog/bluezone/atlanta/interiors/bluezone_atlanta_12242_850822_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/atlanta/tovars/bluezone_atlanta_100751_728261_300x0.jpg"),
      require("../images/catalog/bluezone/atlanta/tovars/bluezone_atlanta_100752_766660_300x0.jpg"),
      require("../images/catalog/bluezone/atlanta/tovars/bluezone_atlanta_100753_873510_300x0.jpg"),
    ],
  },
  {
    id: "320",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Bellisimo",
    interiors: [
      require("../images/catalog/bluezone/bellisimo/interiors/bluezone_bellisimo_16401_547143_0x300.jpg"),
      require("../images/catalog/bluezone/bellisimo/interiors/bluezone_bellisimo_16401_547143_0x500.jpg"),
      require("../images/catalog/bluezone/bellisimo/interiors/bluezone_bellisimo_16401_864870_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/bellisimo/tovars/bluezone_bellisimo_120881_841387_300x0.jpg"),
      require("../images/catalog/bluezone/bellisimo/tovars/bluezone_bellisimo_120882_930876_300x0.jpg"),
    ],
  },
  {
    id: "321",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Biardo",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/bluezone/biardo/tovars/bluezone_biardo_94991_942857_300x0.jpg"),
    ],
  },
  {
    id: "322",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Brezza",
    interiors: [
      require("../images/catalog/bluezone/brezza/interiors/bluezone_brezza_11443_114858_0x300.jpg"),
      require("../images/catalog/bluezone/brezza/interiors/bluezone_brezza_11443_114858_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/brezza/tovars/bluezone_brezza_94992_324438_300x0.jpg"),
    ],
  },
  {
    id: "323",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Bronze_armani",
    interiors: [
      require("../images/catalog/bluezone/bronze_armani/interiors/bluezone_bronze_armani_12243_370691_0x300.jpg"),
      require("../images/catalog/bluezone/bronze_armani/interiors/bluezone_bronze_armani_12243_38039_0x300.jpg"),
      require("../images/catalog/bluezone/bronze_armani/interiors/bluezone_bronze_armani_12243_38039_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/bronze_armani/tovars/bluezone_bronze_armani_100754_663497_300x0.jpg"),
      require("../images/catalog/bluezone/bronze_armani/tovars/bluezone_bronze_armani_100755_119970_300x0.jpg"),
    ],
  },
  {
    id: "324",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Brunt",
    interiors: [
      require("../images/catalog/bluezone/brunt/interiors/bluezone_brunt_12244_160074_0x300.jpg"),
      require("../images/catalog/bluezone/brunt/interiors/bluezone_brunt_12244_160074_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/brunt/tovars/bluezone_brunt_100756_145543_300x0.jpg"),
      require("../images/catalog/bluezone/brunt/tovars/bluezone_brunt_100757_294228_300x0.jpg"),
    ],
  },
  {
    id: "325",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Burberry",
    interiors: [
      require("../images/catalog/bluezone/burberry/interiors/bluezone_burberry_11105_468468_0x300.jpg"),
      require("../images/catalog/bluezone/burberry/interiors/bluezone_burberry_11105_678161_0x300.jpg"),
      require("../images/catalog/bluezone/burberry/interiors/bluezone_burberry_11105_678161_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/burberry/tovars/bluezone_burberry_93217_660289_300x0.jpg"),
      require("../images/catalog/bluezone/burberry/tovars/bluezone_burberry_93218_223399_300x0.jpg"),
    ],
  },
  {
    id: "326",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Bz 10012",
    interiors: [
      require("../images/catalog/bluezone/bz_10012/interiors/bluezone_bz_10012_16402_153334_0x300.jpg"),
      require("../images/catalog/bluezone/bz_10012/interiors/bluezone_bz_10012_16402_308728_0x300.jpg"),
      require("../images/catalog/bluezone/bz_10012/interiors/bluezone_bz_10012_16402_308728_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/bz_10012/tovars/bluezone_bz_10012_120883_387769_300x0.jpg"),
      require("../images/catalog/bluezone/bz_10012/tovars/bluezone_bz_10012_120884_680727_300x0.jpg"),
    ],
  },
  {
    id: "327",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Capitone white",
    interiors: [
      require("../images/catalog/bluezone/capitone_white/interiors/bluezone_capitone_white_11874_651507_0x300.jpg"),
      require("../images/catalog/bluezone/capitone_white/interiors/bluezone_capitone_white_11874_651507_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/capitone_white/tovars/bluezone_capitone_white_97394_826873_300x0.jpg"),
    ],
  },
  {
    id: "328",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Cristallo tiffany",
    interiors: [
      require("../images/catalog/bluezone/cristallo_tiffany/interiors/bluezone_cristallo_tiffany_12245_297106_0x300.jpg"),
      require("../images/catalog/bluezone/cristallo_tiffany/interiors/bluezone_cristallo_tiffany_12245_297106_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/cristallo_tiffany/tovars/bluezone_cristallo_tiffany_100758_561570_300x0.jpg"),
    ],
  },
  {
    id: "329",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Croma onyx",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/bluezone/croma_onyx/tovars/bluezone_croma_onyx_94993_790455_300x0.jpg"),
    ],
  },
  {
    id: "330",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Crystal iris",
    interiors: [
      require("../images/catalog/bluezone/crystal_iris/interiors/bluezone_crystal_iris_11900_982060_0x300.jpg"),
      require("../images/catalog/bluezone/crystal_iris/interiors/bluezone_crystal_iris_11900_982060_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/crystal_iris/tovars/bluezone_crystal_iris_97478_349219_300x0.jpg"),
    ],
  },
  {
    id: "331",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Delta",
    interiors: [
      require("../images/catalog/bluezone/delta/interiors/bluezone_delta_18484_483367_0x300.jpg"),
      require("../images/catalog/bluezone/delta/interiors/bluezone_delta_18484_930244_0x300.jpg"),
      require("../images/catalog/bluezone/delta/interiors/bluezone_delta_18484_930244_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/delta/tovars/bluezone_delta_127987_419380_300x0.jpg"),
      require("../images/catalog/bluezone/delta/tovars/bluezone_delta_127988_902297_300x0.jpg"),
    ],
  },
  {
    id: "332",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Ethipian",
    interiors: [
      require("../images/catalog/bluezone/ethipian/interiors/bluezone_ethipian_13729_430680_0x300.jpg"),
      require("../images/catalog/bluezone/ethipian/interiors/bluezone_ethipian_13729_430680_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/ethipian/tovars/bluezone_ethipian_108748_712359_300x0.jpg"),
    ],
  },
  {
    id: "333",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Favorite onyx",
    interiors: [
      require("../images/catalog/bluezone/favorite_onyx/interiors/bluezone_favorite_onyx_12246_29787_0x300.jpg"),
      require("../images/catalog/bluezone/favorite_onyx/interiors/bluezone_favorite_onyx_12246_29787_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/favorite_onyx/tovars/bluezone_favorite_onyx_100759_947456_300x0.jpg"),
      require("../images/catalog/bluezone/favorite_onyx/tovars/bluezone_favorite_onyx_100760_33599_300x0.jpg"),
    ],
  },
  {
    id: "334",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Fenton blue",
    interiors: [
      require("../images/catalog/bluezone/fenton_blue/interiors/bluezone_fenton_blue_11106_735986_0x300.jpg"),
      require("../images/catalog/bluezone/fenton_blue/interiors/bluezone_fenton_blue_11106_735986_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/fenton_blue/tovars/bluezone_fenton_blue_93219_225613_300x0.jpg"),
    ],
  },
  {
    id: "335",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Ferro natural",
    interiors: [
      require("../images/catalog/bluezone/ferro_natural/interiors/bluezone_ferro_natural_12247_147589_0x300.jpg"),
      require("../images/catalog/bluezone/ferro_natural/interiors/bluezone_ferro_natural_12247_147589_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/ferro_natural/tovars/bluezone_ferro_natural_100761_725702_300x0.jpg"),
      require("../images/catalog/bluezone/ferro_natural/tovars/bluezone_ferro_natural_100762_266063_300x0.jpg"),
    ],
  },
  {
    id: "336",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Filito rosso",
    interiors: [
      require("../images/catalog/bluezone/filito_rosso/interiors/bluezone_filito_rosso_16403_294355_0x300.jpg"),
      require("../images/catalog/bluezone/filito_rosso/interiors/bluezone_filito_rosso_16403_294355_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/filito_rosso/tovars/bluezone_filito_rosso_120885_815044_300x0.jpg"),
    ],
  },
  {
    id: "337",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Florian",
    interiors: [
      require("../images/catalog/bluezone/florian/interiors/bluezone_florian_11445_417577_0x300.jpg"),
      require("../images/catalog/bluezone/florian/interiors/bluezone_florian_11445_417577_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/florian/tovars/bluezone_florian_94994_109959_300x0.jpg"),
    ],
  },
  {
    id: "338",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Galaxy black",
    interiors: [
      require("../images/catalog/bluezone/galaxy_black/interiors/bluezone_galaxy_black_11107_43016_0x300.jpg"),
      require("../images/catalog/bluezone/galaxy_black/interiors/bluezone_galaxy_black_11107_43016_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/galaxy_black/tovars/bluezone_galaxy_black_93220_772564_300x0.jpg"),
    ],
  },
  {
    id: "339",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Ice onyx black",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/bluezone/ice_onyx_black/tovars/bluezone_ice_onyx_black_97480_378625_300x0.jpg"),
    ],
  },
  {
    id: "340",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Infinity",
    interiors: [
      require("../images/catalog/bluezone/infinity/interiors/bluezone_infinity_11875_105800_0x300.jpg"),
      require("../images/catalog/bluezone/infinity/interiors/bluezone_infinity_11875_132215_0x300.jpg"),
      require("../images/catalog/bluezone/infinity/interiors/bluezone_infinity_11875_313784_0x300.jpg"),
      require("../images/catalog/bluezone/infinity/interiors/bluezone_infinity_11875_633816_0x300.jpg"),
      require("../images/catalog/bluezone/infinity/interiors/bluezone_infinity_11875_633816_0x500.jpg"),
      require("../images/catalog/bluezone/infinity/interiors/bluezone_infinity_11875_648756_0x300.jpg"),
      require("../images/catalog/bluezone/infinity/interiors/bluezone_infinity_11875_962374_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/infinity/tovars/bluezone_infinity_97395_481820_300x0.jpg"),
      require("../images/catalog/bluezone/infinity/tovars/bluezone_infinity_97396_707922_300x0.jpg"),
      require("../images/catalog/bluezone/infinity/tovars/bluezone_infinity_97397_954826_300x0.jpg"),
    ],
  },
  {
    id: "341",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Istanbul",
    interiors: [
      require("../images/catalog/bluezone/istanbul/interiors/bluezone_istanbul_15853_293641_0x300.jpg"),
      require("../images/catalog/bluezone/istanbul/interiors/bluezone_istanbul_15853_40702_0x300.jpg"),
      require("../images/catalog/bluezone/istanbul/interiors/bluezone_istanbul_15853_545734_0x300.jpg"),
      require("../images/catalog/bluezone/istanbul/interiors/bluezone_istanbul_15853_545734_0x500.jpg"),
      require("../images/catalog/bluezone/istanbul/interiors/bluezone_istanbul_15853_852719_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/istanbul/tovars/bluezone_istanbul_117131_739072_300x0.jpg"),
      require("../images/catalog/bluezone/istanbul/tovars/bluezone_istanbul_117132_730595_300x0.jpg"),
      require("../images/catalog/bluezone/istanbul/tovars/bluezone_istanbul_117133_211808_300x0.jpg"),
      require("../images/catalog/bluezone/istanbul/tovars/bluezone_istanbul_117134_559670_300x0.jpg"),
    ],
  },
  {
    id: "342",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Liverpool",
    interiors: [
      require("../images/catalog/bluezone/liverpool/interiors/bluezone_liverpool_16404_691605_0x300.jpg"),
      require("../images/catalog/bluezone/liverpool/interiors/bluezone_liverpool_16404_691605_0x500.jpg"),
      require("../images/catalog/bluezone/liverpool/interiors/bluezone_liverpool_16404_990045_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/liverpool/tovars/bluezone_liverpool_120886_95622_300x0.jpg"),
    ],
  },
  {
    id: "343",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Longines",
    interiors: [
      require("../images/catalog/bluezone/longines/interiors/bluezone_longines_16405_775377_0x300.jpg"),
      require("../images/catalog/bluezone/longines/interiors/bluezone_longines_16405_775377_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/longines/tovars/bluezone_longines_120887_626083_300x0.jpg"),
    ],
  },
  {
    id: "344",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Luxuria",
    interiors: [
      require("../images/catalog/bluezone/luxuria/interiors/bluezone_luxuria_11902_30222_0x300.jpg"),
      require("../images/catalog/bluezone/luxuria/interiors/bluezone_luxuria_11902_30222_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/luxuria/tovars/bluezone_luxuria_97481_921743_300x0.jpg"),
    ],
  },
  {
    id: "345",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Mistike",
    interiors: [
      require("../images/catalog/bluezone/mistike/interiors/bluezone_mistike_11903_644290_0x300.jpg"),
      require("../images/catalog/bluezone/mistike/interiors/bluezone_mistike_11903_644290_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/mistike/tovars/bluezone_mistike_97482_45768_300x0.jpg"),
    ],
  },
  {
    id: "346",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Multi_milkyway",
    interiors: [
      require("../images/catalog/bluezone/multi_milkyway/interiors/bluezone_multi_milkyway_12248_17555_0x300.jpg"),
      require("../images/catalog/bluezone/multi_milkyway/interiors/bluezone_multi_milkyway_12248_17555_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/multi_milkyway/tovars/bluezone_multi_milkyway_100763_18280_300x0.jpg"),
    ],
  },
  {
    id: "347",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Noks_black",
    interiors: [
      require("../images/catalog/bluezone/noks_black/interiors/bluezone_noks_black_11108_282594_0x300.jpg"),
      require("../images/catalog/bluezone/noks_black/interiors/bluezone_noks_black_11108_282594_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/noks_black/tovars/bluezone_noks_black_93221_518127_300x0.jpg"),
      require("../images/catalog/bluezone/noks_black/tovars/bluezone_noks_black_93222_84031_300x0.jpg"),
    ],
  },
  {
    id: "348",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Nordic_blue",
    interiors: [
      require("../images/catalog/bluezone/nordic_blue/interiors/bluezone_nordic_blue_11109_159910_0x300.jpg"),
      require("../images/catalog/bluezone/nordic_blue/interiors/bluezone_nordic_blue_11109_159910_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/nordic_blue/tovars/bluezone_nordic_blue_93223_318757_300x0.jpg"),
    ],
  },
  {
    id: "349",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Onyx_fern",
    interiors: [
      require("../images/catalog/bluezone/onyx_fern/interiors/bluezone_onyx_fern_11876_728878_0x300.jpg"),
      require("../images/catalog/bluezone/onyx_fern/interiors/bluezone_onyx_fern_11876_728878_0x500.jpg"),
      require("../images/catalog/bluezone/onyx_fern/interiors/bluezone_onyx_fern_11876_957673_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/onyx_fern/tovars/bluezone_onyx_fern_97398_90725_300x0.jpg"),
    ],
  },
  {
    id: "350",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Onyx_teal",
    interiors: [
      require("../images/catalog/bluezone/onyx_teal/interiors/bluezone_onyx_teal_11899_209914_0x300.jpg"),
      require("../images/catalog/bluezone/onyx_teal/interiors/bluezone_onyx_teal_11899_209914_0x500.jpg"),
      require("../images/catalog/bluezone/onyx_teal/interiors/bluezone_onyx_teal_11899_901273_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/onyx_teal/tovars/bluezone_onyx_teal_97476_899668_300x0.jpg"),
    ],
  },
  {
    id: "351",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Opus",
    interiors: [
      require("../images/catalog/bluezone/opus/interiors/bluezone_opus_12249_115229_0x300.jpg"),
      require("../images/catalog/bluezone/opus/interiors/bluezone_opus_12249_855709_0x300.jpg"),
      require("../images/catalog/bluezone/opus/interiors/bluezone_opus_12249_855709_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/opus/tovars/bluezone_opus_100764_642524_300x0.jpg"),
      require("../images/catalog/bluezone/opus/tovars/bluezone_opus_100765_449922_300x0.jpg"),
    ],
  },
  {
    id: "352",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Oribica",
    interiors: [
      require("../images/catalog/bluezone/oribica/interiors/bluezone_oribica_11877_720540_0x300.jpg"),
      require("../images/catalog/bluezone/oribica/interiors/bluezone_oribica_11877_720540_0x500.jpg"),
      require("../images/catalog/bluezone/oribica/interiors/bluezone_oribica_11877_907099_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/oribica/tovars/bluezone_oribica_97399_58135_300x0.jpg"),
      require("../images/catalog/bluezone/oribica/tovars/bluezone_oribica_97479_875803_300x0.jpg"),
    ],
  },
  {
    id: "353",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Oriental",
    interiors: [
      require("../images/catalog/bluezone/oriental/interiors/bluezone_oriental_13730_107129_0x300.jpg"),
      require("../images/catalog/bluezone/oriental/interiors/bluezone_oriental_13730_107129_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/oriental/tovars/bluezone_oriental_108749_704192_300x0.jpg"),
    ],
  },
  {
    id: "354",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Panda_white",
    interiors: [
      require("../images/catalog/bluezone/panda_white/interiors/bluezone_panda_white_11110_371894_0x300.jpg"),
      require("../images/catalog/bluezone/panda_white/interiors/bluezone_panda_white_11110_371894_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/panda_white/tovars/bluezone_panda_white_93224_336163_300x0.jpg"),
    ],
  },
  {
    id: "355",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Phantom",
    interiors: [
      require("../images/catalog/bluezone/phantom/interiors/bluezone_phantom_16406_208284_0x300.jpg"),
      require("../images/catalog/bluezone/phantom/interiors/bluezone_phantom_16406_208284_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/phantom/tovars/bluezone_phantom_120888_41489_300x0.jpg"),
    ],
  },
  {
    id: "356",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Rockstone_azure",
    interiors: [
      require("../images/catalog/bluezone/rockstone_azure/interiors/bluezone_rockstone_azure_11878_449936_0x300.jpg"),
      require("../images/catalog/bluezone/rockstone_azure/interiors/bluezone_rockstone_azure_11878_449936_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/rockstone_azure/tovars/bluezone_rockstone_azure_97400_291818_300x0.jpg"),
      require("../images/catalog/bluezone/rockstone_azure/tovars/bluezone_rockstone_azure_97477_564195_300x0.jpg"),
    ],
  },
  {
    id: "357",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Romano_versailles",
    interiors: [
      require("../images/catalog/bluezone/romano_versailles/interiors/bluezone_romano_versailles_16407_101446_0x300.jpg"),
      require("../images/catalog/bluezone/romano_versailles/interiors/bluezone_romano_versailles_16407_106847_0x300.jpg"),
      require("../images/catalog/bluezone/romano_versailles/interiors/bluezone_romano_versailles_16407_28001_0x300.jpg"),
      require("../images/catalog/bluezone/romano_versailles/interiors/bluezone_romano_versailles_16407_41509_0x300.jpg"),
      require("../images/catalog/bluezone/romano_versailles/interiors/bluezone_romano_versailles_16407_41509_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/romano_versailles/tovars/bluezone_romano_versailles_120891_799936_300x0.jpg"),
      require("../images/catalog/bluezone/romano_versailles/tovars/bluezone_romano_versailles_120892_689996_300x0.jpg"),
      require("../images/catalog/bluezone/romano_versailles/tovars/bluezone_romano_versailles_120893_308809_300x0.jpg"),
    ],
  },
  {
    id: "358",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Santorini_drizzle",
    interiors: [
      require("../images/catalog/bluezone/santorini_drizzle/interiors/bluezone_santorini_drizzle_11904_402148_0x300.jpg"),
      require("../images/catalog/bluezone/santorini_drizzle/interiors/bluezone_santorini_drizzle_11904_402148_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/santorini_drizzle/tovars/bluezone_santorini_drizzle_97483_511966_300x0.jpg"),
    ],
  },
  {
    id: "359",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Scotish_white",
    interiors: [
      require("../images/catalog/bluezone/scotish_white/interiors/bluezone_scotish_white_11112_321001_0x300.jpg"),
      require("../images/catalog/bluezone/scotish_white/interiors/bluezone_scotish_white_11112_321001_0x500.jpg"),
      require("../images/catalog/bluezone/scotish_white/interiors/bluezone_scotish_white_11112_940926_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/scotish_white/tovars/bluezone_scotish_white_93227_320427_300x0.jpg"),
      require("../images/catalog/bluezone/scotish_white/tovars/bluezone_scotish_white_93228_301458_300x0.jpg"),
      require("../images/catalog/bluezone/scotish_white/tovars/bluezone_scotish_white_93229_11885_300x0.jpg"),
    ],
  },
  {
    id: "360",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Bluezone",
    Collection: "Scot_white",
    interiors: [
      require("../images/catalog/bluezone/scot_white/interiors/bluezone_scot_white_11111_400102_0x300.jpg"),
      require("../images/catalog/bluezone/scot_white/interiors/bluezone_scot_white_11111_400102_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/bluezone/scot_white/tovars/bluezone_scot_white_93225_126370_300x0.jpg"),
      require("../images/catalog/bluezone/scot_white/tovars/bluezone_scot_white_93226_55390_300x0.jpg"),
    ],
  },

  {
    id: "361",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Absolute",
    interiors: [
      require("../images/catalog/delacora/absolute/interiors/delacora_absolute_14006_506225_0x300.jpg"),
      require("../images/catalog/delacora/absolute/interiors/delacora_absolute_14006_506225_0x500.jpg"),
      require("../images/catalog/delacora/absolute/interiors/delacora_absolute_14006_724567_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/absolute/tovars/delacora_absolute_109636_126686_300x0.jpg"),
    ],
  },
  {
    id: "362",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Alicante",
    interiors: [
      require("../images/catalog/delacora/alicante/interiors/delacora_alicante_13657_422208_0x300.jpg"),
      require("../images/catalog/delacora/alicante/interiors/delacora_alicante_13657_833679_0x300.jpg"),
      require("../images/catalog/delacora/alicante/interiors/delacora_alicante_13657_883071_0x300.jpg"),
      require("../images/catalog/delacora/alicante/interiors/delacora_alicante_13657_883071_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/alicante/tovars/delacora_alicante_108446_553795_300x0.jpg"),
      require("../images/catalog/delacora/alicante/tovars/delacora_alicante_108447_49520_300x0.jpg"),
      require("../images/catalog/delacora/alicante/tovars/delacora_alicante_108448_867810_300x0.jpg"),
    ],
  },
  {
    id: "363",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Arts",
    interiors: [
      require("../images/catalog/delacora/arts/interiors/delacora_arts_14007_559809_0x300.jpg"),
      require("../images/catalog/delacora/arts/interiors/delacora_arts_14007_559809_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/arts/tovars/delacora_arts_109649_921056_300x0.jpg"),
    ],
  },
  {
    id: "364",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Baffin_gray",
    interiors: [
      require("../images/catalog/delacora/baffin_gray/interiors/delacora_baffin_gray_9736_676207_0x300.jpg"),
      require("../images/catalog/delacora/baffin_gray/interiors/delacora_baffin_gray_9736_676207_0x500.jpg"),
      require("../images/catalog/delacora/baffin_gray/interiors/delacora_baffin_gray_9736_755854_0x300.jpg"),
      require("../images/catalog/delacora/baffin_gray/interiors/delacora_baffin_gray_9736_792113_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/baffin_gray/tovars/delacora_baffin_gray_83750_225009_300x0.jpg"),
      require("../images/catalog/delacora/baffin_gray/tovars/delacora_baffin_gray_83751_28764_300x0.jpg"),
      require("../images/catalog/delacora/baffin_gray/tovars/delacora_baffin_gray_83752_417085_300x0.jpg"),
      require("../images/catalog/delacora/baffin_gray/tovars/delacora_baffin_gray_83753_577148_300x0.jpg"),
      require("../images/catalog/delacora/baffin_gray/tovars/delacora_baffin_gray_83754_163935_300x0.jpg"),
      require("../images/catalog/delacora/baffin_gray/tovars/delacora_baffin_gray_83755_804291_300x0.jpg"),
    ],
  },
  {
    id: "365",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Barbados",
    interiors: [
      require("../images/catalog/delacora/barbados/interiors/delacora_barbados_18906_259796_0x300.jpg"),
      require("../images/catalog/delacora/barbados/interiors/delacora_barbados_18906_352980_0x300.jpg"),
      require("../images/catalog/delacora/barbados/interiors/delacora_barbados_18906_352980_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/barbados/tovars/delacora_barbados_130190_612604_300x0.jpg"),
      require("../images/catalog/delacora/barbados/tovars/delacora_barbados_130191_838327_300x0.jpg"),
      require("../images/catalog/delacora/barbados/tovars/delacora_barbados_130192_726539_300x0.jpg"),
      require("../images/catalog/delacora/barbados/tovars/delacora_barbados_130193_596856_300x0.jpg"),
    ],
  },
  {
    id: "366",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Bavaro",
    interiors: [
      require("../images/catalog/delacora/bavaro/interiors/delacora_bavaro_19083_122381_0x300.jpg"),
      require("../images/catalog/delacora/bavaro/interiors/delacora_bavaro_19083_629970_0x300.jpg"),
      require("../images/catalog/delacora/bavaro/interiors/delacora_bavaro_19083_629970_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/bavaro/tovars/delacora_bavaro_130757_743500_300x0.jpg"),
      require("../images/catalog/delacora/bavaro/tovars/delacora_bavaro_130758_269070_300x0.jpg"),
      require("../images/catalog/delacora/bavaro/tovars/delacora_bavaro_130759_985873_300x0.jpg"),
    ],
  },
  {
    id: "367",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Bohema",
    interiors: [
      require("../images/catalog/delacora/bohema/interiors/delacora_bohema_15169_366728_0x300.jpg"),
      require("../images/catalog/delacora/bohema/interiors/delacora_bohema_15169_859854_0x300.jpg"),
      require("../images/catalog/delacora/bohema/interiors/delacora_bohema_15169_859854_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/bohema/tovars/delacora_bohema_114786_710611_300x0.jpg"),
      require("../images/catalog/delacora/bohema/tovars/delacora_bohema_114787_256890_300x0.jpg"),
      require("../images/catalog/delacora/bohema/tovars/delacora_bohema_114788_864333_300x0.jpg"),
      require("../images/catalog/delacora/bohema/tovars/delacora_bohema_114789_561658_300x0.jpg"),
      require("../images/catalog/delacora/bohema/tovars/delacora_bohema_114790_28569_300x0.jpg"),
    ],
  },
  {
    id: "368",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Bryston",
    interiors: [
      require("../images/catalog/delacora/bryston/interiors/delacora_bryston_16495_122415_0x300.jpg"),
      require("../images/catalog/delacora/bryston/interiors/delacora_bryston_16495_122415_0x500.jpg"),
      require("../images/catalog/delacora/bryston/interiors/delacora_bryston_16495_691803_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/bryston/tovars/delacora_bryston_121145_563829_300x0.jpg"),
      require("../images/catalog/delacora/bryston/tovars/delacora_bryston_121146_910437_300x0.jpg"),
      require("../images/catalog/delacora/bryston/tovars/delacora_bryston_121147_95048_300x0.jpg"),
    ],
  },
  {
    id: "369",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Calacatta andrea",
    interiors: [
      require("../images/catalog/delacora/calacatta_andrea/interiors/delacora_calacatta_andrea_16905_271608_0x300.jpg"),
      require("../images/catalog/delacora/calacatta_andrea/interiors/delacora_calacatta_andrea_16905_271608_0x500.jpg"),
      require("../images/catalog/delacora/calacatta_andrea/interiors/delacora_calacatta_andrea_16905_563412_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/calacatta_andrea/tovars/delacora_calacatta_andrea_122018_470784_300x0.jpg"),
      require("../images/catalog/delacora/calacatta_andrea/tovars/delacora_calacatta_andrea_122019_957086_300x0.jpg"),
    ],
  },
  {
    id: "370",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Calacatta premium",
    interiors: [
      require("../images/catalog/delacora/calacatta_premium/interiors/delacora_calacatta_premium_16907_187695_0x300.jpg"),
      require("../images/catalog/delacora/calacatta_premium/interiors/delacora_calacatta_premium_16907_187695_0x500.jpg"),
      require("../images/catalog/delacora/calacatta_premium/interiors/delacora_calacatta_premium_16907_372304_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/calacatta_premium/tovars/delacora_calacatta_premium_122022_57045_300x0.jpg"),
      require("../images/catalog/delacora/calacatta_premium/tovars/delacora_calacatta_premium_122023_476157_300x0.jpg"),
    ],
  },
  {
    id: "371",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Canova",
    interiors: [
      require("../images/catalog/delacora/canova/interiors/delacora_canova_14290_144969_0x300.jpg"),
      require("../images/catalog/delacora/canova/interiors/delacora_canova_14290_764364_0x300.jpg"),
      require("../images/catalog/delacora/canova/interiors/delacora_canova_14290_994453_0x300.jpg"),
      require("../images/catalog/delacora/canova/interiors/delacora_canova_14290_994453_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/canova/tovars/delacora_canova_111052_440961_300x0.jpg"),
      require("../images/catalog/delacora/canova/tovars/delacora_canova_111053_521656_300x0.jpg"),
    ],
  },
  {
    id: "372",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Canyon",
    interiors: [
      require("../images/catalog/delacora/canyon/interiors/delacora_canyon_12059_718443_0x300.jpg"),
      require("../images/catalog/delacora/canyon/interiors/delacora_canyon_12059_718443_0x500.jpg"),
      require("../images/catalog/delacora/canyon/interiors/delacora_canyon_12059_856547_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/canyon/tovars/delacora_canyon_98560_362978_300x0.jpg"),
      require("../images/catalog/delacora/canyon/tovars/delacora_canyon_98561_899500_300x0.jpg"),
      require("../images/catalog/delacora/canyon/tovars/delacora_canyon_98562_553206_300x0.jpg"),
    ],
  },
  {
    id: "373",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Carrara cersei",
    interiors: [
      require("../images/catalog/delacora/carrara_cersei/interiors/delacora_carrara_cersei_16906_527909_0x300.jpg"),
      require("../images/catalog/delacora/carrara_cersei/interiors/delacora_carrara_cersei_16906_716029_0x300.jpg"),
      require("../images/catalog/delacora/carrara_cersei/interiors/delacora_carrara_cersei_16906_716029_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/carrara_cersei/tovars/delacora_carrara_cersei_122020_794281_300x0.jpg"),
      require("../images/catalog/delacora/carrara_cersei/tovars/delacora_carrara_cersei_122021_851258_300x0.jpg"),
    ],
  },
  {
    id: "374",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Centro",
    interiors: [
      require("../images/catalog/delacora/centro/interiors/delacora_centro_16908_127519_0x300.jpg"),
      require("../images/catalog/delacora/centro/interiors/delacora_centro_16908_480790_0x300.jpg"),
      require("../images/catalog/delacora/centro/interiors/delacora_centro_16908_497951_0x300.jpg"),
      require("../images/catalog/delacora/centro/interiors/delacora_centro_16908_497951_0x500.jpg"),
      require("../images/catalog/delacora/centro/interiors/delacora_centro_16908_511466_0x300.jpg"),
      require("../images/catalog/delacora/centro/interiors/delacora_centro_16908_605990_0x300.jpg"),
      require("../images/catalog/delacora/centro/interiors/delacora_centro_16908_67237_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122024_119994_300x0.jpg"),
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122025_550208_300x0.jpg"),
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122026_13843_300x0.jpg"),
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122027_261155_300x0.jpg"),
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122028_317575_300x0.jpg"),
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122029_322307_300x0.jpg"),
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122030_213881_300x0.jpg"),
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122031_668901_300x0.jpg"),
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122032_158421_300x0.jpg"),
      require("../images/catalog/delacora/centro/tovars/delacora_centro_122033_959898_300x0.jpg"),
    ],
  },
  {
    id: "375",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Chrome",
    interiors: [
      require("../images/catalog/delacora/chrome/interiors/delacora_chrome_14008_626514_0x300.jpg"),
      require("../images/catalog/delacora/chrome/interiors/delacora_chrome_14008_626514_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/chrome/tovars/delacora_chrome_109651_14379_300x0.jpg"),
      require("../images/catalog/delacora/chrome/tovars/delacora_chrome_109652_503591_300x0.jpg"),
    ],
  },
  {
    id: "376",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Colorstone",
    interiors: [
      require("../images/catalog/delacora/colorstone/interiors/delacora_colorstone_13659_638333_0x300.jpg"),
      require("../images/catalog/delacora/colorstone/interiors/delacora_colorstone_13659_766549_0x300.jpg"),
      require("../images/catalog/delacora/colorstone/interiors/delacora_colorstone_13659_766549_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/colorstone/tovars/delacora_colorstone_108451_560549_300x0.jpg"),
      require("../images/catalog/delacora/colorstone/tovars/delacora_colorstone_108452_952335_300x0.jpg"),
    ],
  },
  {
    id: "377",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Corsa",
    interiors: [
      require("../images/catalog/delacora/corsa/interiors/delacora_corsa_16909_357462_0x300.jpg"),
      require("../images/catalog/delacora/corsa/interiors/delacora_corsa_16909_357462_0x500.jpg"),
      require("../images/catalog/delacora/corsa/interiors/delacora_corsa_16909_433350_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/corsa/tovars/delacora_corsa_122034_755417_300x0.jpg"),
      require("../images/catalog/delacora/corsa/tovars/delacora_corsa_122035_852409_300x0.jpg"),
    ],
  },
  {
    id: "378",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Corten",
    interiors: [
      require("../images/catalog/delacora/corten/interiors/delacora_corten_14009_569068_0x300.jpg"),
      require("../images/catalog/delacora/corten/interiors/delacora_corten_14009_861952_0x300.jpg"),
      require("../images/catalog/delacora/corten/interiors/delacora_corten_14009_861952_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/corten/tovars/delacora_corten_109653_581603_300x0.jpg"),
    ],
  },
  {
    id: "379",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Crystal",
    interiors: [
      require("../images/catalog/delacora/crystal/interiors/delacora_crystal_9741_644490_0x300.jpg"),
      require("../images/catalog/delacora/crystal/interiors/delacora_crystal_9741_644490_0x500.jpg"),
      require("../images/catalog/delacora/crystal/interiors/delacora_crystal_9741_74669_0x300.jpg"),
      require("../images/catalog/delacora/crystal/interiors/delacora_crystal_9741_925626_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/crystal/tovars/delacora_crystal_83776_329359_300x0.jpg"),
      require("../images/catalog/delacora/crystal/tovars/delacora_crystal_83777_208822_300x0.jpg"),
      require("../images/catalog/delacora/crystal/tovars/delacora_crystal_83778_233661_300x0.jpg"),
      require("../images/catalog/delacora/crystal/tovars/delacora_crystal_83779_955762_300x0.jpg"),
      require("../images/catalog/delacora/crystal/tovars/delacora_crystal_83780_878156_300x0.jpg"),
      require("../images/catalog/delacora/crystal/tovars/delacora_crystal_83781_281636_300x0.jpg"),
    ],
  },
  {
    id: "380",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Delight",
    interiors: [
      require("../images/catalog/delacora/delight/interiors/delacora_delight_12713_237372_0x300.jpg"),
      require("../images/catalog/delacora/delight/interiors/delacora_delight_12713_237372_0x500.jpg"),
      require("../images/catalog/delacora/delight/interiors/delacora_delight_12713_577726_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/delight/tovars/delacora_delight_103817_502612_300x0.jpg"),
      require("../images/catalog/delacora/delight/tovars/delacora_delight_103818_779107_300x0.jpg"),
      require("../images/catalog/delacora/delight/tovars/delacora_delight_103819_520729_300x0.jpg"),
      require("../images/catalog/delacora/delight/tovars/delacora_delight_103820_884233_300x0.jpg"),
    ],
  },
  {
    id: "381",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Delta",
    interiors: [
      require("../images/catalog/delacora/delta/interiors/delacora_delta_13660_752116_0x300.jpg"),
      require("../images/catalog/delacora/delta/interiors/delacora_delta_13660_752116_0x500.jpg"),
      require("../images/catalog/delacora/delta/interiors/delacora_delta_13660_948694_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/delta/tovars/delacora_delta_108453_463783_300x0.jpg"),
      require("../images/catalog/delacora/delta/tovars/delacora_delta_108454_741193_300x0.jpg"),
      require("../images/catalog/delacora/delta/tovars/delacora_delta_108455_499157_300x0.jpg"),
      require("../images/catalog/delacora/delta/tovars/delacora_delta_108456_887249_300x0.jpg"),
    ],
  },
  {
    id: "382",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Dixon",
    interiors: [
      require("../images/catalog/delacora/dixon/interiors/delacora_dixon_14010_516629_0x300.jpg"),
      require("../images/catalog/delacora/dixon/interiors/delacora_dixon_14010_516629_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/dixon/tovars/delacora_dixon_109655_666941_300x0.jpg"),
    ],
  },
  {
    id: "383",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Dream",
    interiors: [
      require("../images/catalog/delacora/dream/interiors/delacora_dream_16910_623254_0x300.jpg"),
      require("../images/catalog/delacora/dream/interiors/delacora_dream_16910_728841_0x300.jpg"),
      require("../images/catalog/delacora/dream/interiors/delacora_dream_16910_728841_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/dream/tovars/delacora_dream_122036_873972_300x0.jpg"),
      require("../images/catalog/delacora/dream/tovars/delacora_dream_122037_945320_300x0.jpg"),
    ],
  },
  {
    id: "384",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Estelle",
    interiors: [
      require("../images/catalog/delacora/estelle/interiors/delacora_estelle_16911_223278_0x300.jpg"),
      require("../images/catalog/delacora/estelle/interiors/delacora_estelle_16911_223278_0x500.jpg"),
      require("../images/catalog/delacora/estelle/interiors/delacora_estelle_16911_274495_0x300.jpg"),
      require("../images/catalog/delacora/estelle/interiors/delacora_estelle_16911_63001_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/estelle/tovars/delacora_estelle_122038_280956_300x0.jpg"),
      require("../images/catalog/delacora/estelle/tovars/delacora_estelle_122039_979458_300x0.jpg"),
      require("../images/catalog/delacora/estelle/tovars/delacora_estelle_122040_851043_300x0.jpg"),
      require("../images/catalog/delacora/estelle/tovars/delacora_estelle_122041_821639_300x0.jpg"),
    ],
  },
  {
    id: "385",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Evan",
    interiors: [
      require("../images/catalog/delacora/evan/interiors/delacora_evan_15170_63545_0x300.jpg"),
      require("../images/catalog/delacora/evan/interiors/delacora_evan_15170_861463_0x300.jpg"),
      require("../images/catalog/delacora/evan/interiors/delacora_evan_15170_861463_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/evan/tovars/delacora_evan_114791_345738_300x0.jpg"),
      require("../images/catalog/delacora/evan/tovars/delacora_evan_114792_838499_300x0.jpg"),
      require("../images/catalog/delacora/evan/tovars/delacora_evan_114793_618037_300x0.jpg"),
      require("../images/catalog/delacora/evan/tovars/delacora_evan_114794_835935_300x0.jpg"),
    ],
  },
  {
    id: "386",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Exotic",
    interiors: [
      require("../images/catalog/delacora/exotic/interiors/delacora_exotic_10412_359506_0x300.jpg"),
      require("../images/catalog/delacora/exotic/interiors/delacora_exotic_10412_552847_0x300.jpg"),
      require("../images/catalog/delacora/exotic/interiors/delacora_exotic_10412_552847_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/exotic/tovars/delacora_exotic_88885_496718_300x0.jpg"),
      require("../images/catalog/delacora/exotic/tovars/delacora_exotic_88893_983709_300x0.jpg"),
      require("../images/catalog/delacora/exotic/tovars/delacora_exotic_88894_659270_300x0.jpg"),
    ],
  },
  {
    id: "387",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Faenza",
    interiors: [
      require("../images/catalog/delacora/faenza/interiors/delacora_faenza_13661_673798_0x300.jpg"),
      require("../images/catalog/delacora/faenza/interiors/delacora_faenza_13661_83101_0x300.jpg"),
      require("../images/catalog/delacora/faenza/interiors/delacora_faenza_13661_83101_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/faenza/tovars/delacora_faenza_108457_618764_300x0.jpg"),
      require("../images/catalog/delacora/faenza/tovars/delacora_faenza_108458_772835_300x0.jpg"),
      require("../images/catalog/delacora/faenza/tovars/delacora_faenza_108459_55771_300x0.jpg"),
    ],
  },
  {
    id: "388",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Freedom",
    interiors: [
      require("../images/catalog/delacora/freedom/interiors/delacora_freedom_16930_523664_0x300.jpg"),
      require("../images/catalog/delacora/freedom/interiors/delacora_freedom_16930_523664_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/freedom/tovars/delacora_freedom_122105_281277_300x0.jpg"),
    ],
  },
  {
    id: "389",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Frost",
    interiors: [
      require("../images/catalog/delacora/frost/interiors/delacora_frost_10644_244266_0x300.jpg"),
      require("../images/catalog/delacora/frost/interiors/delacora_frost_10644_244266_0x500.jpg"),
      require("../images/catalog/delacora/frost/interiors/delacora_frost_10644_578731_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/frost/tovars/delacora_frost_90257_227634_300x0.jpg"),
      require("../images/catalog/delacora/frost/tovars/delacora_frost_90258_727705_300x0.jpg"),
      require("../images/catalog/delacora/frost/tovars/delacora_frost_90259_727108_300x0.jpg"),
    ],
  },
  {
    id: "390",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Grafito",
    interiors: [
      require("../images/catalog/delacora/grafito/interiors/delacora_grafito_15171_281522_0x300.jpg"),
      require("../images/catalog/delacora/grafito/interiors/delacora_grafito_15171_281522_0x500.jpg"),
      require("../images/catalog/delacora/grafito/interiors/delacora_grafito_15171_827056_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/grafito/tovars/delacora_grafito_114796_308599_300x0.jpg"),
      require("../images/catalog/delacora/grafito/tovars/delacora_grafito_114797_282462_300x0.jpg"),
      require("../images/catalog/delacora/grafito/tovars/delacora_grafito_114798_34875_300x0.jpg"),
    ],
  },
  {
    id: "391",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Kioto",
    interiors: [
      require("../images/catalog/delacora/kioto/interiors/delacora_kioto_19084_650510_0x300.jpg"),
      require("../images/catalog/delacora/kioto/interiors/delacora_kioto_19084_650510_0x500.jpg"),
      require("../images/catalog/delacora/kioto/interiors/delacora_kioto_19084_765998_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/kioto/tovars/delacora_kioto_130760_325907_300x0.jpg"),
      require("../images/catalog/delacora/kioto/tovars/delacora_kioto_130761_497563_300x0.jpg"),
      require("../images/catalog/delacora/kioto/tovars/delacora_kioto_130762_638644_300x0.jpg"),
    ],
  },
  {
    id: "392",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Kreo",
    interiors: [
      require("../images/catalog/delacora/kreo/interiors/delacora_kreo_16496_284083_0x300.jpg"),
      require("../images/catalog/delacora/kreo/interiors/delacora_kreo_16496_284083_0x500.jpg"),
      require("../images/catalog/delacora/kreo/interiors/delacora_kreo_16496_558194_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/kreo/tovars/delacora_kreo_121148_127820_300x0.jpg"),
      require("../images/catalog/delacora/kreo/tovars/delacora_kreo_121149_738380_300x0.jpg"),
    ],
  },
  {
    id: "393",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Leon",
    interiors: [
      require("../images/catalog/delacora/leon/interiors/delacora_leon_15172_194926_0x300.jpg"),
      require("../images/catalog/delacora/leon/interiors/delacora_leon_15172_570607_0x300.jpg"),
      require("../images/catalog/delacora/leon/interiors/delacora_leon_15172_570607_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/leon/tovars/delacora_leon_114800_357849_300x0.jpg"),
      require("../images/catalog/delacora/leon/tovars/delacora_leon_114801_798357_300x0.jpg"),
      require("../images/catalog/delacora/leon/tovars/delacora_leon_114802_836845_300x0.jpg"),
    ],
  },
  {
    id: "394",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Maiolica",
    interiors: [
      require("../images/catalog/delacora/maiolica/interiors/delacora_maiolica_18907_930282_0x300.jpg"),
      require("../images/catalog/delacora/maiolica/interiors/delacora_maiolica_18907_930282_0x500.jpg"),
      require("../images/catalog/delacora/maiolica/interiors/delacora_maiolica_18907_994788_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/maiolica/tovars/delacora_maiolica_130194_471632_300x0.jpg"),
      require("../images/catalog/delacora/maiolica/tovars/delacora_maiolica_130195_791175_300x0.jpg"),
      require("../images/catalog/delacora/maiolica/tovars/delacora_maiolica_130196_583707_300x0.jpg"),
      require("../images/catalog/delacora/maiolica/tovars/delacora_maiolica_130197_772839_300x0.jpg"),
    ],
  },
  {
    id: "395",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Metallic",
    interiors: [
      require("../images/catalog/delacora/metallic/interiors/delacora_metallic_14011_32411_0x300.jpg"),
      require("../images/catalog/delacora/metallic/interiors/delacora_metallic_14011_32411_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/metallic/tovars/delacora_metallic_109656_249109_300x0.jpg"),
    ],
  },
  {
    id: "396",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Millennium",
    interiors: [
      require("../images/catalog/delacora/millennium/interiors/delacora_millennium_19085_792076_0x300.jpg"),
      require("../images/catalog/delacora/millennium/interiors/delacora_millennium_19085_889433_0x300.jpg"),
      require("../images/catalog/delacora/millennium/interiors/delacora_millennium_19085_889433_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/millennium/tovars/delacora_millennium_130763_91526_300x0.jpg"),
      require("../images/catalog/delacora/millennium/tovars/delacora_millennium_130764_767150_300x0.jpg"),
      require("../images/catalog/delacora/millennium/tovars/delacora_millennium_130765_982992_300x0.jpg"),
      require("../images/catalog/delacora/millennium/tovars/delacora_millennium_130766_68743_300x0.jpg"),
    ],
  },
  {
    id: "397",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Muse",
    interiors: [
      require("../images/catalog/delacora/muse/interiors/delacora_muse_16912_21474_0x300.jpg"),
      require("../images/catalog/delacora/muse/interiors/delacora_muse_16912_21474_0x500.jpg"),
      require("../images/catalog/delacora/muse/interiors/delacora_muse_16912_572769_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/muse/tovars/delacora_muse_122042_992269_300x0.jpg"),
      require("../images/catalog/delacora/muse/tovars/delacora_muse_122043_562421_300x0.jpg"),
    ],
  },
  {
    id: "398",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Nebraska",
    interiors: [
      require("../images/catalog/delacora/nebraska/interiors/delacora_nebraska_15173_178197_0x300.jpg"),
      require("../images/catalog/delacora/nebraska/interiors/delacora_nebraska_15173_487973_0x300.jpg"),
      require("../images/catalog/delacora/nebraska/interiors/delacora_nebraska_15173_487973_0x500.jpg"),
      require("../images/catalog/delacora/nebraska/interiors/delacora_nebraska_15173_498328_0x300.jpg"),
      require("../images/catalog/delacora/nebraska/interiors/delacora_nebraska_15173_73948_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/nebraska/tovars/delacora_nebraska_114803_249309_300x0.jpg"),
      require("../images/catalog/delacora/nebraska/tovars/delacora_nebraska_114804_266719_300x0.jpg"),
      require("../images/catalog/delacora/nebraska/tovars/delacora_nebraska_114805_306085_300x0.jpg"),
      require("../images/catalog/delacora/nebraska/tovars/delacora_nebraska_114806_349682_300x0.jpg"),
    ],
  },
  {
    id: "399",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Omega",
    interiors: [
      require("../images/catalog/delacora/omega/interiors/delacora_omega_14012_350493_0x300.jpg"),
      require("../images/catalog/delacora/omega/interiors/delacora_omega_14012_350493_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/omega/tovars/delacora_omega_109659_601321_300x0.jpg"),
      require("../images/catalog/delacora/omega/tovars/delacora_omega_109660_435132_300x0.jpg"),
    ],
  },
  {
    id: "400",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Oregon",
    interiors: [
      require("../images/catalog/delacora/oregon/interiors/delacora_oregon_14013_788302_0x300.jpg"),
      require("../images/catalog/delacora/oregon/interiors/delacora_oregon_14013_788302_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/oregon/tovars/delacora_oregon_109661_943709_300x0.jpg"),
      require("../images/catalog/delacora/oregon/tovars/delacora_oregon_109662_250794_300x0.jpg"),
      require("../images/catalog/delacora/oregon/tovars/delacora_oregon_109663_483463_300x0.jpg"),
    ],
  },
  {
    id: "401",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Palissandro",
    interiors: [
      require("../images/catalog/delacora/palissandro/interiors/delacora_palissandro_12714_485176_0x300.jpg"),
      require("../images/catalog/delacora/palissandro/interiors/delacora_palissandro_12714_896506_0x300.jpg"),
      require("../images/catalog/delacora/palissandro/interiors/delacora_palissandro_12714_896506_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/palissandro/tovars/delacora_palissandro_103868_603957_300x0.jpg"),
      require("../images/catalog/delacora/palissandro/tovars/delacora_palissandro_103869_911506_300x0.jpg"),
      require("../images/catalog/delacora/palissandro/tovars/delacora_palissandro_103870_732483_300x0.jpg"),
      require("../images/catalog/delacora/palissandro/tovars/delacora_palissandro_103871_563449_300x0.jpg"),
    ],
  },
  {
    id: "402",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Rock",
    interiors: [
      require("../images/catalog/delacora/rock/interiors/delacora_rock_16913_320227_0x300.jpg"),
      require("../images/catalog/delacora/rock/interiors/delacora_rock_16913_320227_0x500.jpg"),
      require("../images/catalog/delacora/rock/interiors/delacora_rock_16913_492105_0x300.jpg"),
      require("../images/catalog/delacora/rock/interiors/delacora_rock_16913_600055_0x300.jpg"),
      require("../images/catalog/delacora/rock/interiors/delacora_rock_16913_658089_0x300.jpg"),
      require("../images/catalog/delacora/rock/interiors/delacora_rock_16913_671761_0x300.jpg"),
      require("../images/catalog/delacora/rock/interiors/delacora_rock_16913_822257_0x300.jpg"),
      require("../images/catalog/delacora/rock/interiors/delacora_rock_16913_915878_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/rock/tovars/delacora_rock_122044_999890_300x0.jpg"),
      require("../images/catalog/delacora/rock/tovars/delacora_rock_122045_134160_300x0.jpg"),
      require("../images/catalog/delacora/rock/tovars/delacora_rock_122046_554971_300x0.jpg"),
      require("../images/catalog/delacora/rock/tovars/delacora_rock_122047_835475_300x0.jpg"),
      require("../images/catalog/delacora/rock/tovars/delacora_rock_122048_162881_300x0.jpg"),
      require("../images/catalog/delacora/rock/tovars/delacora_rock_122049_25755_300x0.jpg"),
      require("../images/catalog/delacora/rock/tovars/delacora_rock_122050_792562_300x0.jpg"),
      require("../images/catalog/delacora/rock/tovars/delacora_rock_122051_947164_300x0.jpg"),
    ],
  },
  {
    id: "403",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Rockstyle",
    interiors: [
      require("../images/catalog/delacora/rockstyle/interiors/delacora_rockstyle_18908_419601_0x300.jpg"),
      require("../images/catalog/delacora/rockstyle/interiors/delacora_rockstyle_18908_419601_0x500.jpg"),
      require("../images/catalog/delacora/rockstyle/interiors/delacora_rockstyle_18908_477783_0x300.jpg"),
      require("../images/catalog/delacora/rockstyle/interiors/delacora_rockstyle_18908_551998_0x300.jpg"),
      require("../images/catalog/delacora/rockstyle/interiors/delacora_rockstyle_18908_571570_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/rockstyle/tovars/delacora_rockstyle_130198_685484_300x0.jpg"),
      require("../images/catalog/delacora/rockstyle/tovars/delacora_rockstyle_130199_132660_300x0.jpg"),
      require("../images/catalog/delacora/rockstyle/tovars/delacora_rockstyle_130200_237520_300x0.jpg"),
    ],
  },
  {
    id: "404",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Romana",
    interiors: [
      require("../images/catalog/delacora/romana/interiors/delacora_romana_16914_165725_0x300.jpg"),
      require("../images/catalog/delacora/romana/interiors/delacora_romana_16914_165725_0x500.jpg"),
      require("../images/catalog/delacora/romana/interiors/delacora_romana_16914_355934_0x300.jpg"),
      require("../images/catalog/delacora/romana/interiors/delacora_romana_16914_415563_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/romana/tovars/delacora_romana_122052_867015_300x0.jpg"),
      require("../images/catalog/delacora/romana/tovars/delacora_romana_122053_839609_300x0.jpg"),
      require("../images/catalog/delacora/romana/tovars/delacora_romana_122054_423321_300x0.jpg"),
      require("../images/catalog/delacora/romana/tovars/delacora_romana_122055_977011_300x0.jpg"),
      require("../images/catalog/delacora/romana/tovars/delacora_romana_122056_389817_300x0.jpg"),
      require("../images/catalog/delacora/romana/tovars/delacora_romana_122057_427166_300x0.jpg"),
    ],
  },
  {
    id: "405",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Roxy",
    interiors: [
      require("../images/catalog/delacora/roxy/interiors/delacora_roxy_14109_235437_0x300.jpg"),
      require("../images/catalog/delacora/roxy/interiors/delacora_roxy_14109_374806_0x300.jpg"),
      require("../images/catalog/delacora/roxy/interiors/delacora_roxy_14109_854989_0x300.jpg"),
      require("../images/catalog/delacora/roxy/interiors/delacora_roxy_14109_854989_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/roxy/tovars/delacora_roxy_110382_104800_300x0.jpg"),
      require("../images/catalog/delacora/roxy/tovars/delacora_roxy_110383_954022_300x0.jpg"),
    ],
  },
  {
    id: "406",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Royal",
    interiors: [
      require("../images/catalog/delacora/royal/interiors/delacora_royal_9748_120311_0x300.jpg"),
      require("../images/catalog/delacora/royal/interiors/delacora_royal_9748_184469_0x300.jpg"),
      require("../images/catalog/delacora/royal/interiors/delacora_royal_9748_184469_0x500.jpg"),
      require("../images/catalog/delacora/royal/interiors/delacora_royal_9748_463020_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/royal/tovars/delacora_royal_83829_240039_300x0.jpg"),
      require("../images/catalog/delacora/royal/tovars/delacora_royal_83830_34452_300x0.jpg"),
      require("../images/catalog/delacora/royal/tovars/delacora_royal_83831_15853_300x0.jpg"),
      require("../images/catalog/delacora/royal/tovars/delacora_royal_83832_50449_300x0.jpg"),
      require("../images/catalog/delacora/royal/tovars/delacora_royal_83833_249462_300x0.jpg"),
      require("../images/catalog/delacora/royal/tovars/delacora_royal_83834_34619_300x0.jpg"),
      require("../images/catalog/delacora/royal/tovars/delacora_royal_83835_457535_300x0.jpg"),
      require("../images/catalog/delacora/royal/tovars/delacora_royal_83836_816612_300x0.jpg"),
    ],
  },
  {
    id: "407",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Safari",
    interiors: [
      require("../images/catalog/delacora/safari/interiors/delacora_safari_16497_705038_0x300.jpg"),
      require("../images/catalog/delacora/safari/interiors/delacora_safari_16497_983477_0x300.jpg"),
      require("../images/catalog/delacora/safari/interiors/delacora_safari_16497_983477_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/safari/tovars/delacora_safari_121150_327800_300x0.jpg"),
      require("../images/catalog/delacora/safari/tovars/delacora_safari_121151_368206_300x0.jpg"),
    ],
  },
  {
    id: "408",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Sandy",
    interiors: [
      require("../images/catalog/delacora/sandy/interiors/delacora_sandy_15028_767261_0x300.jpg"),
      require("../images/catalog/delacora/sandy/interiors/delacora_sandy_15028_870614_0x300.jpg"),
      require("../images/catalog/delacora/sandy/interiors/delacora_sandy_15028_870614_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/sandy/tovars/delacora_sandy_113834_249996_300x0.jpg"),
      require("../images/catalog/delacora/sandy/tovars/delacora_sandy_113835_382476_300x0.jpg"),
    ],
  },
  {
    id: "409",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Slate",
    interiors: [
      require("../images/catalog/delacora/slate/interiors/delacora_slate_14110_486867_0x300.jpg"),
    ],
  },
  {
    id: "410",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Statuary_venato",
    interiors: [
      require("../images/catalog/delacora/statuary_venato/interiors/delacora_statuary_venato_14111_262363_0x300.jpg"),
    ],
  },
  {
    id: "411",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "Turin",
    interiors: [
      require("../images/catalog/delacora/turin/interiors/delacora_turin_14014_990409_0x300.jpg"),
    ],
  },
  {
    id: "412",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "waterfall",
    interiors: [
      require("../images/catalog/delacora/waterfall/interiors/delacora_waterfall_14114_89983_0x300.jpg"),
    ],
  },
  {
    id: "413",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Delacora",
    Collection: "woodstyle",
    interiors: [
      require("../images/catalog/delacora/woodstyle/interiors/delacora_woodstyle_18909_211830.jpg"),
      require("../images/catalog/delacora/woodstyle/interiors/delacora_woodstyle_18909_481747.jpg"),
      require("../images/catalog/delacora/woodstyle/interiors/delacora_woodstyle_18909_490752.jpg"),
      require("../images/catalog/delacora/woodstyle/interiors/delacora_woodstyle_18909_788584.jpg"),
      require("../images/catalog/delacora/woodstyle/interiors/delacora_woodstyle_18909_936735.jpg"),
    ],
    tovars: [
      require("../images/catalog/delacora/woodstyle/tovars/delacora_woodstyle_130201_118777_300x0.jpg"),
      require("../images/catalog/delacora/woodstyle/tovars/delacora_woodstyle_130202_513603_300x0.jpg"),
      require("../images/catalog/delacora/woodstyle/tovars/delacora_woodstyle_130203_845959_500x0.jpg"),
      require("../images/catalog/delacora/woodstyle/tovars/delacora_woodstyle_130204_978734_500x0.jpg"),
      require("../images/catalog/delacora/woodstyle/tovars/delacora_woodstyle_130205_836224_500x0.jpg"),
      require("../images/catalog/delacora/woodstyle/tovars/delacora_woodstyle_130206_248653_500x0.jpg"),
      require("../images/catalog/delacora/woodstyle/tovars/delacora_barbados_130192_726539_500x0.jpg"),
      require("../images/catalog/delacora/woodstyle/tovars/delacora_barbados_130193_596856_500x0.jpg"),
    ],
  },

  {
    id: "414",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Basalt",
    interiors: [
      require("../images/catalog/eletto_ceramica/basalt/interiors/eletto_ceramica_basalt_14857_200164_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/basalt/interiors/eletto_ceramica_basalt_14857_599045_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/basalt/interiors/eletto_ceramica_basalt_14857_599045_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/basalt/interiors/eletto_ceramica_basalt_14857_652831_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/basalt/tovars/eletto_ceramica_basalt_113417_146875_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/basalt/tovars/eletto_ceramica_basalt_113418_638441_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/basalt/tovars/eletto_ceramica_basalt_113419_939106_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/basalt/tovars/eletto_ceramica_basalt_113420_297144_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/basalt/tovars/eletto_ceramica_basalt_113421_633546_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/basalt/tovars/eletto_ceramica_basalt_113422_719906_300x0.jpg"),
    ],
  },
  {
    id: "415",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Bianco covelano",
    interiors: [
      require("../images/catalog/eletto_ceramica/bianco_covelano/interiors/eletto_ceramica_bianco_covelano_10425_107409_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/bianco_covelano/interiors/eletto_ceramica_bianco_covelano_10425_126938_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/bianco_covelano/interiors/eletto_ceramica_bianco_covelano_10425_126938_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/bianco_covelano/interiors/eletto_ceramica_bianco_covelano_10425_583252_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/bianco_covelano/tovars/eletto_ceramica_bianco_covelano_88963_102919_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/bianco_covelano/tovars/eletto_ceramica_bianco_covelano_88964_439986_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/bianco_covelano/tovars/eletto_ceramica_bianco_covelano_88965_215354_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/bianco_covelano/tovars/eletto_ceramica_bianco_covelano_88966_241473_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/bianco_covelano/tovars/eletto_ceramica_bianco_covelano_88967_338807_300x0.jpg"),
    ],
  },
  {
    id: "416",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Black gold",
    interiors: [
      require("../images/catalog/eletto_ceramica/black___gold/interiors/eletto_ceramica_black___gold_10426_224672_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/black___gold/interiors/eletto_ceramica_black___gold_10426_224672_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/black___gold/interiors/eletto_ceramica_black___gold_10426_528149_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/black___gold/interiors/eletto_ceramica_black___gold_10426_70614_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/black___gold/interiors/eletto_ceramica_black___gold_10426_793431_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/black___gold/tovars/eletto_ceramica_black___gold_88968_869291_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/black___gold/tovars/eletto_ceramica_black___gold_88969_413316_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/black___gold/tovars/eletto_ceramica_black___gold_88970_724347_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/black___gold/tovars/eletto_ceramica_black___gold_88971_921905_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/black___gold/tovars/eletto_ceramica_black___gold_88972_503459_300x0.jpg"),
    ],
  },
  {
    id: "417",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Calacatta",
    interiors: [
      require("../images/catalog/eletto_ceramica/calacatta/interiors/eletto_ceramica_calacatta_8712_472903_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta/interiors/eletto_ceramica_calacatta_8712_859652_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta/interiors/eletto_ceramica_calacatta_8712_876289_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta/interiors/eletto_ceramica_calacatta_8712_876289_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/calacatta/tovars/eletto_ceramica_calacatta_113443_733651_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta/tovars/eletto_ceramica_calacatta_74538_958836_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta/tovars/eletto_ceramica_calacatta_74539_614950_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta/tovars/eletto_ceramica_calacatta_74541_660015_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta/tovars/eletto_ceramica_calacatta_74542_567813_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta/tovars/eletto_ceramica_calacatta_74543_252955_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta/tovars/eletto_ceramica_calacatta_83014_69067_300x0.jpg"),
    ],
  },
  {
    id: "418",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Calacatta fantasy",
    interiors: [
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/interiors/eletto_ceramica_calacatta_fantasy_14858_22789_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/interiors/eletto_ceramica_calacatta_fantasy_14858_22789_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/interiors/eletto_ceramica_calacatta_fantasy_14858_435997_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/interiors/eletto_ceramica_calacatta_fantasy_14858_647027_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/tovars/eletto_ceramica_calacatta_fantasy_113423_603413_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/tovars/eletto_ceramica_calacatta_fantasy_113424_901543_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/tovars/eletto_ceramica_calacatta_fantasy_113425_566307_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/tovars/eletto_ceramica_calacatta_fantasy_113426_171043_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/tovars/eletto_ceramica_calacatta_fantasy_113427_339714_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/tovars/eletto_ceramica_calacatta_fantasy_113428_331074_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_fantasy/tovars/eletto_ceramica_calacatta_fantasy_113429_678925_300x0.jpg"),
    ],
  },
  {
    id: "419",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Calacatta grey",
    interiors: [
      require("../images/catalog/eletto_ceramica/calacatta_grey/interiors/eletto_ceramica_calacatta_grey_14859_781751_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_grey/interiors/eletto_ceramica_calacatta_grey_14859_781751_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/calacatta_grey/tovars/eletto_ceramica_calacatta_grey_113430_494857_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_grey/tovars/eletto_ceramica_calacatta_grey_113431_261052_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_grey/tovars/eletto_ceramica_calacatta_grey_113432_900576_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_grey/tovars/eletto_ceramica_calacatta_grey_113433_161659_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_grey/tovars/eletto_ceramica_calacatta_grey_113434_806833_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_grey/tovars/eletto_ceramica_calacatta_grey_113435_187102_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_grey/tovars/eletto_ceramica_calacatta_grey_113436_952450_300x0.jpg"),
    ],
  },
  {
    id: "420",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Calacatta oro",
    interiors: [
      require("../images/catalog/eletto_ceramica/calacatta_oro/interiors/eletto_ceramica_calacatta_oro_10427_163066_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/interiors/eletto_ceramica_calacatta_oro_10427_341748_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/interiors/eletto_ceramica_calacatta_oro_10427_37306_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/interiors/eletto_ceramica_calacatta_oro_10427_390497_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/interiors/eletto_ceramica_calacatta_oro_10427_522380_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/interiors/eletto_ceramica_calacatta_oro_10427_522380_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/interiors/eletto_ceramica_calacatta_oro_10427_651815_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/interiors/eletto_ceramica_calacatta_oro_10427_900664_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/calacatta_oro/tovars/eletto_ceramica_calacatta_oro_88973_417940_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/tovars/eletto_ceramica_calacatta_oro_88974_944904_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/tovars/eletto_ceramica_calacatta_oro_88975_577312_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/tovars/eletto_ceramica_calacatta_oro_88976_760806_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/tovars/eletto_ceramica_calacatta_oro_88977_640864_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/tovars/eletto_ceramica_calacatta_oro_88978_28957_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/calacatta_oro/tovars/eletto_ceramica_calacatta_oro_88979_491375_300x0.jpg"),
    ],
  },
  {
    id: "421",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Couture",
    interiors: [
      require("../images/catalog/eletto_ceramica/couture/interiors/eletto_ceramica_couture_18289_169447_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/couture/interiors/eletto_ceramica_couture_18289_489882_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/couture/interiors/eletto_ceramica_couture_18289_556260_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/couture/interiors/eletto_ceramica_couture_18289_574718_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/couture/interiors/eletto_ceramica_couture_18289_649041_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/couture/interiors/eletto_ceramica_couture_18289_655952_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/couture/interiors/eletto_ceramica_couture_18289_764287_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/couture/interiors/eletto_ceramica_couture_18289_764287_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/couture/interiors/eletto_ceramica_couture_18289_785907_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/couture/tovars/eletto_ceramica_couture_126864_228832_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/couture/tovars/eletto_ceramica_couture_126865_608049_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/couture/tovars/eletto_ceramica_couture_126866_177922_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/couture/tovars/eletto_ceramica_couture_126867_558638_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/couture/tovars/eletto_ceramica_couture_126868_784881_300x0.jpg"),
    ],
  },
  {
    id: "422",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Fletto",
    interiors: [
      require("../images/catalog/eletto_ceramica/fletto/interiors/eletto_ceramica_fletto_12211_281227_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/fletto/interiors/eletto_ceramica_fletto_12211_294287_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/fletto/interiors/eletto_ceramica_fletto_12211_294287_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/fletto/interiors/eletto_ceramica_fletto_12211_649457_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/fletto/interiors/eletto_ceramica_fletto_12211_980133_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/fletto/tovars/eletto_ceramica_fletto_100345_361435_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/fletto/tovars/eletto_ceramica_fletto_100346_651630_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/fletto/tovars/eletto_ceramica_fletto_100347_304124_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/fletto/tovars/eletto_ceramica_fletto_100348_946632_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/fletto/tovars/eletto_ceramica_fletto_100349_188030_300x0.jpg"),
    ],
  },
  {
    id: "423",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Foresta",
    interiors: [
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_119155_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_184209_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_259585_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_368540_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_368540_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_421401_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_428278_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_579095_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_775457_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/interiors/eletto_ceramica_foresta_18290_94650_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/foresta/tovars/eletto_ceramica_foresta_126869_615371_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/tovars/eletto_ceramica_foresta_126870_634517_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/tovars/eletto_ceramica_foresta_126871_166222_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/tovars/eletto_ceramica_foresta_126872_873822_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/tovars/eletto_ceramica_foresta_126873_128421_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/foresta/tovars/eletto_ceramica_foresta_126874_994927_300x0.jpg"),
    ],
  },
  {
    id: "424",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Gala",
    interiors: [
      require("../images/catalog/eletto_ceramica/gala/interiors/eletto_ceramica_gala_10220_170006_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala/interiors/eletto_ceramica_gala_10220_263422_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala/interiors/eletto_ceramica_gala_10220_341334_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala/interiors/eletto_ceramica_gala_10220_341334_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/gala/interiors/eletto_ceramica_gala_10220_346279_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala/interiors/eletto_ceramica_gala_10220_420122_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala/interiors/eletto_ceramica_gala_10220_552686_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala/interiors/eletto_ceramica_gala_10220_566854_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/gala/tovars/eletto_ceramica_gala_87010_417051_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/gala/tovars/eletto_ceramica_gala_87011_297599_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/gala/tovars/eletto_ceramica_gala_87012_77329_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/gala/tovars/eletto_ceramica_gala_87013_374692_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/gala/tovars/eletto_ceramica_gala_88980_135067_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/gala/tovars/eletto_ceramica_gala_88981_745988_300x0.jpg"),
    ],
  },
  {
    id: "425",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Gala ivory",
    interiors: [
      require("../images/catalog/eletto_ceramica/gala_ivory/interiors/eletto_ceramica_gala_ivory_11653_11061_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/interiors/eletto_ceramica_gala_ivory_11653_11061_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/interiors/eletto_ceramica_gala_ivory_11653_219084_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/interiors/eletto_ceramica_gala_ivory_11653_264091_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/interiors/eletto_ceramica_gala_ivory_11653_530219_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/interiors/eletto_ceramica_gala_ivory_11653_575106_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/interiors/eletto_ceramica_gala_ivory_11653_65806_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/interiors/eletto_ceramica_gala_ivory_11653_694359_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/gala_ivory/tovars/eletto_ceramica_gala_ivory_95984_673322_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/tovars/eletto_ceramica_gala_ivory_95985_659322_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/tovars/eletto_ceramica_gala_ivory_95986_104384_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/gala_ivory/tovars/eletto_ceramica_gala_ivory_95987_370671_300x0.jpg"),
    ],
  },
  {
    id: "426",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Grey portogallo",
    interiors: [
      require("../images/catalog/eletto_ceramica/grey_portogallo/interiors/eletto_ceramica_grey_portogallo_14860_25149_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/grey_portogallo/interiors/eletto_ceramica_grey_portogallo_14860_430159_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/grey_portogallo/interiors/eletto_ceramica_grey_portogallo_14860_430159_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/grey_portogallo/interiors/eletto_ceramica_grey_portogallo_14860_956632_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/grey_portogallo/tovars/eletto_ceramica_grey_portogallo_113437_671777_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/grey_portogallo/tovars/eletto_ceramica_grey_portogallo_113438_857865_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/grey_portogallo/tovars/eletto_ceramica_grey_portogallo_113439_263577_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/grey_portogallo/tovars/eletto_ceramica_grey_portogallo_113440_528882_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/grey_portogallo/tovars/eletto_ceramica_grey_portogallo_113444_65263_300x0.jpg"),
    ],
  },
  {
    id: "427",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Insignia",
    interiors: [
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_136582_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_236721_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_324682_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_332483_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_344797_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_434595_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_686602_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_690754_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_690754_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/interiors/eletto_ceramica_insignia_11976_834247_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97898_241349_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97899_254586_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97900_126945_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97901_792392_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97902_488473_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97903_337575_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97904_316350_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97905_366420_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97906_338723_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97907_979317_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97908_702789_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97909_115320_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97910_842647_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/insignia/tovars/eletto_ceramica_insignia_97911_527674_300x0.jpg"),
    ],
  },
  {
    id: "428",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Levanto nero",
    interiors: [
      require("../images/catalog/eletto_ceramica/levanto_nero/interiors/eletto_ceramica_levanto_nero_11654_316585_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/interiors/eletto_ceramica_levanto_nero_11654_316585_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/interiors/eletto_ceramica_levanto_nero_11654_431614_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/interiors/eletto_ceramica_levanto_nero_11654_501085_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/interiors/eletto_ceramica_levanto_nero_11654_52271_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/interiors/eletto_ceramica_levanto_nero_11654_652663_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/interiors/eletto_ceramica_levanto_nero_11654_81229_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/interiors/eletto_ceramica_levanto_nero_11654_857003_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/interiors/eletto_ceramica_levanto_nero_11654_930020_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/levanto_nero/tovars/eletto_ceramica_levanto_nero_95988_662621_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/tovars/eletto_ceramica_levanto_nero_95989_545407_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/levanto_nero/tovars/eletto_ceramica_levanto_nero_95990_737656_300x0.jpg"),
    ],
  },
  {
    id: "429",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Limestone",
    interiors: [
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_141585_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_158421_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_304287_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_425602_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_453677_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_633490_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_670401_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_692535_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_818378_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/interiors/eletto_ceramica_limestone_18371_818378_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127240_120290_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127241_21575_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127242_104182_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127243_933153_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127244_676545_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127245_278337_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127246_187798_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127247_683214_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127248_425287_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/limestone/tovars/eletto_ceramica_limestone_127249_980687_300x0.jpg"),
    ],
  },
  {
    id: "430",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Lunario",
    interiors: [
      require("../images/catalog/eletto_ceramica/lunario/interiors/eletto_ceramica_lunario_18291_175806_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/interiors/eletto_ceramica_lunario_18291_449829_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/interiors/eletto_ceramica_lunario_18291_47898_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/interiors/eletto_ceramica_lunario_18291_563839_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/interiors/eletto_ceramica_lunario_18291_563839_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/interiors/eletto_ceramica_lunario_18291_839468_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/interiors/eletto_ceramica_lunario_18291_852622_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/interiors/eletto_ceramica_lunario_18291_876852_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/interiors/eletto_ceramica_lunario_18291_89275_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/lunario/tovars/eletto_ceramica_lunario_126875_432344_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/tovars/eletto_ceramica_lunario_126876_187303_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/tovars/eletto_ceramica_lunario_126877_354452_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/lunario/tovars/eletto_ceramica_lunario_126878_310887_300x0.jpg"),
    ],
  },
  {
    id: "431",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Malwiya",
    interiors: [
      require("../images/catalog/eletto_ceramica/malwiya/interiors/eletto_ceramica_malwiya_8716_213511_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/interiors/eletto_ceramica_malwiya_8716_480453_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/interiors/eletto_ceramica_malwiya_8716_499773_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/interiors/eletto_ceramica_malwiya_8716_587920_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/interiors/eletto_ceramica_malwiya_8716_587920_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/interiors/eletto_ceramica_malwiya_8716_709768_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_74578_133514_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_74579_140937_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_74580_223197_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_74581_643736_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_74582_824461_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_74583_735769_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_74586_107763_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_74587_796356_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_80634_559720_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/malwiya/tovars/eletto_ceramica_malwiya_80635_416398_300x0.jpg"),
    ],
  },
  {
    id: "432",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Odense",
    interiors: [
      require("../images/catalog/eletto_ceramica/odense/interiors/eletto_ceramica_odense_8717_325019_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/odense/interiors/eletto_ceramica_odense_8717_325019_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/odense/interiors/eletto_ceramica_odense_8717_337954_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/odense/interiors/eletto_ceramica_odense_8717_427034_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/odense/interiors/eletto_ceramica_odense_8717_477130_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_74588_279583_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_74589_133242_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_74590_911426_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_74591_220583_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_74592_92079_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_74593_526376_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_74594_870599_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_74595_649893_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_83017_235378_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/odense/tovars/eletto_ceramica_odense_83018_678958_300x0.jpg"),
    ],
  },
  {
    id: "433",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Old tjikko",
    interiors: [
      require("../images/catalog/eletto_ceramica/old_tjikko/interiors/eletto_ceramica_old_tjikko_8718_61328_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/old_tjikko/interiors/eletto_ceramica_old_tjikko_8718_61328_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/old_tjikko/interiors/eletto_ceramica_old_tjikko_8718_894352_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/old_tjikko/tovars/eletto_ceramica_old_tjikko_74598_769333_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/old_tjikko/tovars/eletto_ceramica_old_tjikko_74599_508363_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/old_tjikko/tovars/eletto_ceramica_old_tjikko_74600_481606_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/old_tjikko/tovars/eletto_ceramica_old_tjikko_82984_457057_300x0.jpg"),
    ],
  },
  {
    id: "434",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Rosa portogallo",
    interiors: [
      require("../images/catalog/eletto_ceramica/rosa_portogallo/interiors/eletto_ceramica_rosa_portogallo_11655_723469_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/interiors/eletto_ceramica_rosa_portogallo_11655_723469_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/interiors/eletto_ceramica_rosa_portogallo_11655_787974_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/interiors/eletto_ceramica_rosa_portogallo_11655_802744_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/interiors/eletto_ceramica_rosa_portogallo_11655_876937_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/interiors/eletto_ceramica_rosa_portogallo_11655_913912_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/interiors/eletto_ceramica_rosa_portogallo_11655_915878_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/rosa_portogallo/tovars/eletto_ceramica_rosa_portogallo_113441_773096_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/tovars/eletto_ceramica_rosa_portogallo_113442_316820_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/tovars/eletto_ceramica_rosa_portogallo_113445_963206_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/tovars/eletto_ceramica_rosa_portogallo_95991_862037_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/tovars/eletto_ceramica_rosa_portogallo_95992_277748_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/tovars/eletto_ceramica_rosa_portogallo_95993_413502_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/rosa_portogallo/tovars/eletto_ceramica_rosa_portogallo_95994_894700_300x0.jpg"),
    ],
  },
  {
    id: "435",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "Eletto Ceramica",
    Collection: "Trevi",
    interiors: [
      require("../images/catalog/eletto_ceramica/trevi/interiors/eletto_ceramica_trevi_9627_19875_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/interiors/eletto_ceramica_trevi_9627_564098_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/interiors/eletto_ceramica_trevi_9627_564098_0x500.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/interiors/eletto_ceramica_trevi_9627_605565_0x300.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/interiors/eletto_ceramica_trevi_9627_960359_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/eletto_ceramica/trevi/tovars/eletto_ceramica_trevi_83006_432172_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/tovars/eletto_ceramica_trevi_83007_685822_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/tovars/eletto_ceramica_trevi_83008_254985_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/tovars/eletto_ceramica_trevi_83009_418892_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/tovars/eletto_ceramica_trevi_83010_263054_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/tovars/eletto_ceramica_trevi_83011_160475_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/tovars/eletto_ceramica_trevi_83012_600165_300x0.jpg"),
      require("../images/catalog/eletto_ceramica/trevi/tovars/eletto_ceramica_trevi_83013_233740_300x0.jpg"),
    ],
  },
  {
    id: "436",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Aglomerat",
    interiors: [
      require("../images/catalog/estima/aglomerat/interiors/estima_aglomerat_3646_691883_0x300.jpg"),
      require("../images/catalog/estima/aglomerat/interiors/estima_aglomerat_3646_691883_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/aglomerat/tovars/estima_aglomerat_24438_685812_300x0.jpg"),
      require("../images/catalog/estima/aglomerat/tovars/estima_aglomerat_24439_926556_300x0.jpg"),
      require("../images/catalog/estima/aglomerat/tovars/estima_aglomerat_81850_360382_300x0.jpg"),
      require("../images/catalog/estima/aglomerat/tovars/estima_aglomerat_81851_122956_300x0.jpg"),
      require("../images/catalog/estima/aglomerat/tovars/estima_aglomerat_96438_373854_300x0.jpg"),
      require("../images/catalog/estima/aglomerat/tovars/estima_aglomerat_96439_313733_300x0.jpg"),
    ],
  },
  {
    id: "437",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Alba",
    interiors: [
      require("../images/catalog/estima/alba/interiors/estima_alba_10812_114380_0x300.jpg"),
      require("../images/catalog/estima/alba/interiors/estima_alba_10812_837479_0x300.jpg"),
      require("../images/catalog/estima/alba/interiors/estima_alba_10812_837479_0x500.jpg"),
      require("../images/catalog/estima/alba/interiors/estima_alba_10812_970907_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/alba/tovars/estima_alba_91203_112136_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91204_714619_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91205_148821_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91206_663930_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91209_176816_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91210_631920_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91211_341546_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91212_98859_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91213_461461_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91214_32749_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91215_445147_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91217_109095_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91218_641948_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91219_53037_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91220_127653_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_91221_827554_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_98172_213818_300x0.jpg"),
      require("../images/catalog/estima/alba/tovars/estima_alba_98173_841845_300x0.jpg"),
    ],
  },
  {
    id: "438",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Artwood",
    interiors: [
      require("../images/catalog/estima/artwood/interiors/estima_artwood_4329_332281_0x300.jpg"),
      require("../images/catalog/estima/artwood/interiors/estima_artwood_4329_332281_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/artwood/tovars/estima_artwood_30784_112889_300x0.jpg"),
      require("../images/catalog/estima/artwood/tovars/estima_artwood_30786_614576_300x0.jpg"),
    ],
  },
  {
    id: "439",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Bernini",
    interiors: [
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_112396_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_137972_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_181821_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_303088_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_330151_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_347787_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_422872_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_471173_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_491182_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_530287_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_583736_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_583736_0x500.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_591830_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_65875_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_715149_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_753026_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_76880_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_784106_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_797606_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_809831_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_839276_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_865114_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_956321_0x300.jpg"),
      require("../images/catalog/estima/bernini/interiors/estima_bernini_14421_987592_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111634_125120_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111635_958423_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111636_644614_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111637_991056_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111638_963377_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111639_15944_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111640_573550_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111641_412344_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111642_43655_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111643_657335_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111644_284344_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111645_375624_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111646_826522_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111647_371544_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111648_789238_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111649_603199_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111720_753807_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111721_313620_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111722_524197_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111723_695890_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111724_249276_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111725_426757_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111726_917826_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111727_617299_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111728_750657_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111729_388037_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111730_72303_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111731_496953_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111732_59900_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111733_600234_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111734_229196_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111735_335762_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111736_254748_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111737_594723_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111738_499968_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111739_391472_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111740_430054_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111741_914062_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111742_799643_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111743_328390_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111744_563447_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111745_332137_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111746_393956_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111747_538827_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111748_950215_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111749_757665_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111750_466683_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111751_704022_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111752_71285_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111753_980881_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111754_399913_300x0.jpg"),
      require("../images/catalog/estima/bernini/tovars/estima_bernini_111755_310563_300x0.jpg"),
    ],
  },
  {
    id: "440",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Brigantina",
    interiors: [
      require("../images/catalog/estima/brigantina/interiors/estima_brigantina_3265_171878_0x300.jpg"),
      require("../images/catalog/estima/brigantina/interiors/estima_brigantina_3265_171878_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_24415_425937_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_24416_551523_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_24418_629201_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_24419_875298_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_24420_685087_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_82463_801750_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_82468_717544_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91229_968257_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91230_259778_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91232_175106_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91233_966382_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91234_376581_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91235_690331_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91236_924088_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91238_244008_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91239_792468_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91240_638708_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91241_382830_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91242_456399_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91244_805525_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91245_14751_300x0.jpg"),
      require("../images/catalog/estima/brigantina/tovars/estima_brigantina_91246_787947_300x0.jpg"),
    ],
  },
  {
    id: "441",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Cave",
    interiors: [
      require("../images/catalog/estima/cave/interiors/estima_cave_16202_926716_0x300.jpg"),
      require("../images/catalog/estima/cave/interiors/estima_cave_16202_926716_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/cave/tovars/estima_cave_119671_68184_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_119672_276180_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_119673_300852_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_119674_918852_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_119675_862966_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_119676_742827_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120136_825025_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120137_581979_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120138_617586_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120139_581841_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120140_900448_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120141_973940_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120142_922784_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120143_862690_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120144_162498_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120145_816169_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120146_242322_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_120147_391618_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129329_16997_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129330_116714_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129331_128308_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129332_474549_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129333_885833_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129334_654034_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129335_128845_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129336_456002_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129337_767587_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129338_520923_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129339_462098_300x0.jpg"),
      require("../images/catalog/estima/cave/tovars/estima_cave_129340_45639_300x0.jpg"),
    ],
  },
  {
    id: "442",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Chambord",
    interiors: [
      require("../images/catalog/estima/chambord/interiors/estima_chambord_5585_750321_0x300.jpg"),
      require("../images/catalog/estima/chambord/interiors/estima_chambord_5585_750321_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/chambord/tovars/estima_chambord_44101_731053_300x0.jpg"),
    ],
  },
  {
    id: "443",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Classic wood",
    interiors: [
      require("../images/catalog/estima/classic_wood/interiors/estima_classic_wood_12016_425005_0x300.jpg"),
      require("../images/catalog/estima/classic_wood/interiors/estima_classic_wood_12016_85982_0x300.jpg"),
      require("../images/catalog/estima/classic_wood/interiors/estima_classic_wood_12016_85982_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_120148_156906_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_120149_210462_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_120150_403255_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_120151_43798_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_120152_45453_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98291_309080_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98292_914802_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98293_914772_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98294_598456_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98295_984898_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98296_593320_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98297_114122_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98298_92330_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98299_130480_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98300_439974_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98301_34190_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98302_482714_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98303_101716_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98304_236273_300x0.jpg"),
      require("../images/catalog/estima/classic_wood/tovars/estima_classic_wood_98305_293386_300x0.jpg"),
    ],
  },
  {
    id: "444",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Comfort",
    interiors: [
      require("../images/catalog/estima/comfort/interiors/estima_comfort_489_214155_0x300.jpg"),
      require("../images/catalog/estima/comfort/interiors/estima_comfort_489_214155_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/comfort/tovars/estima_comfort_28971_897390_300x0.jpg"),
      require("../images/catalog/estima/comfort/tovars/estima_comfort_82503_428517_300x0.jpg"),
    ],
  },
  {
    id: "445",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Cosmos",
    interiors: [
      require("../images/catalog/estima/cosmos/interiors/estima_cosmos_11749_13586_0x300.jpg"),
      require("../images/catalog/estima/cosmos/interiors/estima_cosmos_11749_176774_0x300.jpg"),
      require("../images/catalog/estima/cosmos/interiors/estima_cosmos_11749_456878_0x300.jpg"),
      require("../images/catalog/estima/cosmos/interiors/estima_cosmos_11749_490090_0x300.jpg"),
      require("../images/catalog/estima/cosmos/interiors/estima_cosmos_11749_519835_0x300.jpg"),
      require("../images/catalog/estima/cosmos/interiors/estima_cosmos_11749_519835_0x500.jpg"),
      require("../images/catalog/estima/cosmos/interiors/estima_cosmos_11749_711732_0x300.jpg"),
      require("../images/catalog/estima/cosmos/interiors/estima_cosmos_11749_766205_0x300.jpg"),
      require("../images/catalog/estima/cosmos/interiors/estima_cosmos_11749_786653_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_100893_825943_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_100894_567814_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_100895_946221_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_96448_455609_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_96449_164744_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_96450_107835_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_96451_263805_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_96452_863282_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_96453_250160_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_96454_969909_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_96455_754371_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_96456_159899_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_98174_437213_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_98175_909785_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_98176_911812_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_98177_608708_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_98178_666905_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_98179_318590_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_98180_203036_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_98181_498305_300x0.jpg"),
      require("../images/catalog/estima/cosmos/tovars/estima_cosmos_98182_908792_300x0.jpg"),
    ],
  },
  {
    id: "446",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Fusion",
    interiors: [
      require("../images/catalog/estima/fusion/interiors/estima_fusion_5588_97365_0x300.jpg"),
      require("../images/catalog/estima/fusion/interiors/estima_fusion_5588_97365_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/fusion/tovars/estima_fusion_44111_494725_300x0.jpg"),
      require("../images/catalog/estima/fusion/tovars/estima_fusion_81889_849065_300x0.jpg"),
      require("../images/catalog/estima/fusion/tovars/estima_fusion_98186_897761_300x0.jpg"),
      require("../images/catalog/estima/fusion/tovars/estima_fusion_98187_615899_300x0.jpg"),
    ],
  },
  {
    id: "447",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Gabbro",
    interiors: [
      require("../images/catalog/estima/gabbro/interiors/estima_gabbro_12017_338870_0x300.jpg"),
      require("../images/catalog/estima/gabbro/interiors/estima_gabbro_12017_338870_0x500.jpg"),
      require("../images/catalog/estima/gabbro/interiors/estima_gabbro_12017_395177_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_100896_281375_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_100897_328894_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_100898_644975_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_100899_633073_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_100900_483340_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_100901_921212_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_100902_31653_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_100903_678070_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98306_903650_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98307_317416_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98308_674169_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98309_598863_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98310_784213_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98311_372634_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98312_132717_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98313_29253_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98314_945309_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98315_177141_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98316_971064_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98317_557297_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98318_287777_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98319_445010_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98320_49468_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98321_325940_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98322_744091_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98323_954272_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98324_240712_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98325_342548_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98326_939170_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98327_824033_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98328_446672_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98329_31500_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98330_944514_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98331_876647_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98332_55691_300x0.jpg"),
      require("../images/catalog/estima/gabbro/tovars/estima_gabbro_98333_427229_300x0.jpg"),
    ],
  },
  {
    id: "448",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Gobi",
    interiors: [
      require("../images/catalog/estima/gobi/interiors/estima_gobi_16203_885445_0x300.jpg"),
      require("../images/catalog/estima/gobi/interiors/estima_gobi_16203_885445_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/gobi/tovars/estima_gobi_119677_592436_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_119678_605864_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_119679_154030_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_119680_646140_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_119681_164855_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_119682_471992_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_120153_228402_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_120154_327828_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_120155_460251_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_120156_103406_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_120157_102088_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_120158_761875_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_120159_391937_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_120160_51687_300x0.jpg"),
      require("../images/catalog/estima/gobi/tovars/estima_gobi_120161_582712_300x0.jpg"),
    ],
  },
  {
    id: "449",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Graffito",
    interiors: [
      require("../images/catalog/estima/graffito/interiors/estima_graffito_18701_252228_0x300.jpg"),
      require("../images/catalog/estima/graffito/interiors/estima_graffito_18701_252228_0x500.jpg"),
      require("../images/catalog/estima/graffito/interiors/estima_graffito_18701_894804_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129341_514986_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129342_503247_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129343_850106_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129344_472497_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129345_334055_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129346_102833_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129347_923963_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129348_71001_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129349_701097_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129350_715848_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129351_117132_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129352_65143_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129353_649206_300x0.jpg"),
      require("../images/catalog/estima/graffito/tovars/estima_graffito_129354_895294_300x0.jpg"),
    ],
  },
  {
    id: "450",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Ideal",
    interiors: [
      require("../images/catalog/estima/ideal/interiors/estima_ideal_8897_220429_0x300.jpg"),
      require("../images/catalog/estima/ideal/interiors/estima_ideal_8897_220429_0x500.jpg"),
      require("../images/catalog/estima/ideal/interiors/estima_ideal_8897_405842_0x300.jpg"),
      require("../images/catalog/estima/ideal/interiors/estima_ideal_8897_544460_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/ideal/tovars/estima_ideal_76016_50796_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_76017_680027_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_76018_930986_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_76019_847975_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_82434_818170_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_82435_50860_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_82436_663049_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_83721_617374_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_83722_254675_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_91260_894385_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_91261_466213_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_98188_232267_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_98189_865991_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_98190_838952_300x0.jpg"),
      require("../images/catalog/estima/ideal/tovars/estima_ideal_98191_726568_300x0.jpg"),
    ],
  },
  {
    id: "451",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Improve",
    interiors: [
      require("../images/catalog/estima/improve/interiors/estima_improve_18702_450431_0x300.jpg"),
      require("../images/catalog/estima/improve/interiors/estima_improve_18702_479163_0x300.jpg"),
      require("../images/catalog/estima/improve/interiors/estima_improve_18702_479163_0x500.jpg"),
      require("../images/catalog/estima/improve/interiors/estima_improve_18702_484969_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/improve/tovars/estima_improve_129355_41491_300x0.jpg"),
      require("../images/catalog/estima/improve/tovars/estima_improve_129356_645955_300x0.jpg"),
      require("../images/catalog/estima/improve/tovars/estima_improve_129357_537531_300x0.jpg"),
      require("../images/catalog/estima/improve/tovars/estima_improve_129358_180235_300x0.jpg"),
      require("../images/catalog/estima/improve/tovars/estima_improve_129359_33066_300x0.jpg"),
      require("../images/catalog/estima/improve/tovars/estima_improve_129360_516914_300x0.jpg"),
      require("../images/catalog/estima/improve/tovars/estima_improve_129361_903503_300x0.jpg"),
      require("../images/catalog/estima/improve/tovars/estima_improve_129362_877934_300x0.jpg"),
    ],
  },
  {
    id: "452",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Iron",
    interiors: [
      require("../images/catalog/estima/iron/interiors/estima_iron_5590_191796_0x300.jpg"),
      require("../images/catalog/estima/iron/interiors/estima_iron_5590_225227_0x300.jpg"),
      require("../images/catalog/estima/iron/interiors/estima_iron_5590_225227_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/iron/tovars/estima_iron_44118_244623_300x0.jpg"),
      require("../images/catalog/estima/iron/tovars/estima_iron_75987_343251_300x0.jpg"),
      require("../images/catalog/estima/iron/tovars/estima_iron_98192_575938_300x0.jpg"),
      require("../images/catalog/estima/iron/tovars/estima_iron_98193_578580_300x0.jpg"),
    ],
  },
  {
    id: "453",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Jazz",
    interiors: [
      require("../images/catalog/estima/jazz/interiors/estima_jazz_171_178396_0x300.jpg"),
      require("../images/catalog/estima/jazz/interiors/estima_jazz_171_178396_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/jazz/tovars/estima_jazz_35770_96711_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_35771_946560_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_35772_846022_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_35773_925611_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_35774_207598_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_35775_150331_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_35776_916079_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_35777_422470_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_65861_16504_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_65862_574533_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_65863_710143_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_65864_173022_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_7385_235405_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_7386_774349_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_7387_566838_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_7388_789585_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_91262_810697_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_91263_339533_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_91264_565309_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_91265_452646_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_91266_382571_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_91267_682964_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_91268_280200_300x0.jpg"),
      require("../images/catalog/estima/jazz/tovars/estima_jazz_91269_984468_300x0.jpg"),
    ],
  },
  {
    id: "454",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Kraft wood",
    interiors: [
      require("../images/catalog/estima/kraft_wood/interiors/estima_kraft_wood_18703_186782_0x300.jpg"),
      require("../images/catalog/estima/kraft_wood/interiors/estima_kraft_wood_18703_186782_0x500.jpg"),
      require("../images/catalog/estima/kraft_wood/interiors/estima_kraft_wood_18703_238195_0x300.jpg"),
      require("../images/catalog/estima/kraft_wood/interiors/estima_kraft_wood_18703_265277_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129363_361618_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129364_833101_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129365_597773_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129366_158835_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129367_532512_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129368_761776_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129369_343362_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129370_675733_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129371_227155_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129372_816603_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129373_189413_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129374_339506_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129375_621511_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129376_247965_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129377_687396_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129378_811095_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129379_821159_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129380_828266_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129381_768079_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129382_430919_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129383_869055_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129384_582994_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129385_678313_300x0.jpg"),
      require("../images/catalog/estima/kraft_wood/tovars/estima_kraft_wood_129386_924439_300x0.jpg"),
    ],
  },
  {
    id: "455",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Loft",
    interiors: [
      require("../images/catalog/estima/loft/interiors/estima_loft_3388_565535_0x300.jpg"),
      require("../images/catalog/estima/loft/interiors/estima_loft_3388_565535_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/loft/tovars/estima_loft_30814_134421_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_30815_784561_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_30816_507311_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_30817_200611_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_30818_702844_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_30819_263050_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_30820_209195_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_30821_251808_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_30822_459411_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_30823_261164_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_35784_164056_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_35785_131660_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_35786_74029_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_35787_378380_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_35788_753260_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91282_980622_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91283_898095_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91284_719113_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91285_442185_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91286_103927_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91287_301600_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91288_410442_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91289_353707_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91290_466707_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91292_376824_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91293_720289_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91294_157039_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91295_300913_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_91296_954299_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98194_873716_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98195_856838_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98196_705762_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98197_315667_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98198_517633_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98199_701579_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98200_97769_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98201_151240_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98202_105014_300x0.jpg"),
      require("../images/catalog/estima/loft/tovars/estima_loft_98203_852854_300x0.jpg"),
    ],
  },
  {
    id: "456",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Luna",
    interiors: [
      require("../images/catalog/estima/luna/interiors/estima_luna_10811_327283_0x300.jpg"),
      require("../images/catalog/estima/luna/interiors/estima_luna_10811_327283_0x500.jpg"),
      require("../images/catalog/estima/luna/interiors/estima_luna_10811_789966_0x300.jpg"),
      require("../images/catalog/estima/luna/interiors/estima_luna_10811_888760_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/luna/tovars/estima_luna_91155_777599_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91156_725091_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91157_362787_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91158_486006_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91159_744778_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91160_491430_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91161_72855_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91162_932604_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91163_959581_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91164_750623_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91165_586911_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91166_756912_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91170_281394_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91171_561793_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91172_462197_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91182_28557_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91183_195604_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91184_568857_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91185_190001_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91186_800339_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91187_132748_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91188_163904_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91189_577938_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91194_847841_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91195_516693_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91196_63945_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91197_592619_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91198_998124_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91199_126802_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91200_525224_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91201_957706_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_91202_867426_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_96440_529394_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_96441_382454_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_96442_661015_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_96443_659071_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98205_389873_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98206_66673_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98207_603894_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98208_817088_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98209_966459_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98210_515706_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98211_425796_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98212_633365_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98213_824298_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98214_618834_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98215_131671_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98216_733090_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98217_516596_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98218_737572_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98219_955359_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98220_382587_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98221_576524_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98222_681927_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98223_948526_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98224_155105_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98225_555643_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98226_805365_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98227_850868_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98228_861312_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98229_322998_300x0.jpg"),
      require("../images/catalog/estima/luna/tovars/estima_luna_98230_552448_300x0.jpg"),
    ],
  },
  {
    id: "457",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Mild",
    interiors: [
      require("../images/catalog/estima/mild/interiors/estima_mild_3645_260108_0x300.jpg"),
      require("../images/catalog/estima/mild/interiors/estima_mild_3645_260108_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/mild/tovars/estima_mild_24432_472370_300x0.jpg"),
      require("../images/catalog/estima/mild/tovars/estima_mild_24436_994288_300x0.jpg"),
      require("../images/catalog/estima/mild/tovars/estima_mild_27668_330351_300x0.jpg"),
      require("../images/catalog/estima/mild/tovars/estima_mild_35793_790380_300x0.jpg"),
      require("../images/catalog/estima/mild/tovars/estima_mild_35795_705452_300x0.jpg"),
      require("../images/catalog/estima/mild/tovars/estima_mild_35796_540459_300x0.jpg"),
      require("../images/catalog/estima/mild/tovars/estima_mild_35797_906456_300x0.jpg"),
      require("../images/catalog/estima/mild/tovars/estima_mild_35799_482808_300x0.jpg"),
      require("../images/catalog/estima/mild/tovars/estima_mild_35800_825015_300x0.jpg"),
    ],
  },
  {
    id: "458",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Miramare",
    interiors: [
      require("../images/catalog/estima/miramare/interiors/estima_miramare_18705_519407_0x300.jpg"),
      require("../images/catalog/estima/miramare/interiors/estima_miramare_18705_519407_0x500.jpg"),
      require("../images/catalog/estima/miramare/interiors/estima_miramare_18705_635190_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/miramare/tovars/estima_miramare_129431_650882_300x0.jpg"),
      require("../images/catalog/estima/miramare/tovars/estima_miramare_129432_858624_300x0.jpg"),
      require("../images/catalog/estima/miramare/tovars/estima_miramare_129433_275013_300x0.jpg"),
      require("../images/catalog/estima/miramare/tovars/estima_miramare_129434_679493_300x0.jpg"),
      require("../images/catalog/estima/miramare/tovars/estima_miramare_129435_435810_300x0.jpg"),
      require("../images/catalog/estima/miramare/tovars/estima_miramare_129436_223578_300x0.jpg"),
      require("../images/catalog/estima/miramare/tovars/estima_miramare_129437_910152_300x0.jpg"),
      require("../images/catalog/estima/miramare/tovars/estima_miramare_129438_386832_300x0.jpg"),
      require("../images/catalog/estima/miramare/tovars/estima_miramare_129439_196658_300x0.jpg"),
    ],
  },
  {
    id: "459",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Montis",
    interiors: [
      require("../images/catalog/estima/montis/interiors/estima_montis_10809_43176_0x300.jpg"),
      require("../images/catalog/estima/montis/interiors/estima_montis_10809_43176_0x500.jpg"),
      require("../images/catalog/estima/montis/interiors/estima_montis_10809_685063_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/montis/tovars/estima_montis_91124_687766_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91125_654307_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91126_797330_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91127_530771_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91130_974881_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91131_705284_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91132_221702_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91133_125821_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91134_469844_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91136_346441_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91137_23585_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91138_434244_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91139_818093_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91140_927995_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91141_261601_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_91142_380923_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_98231_949083_300x0.jpg"),
      require("../images/catalog/estima/montis/tovars/estima_montis_98232_464240_300x0.jpg"),
    ],
  },
  {
    id: "460",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Nolana",
    interiors: [
      require("../images/catalog/estima/nolana/interiors/estima_nolana_18704_601749_0x300.jpg"),
      require("../images/catalog/estima/nolana/interiors/estima_nolana_18704_601749_0x500.jpg"),
      require("../images/catalog/estima/nolana/interiors/estima_nolana_18704_913786_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129388_888451_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129389_478540_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129390_130232_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129391_297367_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129392_378952_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129393_998469_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129394_671318_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129395_340195_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129396_954372_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129397_518614_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129398_435820_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129399_360218_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129400_628867_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129401_766575_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129402_957034_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129403_413082_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129404_780233_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129405_249847_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129406_651747_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129407_702759_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129408_174719_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129409_667392_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129410_56638_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129411_587229_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129412_197996_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129413_254231_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129414_511123_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129415_310927_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129416_865174_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129417_845645_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129418_256966_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129419_10554_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129420_292072_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129421_948244_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129422_612372_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129423_695901_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129424_91368_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129425_924592_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129426_470413_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129427_883003_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129428_392922_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129429_879852_300x0.jpg"),
      require("../images/catalog/estima/nolana/tovars/estima_nolana_129430_366864_300x0.jpg"),
    ],
  },
  {
    id: "461",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Rainbow",
    interiors: [
      require("../images/catalog/estima/rainbow/interiors/estima_rainbow_164_798742_0x300.jpg"),
      require("../images/catalog/estima/rainbow/interiors/estima_rainbow_164_798742_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_13518_674395_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_13520_782182_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_13522_716974_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_13526_163421_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_13530_127153_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_13532_79217_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_13534_144478_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34753_570105_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34755_599264_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34756_937569_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34757_950555_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34758_495369_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34767_514196_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34768_491663_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34769_297985_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34772_232560_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34773_699420_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_34774_193150_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82020_201163_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82090_446008_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82091_614175_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82092_685946_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82093_407603_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82097_860405_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82098_583546_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82099_63964_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82100_419409_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82101_184269_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_82102_901510_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98233_647463_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98234_801938_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98235_226289_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98236_37336_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98237_858612_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98238_820184_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98239_844426_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98240_825072_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98241_335891_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98242_270223_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98243_458437_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98244_160189_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98245_879058_300x0.jpg"),
      require("../images/catalog/estima/rainbow/tovars/estima_rainbow_98246_580110_300x0.jpg"),
    ],
  },
  {
    id: "462",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Sherwood",
    interiors: [
      require("../images/catalog/estima/sherwood/interiors/estima_sherwood_5593_182363_0x300.jpg"),
      require("../images/catalog/estima/sherwood/interiors/estima_sherwood_5593_182363_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/sherwood/tovars/estima_sherwood_44133_692768_300x0.jpg"),
    ],
  },
  {
    id: "463",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Soft wood",
    interiors: [
      require("../images/catalog/estima/soft_wood/interiors/estima_soft_wood_16204_496758_0x300.jpg"),
      require("../images/catalog/estima/soft_wood/interiors/estima_soft_wood_16204_496758_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_119683_729635_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_119684_674102_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_119685_850681_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120162_323018_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120163_366848_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120164_988326_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120165_294046_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120166_870358_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120167_813352_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120168_866027_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120169_487944_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120170_395193_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120171_766475_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120172_461884_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120173_317978_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120174_629166_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120175_614383_300x0.jpg"),
      require("../images/catalog/estima/soft_wood/tovars/estima_soft_wood_120176_134148_300x0.jpg"),
    ],
  },
  {
    id: "464",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Standard",
    interiors: [
      require("../images/catalog/estima/standard/interiors/estima_standard_5870_123641_0x300.jpg"),
      require("../images/catalog/estima/standard/interiors/estima_standard_5870_144380_0x300.jpg"),
      require("../images/catalog/estima/standard/interiors/estima_standard_5870_382859_0x300.jpg"),
      require("../images/catalog/estima/standard/interiors/estima_standard_5870_684849_0x300.jpg"),
      require("../images/catalog/estima/standard/interiors/estima_standard_5870_684849_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/standard/tovars/estima_standard_46897_390443_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_46898_921861_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_46899_816446_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_46906_826979_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_46907_335126_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_46910_322834_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_46911_205356_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_46912_745109_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_46921_635028_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_47075_127899_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_47093_661231_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_82158_491910_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_98247_883280_300x0.jpg"),
      require("../images/catalog/estima/standard/tovars/estima_standard_98249_317682_300x0.jpg"),
    ],
  },
  {
    id: "465",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Technica standard",
    interiors: [
      require("../images/catalog/estima/technica_standard/interiors/estima_technica_standard_5869_604342_0x300.jpg"),
      require("../images/catalog/estima/technica_standard/interiors/estima_technica_standard_5869_604342_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/technica_standard/tovars/estima_technica_standard_46889_692896_300x0.jpg"),
      require("../images/catalog/estima/technica_standard/tovars/estima_technica_standard_46890_98160_300x0.jpg"),
      require("../images/catalog/estima/technica_standard/tovars/estima_technica_standard_46895_660831_300x0.jpg"),
      require("../images/catalog/estima/technica_standard/tovars/estima_technica_standard_46896_735473_300x0.jpg"),
      require("../images/catalog/estima/technica_standard/tovars/estima_technica_standard_70920_185511_300x0.jpg"),
    ],
  },
  {
    id: "466",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Terra",
    interiors: [
      require("../images/catalog/estima/terra/interiors/estima_terra_10810_736634_0x300.jpg"),
      require("../images/catalog/estima/terra/interiors/estima_terra_10810_736634_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/terra/tovars/estima_terra_91143_576406_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91144_960967_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91145_171443_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91146_614733_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91147_563891_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91148_973903_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91173_493098_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91174_677615_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91175_922042_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91176_829540_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91177_691202_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91178_356287_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91179_647634_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91180_619198_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_91181_607889_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_96444_576557_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_96445_321107_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_96446_75045_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_96447_655912_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98254_716769_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98255_39313_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98256_76212_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98257_522135_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98258_880182_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98259_927526_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98260_835134_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98261_432631_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98262_876609_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98263_299375_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98264_80094_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98265_678547_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98266_515665_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98267_107432_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98268_537160_300x0.jpg"),
      require("../images/catalog/estima/terra/tovars/estima_terra_98269_335850_300x0.jpg"),
    ],
  },
  {
    id: "467",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Tottori",
    interiors: [
      require("../images/catalog/estima/tottori/interiors/estima_tottori_16205_271949_0x300.jpg"),
      require("../images/catalog/estima/tottori/interiors/estima_tottori_16205_271949_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/tottori/tovars/estima_tottori_119686_608503_300x0.jpg"),
      require("../images/catalog/estima/tottori/tovars/estima_tottori_119687_737977_300x0.jpg"),
      require("../images/catalog/estima/tottori/tovars/estima_tottori_119688_397565_300x0.jpg"),
      require("../images/catalog/estima/tottori/tovars/estima_tottori_119689_457832_300x0.jpg"),
      require("../images/catalog/estima/tottori/tovars/estima_tottori_120177_861489_300x0.jpg"),
      require("../images/catalog/estima/tottori/tovars/estima_tottori_120178_996003_300x0.jpg"),
      require("../images/catalog/estima/tottori/tovars/estima_tottori_120179_281056_300x0.jpg"),
      require("../images/catalog/estima/tottori/tovars/estima_tottori_120180_71952_300x0.jpg"),
      require("../images/catalog/estima/tottori/tovars/estima_tottori_120181_399258_300x0.jpg"),
      require("../images/catalog/estima/tottori/tovars/estima_tottori_120182_314855_300x0.jpg"),
    ],
  },
  {
    id: "468",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Traffic",
    interiors: [
      require("../images/catalog/estima/traffic/interiors/estima_traffic_4141_607102_0x300.jpg"),
      require("../images/catalog/estima/traffic/interiors/estima_traffic_4141_60869_0x300.jpg"),
      require("../images/catalog/estima/traffic/interiors/estima_traffic_4141_664439_0x300.jpg"),
      require("../images/catalog/estima/traffic/interiors/estima_traffic_4141_83581_0x300.jpg"),
      require("../images/catalog/estima/traffic/interiors/estima_traffic_4141_83581_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/traffic/tovars/estima_traffic_28983_962125_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_28985_605638_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_35871_646248_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_35873_905459_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_91323_939509_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_91325_929623_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_91327_337129_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_91329_395909_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_91331_735148_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_91333_341882_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_91335_183856_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_91337_629534_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_98250_838640_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_98251_768242_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_98252_884208_300x0.jpg"),
      require("../images/catalog/estima/traffic/tovars/estima_traffic_98253_520568_300x0.jpg"),
    ],
  },
  {
    id: "469",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Tramontana",
    interiors: [
      require("../images/catalog/estima/tramontana/interiors/estima_tramontana_14180_136049_0x300.jpg"),
      require("../images/catalog/estima/tramontana/interiors/estima_tramontana_14180_267859_0x300.jpg"),
      require("../images/catalog/estima/tramontana/interiors/estima_tramontana_14180_317562_0x300.jpg"),
      require("../images/catalog/estima/tramontana/interiors/estima_tramontana_14180_348059_0x300.jpg"),
      require("../images/catalog/estima/tramontana/interiors/estima_tramontana_14180_348059_0x500.jpg"),
      require("../images/catalog/estima/tramontana/interiors/estima_tramontana_14180_349507_0x300.jpg"),
      require("../images/catalog/estima/tramontana/interiors/estima_tramontana_14180_366312_0x300.jpg"),
      require("../images/catalog/estima/tramontana/interiors/estima_tramontana_14180_724966_0x300.jpg"),
      require("../images/catalog/estima/tramontana/interiors/estima_tramontana_14180_78102_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_110764_618883_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_110765_725304_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_110766_591072_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_110767_946418_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_110768_589975_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_110769_916896_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_110770_362202_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_110771_682497_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111586_91643_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111587_607490_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111588_526658_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111589_640025_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111590_734347_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111591_521443_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111592_94740_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111593_525661_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111594_400472_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111595_910510_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111596_484170_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111597_501612_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111598_737315_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111599_853113_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111600_654962_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111601_687291_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111602_92650_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111603_452769_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111604_494925_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111605_207990_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111606_875935_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111607_14953_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111608_371330_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111609_582493_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111610_448966_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111611_37709_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111612_93783_300x0.jpg"),
      require("../images/catalog/estima/tramontana/tovars/estima_tramontana_111613_872000_300x0.jpg"),
    ],
  },
  {
    id: "470",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Vision",
    interiors: [
      require("../images/catalog/estima/vision/interiors/estima_vision_8900_612362_0x300.jpg"),
      require("../images/catalog/estima/vision/interiors/estima_vision_8900_706493_0x300.jpg"),
      require("../images/catalog/estima/vision/interiors/estima_vision_8900_706493_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/vision/tovars/estima_vision_76048_456464_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_76049_380765_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_76050_298888_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_76051_425552_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_76052_465469_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_76053_219884_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_76054_620631_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_76055_846187_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_76056_300312_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_76058_324192_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_82422_279396_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_82423_858061_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_82424_520744_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_82425_371281_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_82426_534320_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_83727_18417_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_83728_739435_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_91339_798096_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_91340_984555_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_91342_959068_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_91343_363406_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_98270_941859_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_98271_362232_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_98272_661742_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_98273_212082_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_98274_810671_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_98275_811932_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_98276_91141_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_98277_390782_300x0.jpg"),
      require("../images/catalog/estima/vision/tovars/estima_vision_98278_695213_300x0.jpg"),
    ],
  },
  {
    id: "471",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima",
    Collection: "Yourcolor",
    interiors: [
      require("../images/catalog/estima/yourcolor/interiors/estima_yourcolor_10112_361957_0x300.jpg"),
      require("../images/catalog/estima/yourcolor/interiors/estima_yourcolor_10112_361957_0x500.jpg"),
      require("../images/catalog/estima/yourcolor/interiors/estima_yourcolor_10112_612460_0x300.jpg"),
      require("../images/catalog/estima/yourcolor/interiors/estima_yourcolor_10112_763310_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_107925_677443_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_107926_860081_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86266_175722_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86267_785526_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86268_473176_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86269_626522_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86270_479546_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86271_728561_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86274_67695_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86282_307376_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86286_928723_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86288_195949_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86296_123191_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86302_214721_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86303_577475_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86308_63689_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86314_521470_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86315_429809_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86320_332657_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86321_694150_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86322_598817_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86323_638754_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86332_533157_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86342_550969_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86343_175644_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86354_823329_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86355_937240_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_86366_81658_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98279_476796_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98280_698465_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98281_533853_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98282_245039_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98283_582673_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98284_54422_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98285_951810_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98286_611987_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98287_120635_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98288_473945_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98289_492170_300x0.jpg"),
      require("../images/catalog/estima/yourcolor/tovars/estima_yourcolor_98290_48161_300x0.jpg"),
    ],
  },
  {
    id: "472",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Art",
    interiors: [
      require("../images/catalog/estima_city/art/interiors/estima_city_art_10584_358330_0x300.jpg"),
      require("../images/catalog/estima_city/art/interiors/estima_city_art_10584_358330_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/art/tovars/estima_city_art_89974_352011_300x0.jpg"),
    ],
  },
  {
    id: "473",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Brigantina",
    interiors: [
      require("../images/catalog/estima_city/brigantina/interiors/estima_city_brigantina_12780_355347_0x300.jpg"),
      require("../images/catalog/estima_city/brigantina/interiors/estima_city_brigantina_12780_392565_0x300.jpg"),
      require("../images/catalog/estima_city/brigantina/interiors/estima_city_brigantina_12780_631535_0x300.jpg"),
      require("../images/catalog/estima_city/brigantina/interiors/estima_city_brigantina_12780_840896_0x300.jpg"),
      require("../images/catalog/estima_city/brigantina/interiors/estima_city_brigantina_12780_840896_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_94436_23207_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_94437_848299_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_94438_266508_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_94439_399066_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_98158_709947_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_98159_739627_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_98160_157146_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_98161_290898_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_98162_137180_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_98163_441951_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_98164_660795_300x0.jpg"),
      require("../images/catalog/estima_city/brigantina/tovars/estima_city_brigantina_98165_995817_300x0.jpg"),
    ],
  },
  {
    id: "474",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Dream_wood",
    interiors: [
      require("../images/catalog/estima_city/dream_wood/interiors/estima_city_dream_wood_10813_145559_0x300.jpg"),
      require("../images/catalog/estima_city/dream_wood/interiors/estima_city_dream_wood_10813_145559_0x500.jpg"),
      require("../images/catalog/estima_city/dream_wood/interiors/estima_city_dream_wood_10813_638565_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/dream_wood/tovars/estima_city_dream_wood_91222_611895_300x0.jpg"),
      require("../images/catalog/estima_city/dream_wood/tovars/estima_city_dream_wood_91223_307656_300x0.jpg"),
      require("../images/catalog/estima_city/dream_wood/tovars/estima_city_dream_wood_94442_939182_300x0.jpg"),
      require("../images/catalog/estima_city/dream_wood/tovars/estima_city_dream_wood_94443_156693_300x0.jpg"),
      require("../images/catalog/estima_city/dream_wood/tovars/estima_city_dream_wood_98138_982982_300x0.jpg"),
      require("../images/catalog/estima_city/dream_wood/tovars/estima_city_dream_wood_98139_987199_300x0.jpg"),
      require("../images/catalog/estima_city/dream_wood/tovars/estima_city_dream_wood_98142_231037_300x0.jpg"),
      require("../images/catalog/estima_city/dream_wood/tovars/estima_city_dream_wood_98143_152353_300x0.jpg"),
    ],
  },
  {
    id: "475",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Jam",
    interiors: [
      require("../images/catalog/estima_city/jam/interiors/estima_city_jam_18709_110909_0x300.jpg"),
      require("../images/catalog/estima_city/jam/interiors/estima_city_jam_18709_110909_0x500.jpg"),
      require("../images/catalog/estima_city/jam/interiors/estima_city_jam_18709_287326_0x300.jpg"),
      require("../images/catalog/estima_city/jam/interiors/estima_city_jam_18709_702196_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/jam/tovars/estima_city_jam_129494_451116_300x0.jpg"),
      require("../images/catalog/estima_city/jam/tovars/estima_city_jam_129495_251010_300x0.jpg"),
    ],
  },
  {
    id: "476",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Jasper",
    interiors: [
      require("../images/catalog/estima_city/jasper/interiors/estima_city_jasper_15830_304147_0x300.jpg"),
      require("../images/catalog/estima_city/jasper/interiors/estima_city_jasper_15830_304147_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/jasper/tovars/estima_city_jasper_116906_680838_300x0.jpg"),
      require("../images/catalog/estima_city/jasper/tovars/estima_city_jasper_116907_326638_300x0.jpg"),
      require("../images/catalog/estima_city/jasper/tovars/estima_city_jasper_116908_47102_300x0.jpg"),
      require("../images/catalog/estima_city/jasper/tovars/estima_city_jasper_116909_533987_300x0.jpg"),
      require("../images/catalog/estima_city/jasper/tovars/estima_city_jasper_116910_243490_300x0.jpg"),
      require("../images/catalog/estima_city/jasper/tovars/estima_city_jasper_116911_445176_300x0.jpg"),
    ],
  },
  {
    id: "477",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Melody",
    interiors: [
      require("../images/catalog/estima_city/melody/interiors/estima_city_melody_10585_406858_0x300.jpg"),
      require("../images/catalog/estima_city/melody/interiors/estima_city_melody_10585_406858_0x500.jpg"),
      require("../images/catalog/estima_city/melody/interiors/estima_city_melody_10585_59712_0x300.jpg"),
      require("../images/catalog/estima_city/melody/interiors/estima_city_melody_10585_794701_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_104877_450781_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_104878_480242_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_104879_305372_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_104881_300747_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_104882_377644_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_119690_421795_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_119691_499560_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_119692_275867_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_119693_951645_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_119995_679725_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_119996_805436_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_89975_455953_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_89976_925722_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_89977_658888_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_93131_246502_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_93132_433918_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_98146_181493_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_98147_757119_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_98148_406778_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_98166_782102_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_98167_633607_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_98168_403434_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_98169_765084_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_98170_620806_300x0.jpg"),
      require("../images/catalog/estima_city/melody/tovars/estima_city_melody_98171_294858_300x0.jpg"),
    ],
  },
  {
    id: "478",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Modern wood",
    interiors: [
      require("../images/catalog/estima_city/modern_wood/interiors/estima_city_modern_wood_10814_775991_0x300.jpg"),
      require("../images/catalog/estima_city/modern_wood/interiors/estima_city_modern_wood_10814_775991_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/modern_wood/tovars/estima_city_modern_wood_91226_461562_300x0.jpg"),
      require("../images/catalog/estima_city/modern_wood/tovars/estima_city_modern_wood_91227_205832_300x0.jpg"),
      require("../images/catalog/estima_city/modern_wood/tovars/estima_city_modern_wood_91228_582486_300x0.jpg"),
      require("../images/catalog/estima_city/modern_wood/tovars/estima_city_modern_wood_94462_888889_300x0.jpg"),
      require("../images/catalog/estima_city/modern_wood/tovars/estima_city_modern_wood_94463_716841_300x0.jpg"),
      require("../images/catalog/estima_city/modern_wood/tovars/estima_city_modern_wood_94464_501539_300x0.jpg"),
      require("../images/catalog/estima_city/modern_wood/tovars/estima_city_modern_wood_98149_594327_300x0.jpg"),
      require("../images/catalog/estima_city/modern_wood/tovars/estima_city_modern_wood_98150_831400_300x0.jpg"),
      require("../images/catalog/estima_city/modern_wood/tovars/estima_city_modern_wood_98151_600200_300x0.jpg"),
    ],
  },
  {
    id: "479",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Newport",
    interiors: [
      require("../images/catalog/estima_city/newport/interiors/estima_city_newport_15831_556517_0x300.jpg"),
      require("../images/catalog/estima_city/newport/interiors/estima_city_newport_15831_556517_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/newport/tovars/estima_city_newport_116912_363613_300x0.jpg"),
      require("../images/catalog/estima_city/newport/tovars/estima_city_newport_116913_976507_300x0.jpg"),
      require("../images/catalog/estima_city/newport/tovars/estima_city_newport_116914_498016_300x0.jpg"),
      require("../images/catalog/estima_city/newport/tovars/estima_city_newport_116915_881489_300x0.jpg"),
      require("../images/catalog/estima_city/newport/tovars/estima_city_newport_116916_192999_300x0.jpg"),
      require("../images/catalog/estima_city/newport/tovars/estima_city_newport_116917_797515_300x0.jpg"),
      require("../images/catalog/estima_city/newport/tovars/estima_city_newport_119998_367383_300x0.jpg"),
      require("../images/catalog/estima_city/newport/tovars/estima_city_newport_119999_906316_300x0.jpg"),
      require("../images/catalog/estima_city/newport/tovars/estima_city_newport_120000_499926_300x0.jpg"),
    ],
  },
  {
    id: "480",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Ragtime",
    interiors: [
      require("../images/catalog/estima_city/ragtime/interiors/estima_city_ragtime_10586_516307_0x300.jpg"),
      require("../images/catalog/estima_city/ragtime/interiors/estima_city_ragtime_10586_516307_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/ragtime/tovars/estima_city_ragtime_104883_736212_300x0.jpg"),
      require("../images/catalog/estima_city/ragtime/tovars/estima_city_ragtime_104884_581350_300x0.jpg"),
      require("../images/catalog/estima_city/ragtime/tovars/estima_city_ragtime_104885_542518_300x0.jpg"),
      require("../images/catalog/estima_city/ragtime/tovars/estima_city_ragtime_104886_869279_300x0.jpg"),
      require("../images/catalog/estima_city/ragtime/tovars/estima_city_ragtime_89979_193308_300x0.jpg"),
      require("../images/catalog/estima_city/ragtime/tovars/estima_city_ragtime_89980_394508_300x0.jpg"),
      require("../images/catalog/estima_city/ragtime/tovars/estima_city_ragtime_89981_269258_300x0.jpg"),
      require("../images/catalog/estima_city/ragtime/tovars/estima_city_ragtime_89982_833230_300x0.jpg"),
    ],
  },
  {
    id: "481",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Rock",
    interiors: [
      require("../images/catalog/estima_city/rock/interiors/estima_city_rock_10587_904091_0x300.jpg"),
      require("../images/catalog/estima_city/rock/interiors/estima_city_rock_10587_904091_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/rock/tovars/estima_city_rock_89983_566431_300x0.jpg"),
      require("../images/catalog/estima_city/rock/tovars/estima_city_rock_93133_523308_300x0.jpg"),
      require("../images/catalog/estima_city/rock/tovars/estima_city_rock_93134_193920_300x0.jpg"),
      require("../images/catalog/estima_city/rock/tovars/estima_city_rock_94465_219896_300x0.jpg"),
    ],
  },
  {
    id: "482",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Seed",
    interiors: [
      require("../images/catalog/estima_city/seed/interiors/estima_city_seed_18708_268669_0x300.jpg"),
      require("../images/catalog/estima_city/seed/interiors/estima_city_seed_18708_330772_0x300.jpg"),
      require("../images/catalog/estima_city/seed/interiors/estima_city_seed_18708_330772_0x500.jpg"),
      require("../images/catalog/estima_city/seed/interiors/estima_city_seed_18708_368130_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/seed/tovars/estima_city_seed_129485_379849_300x0.jpg"),
      require("../images/catalog/estima_city/seed/tovars/estima_city_seed_129486_759948_300x0.jpg"),
      require("../images/catalog/estima_city/seed/tovars/estima_city_seed_129487_811275_300x0.jpg"),
      require("../images/catalog/estima_city/seed/tovars/estima_city_seed_129488_286453_300x0.jpg"),
      require("../images/catalog/estima_city/seed/tovars/estima_city_seed_129489_820780_300x0.jpg"),
      require("../images/catalog/estima_city/seed/tovars/estima_city_seed_129490_558871_300x0.jpg"),
      require("../images/catalog/estima_city/seed/tovars/estima_city_seed_129491_734411_300x0.jpg"),
      require("../images/catalog/estima_city/seed/tovars/estima_city_seed_129492_63700_300x0.jpg"),
      require("../images/catalog/estima_city/seed/tovars/estima_city_seed_129493_446150_300x0.jpg"),
    ],
  },
  {
    id: "483",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Solo",
    interiors: [
      require("../images/catalog/estima_city/solo/interiors/estima_city_solo_10588_547716_0x300.jpg"),
      require("../images/catalog/estima_city/solo/interiors/estima_city_solo_10588_547716_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/solo/tovars/estima_city_solo_89984_143160_300x0.jpg"),
    ],
  },
  {
    id: "484",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Stride",
    interiors: [
      require("../images/catalog/estima_city/stride/interiors/estima_city_stride_18710_238093_0x300.jpg"),
      require("../images/catalog/estima_city/stride/interiors/estima_city_stride_18710_238093_0x500.jpg"),
      require("../images/catalog/estima_city/stride/interiors/estima_city_stride_18710_90125_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129496_762207_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129497_500334_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129498_286674_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129499_132446_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129500_571662_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129501_581787_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129502_528642_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129503_656395_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129504_937941_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129505_678965_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129506_581870_300x0.jpg"),
      require("../images/catalog/estima_city/stride/tovars/estima_city_stride_129507_356344_300x0.jpg"),
    ],
  },
  {
    id: "485",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Estima City",
    Collection: "Underground",
    interiors: [
      require("../images/catalog/estima_city/underground/interiors/estima_city_underground_10589_98545_0x300.jpg"),
      require("../images/catalog/estima_city/underground/interiors/estima_city_underground_10589_98545_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/estima_city/underground/tovars/estima_city_underground_107912_514111_300x0.jpg"),
      require("../images/catalog/estima_city/underground/tovars/estima_city_underground_107913_782157_300x0.jpg"),
      require("../images/catalog/estima_city/underground/tovars/estima_city_underground_107914_92654_300x0.jpg"),
      require("../images/catalog/estima_city/underground/tovars/estima_city_underground_89985_511877_300x0.jpg"),
      require("../images/catalog/estima_city/underground/tovars/estima_city_underground_89986_371885_300x0.jpg"),
      require("../images/catalog/estima_city/underground/tovars/estima_city_underground_89987_159859_300x0.jpg"),
      require("../images/catalog/estima_city/underground/tovars/estima_city_underground_98155_968229_300x0.jpg"),
      require("../images/catalog/estima_city/underground/tovars/estima_city_underground_98156_233051_300x0.jpg"),
      require("../images/catalog/estima_city/underground/tovars/estima_city_underground_98157_504299_300x0.jpg"),
    ],
  },
  {
    id: "486",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Allaki",
    interiors: [
      require("../images/catalog/graniteya/allaki/interiors/graniteya_allaki_9201_177837_0x300.jpg"),
      require("../images/catalog/graniteya/allaki/interiors/graniteya_allaki_9201_177837_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/allaki/tovars/graniteya_allaki_78554_509005_300x0.jpg"),
      require("../images/catalog/graniteya/allaki/tovars/graniteya_allaki_78555_502238_300x0.jpg"),
      require("../images/catalog/graniteya/allaki/tovars/graniteya_allaki_78556_649896_300x0.jpg"),
      require("../images/catalog/graniteya/allaki/tovars/graniteya_allaki_78557_730712_300x0.jpg"),
      require("../images/catalog/graniteya/allaki/tovars/graniteya_allaki_87313_20826_300x0.jpg"),
      require("../images/catalog/graniteya/allaki/tovars/graniteya_allaki_87314_311706_300x0.jpg"),
      require("../images/catalog/graniteya/allaki/tovars/graniteya_allaki_87315_703418_300x0.jpg"),
      require("../images/catalog/graniteya/allaki/tovars/graniteya_allaki_87316_665640_300x0.jpg"),
    ],
  },
  {
    id: "487",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Arkaim",
    interiors: [
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_165775_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_246488_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_299172_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_381697_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_426055_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_440480_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_447178_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_614997_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_616981_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_639424_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_755164_0x300.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_755164_0x500.jpg"),
      require("../images/catalog/graniteya/arkaim/interiors/graniteya_arkaim_9202_999540_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/arkaim/tovars/graniteya_arkaim_78558_549681_300x0.jpg"),
      require("../images/catalog/graniteya/arkaim/tovars/graniteya_arkaim_78559_464187_300x0.jpg"),
      require("../images/catalog/graniteya/arkaim/tovars/graniteya_arkaim_78560_384099_300x0.jpg"),
      require("../images/catalog/graniteya/arkaim/tovars/graniteya_arkaim_78561_284101_300x0.jpg"),
      require("../images/catalog/graniteya/arkaim/tovars/graniteya_arkaim_87317_464195_300x0.jpg"),
      require("../images/catalog/graniteya/arkaim/tovars/graniteya_arkaim_87318_910441_300x0.jpg"),
      require("../images/catalog/graniteya/arkaim/tovars/graniteya_arkaim_87319_172176_300x0.jpg"),
      require("../images/catalog/graniteya/arkaim/tovars/graniteya_arkaim_87320_170119_300x0.jpg"),
    ],
  },
  {
    id: "488",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Artbeton",
    interiors: [
      require("../images/catalog/graniteya/artbeton/interiors/graniteya_artbeton_17969_645144_0x300.jpg"),
      require("../images/catalog/graniteya/artbeton/interiors/graniteya_artbeton_17969_645144_0x500.jpg"),
      require("../images/catalog/graniteya/artbeton/interiors/graniteya_artbeton_17969_69459_0x300.jpg"),
      require("../images/catalog/graniteya/artbeton/interiors/graniteya_artbeton_17969_983878_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125681_954572_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125682_991143_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125683_643959_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125684_506801_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125685_464663_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125686_776660_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125687_90237_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125688_673509_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125689_788713_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125690_858326_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125691_224471_300x0.jpg"),
      require("../images/catalog/graniteya/artbeton/tovars/graniteya_artbeton_125692_124460_300x0.jpg"),
    ],
  },
  {
    id: "489",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Iremel",
    interiors: [
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_108685_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_111024_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_129585_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_144974_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_144974_0x500.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_153852_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_203979_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_220939_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_261943_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_469140_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_480247_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_638730_0x300.jpg"),
      require("../images/catalog/graniteya/iremel/interiors/graniteya_iremel_9203_872864_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_78562_510414_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_78563_614173_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_78564_882134_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_78565_548076_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_87321_707111_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_87322_964456_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_87323_239075_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_87324_569220_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_87325_702692_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_87326_981160_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_87327_891784_300x0.jpg"),
      require("../images/catalog/graniteya/iremel/tovars/graniteya_iremel_87328_700730_300x0.jpg"),
    ],
  },
  {
    id: "490",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Iset",
    interiors: [
      require("../images/catalog/graniteya/iset/interiors/graniteya_iset_9204_50021_0x300.jpg"),
      require("../images/catalog/graniteya/iset/interiors/graniteya_iset_9204_830129_0x300.jpg"),
      require("../images/catalog/graniteya/iset/interiors/graniteya_iset_9204_863364_0x300.jpg"),
      require("../images/catalog/graniteya/iset/interiors/graniteya_iset_9204_863364_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/iset/tovars/graniteya_iset_78566_526292_300x0.jpg"),
      require("../images/catalog/graniteya/iset/tovars/graniteya_iset_78567_662673_300x0.jpg"),
      require("../images/catalog/graniteya/iset/tovars/graniteya_iset_87329_416907_300x0.jpg"),
      require("../images/catalog/graniteya/iset/tovars/graniteya_iset_87330_904633_300x0.jpg"),
    ],
  },
  {
    id: "491",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Karatash",
    interiors: [
      require("../images/catalog/graniteya/karatash/interiors/graniteya_karatash_9492_815271_0x300.jpg"),
      require("../images/catalog/graniteya/karatash/interiors/graniteya_karatash_9492_815271_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_81679_672122_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_81680_525602_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_81681_196567_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_81682_531304_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_81683_394789_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_81684_126660_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_87331_495263_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_87332_924241_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_87333_271464_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_87334_91907_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_87335_912785_300x0.jpg"),
      require("../images/catalog/graniteya/karatash/tovars/graniteya_karatash_87336_632807_300x0.jpg"),
    ],
  },
  {
    id: "492",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Kirety",
    interiors: [
      require("../images/catalog/graniteya/kirety/interiors/graniteya_kirety_9205_154595_0x300.jpg"),
      require("../images/catalog/graniteya/kirety/interiors/graniteya_kirety_9205_722949_0x300.jpg"),
      require("../images/catalog/graniteya/kirety/interiors/graniteya_kirety_9205_756718_0x300.jpg"),
      require("../images/catalog/graniteya/kirety/interiors/graniteya_kirety_9205_756718_0x500.jpg"),
      require("../images/catalog/graniteya/kirety/interiors/graniteya_kirety_9205_855423_0x300.jpg"),
      require("../images/catalog/graniteya/kirety/interiors/graniteya_kirety_9205_924556_0x300.jpg"),
      require("../images/catalog/graniteya/kirety/interiors/graniteya_kirety_9205_967785_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_78568_565499_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_78569_209536_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_78570_808026_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_78571_482691_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_78572_685011_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_78573_10242_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_78574_962106_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_78575_380221_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_87337_264658_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_87338_279739_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_87339_300980_300x0.jpg"),
      require("../images/catalog/graniteya/kirety/tovars/graniteya_kirety_87340_341644_300x0.jpg"),
    ],
  },
  {
    id: "493",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Kojjva",
    interiors: [
      require("../images/catalog/graniteya/kojjva/interiors/graniteya_kojjva_9206_539554_0x300.jpg"),
      require("../images/catalog/graniteya/kojjva/interiors/graniteya_kojjva_9206_539554_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/kojjva/tovars/graniteya_kojjva_78576_106307_300x0.jpg"),
      require("../images/catalog/graniteya/kojjva/tovars/graniteya_kojjva_78577_67600_300x0.jpg"),
      require("../images/catalog/graniteya/kojjva/tovars/graniteya_kojjva_87341_105337_300x0.jpg"),
      require("../images/catalog/graniteya/kojjva/tovars/graniteya_kojjva_87342_336789_300x0.jpg"),
    ],
  },
  {
    id: "494",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Konzhak",
    interiors: [
      require("../images/catalog/graniteya/konzhak/interiors/graniteya_konzhak_9207_156536_0x300.jpg"),
      require("../images/catalog/graniteya/konzhak/interiors/graniteya_konzhak_9207_170127_0x300.jpg"),
      require("../images/catalog/graniteya/konzhak/interiors/graniteya_konzhak_9207_170127_0x500.jpg"),
      require("../images/catalog/graniteya/konzhak/interiors/graniteya_konzhak_9207_186286_0x300.jpg"),
      require("../images/catalog/graniteya/konzhak/interiors/graniteya_konzhak_9207_30510_0x300.jpg"),
      require("../images/catalog/graniteya/konzhak/interiors/graniteya_konzhak_9207_391085_0x300.jpg"),
      require("../images/catalog/graniteya/konzhak/interiors/graniteya_konzhak_9207_572592_0x300.jpg"),
      require("../images/catalog/graniteya/konzhak/interiors/graniteya_konzhak_9207_600149_0x300.jpg"),
      require("../images/catalog/graniteya/konzhak/interiors/graniteya_konzhak_9207_600608_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_78578_333708_300x0.jpg"),
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_78579_250587_300x0.jpg"),
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_78580_514217_300x0.jpg"),
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_87343_353510_300x0.jpg"),
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_87344_116165_300x0.jpg"),
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_87345_638497_300x0.jpg"),
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_87346_56928_300x0.jpg"),
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_87347_771806_300x0.jpg"),
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_87348_102692_300x0.jpg"),
      require("../images/catalog/graniteya/konzhak/tovars/graniteya_konzhak_87349_957371_300x0.jpg"),
    ],
  },
  {
    id: "495",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Kukazar",
    interiors: [
      require("../images/catalog/graniteya/kukazar/interiors/graniteya_kukazar_9208_305872_0x300.jpg"),
      require("../images/catalog/graniteya/kukazar/interiors/graniteya_kukazar_9208_413432_0x300.jpg"),
      require("../images/catalog/graniteya/kukazar/interiors/graniteya_kukazar_9208_735124_0x300.jpg"),
      require("../images/catalog/graniteya/kukazar/interiors/graniteya_kukazar_9208_944291_0x300.jpg"),
      require("../images/catalog/graniteya/kukazar/interiors/graniteya_kukazar_9208_944291_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/kukazar/tovars/graniteya_kukazar_78581_901216_300x0.jpg"),
      require("../images/catalog/graniteya/kukazar/tovars/graniteya_kukazar_78582_722378_300x0.jpg"),
      require("../images/catalog/graniteya/kukazar/tovars/graniteya_kukazar_78583_383538_300x0.jpg"),
      require("../images/catalog/graniteya/kukazar/tovars/graniteya_kukazar_78584_94987_300x0.jpg"),
      require("../images/catalog/graniteya/kukazar/tovars/graniteya_kukazar_87350_933984_300x0.jpg"),
      require("../images/catalog/graniteya/kukazar/tovars/graniteya_kukazar_87351_262813_300x0.jpg"),
    ],
  },
  {
    id: "496",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Nejjva",
    interiors: [
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_118730_0x300.jpg"),
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_246713_0x300.jpg"),
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_342728_0x300.jpg"),
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_673865_0x300.jpg"),
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_673865_0x500.jpg"),
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_718816_0x300.jpg"),
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_73931_0x300.jpg"),
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_779915_0x300.jpg"),
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_886541_0x300.jpg"),
      require("../images/catalog/graniteya/nejjva/interiors/graniteya_nejjva_17963_939306_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125650_151373_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125651_769496_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125652_494039_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125654_64579_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125655_54180_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125656_874784_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125657_281413_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125658_308176_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125659_55008_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125661_795736_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125662_66466_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125663_352704_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125664_633887_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125665_586363_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125666_74078_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125668_94643_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125669_373489_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_125670_555788_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_127039_305340_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_127040_868823_300x0.jpg"),
      require("../images/catalog/graniteya/nejjva/tovars/graniteya_nejjva_127041_316326_300x0.jpg"),
    ],
  },
  {
    id: "497",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Pajjer",
    interiors: [
      require("../images/catalog/graniteya/pajjer/interiors/graniteya_pajjer_9209_178736_0x300.jpg"),
      require("../images/catalog/graniteya/pajjer/interiors/graniteya_pajjer_9209_292075_0x300.jpg"),
      require("../images/catalog/graniteya/pajjer/interiors/graniteya_pajjer_9209_807468_0x300.jpg"),
      require("../images/catalog/graniteya/pajjer/interiors/graniteya_pajjer_9209_883680_0x300.jpg"),
      require("../images/catalog/graniteya/pajjer/interiors/graniteya_pajjer_9209_98094_0x300.jpg"),
      require("../images/catalog/graniteya/pajjer/interiors/graniteya_pajjer_9209_982367_0x300.jpg"),
      require("../images/catalog/graniteya/pajjer/interiors/graniteya_pajjer_9209_987069_0x300.jpg"),
      require("../images/catalog/graniteya/pajjer/interiors/graniteya_pajjer_9209_987069_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_78585_231383_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_78586_875777_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_78587_734885_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_78588_952097_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_78589_425459_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_78590_199072_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_78591_336196_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_78592_699562_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_87352_664482_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_87353_898440_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_87354_491890_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_87355_233703_300x0.jpg"),
      require("../images/catalog/graniteya/pajjer/tovars/graniteya_pajjer_87356_601133_300x0.jpg"),
    ],
  },
  {
    id: "498",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Shikhan",
    interiors: [
      require("../images/catalog/graniteya/shikhan/interiors/graniteya_shikhan_9216_18864_0x300.jpg"),
      require("../images/catalog/graniteya/shikhan/interiors/graniteya_shikhan_9216_822246_0x300.jpg"),
      require("../images/catalog/graniteya/shikhan/interiors/graniteya_shikhan_9216_822246_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/shikhan/tovars/graniteya_shikhan_78622_611593_300x0.jpg"),
      require("../images/catalog/graniteya/shikhan/tovars/graniteya_shikhan_87397_872259_300x0.jpg"),
      require("../images/catalog/graniteya/shikhan/tovars/graniteya_shikhan_87398_42931_300x0.jpg"),
    ],
  },
  {
    id: "499",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Shunut",
    interiors: [
      require("../images/catalog/graniteya/shunut/interiors/graniteya_shunut_9217_202416_0x300.jpg"),
      require("../images/catalog/graniteya/shunut/interiors/graniteya_shunut_9217_299171_0x300.jpg"),
      require("../images/catalog/graniteya/shunut/interiors/graniteya_shunut_9217_316804_0x300.jpg"),
      require("../images/catalog/graniteya/shunut/interiors/graniteya_shunut_9217_316804_0x500.jpg"),
      require("../images/catalog/graniteya/shunut/interiors/graniteya_shunut_9217_816333_0x300.jpg"),
      require("../images/catalog/graniteya/shunut/interiors/graniteya_shunut_9217_910341_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/shunut/tovars/graniteya_shunut_78623_184022_300x0.jpg"),
      require("../images/catalog/graniteya/shunut/tovars/graniteya_shunut_78624_654796_300x0.jpg"),
      require("../images/catalog/graniteya/shunut/tovars/graniteya_shunut_78625_551964_300x0.jpg"),
      require("../images/catalog/graniteya/shunut/tovars/graniteya_shunut_78626_755719_300x0.jpg"),
      require("../images/catalog/graniteya/shunut/tovars/graniteya_shunut_87399_465321_300x0.jpg"),
      require("../images/catalog/graniteya/shunut/tovars/graniteya_shunut_87400_858985_300x0.jpg"),
    ],
  },
  {
    id: "500",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Sinara",
    interiors: [
      require("../images/catalog/graniteya/sinara/interiors/graniteya_sinara_9210_136219_0x300.jpg"),
      require("../images/catalog/graniteya/sinara/interiors/graniteya_sinara_9210_155439_0x300.jpg"),
      require("../images/catalog/graniteya/sinara/interiors/graniteya_sinara_9210_28407_0x300.jpg"),
      require("../images/catalog/graniteya/sinara/interiors/graniteya_sinara_9210_28407_0x500.jpg"),
      require("../images/catalog/graniteya/sinara/interiors/graniteya_sinara_9210_68430_0x300.jpg"),
      require("../images/catalog/graniteya/sinara/interiors/graniteya_sinara_9210_902158_0x300.jpg"),
      require("../images/catalog/graniteya/sinara/interiors/graniteya_sinara_9210_956350_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78593_699488_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78594_940371_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78595_581696_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78596_247564_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78597_466663_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78598_244369_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78599_803065_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78600_666200_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78601_52396_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_78602_285756_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87311_45808_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87312_21864_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87357_473050_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87358_125487_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87359_301863_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87360_879959_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87361_30121_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87362_787128_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87363_804200_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87364_291586_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87365_869036_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87366_716986_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87367_914394_300x0.jpg"),
      require("../images/catalog/graniteya/sinara/tovars/graniteya_sinara_87368_133694_300x0.jpg"),
    ],
  },
  {
    id: "501",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Sugomak",
    interiors: [
      require("../images/catalog/graniteya/sugomak/interiors/graniteya_sugomak_9211_36215_0x300.jpg"),
      require("../images/catalog/graniteya/sugomak/interiors/graniteya_sugomak_9211_36215_0x500.jpg"),
      require("../images/catalog/graniteya/sugomak/interiors/graniteya_sugomak_9211_366269_0x300.jpg"),
      require("../images/catalog/graniteya/sugomak/interiors/graniteya_sugomak_9211_749165_0x300.jpg"),
      require("../images/catalog/graniteya/sugomak/interiors/graniteya_sugomak_9211_811773_0x300.jpg"),
      require("../images/catalog/graniteya/sugomak/interiors/graniteya_sugomak_9211_826715_0x300.jpg"),
      require("../images/catalog/graniteya/sugomak/interiors/graniteya_sugomak_9211_956370_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_78603_351211_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_78604_52639_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_78605_247863_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_78606_721434_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_78607_148947_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_78608_305464_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_87369_986726_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_87370_215374_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_87371_465340_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_87372_92063_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_87373_542165_300x0.jpg"),
      require("../images/catalog/graniteya/sugomak/tovars/graniteya_sugomak_87374_808851_300x0.jpg"),
    ],
  },
  {
    id: "502",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Sungul",
    interiors: [
      require("../images/catalog/graniteya/sungul/interiors/graniteya_sungul_9212_909294_0x300.jpg"),
      require("../images/catalog/graniteya/sungul/interiors/graniteya_sungul_9212_909294_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/sungul/tovars/graniteya_sungul_78609_55142_300x0.jpg"),
      require("../images/catalog/graniteya/sungul/tovars/graniteya_sungul_78610_389535_300x0.jpg"),
      require("../images/catalog/graniteya/sungul/tovars/graniteya_sungul_87375_198229_300x0.jpg"),
      require("../images/catalog/graniteya/sungul/tovars/graniteya_sungul_87376_180662_300x0.jpg"),
      require("../images/catalog/graniteya/sungul/tovars/graniteya_sungul_87377_855781_300x0.jpg"),
      require("../images/catalog/graniteya/sungul/tovars/graniteya_sungul_87378_960036_300x0.jpg"),
    ],
  },
  {
    id: "503",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Taganajj",
    interiors: [
      require("../images/catalog/graniteya/taganajj/interiors/graniteya_taganajj_9213_347455_0x300.jpg"),
      require("../images/catalog/graniteya/taganajj/interiors/graniteya_taganajj_9213_347455_0x500.jpg"),
      require("../images/catalog/graniteya/taganajj/interiors/graniteya_taganajj_9213_367966_0x300.jpg"),
      require("../images/catalog/graniteya/taganajj/interiors/graniteya_taganajj_9213_509902_0x300.jpg"),
      require("../images/catalog/graniteya/taganajj/interiors/graniteya_taganajj_9213_512806_0x300.jpg"),
      require("../images/catalog/graniteya/taganajj/interiors/graniteya_taganajj_9213_85399_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_124947_75534_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_124948_280549_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_78611_809682_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_78613_111913_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_78614_193221_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_78615_41347_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_87379_273356_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_87380_813153_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_87381_894020_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_87382_526170_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_87383_477635_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_87384_792461_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_87385_18060_300x0.jpg"),
      require("../images/catalog/graniteya/taganajj/tovars/graniteya_taganajj_87386_701340_300x0.jpg"),
    ],
  },
  {
    id: "504",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Turgoyak",
    interiors: [
      require("../images/catalog/graniteya/turgoyak/interiors/graniteya_turgoyak_9214_110051_0x300.jpg"),
      require("../images/catalog/graniteya/turgoyak/interiors/graniteya_turgoyak_9214_110051_0x500.jpg"),
      require("../images/catalog/graniteya/turgoyak/interiors/graniteya_turgoyak_9214_29690_0x300.jpg"),
      require("../images/catalog/graniteya/turgoyak/interiors/graniteya_turgoyak_9214_544253_0x300.jpg"),
      require("../images/catalog/graniteya/turgoyak/interiors/graniteya_turgoyak_9214_835177_0x300.jpg"),
      require("../images/catalog/graniteya/turgoyak/interiors/graniteya_turgoyak_9214_840127_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/turgoyak/tovars/graniteya_turgoyak_78616_333298_300x0.jpg"),
      require("../images/catalog/graniteya/turgoyak/tovars/graniteya_turgoyak_78617_68999_300x0.jpg"),
      require("../images/catalog/graniteya/turgoyak/tovars/graniteya_turgoyak_87387_393595_300x0.jpg"),
      require("../images/catalog/graniteya/turgoyak/tovars/graniteya_turgoyak_87388_481112_300x0.jpg"),
      require("../images/catalog/graniteya/turgoyak/tovars/graniteya_turgoyak_87389_816829_300x0.jpg"),
      require("../images/catalog/graniteya/turgoyak/tovars/graniteya_turgoyak_87390_685460_300x0.jpg"),
      require("../images/catalog/graniteya/turgoyak/tovars/graniteya_turgoyak_87391_361071_300x0.jpg"),
      require("../images/catalog/graniteya/turgoyak/tovars/graniteya_turgoyak_87392_836951_300x0.jpg"),
    ],
  },
  {
    id: "505",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Uvildy",
    interiors: [
      require("../images/catalog/graniteya/uvildy/interiors/graniteya_uvildy_9215_433123_0x300.jpg"),
      require("../images/catalog/graniteya/uvildy/interiors/graniteya_uvildy_9215_433123_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/uvildy/tovars/graniteya_uvildy_78618_766234_300x0.jpg"),
      require("../images/catalog/graniteya/uvildy/tovars/graniteya_uvildy_78619_285395_300x0.jpg"),
      require("../images/catalog/graniteya/uvildy/tovars/graniteya_uvildy_78620_484460_300x0.jpg"),
      require("../images/catalog/graniteya/uvildy/tovars/graniteya_uvildy_78621_955307_300x0.jpg"),
      require("../images/catalog/graniteya/uvildy/tovars/graniteya_uvildy_87393_472589_300x0.jpg"),
      require("../images/catalog/graniteya/uvildy/tovars/graniteya_uvildy_87394_165272_300x0.jpg"),
      require("../images/catalog/graniteya/uvildy/tovars/graniteya_uvildy_87395_128537_300x0.jpg"),
      require("../images/catalog/graniteya/uvildy/tovars/graniteya_uvildy_87396_341625_300x0.jpg"),
    ],
  },
  {
    id: "506",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Гранитея",
    Collection: "Yurma",
    interiors: [
      require("../images/catalog/graniteya/yurma/interiors/graniteya_yurma_9218_834741_0x300.jpg"),
      require("../images/catalog/graniteya/yurma/interiors/graniteya_yurma_9218_834741_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/graniteya/yurma/tovars/graniteya_yurma_100083_413207_300x0.jpg"),
      require("../images/catalog/graniteya/yurma/tovars/graniteya_yurma_78627_892362_300x0.jpg"),
      require("../images/catalog/graniteya/yurma/tovars/graniteya_yurma_78628_18628_300x0.jpg"),
      require("../images/catalog/graniteya/yurma/tovars/graniteya_yurma_87401_248307_300x0.jpg"),
      require("../images/catalog/graniteya/yurma/tovars/graniteya_yurma_87402_920663_300x0.jpg"),
    ],
  },
  {
    id: "507",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Ajanta",
    interiors: [
      require("../images/catalog/gresse/ajanta/interiors/gresse_ajanta_11971_595657_0x300.jpg"),
      require("../images/catalog/gresse/ajanta/interiors/gresse_ajanta_11971_715261_0x300.jpg"),
      require("../images/catalog/gresse/ajanta/interiors/gresse_ajanta_11971_715261_0x500.jpg"),
      require("../images/catalog/gresse/ajanta/interiors/gresse_ajanta_11971_958726_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/ajanta/tovars/gresse_ajanta_97879_863329_300x0.jpg"),
      require("../images/catalog/gresse/ajanta/tovars/gresse_ajanta_97880_824884_300x0.jpg"),
      require("../images/catalog/gresse/ajanta/tovars/gresse_ajanta_97881_986250_300x0.jpg"),
      require("../images/catalog/gresse/ajanta/tovars/gresse_ajanta_97882_318043_300x0.jpg"),
      require("../images/catalog/gresse/ajanta/tovars/gresse_ajanta_97883_899559_300x0.jpg"),
      require("../images/catalog/gresse/ajanta/tovars/gresse_ajanta_97884_394679_300x0.jpg"),
      require("../images/catalog/gresse/ajanta/tovars/gresse_ajanta_97885_521098_300x0.jpg"),
      require("../images/catalog/gresse/ajanta/tovars/gresse_ajanta_97886_969947_300x0.jpg"),
      require("../images/catalog/gresse/ajanta/tovars/gresse_ajanta_97887_807155_300x0.jpg"),
    ],
  },
  {
    id: "508",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Arbel",
    interiors: [
      require("../images/catalog/gresse/arbel/interiors/gresse_arbel_11972_710463_0x300.jpg"),
      require("../images/catalog/gresse/arbel/interiors/gresse_arbel_11972_710463_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/arbel/tovars/gresse_arbel_97888_608963_300x0.jpg"),
      require("../images/catalog/gresse/arbel/tovars/gresse_arbel_97889_512312_300x0.jpg"),
      require("../images/catalog/gresse/arbel/tovars/gresse_arbel_97890_122206_300x0.jpg"),
    ],
  },
  {
    id: "509",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Ellora",
    interiors: [
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_103519_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_141634_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_164540_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_245116_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_306961_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_34378_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_430756_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_430756_0x500.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_58651_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_851585_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_869879_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_890578_0x300.jpg"),
      require("../images/catalog/gresse/ellora/interiors/gresse_ellora_11963_995202_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_116820_398747_300x0.jpg"),
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_116821_450003_300x0.jpg"),
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_116822_202079_300x0.jpg"),
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_116823_190758_300x0.jpg"),
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_97823_758339_300x0.jpg"),
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_97824_995712_300x0.jpg"),
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_97825_24045_300x0.jpg"),
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_97826_884810_300x0.jpg"),
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_97827_471977_300x0.jpg"),
      require("../images/catalog/gresse/ellora/tovars/gresse_ellora_97828_892574_300x0.jpg"),
    ],
  },
  {
    id: "510",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Gila",
    interiors: [
      require("../images/catalog/gresse/gila/interiors/gresse_gila_11965_119036_0x300.jpg"),
      require("../images/catalog/gresse/gila/interiors/gresse_gila_11965_119036_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/gila/tovars/gresse_gila_97837_224337_300x0.jpg"),
      require("../images/catalog/gresse/gila/tovars/gresse_gila_97838_154521_300x0.jpg"),
      require("../images/catalog/gresse/gila/tovars/gresse_gila_97839_69497_300x0.jpg"),
      require("../images/catalog/gresse/gila/tovars/gresse_gila_97840_358607_300x0.jpg"),
    ],
  },
  {
    id: "511",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Lalibela",
    interiors: [
      require("../images/catalog/gresse/lalibela/interiors/gresse_lalibela_11966_131622_0x300.jpg"),
      require("../images/catalog/gresse/lalibela/interiors/gresse_lalibela_11966_202407_0x300.jpg"),
      require("../images/catalog/gresse/lalibela/interiors/gresse_lalibela_11966_33984_0x300.jpg"),
      require("../images/catalog/gresse/lalibela/interiors/gresse_lalibela_11966_33984_0x500.jpg"),
      require("../images/catalog/gresse/lalibela/interiors/gresse_lalibela_11966_353009_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/lalibela/tovars/gresse_lalibela_97841_829857_300x0.jpg"),
      require("../images/catalog/gresse/lalibela/tovars/gresse_lalibela_97842_962916_300x0.jpg"),
      require("../images/catalog/gresse/lalibela/tovars/gresse_lalibela_97843_644060_300x0.jpg"),
      require("../images/catalog/gresse/lalibela/tovars/gresse_lalibela_97844_790518_300x0.jpg"),
    ],
  },
  {
    id: "512",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Madain",
    interiors: [
      require("../images/catalog/gresse/madain/interiors/gresse_madain_11969_239208_0x300.jpg"),
      require("../images/catalog/gresse/madain/interiors/gresse_madain_11969_293327_0x300.jpg"),
      require("../images/catalog/gresse/madain/interiors/gresse_madain_11969_696965_0x300.jpg"),
      require("../images/catalog/gresse/madain/interiors/gresse_madain_11969_696965_0x500.jpg"),
      require("../images/catalog/gresse/madain/interiors/gresse_madain_11969_770184_0x300.jpg"),
      require("../images/catalog/gresse/madain/interiors/gresse_madain_11969_884784_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/madain/tovars/gresse_madain_97865_670939_300x0.jpg"),
      require("../images/catalog/gresse/madain/tovars/gresse_madain_97866_246248_300x0.jpg"),
      require("../images/catalog/gresse/madain/tovars/gresse_madain_97867_501542_300x0.jpg"),
      require("../images/catalog/gresse/madain/tovars/gresse_madain_97868_885278_300x0.jpg"),
      require("../images/catalog/gresse/madain/tovars/gresse_madain_97869_390771_300x0.jpg"),
      require("../images/catalog/gresse/madain/tovars/gresse_madain_97870_561041_300x0.jpg"),
      require("../images/catalog/gresse/madain/tovars/gresse_madain_97871_243885_300x0.jpg"),
      require("../images/catalog/gresse/madain/tovars/gresse_madain_97872_220628_300x0.jpg"),
    ],
  },
  {
    id: "513",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Matera",
    interiors: [
      require("../images/catalog/gresse/matera/interiors/gresse_matera_11968_152952_0x300.jpg"),
      require("../images/catalog/gresse/matera/interiors/gresse_matera_11968_359590_0x300.jpg"),
      require("../images/catalog/gresse/matera/interiors/gresse_matera_11968_43121_0x300.jpg"),
      require("../images/catalog/gresse/matera/interiors/gresse_matera_11968_43121_0x500.jpg"),
      require("../images/catalog/gresse/matera/interiors/gresse_matera_11968_50686_0x300.jpg"),
      require("../images/catalog/gresse/matera/interiors/gresse_matera_11968_52268_0x300.jpg"),
      require("../images/catalog/gresse/matera/interiors/gresse_matera_11968_547189_0x300.jpg"),
      require("../images/catalog/gresse/matera/interiors/gresse_matera_11968_645934_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/matera/tovars/gresse_matera_116824_633268_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_116825_245018_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97849_488515_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97850_219651_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97851_454714_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97852_84674_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97853_408429_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97854_213053_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97855_80386_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97856_422475_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97857_97864_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97858_542365_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97859_315050_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97860_353166_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97861_626967_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97862_586162_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97863_547794_300x0.jpg"),
      require("../images/catalog/gresse/matera/tovars/gresse_matera_97864_610526_300x0.jpg"),
    ],
  },
  {
    id: "514",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Petra",
    interiors: [
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_11786_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_172548_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_431073_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_505151_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_577512_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_622857_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_660009_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_681235_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_69522_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_69522_0x500.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_715375_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_90036_0x300.jpg"),
      require("../images/catalog/gresse/petra/interiors/gresse_petra_11964_954395_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/petra/tovars/gresse_petra_116826_750753_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_116827_825879_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_116828_339043_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_116829_134652_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_116830_309774_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_116831_589831_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_97829_265300_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_97830_94601_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_97831_281111_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_97832_204626_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_97833_983559_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_97834_94776_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_97835_698454_300x0.jpg"),
      require("../images/catalog/gresse/petra/tovars/gresse_petra_97836_891015_300x0.jpg"),
    ],
  },
  {
    id: "515",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Sigiriya",
    interiors: [
      require("../images/catalog/gresse/sigiriya/interiors/gresse_sigiriya_11970_537968_0x300.jpg"),
      require("../images/catalog/gresse/sigiriya/interiors/gresse_sigiriya_11970_537968_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/sigiriya/tovars/gresse_sigiriya_97873_523958_300x0.jpg"),
      require("../images/catalog/gresse/sigiriya/tovars/gresse_sigiriya_97874_877946_300x0.jpg"),
      require("../images/catalog/gresse/sigiriya/tovars/gresse_sigiriya_97875_11146_300x0.jpg"),
      require("../images/catalog/gresse/sigiriya/tovars/gresse_sigiriya_97876_672966_300x0.jpg"),
      require("../images/catalog/gresse/sigiriya/tovars/gresse_sigiriya_97877_346367_300x0.jpg"),
      require("../images/catalog/gresse/sigiriya/tovars/gresse_sigiriya_97878_776597_300x0.jpg"),
    ],
  },
  {
    id: "516",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Simbel",
    interiors: [
      require("../images/catalog/gresse/simbel/interiors/gresse_simbel_11967_160392_0x300.jpg"),
      require("../images/catalog/gresse/simbel/interiors/gresse_simbel_11967_24768_0x300.jpg"),
      require("../images/catalog/gresse/simbel/interiors/gresse_simbel_11967_553132_0x300.jpg"),
      require("../images/catalog/gresse/simbel/interiors/gresse_simbel_11967_666722_0x300.jpg"),
      require("../images/catalog/gresse/simbel/interiors/gresse_simbel_11967_705614_0x300.jpg"),
      require("../images/catalog/gresse/simbel/interiors/gresse_simbel_11967_963025_0x300.jpg"),
      require("../images/catalog/gresse/simbel/interiors/gresse_simbel_11967_963025_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_116784_357628_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_116785_219421_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_116786_773762_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_116787_80841_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_116788_676975_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_116789_802689_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_116790_600186_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_116791_792011_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_97845_159007_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_97846_468420_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_97847_775449_300x0.jpg"),
      require("../images/catalog/gresse/simbel/tovars/gresse_simbel_97848_200361_300x0.jpg"),
    ],
  },
  {
    id: "517",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Gresse",
    Collection: "Troo",
    interiors: [
      require("../images/catalog/gresse/troo/interiors/gresse_troo_11973_830774_0x300.jpg"),
      require("../images/catalog/gresse/troo/interiors/gresse_troo_11973_830774_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/gresse/troo/tovars/gresse_troo_97891_952131_300x0.jpg"),
      require("../images/catalog/gresse/troo/tovars/gresse_troo_97892_139280_300x0.jpg"),
    ],
  },
  {
    id: "518",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Керлайф",
    Collection: "Marblestone",
    interiors: [
      require("../images/catalog/kerlife/marblestone/interiors/kerlife_marblestone_16897_218575_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "519",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Керлайф",
    Collection: "Nrk",
    interiors: [
      require("../images/catalog/kerlife/nrk/interiors/kerlife_nrk_18475_553626_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "520",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Керлайф",
    Collection: "Rabat",
    interiors: [
      require("../images/catalog/kerlife/rabat/interiors/kerlife_rabat_15966_569010_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "521",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Керлайф",
    Collection: "Small tile",
    interiors: [
      require("../images/catalog/kerlife/small_tile/interiors/kerlife_small_tile_11284_28866_0x300.jpg"),
      require("../images/catalog/kerlife/small_tile/interiors/kerlife_small_tile_11284_316867_0x300.jpg"),
      require("../images/catalog/kerlife/small_tile/interiors/kerlife_small_tile_11284_316867_0x500.jpg"),
      require("../images/catalog/kerlife/small_tile/interiors/kerlife_small_tile_11284_444105_0x300.jpg"),
      require("../images/catalog/kerlife/small_tile/interiors/kerlife_small_tile_11284_749725_0x300.jpg"),
      require("../images/catalog/kerlife/small_tile/interiors/kerlife_small_tile_11284_96723_0x300.jpg"),
      require("../images/catalog/kerlife/small_tile/interiors/kerlife_small_tile_11284_990176_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/kerlife/small_tile/tovars/kerlife_small_tile_94248_341472_300x0.jpg"),
      require("../images/catalog/kerlife/small_tile/tovars/kerlife_small_tile_94250_361150_300x0.jpg"),
    ],
  },
  {
    id: "522",
    Сountry: "Россия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Керлайф",
    Collection: "Tanger",
    interiors: [
      require("../images/catalog/kerlife/tanger/interiors/kerlife_tanger_15967_701023_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "523",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Ajjrish",
    interiors: [
      require("../images/catalog/lb_ceramics/ajjrish/interiors/lb_ceramics_ajjrish_7737_493166_0x300.jpg"),
      require("../images/catalog/lb_ceramics/ajjrish/interiors/lb_ceramics_ajjrish_7737_493166_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/ajjrish/tovars/lb_ceramics_ajjrish_66608_881794_300x0.jpg"),
      require("../images/catalog/lb_ceramics/ajjrish/tovars/lb_ceramics_ajjrish_66609_175192_300x0.jpg"),
      require("../images/catalog/lb_ceramics/ajjrish/tovars/lb_ceramics_ajjrish_66610_845325_300x0.jpg"),
    ],
  },
  {
    id: "524",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Albervud",
    interiors: [
      require("../images/catalog/lb_ceramics/albervud/interiors/lb_ceramics_albervud_8120_675732_0x300.jpg"),
      require("../images/catalog/lb_ceramics/albervud/interiors/lb_ceramics_albervud_8120_675732_0x500.jpg"),
      require("../images/catalog/lb_ceramics/albervud/interiors/lb_ceramics_albervud_8120_836199_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/albervud/tovars/lb_ceramics_albervud_70264_643156_300x0.jpg"),
      require("../images/catalog/lb_ceramics/albervud/tovars/lb_ceramics_albervud_70265_160911_300x0.jpg"),
      require("../images/catalog/lb_ceramics/albervud/tovars/lb_ceramics_albervud_70266_692558_300x0.jpg"),
      require("../images/catalog/lb_ceramics/albervud/tovars/lb_ceramics_albervud_70267_46310_300x0.jpg"),
      require("../images/catalog/lb_ceramics/albervud/tovars/lb_ceramics_albervud_70268_200873_300x0.jpg"),
      require("../images/catalog/lb_ceramics/albervud/tovars/lb_ceramics_albervud_70269_245277_300x0.jpg"),
      require("../images/catalog/lb_ceramics/albervud/tovars/lb_ceramics_albervud_70270_149533_300x0.jpg"),
      require("../images/catalog/lb_ceramics/albervud/tovars/lb_ceramics_albervud_70271_777665_300x0.jpg"),
      require("../images/catalog/lb_ceramics/albervud/tovars/lb_ceramics_albervud_70272_925473_300x0.jpg"),
    ],
  },
  {
    id: "525",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Andersson",
    interiors: [
      require("../images/catalog/lb_ceramics/andersson/interiors/lb_ceramics_andersson_10636_193141_0x300.jpg"),
      require("../images/catalog/lb_ceramics/andersson/interiors/lb_ceramics_andersson_10636_23438_0x300.jpg"),
      require("../images/catalog/lb_ceramics/andersson/interiors/lb_ceramics_andersson_10636_23438_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/andersson/tovars/lb_ceramics_andersson_90221_355042_300x0.jpg"),
      require("../images/catalog/lb_ceramics/andersson/tovars/lb_ceramics_andersson_90222_661453_300x0.jpg"),
      require("../images/catalog/lb_ceramics/andersson/tovars/lb_ceramics_andersson_90223_546111_300x0.jpg"),
    ],
  },
  {
    id: "526",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Asgard",
    interiors: [
      require("../images/catalog/lb_ceramics/asgard/interiors/lb_ceramics_asgard_18682_79539_0x300.jpg"),
      require("../images/catalog/lb_ceramics/asgard/interiors/lb_ceramics_asgard_18682_79539_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/asgard/tovars/lb_ceramics_asgard_129279_71229_300x0.jpg"),
      require("../images/catalog/lb_ceramics/asgard/tovars/lb_ceramics_asgard_129280_609440_300x0.jpg"),
    ],
  },
  {
    id: "527",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Aspen",
    interiors: [
      require("../images/catalog/lb_ceramics/aspen/interiors/lb_ceramics_aspen_11769_137144_0x300.jpg"),
      require("../images/catalog/lb_ceramics/aspen/interiors/lb_ceramics_aspen_11769_137144_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/aspen/tovars/lb_ceramics_aspen_96491_182982_300x0.jpg"),
      require("../images/catalog/lb_ceramics/aspen/tovars/lb_ceramics_aspen_96492_80381_300x0.jpg"),
      require("../images/catalog/lb_ceramics/aspen/tovars/lb_ceramics_aspen_96493_207059_300x0.jpg"),
    ],
  },
  {
    id: "528",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Bliss",
    interiors: [
      require("../images/catalog/lb_ceramics/bliss/interiors/lb_ceramics_bliss_13482_799505_0x300.jpg"),
      require("../images/catalog/lb_ceramics/bliss/interiors/lb_ceramics_bliss_13482_799505_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/bliss/tovars/lb_ceramics_bliss_107220_654672_300x0.jpg"),
      require("../images/catalog/lb_ceramics/bliss/tovars/lb_ceramics_bliss_107221_128773_300x0.jpg"),
      require("../images/catalog/lb_ceramics/bliss/tovars/lb_ceramics_bliss_107222_940152_300x0.jpg"),
      require("../images/catalog/lb_ceramics/bliss/tovars/lb_ceramics_bliss_107223_511241_300x0.jpg"),
      require("../images/catalog/lb_ceramics/bliss/tovars/lb_ceramics_bliss_107224_472114_300x0.jpg"),
    ],
  },
  {
    id: "529",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Blyum",
    interiors: [
      require("../images/catalog/lb_ceramics/blyum/interiors/lb_ceramics_blyum_11770_454253_0x300.jpg"),
      require("../images/catalog/lb_ceramics/blyum/interiors/lb_ceramics_blyum_11770_454253_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/blyum/tovars/lb_ceramics_blyum_96494_319787_300x0.jpg"),
      require("../images/catalog/lb_ceramics/blyum/tovars/lb_ceramics_blyum_96495_290491_300x0.jpg"),
      require("../images/catalog/lb_ceramics/blyum/tovars/lb_ceramics_blyum_96496_777327_300x0.jpg"),
      require("../images/catalog/lb_ceramics/blyum/tovars/lb_ceramics_blyum_96497_387262_300x0.jpg"),
    ],
  },
  {
    id: "530",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Diamante",
    interiors: [
      require("../images/catalog/lb_ceramics/diamante/interiors/lb_ceramics_diamante_10638_134333_0x300.jpg"),
      require("../images/catalog/lb_ceramics/diamante/interiors/lb_ceramics_diamante_10638_134333_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/diamante/tovars/lb_ceramics_diamante_90228_690177_300x0.jpg"),
      require("../images/catalog/lb_ceramics/diamante/tovars/lb_ceramics_diamante_90229_837318_300x0.jpg"),
    ],
  },
  {
    id: "531",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Dikkens",
    interiors: [
      require("../images/catalog/lb_ceramics/dikkens/interiors/lb_ceramics_dikkens_17016_140134_0x300.jpg"),
      require("../images/catalog/lb_ceramics/dikkens/interiors/lb_ceramics_dikkens_17016_140134_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/dikkens/tovars/lb_ceramics_dikkens_122833_222404_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dikkens/tovars/lb_ceramics_dikkens_122834_400276_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dikkens/tovars/lb_ceramics_dikkens_122835_418458_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dikkens/tovars/lb_ceramics_dikkens_129281_521849_300x0.jpg"),
    ],
  },
  {
    id: "532",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Domino",
    interiors: [
      require("../images/catalog/lb_ceramics/domino/interiors/lb_ceramics_domino_13484_401319_0x300.jpg"),
      require("../images/catalog/lb_ceramics/domino/interiors/lb_ceramics_domino_13484_401319_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/domino/tovars/lb_ceramics_domino_107230_238721_300x0.jpg"),
      require("../images/catalog/lb_ceramics/domino/tovars/lb_ceramics_domino_107231_448332_300x0.jpg"),
    ],
  },
  {
    id: "533",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Dyuna",
    interiors: [
      require("../images/catalog/lb_ceramics/dyuna/interiors/lb_ceramics_dyuna_7738_703095_0x300.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/interiors/lb_ceramics_dyuna_7738_961754_0x300.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/interiors/lb_ceramics_dyuna_7738_961754_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66611_433765_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66612_614247_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66613_290880_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66614_869369_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66615_461765_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66616_365238_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66617_138507_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66618_436154_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66619_481182_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dyuna/tovars/lb_ceramics_dyuna_66620_851869_300x0.jpg"),
    ],
  },
  {
    id: "534",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Dzhapandi",
    interiors: [
      require("../images/catalog/lb_ceramics/dzhapandi/interiors/lb_ceramics_dzhapandi_11772_474084_0x300.jpg"),
      require("../images/catalog/lb_ceramics/dzhapandi/interiors/lb_ceramics_dzhapandi_11772_474084_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/dzhapandi/tovars/lb_ceramics_dzhapandi_96504_674092_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dzhapandi/tovars/lb_ceramics_dzhapandi_96505_454465_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dzhapandi/tovars/lb_ceramics_dzhapandi_96506_595478_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dzhapandi/tovars/lb_ceramics_dzhapandi_96508_186407_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dzhapandi/tovars/lb_ceramics_dzhapandi_96509_672537_300x0.jpg"),
    ],
  },
  {
    id: "535",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Dzhordano",
    interiors: [
      require("../images/catalog/lb_ceramics/dzhordano/interiors/lb_ceramics_dzhordano_16729_394603_0x300.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/interiors/lb_ceramics_dzhordano_16729_434398_0x300.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/interiors/lb_ceramics_dzhordano_16729_546917_0x300.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/interiors/lb_ceramics_dzhordano_16729_647699_0x300.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/interiors/lb_ceramics_dzhordano_16729_647699_0x500.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/interiors/lb_ceramics_dzhordano_16729_844256_0x300.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/interiors/lb_ceramics_dzhordano_16729_88095_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/dzhordano/tovars/lb_ceramics_dzhordano_121538_586316_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/tovars/lb_ceramics_dzhordano_121539_888144_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/tovars/lb_ceramics_dzhordano_121540_487492_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/tovars/lb_ceramics_dzhordano_121541_46461_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/tovars/lb_ceramics_dzhordano_121542_641435_300x0.jpg"),
      require("../images/catalog/lb_ceramics/dzhordano/tovars/lb_ceramics_dzhordano_121543_570483_300x0.jpg"),
    ],
  },
  {
    id: "536",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Ehllen",
    interiors: [
      require("../images/catalog/lb_ceramics/ehllen/interiors/lb_ceramics_ehllen_11778_789396_0x300.jpg"),
      require("../images/catalog/lb_ceramics/ehllen/interiors/lb_ceramics_ehllen_11778_789396_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/ehllen/tovars/lb_ceramics_ehllen_96535_679488_300x0.jpg"),
      require("../images/catalog/lb_ceramics/ehllen/tovars/lb_ceramics_ehllen_96536_604879_300x0.jpg"),
      require("../images/catalog/lb_ceramics/ehllen/tovars/lb_ceramics_ehllen_96538_806196_300x0.jpg"),
      require("../images/catalog/lb_ceramics/ehllen/tovars/lb_ceramics_ehllen_96539_781288_300x0.jpg"),
    ],
  },
  {
    id: "537",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Ehlzas",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/lb_ceramics/ehlzas/tovars/lb_ceramics_ehlzas_96540_221414_300x0.jpg"),
    ],
  },
  {
    id: "538",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Fiori_gridzho",
    interiors: [
      require("../images/catalog/lb_ceramics/fiori_gridzho/interiors/lb_ceramics_fiori_gridzho_6744_84710_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/fiori_gridzho/tovars/lb_ceramics_fiori_gridzho_57383_938638_300x0.jpg"),
      require("../images/catalog/lb_ceramics/fiori_gridzho/tovars/lb_ceramics_fiori_gridzho_57384_302715_300x0.jpg"),
      require("../images/catalog/lb_ceramics/fiori_gridzho/tovars/lb_ceramics_fiori_gridzho_57385_604300_300x0.jpg"),
      require("../images/catalog/lb_ceramics/fiori_gridzho/tovars/lb_ceramics_fiori_gridzho_57386_934201_300x0.jpg"),
      require("../images/catalog/lb_ceramics/fiori_gridzho/tovars/lb_ceramics_fiori_gridzho_57387_423604_300x0.jpg"),
      require("../images/catalog/lb_ceramics/fiori_gridzho/tovars/lb_ceramics_fiori_gridzho_57388_648821_300x0.jpg"),
      require("../images/catalog/lb_ceramics/fiori_gridzho/tovars/lb_ceramics_fiori_gridzho_57389_703104_300x0.jpg"),
      require("../images/catalog/lb_ceramics/fiori_gridzho/tovars/lb_ceramics_fiori_gridzho_57391_341784_300x0.jpg"),
      require("../images/catalog/lb_ceramics/fiori_gridzho/tovars/lb_ceramics_fiori_gridzho_57392_620607_300x0.jpg"),
    ],
  },
  {
    id: "539",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Gauss",
    interiors: [
      require("../images/catalog/lb_ceramics/gauss/interiors/lb_ceramics_gauss_11771_60591_0x300.jpg"),
      require("../images/catalog/lb_ceramics/gauss/interiors/lb_ceramics_gauss_11771_60591_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/gauss/tovars/lb_ceramics_gauss_96498_877628_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gauss/tovars/lb_ceramics_gauss_96499_616347_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gauss/tovars/lb_ceramics_gauss_96500_278899_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gauss/tovars/lb_ceramics_gauss_96501_593932_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gauss/tovars/lb_ceramics_gauss_96502_383539_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gauss/tovars/lb_ceramics_gauss_96503_177491_300x0.jpg"),
    ],
  },
  {
    id: "540",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Gunnar",
    interiors: [
      require("../images/catalog/lb_ceramics/gunnar/interiors/lb_ceramics_gunnar_13483_672405_0x300.jpg"),
      require("../images/catalog/lb_ceramics/gunnar/interiors/lb_ceramics_gunnar_13483_672405_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/gunnar/tovars/lb_ceramics_gunnar_107225_443691_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gunnar/tovars/lb_ceramics_gunnar_107226_927326_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gunnar/tovars/lb_ceramics_gunnar_107227_922827_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gunnar/tovars/lb_ceramics_gunnar_107228_465953_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gunnar/tovars/lb_ceramics_gunnar_107229_993078_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gunnar/tovars/lb_ceramics_gunnar_111351_878761_300x0.jpg"),
      require("../images/catalog/lb_ceramics/gunnar/tovars/lb_ceramics_gunnar_111352_999803_300x0.jpg"),
    ],
  },
  {
    id: "541",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Ipanema",
    interiors: [
      require("../images/catalog/lb_ceramics/ipanema/interiors/lb_ceramics_ipanema_10639_843940_0x300.jpg"),
      require("../images/catalog/lb_ceramics/ipanema/interiors/lb_ceramics_ipanema_10639_843940_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/ipanema/tovars/lb_ceramics_ipanema_90230_20546_300x0.jpg"),
      require("../images/catalog/lb_ceramics/ipanema/tovars/lb_ceramics_ipanema_90231_693840_300x0.jpg"),
      require("../images/catalog/lb_ceramics/ipanema/tovars/lb_ceramics_ipanema_90232_15684_300x0.jpg"),
      require("../images/catalog/lb_ceramics/ipanema/tovars/lb_ceramics_ipanema_90233_550002_300x0.jpg"),
      require("../images/catalog/lb_ceramics/ipanema/tovars/lb_ceramics_ipanema_90234_701027_300x0.jpg"),
    ],
  },
  {
    id: "542",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Kampanilya",
    interiors: [
      require("../images/catalog/lb_ceramics/kampanilya/interiors/lb_ceramics_kampanilya_7739_607484_0x300.jpg"),
      require("../images/catalog/lb_ceramics/kampanilya/interiors/lb_ceramics_kampanilya_7739_607484_0x500.jpg"),
      require("../images/catalog/lb_ceramics/kampanilya/interiors/lb_ceramics_kampanilya_7739_81547_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/kampanilya/tovars/lb_ceramics_kampanilya_66622_651498_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kampanilya/tovars/lb_ceramics_kampanilya_66623_757049_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kampanilya/tovars/lb_ceramics_kampanilya_66625_744067_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kampanilya/tovars/lb_ceramics_kampanilya_66626_742641_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kampanilya/tovars/lb_ceramics_kampanilya_66627_170971_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kampanilya/tovars/lb_ceramics_kampanilya_66628_777245_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kampanilya/tovars/lb_ceramics_kampanilya_66629_661910_300x0.jpg"),
    ],
  },
  {
    id: "543",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Karrara_nova",
    interiors: [
      require("../images/catalog/lb_ceramics/karrara_nova/interiors/lb_ceramics_karrara_nova_13485_39267_0x300.jpg"),
      require("../images/catalog/lb_ceramics/karrara_nova/interiors/lb_ceramics_karrara_nova_13485_39267_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/karrara_nova/tovars/lb_ceramics_karrara_nova_107232_529496_300x0.jpg"),
      require("../images/catalog/lb_ceramics/karrara_nova/tovars/lb_ceramics_karrara_nova_107233_406246_300x0.jpg"),
      require("../images/catalog/lb_ceramics/karrara_nova/tovars/lb_ceramics_karrara_nova_107234_560168_300x0.jpg"),
      require("../images/catalog/lb_ceramics/karrara_nova/tovars/lb_ceramics_karrara_nova_107235_260128_300x0.jpg"),
      require("../images/catalog/lb_ceramics/karrara_nova/tovars/lb_ceramics_karrara_nova_107236_85271_300x0.jpg"),
    ],
  },
  {
    id: "544",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Karrarskijj_mramor_i_loft",
    interiors: [
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/interiors/lb_ceramics_karrarskijj_mramor_i_loft_9635_162497_0x300.jpg"),
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/interiors/lb_ceramics_karrarskijj_mramor_i_loft_9635_505132_0x300.jpg"),
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/interiors/lb_ceramics_karrarskijj_mramor_i_loft_9635_505132_0x500.jpg"),
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/interiors/lb_ceramics_karrarskijj_mramor_i_loft_9635_571138_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/tovars/lb_ceramics_karrarskijj_mramor_i_loft_35305_125402_300x0.jpg"),
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/tovars/lb_ceramics_karrarskijj_mramor_i_loft_83043_636615_300x0.jpg"),
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/tovars/lb_ceramics_karrarskijj_mramor_i_loft_83044_429220_300x0.jpg"),
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/tovars/lb_ceramics_karrarskijj_mramor_i_loft_83045_185352_300x0.jpg"),
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/tovars/lb_ceramics_karrarskijj_mramor_i_loft_83046_480652_300x0.jpg"),
      require("../images/catalog/lb_ceramics/karrarskijj_mramor_i_loft/tovars/lb_ceramics_karrarskijj_mramor_i_loft_83047_842520_300x0.jpg"),
    ],
  },
  {
    id: "545",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Kehrroll",
    interiors: [
      require("../images/catalog/lb_ceramics/kehrroll/interiors/lb_ceramics_kehrroll_18683_961284_0x300.jpg"),
      require("../images/catalog/lb_ceramics/kehrroll/interiors/lb_ceramics_kehrroll_18683_961284_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/kehrroll/tovars/lb_ceramics_kehrroll_129282_453666_300x0.jpg"),
    ],
  },
  {
    id: "546",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Kincugi",
    interiors: [
      require("../images/catalog/lb_ceramics/kincugi/interiors/lb_ceramics_kincugi_13486_853791_0x300.jpg"),
      require("../images/catalog/lb_ceramics/kincugi/interiors/lb_ceramics_kincugi_13486_853791_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/kincugi/tovars/lb_ceramics_kincugi_107237_634101_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kincugi/tovars/lb_ceramics_kincugi_107238_548478_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kincugi/tovars/lb_ceramics_kincugi_107239_149137_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kincugi/tovars/lb_ceramics_kincugi_107240_829330_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kincugi/tovars/lb_ceramics_kincugi_107241_117190_300x0.jpg"),
    ],
  },
  {
    id: "547",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Kipling",
    interiors: [
      require("../images/catalog/lb_ceramics/kipling/interiors/lb_ceramics_kipling_17017_284337_0x300.jpg"),
      require("../images/catalog/lb_ceramics/kipling/interiors/lb_ceramics_kipling_17017_284337_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/kipling/tovars/lb_ceramics_kipling_122836_114960_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kipling/tovars/lb_ceramics_kipling_122837_868918_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kipling/tovars/lb_ceramics_kipling_122838_284610_300x0.jpg"),
      require("../images/catalog/lb_ceramics/kipling/tovars/lb_ceramics_kipling_122839_462806_300x0.jpg"),
    ],
  },
  {
    id: "548",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Lissabon",
    interiors: [
      require("../images/catalog/lb_ceramics/lissabon/interiors/lb_ceramics_lissabon_11774_294585_0x300.jpg"),
      require("../images/catalog/lb_ceramics/lissabon/interiors/lb_ceramics_lissabon_11774_294585_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/lissabon/tovars/lb_ceramics_lissabon_96516_623887_300x0.jpg"),
      require("../images/catalog/lb_ceramics/lissabon/tovars/lb_ceramics_lissabon_96517_470584_300x0.jpg"),
      require("../images/catalog/lb_ceramics/lissabon/tovars/lb_ceramics_lissabon_96518_281629_300x0.jpg"),
      require("../images/catalog/lb_ceramics/lissabon/tovars/lb_ceramics_lissabon_96519_646971_300x0.jpg"),
      require("../images/catalog/lb_ceramics/lissabon/tovars/lb_ceramics_lissabon_96520_192960_300x0.jpg"),
      require("../images/catalog/lb_ceramics/lissabon/tovars/lb_ceramics_lissabon_96521_196135_300x0.jpg"),
    ],
  },
  {
    id: "549",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Loft_stajjl",
    interiors: [
      require("../images/catalog/lb_ceramics/loft_stajjl/interiors/lb_ceramics_loft_stajjl_5644_306600_0x300.jpg"),
      require("../images/catalog/lb_ceramics/loft_stajjl/interiors/lb_ceramics_loft_stajjl_5644_306600_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/loft_stajjl/tovars/lb_ceramics_loft_stajjl_44491_884439_300x0.jpg"),
      require("../images/catalog/lb_ceramics/loft_stajjl/tovars/lb_ceramics_loft_stajjl_44494_577208_300x0.jpg"),
    ],
  },
  {
    id: "550",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Lokivud",
    interiors: [
      require("../images/catalog/lb_ceramics/lokivud/interiors/lb_ceramics_lokivud_11775_366233_0x300.jpg"),
      require("../images/catalog/lb_ceramics/lokivud/interiors/lb_ceramics_lokivud_11775_366233_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/lokivud/tovars/lb_ceramics_lokivud_96522_819955_300x0.jpg"),
      require("../images/catalog/lb_ceramics/lokivud/tovars/lb_ceramics_lokivud_96523_263342_300x0.jpg"),
      require("../images/catalog/lb_ceramics/lokivud/tovars/lb_ceramics_lokivud_96524_393196_300x0.jpg"),
    ],
  },
  {
    id: "551",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Makedoniya",
    interiors: [
      require("../images/catalog/lb_ceramics/makedoniya/interiors/lb_ceramics_makedoniya_11297_243812_0x300.jpg"),
      require("../images/catalog/lb_ceramics/makedoniya/interiors/lb_ceramics_makedoniya_11297_535956_0x300.jpg"),
      require("../images/catalog/lb_ceramics/makedoniya/interiors/lb_ceramics_makedoniya_11297_535956_0x500.jpg"),
      require("../images/catalog/lb_ceramics/makedoniya/interiors/lb_ceramics_makedoniya_11297_935624_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/makedoniya/tovars/lb_ceramics_makedoniya_94355_994495_300x0.jpg"),
      require("../images/catalog/lb_ceramics/makedoniya/tovars/lb_ceramics_makedoniya_94356_270866_300x0.jpg"),
      require("../images/catalog/lb_ceramics/makedoniya/tovars/lb_ceramics_makedoniya_94357_674860_300x0.jpg"),
    ],
  },
  {
    id: "552",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Merc",
    interiors: [
      require("../images/catalog/lb_ceramics/merc/interiors/lb_ceramics_merc_16201_908417_0x300.jpg"),
      require("../images/catalog/lb_ceramics/merc/interiors/lb_ceramics_merc_16201_908417_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/merc/tovars/lb_ceramics_merc_119653_519246_300x0.jpg"),
      require("../images/catalog/lb_ceramics/merc/tovars/lb_ceramics_merc_119654_388687_300x0.jpg"),
      require("../images/catalog/lb_ceramics/merc/tovars/lb_ceramics_merc_119656_73874_300x0.jpg"),
    ],
  },
  {
    id: "553",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Mezon",
    interiors: [
      require("../images/catalog/lb_ceramics/mezon/interiors/lb_ceramics_mezon_4731_854814_0x300.jpg"),
      require("../images/catalog/lb_ceramics/mezon/interiors/lb_ceramics_mezon_4731_854814_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/mezon/tovars/lb_ceramics_mezon_35006_234170_300x0.jpg"),
      require("../images/catalog/lb_ceramics/mezon/tovars/lb_ceramics_mezon_35007_538398_300x0.jpg"),
      require("../images/catalog/lb_ceramics/mezon/tovars/lb_ceramics_mezon_35008_105670_300x0.jpg"),
      require("../images/catalog/lb_ceramics/mezon/tovars/lb_ceramics_mezon_35009_830611_300x0.jpg"),
      require("../images/catalog/lb_ceramics/mezon/tovars/lb_ceramics_mezon_35010_502144_300x0.jpg"),
    ],
  },
  {
    id: "554",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Milaneze dizajjn",
    interiors: [
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/interiors/lb_ceramics_milaneze_dizajjn_6719_516008_0x300.jpg"),
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/interiors/lb_ceramics_milaneze_dizajjn_6719_516008_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/tovars/lb_ceramics_milaneze_dizajjn_57221_25136_300x0.jpg"),
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/tovars/lb_ceramics_milaneze_dizajjn_57225_413818_300x0.jpg"),
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/tovars/lb_ceramics_milaneze_dizajjn_57229_358733_300x0.jpg"),
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/tovars/lb_ceramics_milaneze_dizajjn_57230_631384_300x0.jpg"),
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/tovars/lb_ceramics_milaneze_dizajjn_57234_586215_300x0.jpg"),
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/tovars/lb_ceramics_milaneze_dizajjn_57238_281717_300x0.jpg"),
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/tovars/lb_ceramics_milaneze_dizajjn_57242_369801_300x0.jpg"),
      require("../images/catalog/lb_ceramics/milaneze_dizajjn/tovars/lb_ceramics_milaneze_dizajjn_57246_501119_300x0.jpg"),
    ],
  },
  {
    id: "555",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Mirabello",
    interiors: [
      require("../images/catalog/lb_ceramics/mirabello/interiors/lb_ceramics_mirabello_17018_444462_0x300.jpg"),
      require("../images/catalog/lb_ceramics/mirabello/interiors/lb_ceramics_mirabello_17018_444462_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/mirabello/tovars/lb_ceramics_mirabello_122840_304714_300x0.jpg"),
    ],
  },
  {
    id: "556",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Moreska",
    interiors: [
      require("../images/catalog/lb_ceramics/moreska/interiors/lb_ceramics_moreska_9636_549210_0x300.jpg"),
      require("../images/catalog/lb_ceramics/moreska/interiors/lb_ceramics_moreska_9636_595942_0x300.jpg"),
      require("../images/catalog/lb_ceramics/moreska/interiors/lb_ceramics_moreska_9636_595942_0x500.jpg"),
      require("../images/catalog/lb_ceramics/moreska/interiors/lb_ceramics_moreska_9636_923392_0x300.jpg"),
      require("../images/catalog/lb_ceramics/moreska/interiors/lb_ceramics_moreska_9636_92816_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83048_607526_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83049_166474_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83050_97505_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83051_26419_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83052_419529_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83053_247982_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83054_616585_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83055_643271_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83056_307050_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83057_297785_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83058_293257_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83059_532429_300x0.jpg"),
      require("../images/catalog/lb_ceramics/moreska/tovars/lb_ceramics_moreska_83060_966744_300x0.jpg"),
    ],
  },
  {
    id: "557",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Naomi",
    interiors: [
      require("../images/catalog/lb_ceramics/naomi/interiors/lb_ceramics_naomi_6128_340220_0x300.jpg"),
      require("../images/catalog/lb_ceramics/naomi/interiors/lb_ceramics_naomi_6128_340220_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/naomi/tovars/lb_ceramics_naomi_35336_717869_300x0.jpg"),
      require("../images/catalog/lb_ceramics/naomi/tovars/lb_ceramics_naomi_35338_187204_300x0.jpg"),
      require("../images/catalog/lb_ceramics/naomi/tovars/lb_ceramics_naomi_50416_386890_300x0.jpg"),
      require("../images/catalog/lb_ceramics/naomi/tovars/lb_ceramics_naomi_50417_333444_300x0.jpg"),
      require("../images/catalog/lb_ceramics/naomi/tovars/lb_ceramics_naomi_50418_525674_300x0.jpg"),
      require("../images/catalog/lb_ceramics/naomi/tovars/lb_ceramics_naomi_50419_575291_300x0.jpg"),
    ],
  },
  {
    id: "558",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Niagara",
    interiors: [
      require("../images/catalog/lb_ceramics/niagara/interiors/lb_ceramics_niagara_11776_616614_0x300.jpg"),
      require("../images/catalog/lb_ceramics/niagara/interiors/lb_ceramics_niagara_11776_616614_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/niagara/tovars/lb_ceramics_niagara_96525_139742_300x0.jpg"),
      require("../images/catalog/lb_ceramics/niagara/tovars/lb_ceramics_niagara_96526_543834_300x0.jpg"),
      require("../images/catalog/lb_ceramics/niagara/tovars/lb_ceramics_niagara_96527_170524_300x0.jpg"),
      require("../images/catalog/lb_ceramics/niagara/tovars/lb_ceramics_niagara_96529_421463_300x0.jpg"),
    ],
  },
  {
    id: "559",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Nordanvind",
    interiors: [
      require("../images/catalog/lb_ceramics/nordanvind/interiors/lb_ceramics_nordanvind_6732_528157_0x300.jpg"),
      require("../images/catalog/lb_ceramics/nordanvind/interiors/lb_ceramics_nordanvind_6732_528157_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/nordanvind/tovars/lb_ceramics_nordanvind_57325_368014_300x0.jpg"),
      require("../images/catalog/lb_ceramics/nordanvind/tovars/lb_ceramics_nordanvind_57326_366363_300x0.jpg"),
      require("../images/catalog/lb_ceramics/nordanvind/tovars/lb_ceramics_nordanvind_57327_293785_300x0.jpg"),
      require("../images/catalog/lb_ceramics/nordanvind/tovars/lb_ceramics_nordanvind_57328_971064_300x0.jpg"),
      require("../images/catalog/lb_ceramics/nordanvind/tovars/lb_ceramics_nordanvind_57329_761073_300x0.jpg"),
      require("../images/catalog/lb_ceramics/nordanvind/tovars/lb_ceramics_nordanvind_57330_195332_300x0.jpg"),
      require("../images/catalog/lb_ceramics/nordanvind/tovars/lb_ceramics_nordanvind_57331_178131_300x0.jpg"),
    ],
  },
  {
    id: "560",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Oklend",
    interiors: [
      require("../images/catalog/lb_ceramics/oklend/interiors/lb_ceramics_oklend_13487_322054_0x300.jpg"),
      require("../images/catalog/lb_ceramics/oklend/interiors/lb_ceramics_oklend_13487_322054_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/oklend/tovars/lb_ceramics_oklend_107242_614336_300x0.jpg"),
      require("../images/catalog/lb_ceramics/oklend/tovars/lb_ceramics_oklend_107243_242719_300x0.jpg"),
      require("../images/catalog/lb_ceramics/oklend/tovars/lb_ceramics_oklend_107244_315594_300x0.jpg"),
    ],
  },
  {
    id: "561",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Parizhanka",
    interiors: [
      require("../images/catalog/lb_ceramics/parizhanka/interiors/lb_ceramics_parizhanka_8309_38750_0x300.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/interiors/lb_ceramics_parizhanka_8309_678691_0x300.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/interiors/lb_ceramics_parizhanka_8309_705281_0x300.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/interiors/lb_ceramics_parizhanka_8309_705281_0x500.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/interiors/lb_ceramics_parizhanka_8309_931317_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71650_953666_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71651_26999_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71652_786132_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71653_899989_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71654_332736_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71655_226158_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71656_173851_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71657_58622_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71658_479821_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71659_133213_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71660_499493_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71661_339629_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71663_338412_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71664_258910_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71665_612597_300x0.jpg"),
      require("../images/catalog/lb_ceramics/parizhanka/tovars/lb_ceramics_parizhanka_71666_638943_300x0.jpg"),
    ],
  },
  {
    id: "562",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Patio",
    interiors: [
      require("../images/catalog/lb_ceramics/patio/interiors/lb_ceramics_patio_13488_324987_0x300.jpg"),
      require("../images/catalog/lb_ceramics/patio/interiors/lb_ceramics_patio_13488_324987_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/patio/tovars/lb_ceramics_patio_107245_523607_300x0.jpg"),
      require("../images/catalog/lb_ceramics/patio/tovars/lb_ceramics_patio_107246_103213_300x0.jpg"),
      require("../images/catalog/lb_ceramics/patio/tovars/lb_ceramics_patio_107247_925606_300x0.jpg"),
    ],
  },
  {
    id: "563",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Rerikh",
    interiors: [
      require("../images/catalog/lb_ceramics/rerikh/interiors/lb_ceramics_rerikh_18684_974789_0x300.jpg"),
      require("../images/catalog/lb_ceramics/rerikh/interiors/lb_ceramics_rerikh_18684_974789_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/rerikh/tovars/lb_ceramics_rerikh_129284_641646_300x0.jpg"),
    ],
  },
  {
    id: "564",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "River vud",
    interiors: [
      require("../images/catalog/lb_ceramics/river_vud/interiors/lb_ceramics_river_vud_4312_272295_0x300.jpg"),
      require("../images/catalog/lb_ceramics/river_vud/interiors/lb_ceramics_river_vud_4312_272295_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/river_vud/tovars/lb_ceramics_river_vud_30673_548964_300x0.jpg"),
      require("../images/catalog/lb_ceramics/river_vud/tovars/lb_ceramics_river_vud_30674_206359_300x0.jpg"),
    ],
  },
  {
    id: "565",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Rosa rok",
    interiors: [
      require("../images/catalog/lb_ceramics/rosa_rok/interiors/lb_ceramics_rosa_rok_13489_109368_0x300.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/interiors/lb_ceramics_rosa_rok_13489_109368_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107248_376160_300x0.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107249_32274_300x0.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107250_538446_300x0.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107251_30832_300x0.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107252_151048_300x0.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107253_478598_300x0.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107254_532075_300x0.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107255_613164_300x0.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107256_912291_300x0.jpg"),
      require("../images/catalog/lb_ceramics/rosa_rok/tovars/lb_ceramics_rosa_rok_107257_459401_300x0.jpg"),
    ],
  },
  {
    id: "566",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Shebbi shik",
    interiors: [
      require("../images/catalog/lb_ceramics/shebbi_shik/interiors/lb_ceramics_shebbi_shik_5651_898880_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/shebbi_shik/tovars/lb_ceramics_shebbi_shik_44597_854443_300x0.jpg"),
      require("../images/catalog/lb_ceramics/shebbi_shik/tovars/lb_ceramics_shebbi_shik_44598_998715_300x0.jpg"),
      require("../images/catalog/lb_ceramics/shebbi_shik/tovars/lb_ceramics_shebbi_shik_44599_905609_300x0.jpg"),
      require("../images/catalog/lb_ceramics/shebbi_shik/tovars/lb_ceramics_shebbi_shik_44600_829096_300x0.jpg"),
      require("../images/catalog/lb_ceramics/shebbi_shik/tovars/lb_ceramics_shebbi_shik_44601_526587_300x0.jpg"),
      require("../images/catalog/lb_ceramics/shebbi_shik/tovars/lb_ceramics_shebbi_shik_44602_427601_300x0.jpg"),
      require("../images/catalog/lb_ceramics/shebbi_shik/tovars/lb_ceramics_shebbi_shik_44603_772499_300x0.jpg"),
    ],
  },
  {
    id: "567",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Shvarc",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/lb_ceramics/shvarc/tovars/lb_ceramics_shvarc_129283_757398_300x0.jpg"),
    ],
  },
  {
    id: "568",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Smart",
    interiors: [
      require("../images/catalog/lb_ceramics/smart/interiors/lb_ceramics_smart_11777_549403_0x300.jpg"),
      require("../images/catalog/lb_ceramics/smart/interiors/lb_ceramics_smart_11777_549403_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/smart/tovars/lb_ceramics_smart_96530_776873_300x0.jpg"),
      require("../images/catalog/lb_ceramics/smart/tovars/lb_ceramics_smart_96531_785906_300x0.jpg"),
      require("../images/catalog/lb_ceramics/smart/tovars/lb_ceramics_smart_96532_15395_300x0.jpg"),
      require("../images/catalog/lb_ceramics/smart/tovars/lb_ceramics_smart_96533_160412_300x0.jpg"),
      require("../images/catalog/lb_ceramics/smart/tovars/lb_ceramics_smart_96534_953398_300x0.jpg"),
    ],
  },
  {
    id: "569",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Sten",
    interiors: [
      require("../images/catalog/lb_ceramics/sten/interiors/lb_ceramics_sten_10640_939089_0x300.jpg"),
      require("../images/catalog/lb_ceramics/sten/interiors/lb_ceramics_sten_10640_939089_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/sten/tovars/lb_ceramics_sten_90235_559676_300x0.jpg"),
      require("../images/catalog/lb_ceramics/sten/tovars/lb_ceramics_sten_90236_766846_300x0.jpg"),
      require("../images/catalog/lb_ceramics/sten/tovars/lb_ceramics_sten_90237_313483_300x0.jpg"),
      require("../images/catalog/lb_ceramics/sten/tovars/lb_ceramics_sten_90238_95580_300x0.jpg"),
    ],
  },
  {
    id: "570",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Taverna",
    interiors: [
      require("../images/catalog/lb_ceramics/taverna/interiors/lb_ceramics_taverna_10641_541151_0x300.jpg"),
      require("../images/catalog/lb_ceramics/taverna/interiors/lb_ceramics_taverna_10641_541151_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/taverna/tovars/lb_ceramics_taverna_90239_401307_300x0.jpg"),
      require("../images/catalog/lb_ceramics/taverna/tovars/lb_ceramics_taverna_90240_442503_300x0.jpg"),
    ],
  },
  {
    id: "571",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Titan",
    interiors: [
      require("../images/catalog/lb_ceramics/titan/interiors/lb_ceramics_titan_8971_798526_0x300.jpg"),
      require("../images/catalog/lb_ceramics/titan/interiors/lb_ceramics_titan_8971_798526_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/titan/tovars/lb_ceramics_titan_76806_900379_300x0.jpg"),
      require("../images/catalog/lb_ceramics/titan/tovars/lb_ceramics_titan_76807_758149_300x0.jpg"),
      require("../images/catalog/lb_ceramics/titan/tovars/lb_ceramics_titan_76808_892575_300x0.jpg"),
      require("../images/catalog/lb_ceramics/titan/tovars/lb_ceramics_titan_76809_107634_300x0.jpg"),
    ],
  },
  {
    id: "572",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Venskijj les",
    interiors: [
      require("../images/catalog/lb_ceramics/venskijj_les/interiors/lb_ceramics_venskijj_les_4311_174073_0x300.jpg"),
      require("../images/catalog/lb_ceramics/venskijj_les/interiors/lb_ceramics_venskijj_les_4311_394010_0x300.jpg"),
      require("../images/catalog/lb_ceramics/venskijj_les/interiors/lb_ceramics_venskijj_les_4311_394010_0x500.jpg"),
      require("../images/catalog/lb_ceramics/venskijj_les/interiors/lb_ceramics_venskijj_les_4311_869839_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/venskijj_les/tovars/lb_ceramics_venskijj_les_30665_442143_300x0.jpg"),
      require("../images/catalog/lb_ceramics/venskijj_les/tovars/lb_ceramics_venskijj_les_30666_960947_300x0.jpg"),
      require("../images/catalog/lb_ceramics/venskijj_les/tovars/lb_ceramics_venskijj_les_30668_76189_300x0.jpg"),
      require("../images/catalog/lb_ceramics/venskijj_les/tovars/lb_ceramics_venskijj_les_30669_918282_300x0.jpg"),
      require("../images/catalog/lb_ceramics/venskijj_les/tovars/lb_ceramics_venskijj_les_30670_755739_300x0.jpg"),
      require("../images/catalog/lb_ceramics/venskijj_les/tovars/lb_ceramics_venskijj_les_30672_18582_300x0.jpg"),
    ],
  },
  {
    id: "573",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Vestanvind",
    interiors: [
      require("../images/catalog/lb_ceramics/vestanvind/interiors/lb_ceramics_vestanvind_6709_27579_0x300.jpg"),
      require("../images/catalog/lb_ceramics/vestanvind/interiors/lb_ceramics_vestanvind_6709_344947_0x300.jpg"),
      require("../images/catalog/lb_ceramics/vestanvind/interiors/lb_ceramics_vestanvind_6709_344947_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/vestanvind/tovars/lb_ceramics_vestanvind_57153_971785_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestanvind/tovars/lb_ceramics_vestanvind_57154_154177_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestanvind/tovars/lb_ceramics_vestanvind_57155_222749_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestanvind/tovars/lb_ceramics_vestanvind_57156_785630_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestanvind/tovars/lb_ceramics_vestanvind_57157_876376_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestanvind/tovars/lb_ceramics_vestanvind_57158_562642_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestanvind/tovars/lb_ceramics_vestanvind_57159_81053_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestanvind/tovars/lb_ceramics_vestanvind_57160_538621_300x0.jpg"),
    ],
  },
  {
    id: "574",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Vestern vud",
    interiors: [
      require("../images/catalog/lb_ceramics/vestern_vud/interiors/lb_ceramics_vestern_vud_3721_152230_0x300.jpg"),
      require("../images/catalog/lb_ceramics/vestern_vud/interiors/lb_ceramics_vestern_vud_3721_231403_0x300.jpg"),
      require("../images/catalog/lb_ceramics/vestern_vud/interiors/lb_ceramics_vestern_vud_3721_431174_0x300.jpg"),
      require("../images/catalog/lb_ceramics/vestern_vud/interiors/lb_ceramics_vestern_vud_3721_431174_0x500.jpg"),
      require("../images/catalog/lb_ceramics/vestern_vud/interiors/lb_ceramics_vestern_vud_3721_766744_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/vestern_vud/tovars/lb_ceramics_vestern_vud_25573_341791_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestern_vud/tovars/lb_ceramics_vestern_vud_61653_713965_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestern_vud/tovars/lb_ceramics_vestern_vud_61654_453358_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vestern_vud/tovars/lb_ceramics_vestern_vud_61655_63582_300x0.jpg"),
    ],
  },
  {
    id: "575",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Vintazh vud",
    interiors: [
      require("../images/catalog/lb_ceramics/vintazh_vud/interiors/lb_ceramics_vintazh_vud_9634_186213_0x300.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/interiors/lb_ceramics_vintazh_vud_9634_453990_0x300.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/interiors/lb_ceramics_vintazh_vud_9634_453990_0x500.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/interiors/lb_ceramics_vintazh_vud_9634_518514_0x300.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/interiors/lb_ceramics_vintazh_vud_9634_724185_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/lb_ceramics/vintazh_vud/tovars/lb_ceramics_vintazh_vud_83039_430391_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/tovars/lb_ceramics_vintazh_vud_83040_806443_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/tovars/lb_ceramics_vintazh_vud_83041_161394_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/tovars/lb_ceramics_vintazh_vud_83042_110698_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/tovars/lb_ceramics_vintazh_vud_96488_33082_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/tovars/lb_ceramics_vintazh_vud_96489_722375_300x0.jpg"),
      require("../images/catalog/lb_ceramics/vintazh_vud/tovars/lb_ceramics_vintazh_vud_96490_914506_300x0.jpg"),
    ],
  },
  {
    id: "576",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "LB Ceramics",
    Collection: "Yansson",
    interiors: [require("../images/catalog/noFoto.jpg")],
    tovars: [
      require("../images/catalog/lb_ceramics/yansson/tovars/lb_ceramics_yansson_129285_924362_300x0.jpg"),
    ],
  },
  {
    id: "577",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Adamant blue",
    interiors: [
      require("../images/catalog/lcm/adamant_blue/interiors/lcm_adamant_blue_18303_379580_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "5788",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Agate pheonix",
    interiors: [
      require("../images/catalog/lcm/agate_pheonix/interiors/lcm_agate_pheonix_15654_14112_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "579",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Amazon river",
    interiors: [
      require("../images/catalog/lcm/amazon_river/interiors/lcm_amazon_river_17125_473141_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "580",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Amber agate",
    interiors: [
      require("../images/catalog/lcm/amber_agate/interiors/lcm_amber_agate_15655_58305_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "581",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "American calacatta",
    interiors: [
      require("../images/catalog/lcm/american_calacatta/interiors/lcm_american_calacatta_15656_766465_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "582",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Angel agate",
    interiors: [
      require("../images/catalog/lcm/angel_agate/interiors/lcm_angel_agate_18304_878514_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "583",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Argentina",
    interiors: [
      require("../images/catalog/lcm/argentina/interiors/lcm_argentina_18305_123265_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "584",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Armani marble",
    interiors: [
      require("../images/catalog/lcm/armani_marble/interiors/lcm_armani_marble_15657_544368_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "585",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Atlantic marble",
    interiors: [
      require("../images/catalog/lcm/atlantic_marble/interiors/lcm_atlantic_marble_15658_487896_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "586",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Barcelo",
    interiors: [
      require("../images/catalog/lcm/barcelo/interiors/lcm_barcelo_15659_884579_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "587",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Black sky",
    interiors: [
      require("../images/catalog/lcm/black_sky/interiors/lcm_black_sky_18306_738139_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "588",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Blumarin",
    interiors: [
      require("../images/catalog/lcm/blumarin/interiors/lcm_blumarin_17126_632095_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "589",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Calacatta borghini",
    interiors: [
      require("../images/catalog/lcm/calacatta_borghini/interiors/lcm_calacatta_borghini_15660_92685_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "590",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Calacatta montreal",
    interiors: [
      require("../images/catalog/lcm/calacatta_montreal/interiors/lcm_calacatta_montreal_15661_710763_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "591",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Cascade",
    interiors: [
      require("../images/catalog/lcm/cascade/interiors/lcm_cascade_15662_238353_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "592",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Caspian onyx",
    interiors: [
      require("../images/catalog/lcm/caspian_onyx/interiors/lcm_caspian_onyx_18307_120569_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "593",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Columbia",
    interiors: [
      require("../images/catalog/lcm/columbia/interiors/lcm_columbia_15663_839783_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "594",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Cuba blue",
    interiors: [
      require("../images/catalog/lcm/cuba_blue/interiors/lcm_cuba_blue_17127_447324_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "595",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Dallas",
    interiors: [
      require("../images/catalog/lcm/dallas/interiors/lcm_dallas_15664_901149_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "596",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Davinci",
    interiors: [
      require("../images/catalog/lcm/davinci/interiors/lcm_davinci_17128_738206_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "597",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Domines",
    interiors: [
      require("../images/catalog/lcm/domines/interiors/lcm_domines_15665_280198_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "598",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Florim",
    interiors: [
      require("../images/catalog/lcm/florim/interiors/lcm_florim_15667_919486_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "599",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Grand antique",
    interiors: [
      require("../images/catalog/lcm/grand_antique/interiors/lcm_grand_antique_17129_102457_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "600",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Ice onyx",
    interiors: [
      require("../images/catalog/lcm/ice_onyx/interiors/lcm_ice_onyx_15668_207678_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "601",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Illusion",
    interiors: [
      require("../images/catalog/lcm/illusion/interiors/lcm_illusion_17130_13212_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "602",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Imperial",
    interiors: [
      require("../images/catalog/lcm/imperial/interiors/lcm_imperial_15669_71961_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "603",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Iron",
    interiors: [
      require("../images/catalog/lcm/iron/interiors/lcm_iron_18308_230714_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "604",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Jupiter marmo",
    interiors: [
      require("../images/catalog/lcm/jupiter_marmo/interiors/lcm_jupiter_marmo_18310_174364_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "605",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Karibib fantasy",
    interiors: [
      require("../images/catalog/lcm/karibib_fantasy/interiors/lcm_karibib_fantasy_15670_88539_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "606",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Latina",
    interiors: [
      require("../images/catalog/lcm/latina/interiors/lcm_latina_18311_651781_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "607",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Lenart",
    interiors: [
      require("../images/catalog/lcm/lenart/interiors/lcm_lenart_18312_709342_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "608",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Limestone",
    interiors: [
      require("../images/catalog/lcm/limestone/interiors/lcm_limestone_15671_774626_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "609",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Linari",
    interiors: [
      require("../images/catalog/lcm/linari/interiors/lcm_linari_18314_221202_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "610",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Marmomac",
    interiors: [
      require("../images/catalog/lcm/marmomac/interiors/lcm_marmomac_18318_742119_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "611",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Matrix",
    interiors: [
      require("../images/catalog/lcm/matrix/interiors/lcm_matrix_18315_971247_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "612",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Monaco onyx",
    interiors: [
      require("../images/catalog/lcm/monaco_onyx/interiors/lcm_monaco_onyx_18316_435099_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "613",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Moonstone",
    interiors: [
      require("../images/catalog/lcm/moonstone/interiors/lcm_moonstone_15673_985829_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "614",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Namibia",
    interiors: [
      require("../images/catalog/lcm/namibia/interiors/lcm_namibia_18319_216849_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "615",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Onyx royal",
    interiors: [
      require("../images/catalog/lcm/onyx_royal/interiors/lcm_onyx_royal_15674_355891_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "616",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Onyx smoke",
    interiors: [
      require("../images/catalog/lcm/onyx_smoke/interiors/lcm_onyx_smoke_15675_859053_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "617",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Persia",
    interiors: [
      require("../images/catalog/lcm/persia/interiors/lcm_persia_15676_779535_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "618",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Premiere stone",
    interiors: [
      require("../images/catalog/lcm/premiere_stone/interiors/lcm_premiere_stone_17131_866436_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "619",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Sandy carrara",
    interiors: [
      require("../images/catalog/lcm/sandy_carrara/interiors/lcm_sandy_carrara_15677_627617_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "620",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Sapphire agate",
    interiors: [
      require("../images/catalog/lcm/sapphire_agate/interiors/lcm_sapphire_agate_18320_306061_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "621",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Sirius",
    interiors: [
      require("../images/catalog/lcm/sirius/interiors/lcm_sirius_15678_664751_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "622",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Skyros",
    interiors: [
      require("../images/catalog/lcm/skyros/interiors/lcm_skyros_15679_552395_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "623",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Snow onyx",
    interiors: [
      require("../images/catalog/lcm/snow_onyx/interiors/lcm_snow_onyx_15680_945084_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "624",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "LCM",
    Collection: "Statuario",
    interiors: [
      require("../images/catalog/lcm/statuario/interiors/lcm_statuario_15681_228702_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "625",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Adele arctic",
    interiors: [
      require("../images/catalog/newtrend/adele_arctic/interiors/newtrend_adele_arctic_10133_136608_0x300.jpg"),
      require("../images/catalog/newtrend/adele_arctic/interiors/newtrend_adele_arctic_10133_356346_0x300.jpg"),
      require("../images/catalog/newtrend/adele_arctic/interiors/newtrend_adele_arctic_10133_864580_0x300.jpg"),
      require("../images/catalog/newtrend/adele_arctic/interiors/newtrend_adele_arctic_10133_864580_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/adele_arctic/tovars/newtrend_adele_arctic_86425_857856_300x0.jpg"),
      require("../images/catalog/newtrend/adele_arctic/tovars/newtrend_adele_arctic_86426_451131_300x0.jpg"),
      require("../images/catalog/newtrend/adele_arctic/tovars/newtrend_adele_arctic_86427_148052_300x0.jpg"),
      require("../images/catalog/newtrend/adele_arctic/tovars/newtrend_adele_arctic_86428_611292_300x0.jpg"),
      require("../images/catalog/newtrend/adele_arctic/tovars/newtrend_adele_arctic_86429_413245_300x0.jpg"),
      require("../images/catalog/newtrend/adele_arctic/tovars/newtrend_adele_arctic_86430_770342_300x0.jpg"),
    ],
  },
  {
    id: "626",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Adele latte",
    interiors: [
      require("../images/catalog/newtrend/adele_latte/interiors/newtrend_adele_latte_10134_356924_0x300.jpg"),
      require("../images/catalog/newtrend/adele_latte/interiors/newtrend_adele_latte_10134_356924_0x500.jpg"),
      require("../images/catalog/newtrend/adele_latte/interiors/newtrend_adele_latte_10134_708305_0x300.jpg"),
      require("../images/catalog/newtrend/adele_latte/interiors/newtrend_adele_latte_10134_889919_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/adele_latte/tovars/newtrend_adele_latte_86431_150253_300x0.jpg"),
      require("../images/catalog/newtrend/adele_latte/tovars/newtrend_adele_latte_86432_838537_300x0.jpg"),
      require("../images/catalog/newtrend/adele_latte/tovars/newtrend_adele_latte_86433_29154_300x0.jpg"),
      require("../images/catalog/newtrend/adele_latte/tovars/newtrend_adele_latte_86434_158763_300x0.jpg"),
      require("../images/catalog/newtrend/adele_latte/tovars/newtrend_adele_latte_86435_992389_300x0.jpg"),
      require("../images/catalog/newtrend/adele_latte/tovars/newtrend_adele_latte_86436_335664_300x0.jpg"),
    ],
  },
  {
    id: "627",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Agatha",
    interiors: [
      require("../images/catalog/newtrend/agatha/interiors/newtrend_agatha_10135_176921_0x300.jpg"),
      require("../images/catalog/newtrend/agatha/interiors/newtrend_agatha_10135_231107_0x300.jpg"),
      require("../images/catalog/newtrend/agatha/interiors/newtrend_agatha_10135_561032_0x300.jpg"),
      require("../images/catalog/newtrend/agatha/interiors/newtrend_agatha_10135_74302_0x300.jpg"),
      require("../images/catalog/newtrend/agatha/interiors/newtrend_agatha_10135_959385_0x300.jpg"),
      require("../images/catalog/newtrend/agatha/interiors/newtrend_agatha_10135_959385_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/agatha/tovars/newtrend_agatha_86437_464650_300x0.jpg"),
      require("../images/catalog/newtrend/agatha/tovars/newtrend_agatha_86438_84964_300x0.jpg"),
      require("../images/catalog/newtrend/agatha/tovars/newtrend_agatha_86439_828125_300x0.jpg"),
      require("../images/catalog/newtrend/agatha/tovars/newtrend_agatha_86440_883728_300x0.jpg"),
    ],
  },
  {
    id: "628",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Amadeo",
    interiors: [
      require("../images/catalog/newtrend/amadeo/interiors/newtrend_amadeo_14291_185086_0x300.jpg"),
      require("../images/catalog/newtrend/amadeo/interiors/newtrend_amadeo_14291_185086_0x500.jpg"),
      require("../images/catalog/newtrend/amadeo/interiors/newtrend_amadeo_14291_198990_0x300.jpg"),
      require("../images/catalog/newtrend/amadeo/interiors/newtrend_amadeo_14291_444061_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/amadeo/tovars/newtrend_amadeo_111054_460562_300x0.jpg"),
      require("../images/catalog/newtrend/amadeo/tovars/newtrend_amadeo_111055_385144_300x0.jpg"),
      require("../images/catalog/newtrend/amadeo/tovars/newtrend_amadeo_111056_800151_300x0.jpg"),
      require("../images/catalog/newtrend/amadeo/tovars/newtrend_amadeo_111057_566429_300x0.jpg"),
    ],
  },
  {
    id: "629",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Artwood",
    interiors: [
      require("../images/catalog/newtrend/artwood/interiors/newtrend_artwood_14292_205671_0x300.jpg"),
      require("../images/catalog/newtrend/artwood/interiors/newtrend_artwood_14292_205671_0x500.jpg"),
      require("../images/catalog/newtrend/artwood/interiors/newtrend_artwood_14292_354815_0x300.jpg"),
      require("../images/catalog/newtrend/artwood/interiors/newtrend_artwood_14292_366428_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/artwood/tovars/newtrend_artwood_111058_179028_300x0.jpg"),
      require("../images/catalog/newtrend/artwood/tovars/newtrend_artwood_111059_99238_300x0.jpg"),
    ],
  },
  {
    id: "630",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Artwork",
    interiors: [
      require("../images/catalog/newtrend/artwork/interiors/newtrend_artwork_10136_290955_0x300.jpg"),
      require("../images/catalog/newtrend/artwork/interiors/newtrend_artwork_10136_569985_0x300.jpg"),
      require("../images/catalog/newtrend/artwork/interiors/newtrend_artwork_10136_699752_0x300.jpg"),
      require("../images/catalog/newtrend/artwork/interiors/newtrend_artwork_10136_699752_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/artwork/tovars/newtrend_artwork_86441_372262_300x0.jpg"),
      require("../images/catalog/newtrend/artwork/tovars/newtrend_artwork_86442_898061_300x0.jpg"),
      require("../images/catalog/newtrend/artwork/tovars/newtrend_artwork_86443_366497_300x0.jpg"),
      require("../images/catalog/newtrend/artwork/tovars/newtrend_artwork_86444_181031_300x0.jpg"),
    ],
  },
  {
    id: "631",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Atelier",
    interiors: [
      require("../images/catalog/newtrend/atelier/interiors/newtrend_atelier_10137_512668_0x300.jpg"),
      require("../images/catalog/newtrend/atelier/interiors/newtrend_atelier_10137_512668_0x500.jpg"),
      require("../images/catalog/newtrend/atelier/interiors/newtrend_atelier_10137_543772_0x300.jpg"),
      require("../images/catalog/newtrend/atelier/interiors/newtrend_atelier_10137_826507_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/atelier/tovars/newtrend_atelier_86447_673952_300x0.jpg"),
    ],
  },
  {
    id: "632",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Bang",
    interiors: [
      require("../images/catalog/newtrend/bang/interiors/newtrend_bang_10138_316275_0x300.jpg"),
      require("../images/catalog/newtrend/bang/interiors/newtrend_bang_10138_902517_0x300.jpg"),
      require("../images/catalog/newtrend/bang/interiors/newtrend_bang_10138_902517_0x500.jpg"),
      require("../images/catalog/newtrend/bang/interiors/newtrend_bang_10138_997193_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/bang/tovars/newtrend_bang_86449_606106_300x0.jpg"),
    ],
  },
  {
    id: "633",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Black white",
    interiors: [
      require("../images/catalog/newtrend/black_white/interiors/newtrend_black_white_9790_606418_0x300.jpg"),
      require("../images/catalog/newtrend/black_white/interiors/newtrend_black_white_9790_606418_0x500.jpg"),
      require("../images/catalog/newtrend/black_white/interiors/newtrend_black_white_9790_979751_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/black_white/tovars/newtrend_black_white_84045_791117_300x0.jpg"),
      require("../images/catalog/newtrend/black_white/tovars/newtrend_black_white_84046_328103_300x0.jpg"),
    ],
  },
  {
    id: "634",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Blues",
    interiors: [
      require("../images/catalog/newtrend/blues/interiors/newtrend_blues_14293_581473_0x300.jpg"),
      require("../images/catalog/newtrend/blues/interiors/newtrend_blues_14293_764741_0x300.jpg"),
      require("../images/catalog/newtrend/blues/interiors/newtrend_blues_14293_764741_0x500.jpg"),
      require("../images/catalog/newtrend/blues/interiors/newtrend_blues_14293_854402_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/blues/tovars/newtrend_blues_111060_616112_300x0.jpg"),
      require("../images/catalog/newtrend/blues/tovars/newtrend_blues_111061_103347_300x0.jpg"),
      require("../images/catalog/newtrend/blues/tovars/newtrend_blues_111062_547359_300x0.jpg"),
      require("../images/catalog/newtrend/blues/tovars/newtrend_blues_111063_366744_300x0.jpg"),
      require("../images/catalog/newtrend/blues/tovars/newtrend_blues_111064_370130_300x0.jpg"),
    ],
  },
  {
    id: "635",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Bounty",
    interiors: [
      require("../images/catalog/newtrend/bounty/interiors/newtrend_bounty_10139_446552_0x300.jpg"),
      require("../images/catalog/newtrend/bounty/interiors/newtrend_bounty_10139_459413_0x300.jpg"),
      require("../images/catalog/newtrend/bounty/interiors/newtrend_bounty_10139_459413_0x500.jpg"),
      require("../images/catalog/newtrend/bounty/interiors/newtrend_bounty_10139_945142_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/bounty/tovars/newtrend_bounty_86454_692677_300x0.jpg"),
    ],
  },
  {
    id: "636",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Brazylyan sky",
    interiors: [
      require("../images/catalog/newtrend/brazylyan_sky/interiors/newtrend_brazylyan_sky_14275_165824_0x300.jpg"),
      require("../images/catalog/newtrend/brazylyan_sky/interiors/newtrend_brazylyan_sky_14275_165824_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/brazylyan_sky/tovars/newtrend_brazylyan_sky_111015_793884_300x0.jpg"),
    ],
  },
  {
    id: "637",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Candy",
    interiors: [
      require("../images/catalog/newtrend/candy/interiors/newtrend_candy_9944_136676_0x300.jpg"),
      require("../images/catalog/newtrend/candy/interiors/newtrend_candy_9944_375964_0x300.jpg"),
      require("../images/catalog/newtrend/candy/interiors/newtrend_candy_9944_580811_0x300.jpg"),
      require("../images/catalog/newtrend/candy/interiors/newtrend_candy_9944_580811_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/candy/tovars/newtrend_candy_85134_950910_300x0.jpg"),
      require("../images/catalog/newtrend/candy/tovars/newtrend_candy_85135_718792_300x0.jpg"),
      require("../images/catalog/newtrend/candy/tovars/newtrend_candy_85136_930292_300x0.jpg"),
      require("../images/catalog/newtrend/candy/tovars/newtrend_candy_85137_414382_300x0.jpg"),
      require("../images/catalog/newtrend/candy/tovars/newtrend_candy_85138_577808_300x0.jpg"),
    ],
  },
  {
    id: "638",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Chicago",
    interiors: [
      require("../images/catalog/newtrend/chicago/interiors/newtrend_chicago_10140_190683_0x300.jpg"),
      require("../images/catalog/newtrend/chicago/interiors/newtrend_chicago_10140_273338_0x300.jpg"),
      require("../images/catalog/newtrend/chicago/interiors/newtrend_chicago_10140_355728_0x300.jpg"),
      require("../images/catalog/newtrend/chicago/interiors/newtrend_chicago_10140_355728_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/chicago/tovars/newtrend_chicago_86456_887420_300x0.jpg"),
      require("../images/catalog/newtrend/chicago/tovars/newtrend_chicago_86457_407295_300x0.jpg"),
      require("../images/catalog/newtrend/chicago/tovars/newtrend_chicago_86458_143809_300x0.jpg"),
    ],
  },
  {
    id: "639",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Chicago gray",
    interiors: [
      require("../images/catalog/newtrend/chicago_gray/interiors/newtrend_chicago_gray_10141_402346_0x300.jpg"),
      require("../images/catalog/newtrend/chicago_gray/interiors/newtrend_chicago_gray_10141_495847_0x300.jpg"),
      require("../images/catalog/newtrend/chicago_gray/interiors/newtrend_chicago_gray_10141_89966_0x300.jpg"),
      require("../images/catalog/newtrend/chicago_gray/interiors/newtrend_chicago_gray_10141_89966_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/chicago_gray/tovars/newtrend_chicago_gray_86459_35472_300x0.jpg"),
      require("../images/catalog/newtrend/chicago_gray/tovars/newtrend_chicago_gray_86460_18587_300x0.jpg"),
      require("../images/catalog/newtrend/chicago_gray/tovars/newtrend_chicago_gray_86461_547055_300x0.jpg"),
      require("../images/catalog/newtrend/chicago_gray/tovars/newtrend_chicago_gray_86462_795816_300x0.jpg"),
    ],
  },
  {
    id: "640",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Congo",
    interiors: [
      require("../images/catalog/newtrend/congo/interiors/newtrend_congo_14294_289604_0x300.jpg"),
      require("../images/catalog/newtrend/congo/interiors/newtrend_congo_14294_337565_0x300.jpg"),
      require("../images/catalog/newtrend/congo/interiors/newtrend_congo_14294_820174_0x300.jpg"),
      require("../images/catalog/newtrend/congo/interiors/newtrend_congo_14294_820174_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/congo/tovars/newtrend_congo_111065_63067_300x0.jpg"),
      require("../images/catalog/newtrend/congo/tovars/newtrend_congo_111066_352855_300x0.jpg"),
      require("../images/catalog/newtrend/congo/tovars/newtrend_congo_111067_878668_300x0.jpg"),
      require("../images/catalog/newtrend/congo/tovars/newtrend_congo_111068_175486_300x0.jpg"),
      require("../images/catalog/newtrend/congo/tovars/newtrend_congo_111069_598871_300x0.jpg"),
    ],
  },
  {
    id: "641",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Corfu",
    interiors: [
      require("../images/catalog/newtrend/corfu/interiors/newtrend_corfu_9791_156058_0x300.jpg"),
      require("../images/catalog/newtrend/corfu/interiors/newtrend_corfu_9791_156058_0x500.jpg"),
      require("../images/catalog/newtrend/corfu/interiors/newtrend_corfu_9791_757670_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/corfu/tovars/newtrend_corfu_84047_989271_300x0.jpg"),
      require("../images/catalog/newtrend/corfu/tovars/newtrend_corfu_84049_524370_300x0.jpg"),
    ],
  },
  {
    id: "642",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Corten",
    interiors: [
      require("../images/catalog/newtrend/corten/interiors/newtrend_corten_16296_138513_0x300.jpg"),
      require("../images/catalog/newtrend/corten/interiors/newtrend_corten_16296_138513_0x500.jpg"),
      require("../images/catalog/newtrend/corten/interiors/newtrend_corten_16296_683645_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/corten/tovars/newtrend_corten_111016_299086_300x0.jpg"),
      require("../images/catalog/newtrend/corten/tovars/newtrend_corten_111017_59681_300x0.jpg"),
    ],
  },
  {
    id: "643",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Creta",
    interiors: [
      require("../images/catalog/newtrend/creta/interiors/newtrend_creta_9792_457843_0x300.jpg"),
      require("../images/catalog/newtrend/creta/interiors/newtrend_creta_9792_81802_0x300.jpg"),
      require("../images/catalog/newtrend/creta/interiors/newtrend_creta_9792_81802_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/creta/tovars/newtrend_creta_84051_138644_300x0.jpg"),
      require("../images/catalog/newtrend/creta/tovars/newtrend_creta_84052_66531_300x0.jpg"),
    ],
  },
  {
    id: "644",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Dacar",
    interiors: [
      require("../images/catalog/newtrend/dacar/interiors/newtrend_dacar_14295_164911_0x300.jpg"),
      require("../images/catalog/newtrend/dacar/interiors/newtrend_dacar_14295_164911_0x500.jpg"),
      require("../images/catalog/newtrend/dacar/interiors/newtrend_dacar_14295_448004_0x300.jpg"),
      require("../images/catalog/newtrend/dacar/interiors/newtrend_dacar_14295_943067_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/dacar/tovars/newtrend_dacar_111070_207412_300x0.jpg"),
    ],
  },
  {
    id: "645",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Dance",
    interiors: [
      require("../images/catalog/newtrend/dance/interiors/newtrend_dance_9793_386489_0x300.jpg"),
      require("../images/catalog/newtrend/dance/interiors/newtrend_dance_9793_44465_0x300.jpg"),
      require("../images/catalog/newtrend/dance/interiors/newtrend_dance_9793_461344_0x300.jpg"),
      require("../images/catalog/newtrend/dance/interiors/newtrend_dance_9793_461344_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/dance/tovars/newtrend_dance_84054_234073_300x0.jpg"),
    ],
  },
  {
    id: "646",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Dax",
    interiors: [
      require("../images/catalog/newtrend/dax/interiors/newtrend_dax_10248_106627_0x300.jpg"),
      require("../images/catalog/newtrend/dax/interiors/newtrend_dax_10248_556316_0x300.jpg"),
      require("../images/catalog/newtrend/dax/interiors/newtrend_dax_10248_556316_0x500.jpg"),
      require("../images/catalog/newtrend/dax/interiors/newtrend_dax_10248_657661_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/dax/tovars/newtrend_dax_87228_897200_300x0.jpg"),
      require("../images/catalog/newtrend/dax/tovars/newtrend_dax_87229_302318_300x0.jpg"),
      require("../images/catalog/newtrend/dax/tovars/newtrend_dax_87230_328127_300x0.jpg"),
      require("../images/catalog/newtrend/dax/tovars/newtrend_dax_87231_100231_300x0.jpg"),
    ],
  },
  {
    id: "647",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Destone",
    interiors: [
      require("../images/catalog/newtrend/destone/interiors/newtrend_destone_14276_497973_0x300.jpg"),
      require("../images/catalog/newtrend/destone/interiors/newtrend_destone_14276_497973_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/destone/tovars/newtrend_destone_111018_924318_300x0.jpg"),
      require("../images/catalog/newtrend/destone/tovars/newtrend_destone_111019_458119_300x0.jpg"),
    ],
  },
  {
    id: "648",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Dover",
    interiors: [
      require("../images/catalog/newtrend/dover/interiors/newtrend_dover_10415_335651_0x300.jpg"),
      require("../images/catalog/newtrend/dover/interiors/newtrend_dover_10415_795557_0x300.jpg"),
      require("../images/catalog/newtrend/dover/interiors/newtrend_dover_10415_905055_0x300.jpg"),
      require("../images/catalog/newtrend/dover/interiors/newtrend_dover_10415_905055_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/dover/tovars/newtrend_dover_88902_285384_300x0.jpg"),
      require("../images/catalog/newtrend/dover/tovars/newtrend_dover_88903_254099_300x0.jpg"),
      require("../images/catalog/newtrend/dover/tovars/newtrend_dover_88904_850587_300x0.jpg"),
      require("../images/catalog/newtrend/dover/tovars/newtrend_dover_88905_525563_300x0.jpg"),
    ],
  },
  {
    id: "649",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Emerald",
    interiors: [
      require("../images/catalog/newtrend/emerald/interiors/newtrend_emerald_9946_817923_0x300.jpg"),
      require("../images/catalog/newtrend/emerald/interiors/newtrend_emerald_9946_817923_0x500.jpg"),
      require("../images/catalog/newtrend/emerald/interiors/newtrend_emerald_9946_854578_0x300.jpg"),
      require("../images/catalog/newtrend/emerald/interiors/newtrend_emerald_9946_989711_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/emerald/tovars/newtrend_emerald_85143_27501_300x0.jpg"),
      require("../images/catalog/newtrend/emerald/tovars/newtrend_emerald_85144_455690_300x0.jpg"),
      require("../images/catalog/newtrend/emerald/tovars/newtrend_emerald_85145_329450_300x0.jpg"),
      require("../images/catalog/newtrend/emerald/tovars/newtrend_emerald_85146_502633_300x0.jpg"),
    ],
  },
  {
    id: "650",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Essense",
    interiors: [
      require("../images/catalog/newtrend/essense/interiors/newtrend_essense_10142_104090_0x300.jpg"),
      require("../images/catalog/newtrend/essense/interiors/newtrend_essense_10142_209349_0x300.jpg"),
      require("../images/catalog/newtrend/essense/interiors/newtrend_essense_10142_246192_0x300.jpg"),
      require("../images/catalog/newtrend/essense/interiors/newtrend_essense_10142_845121_0x300.jpg"),
      require("../images/catalog/newtrend/essense/interiors/newtrend_essense_10142_855031_0x300.jpg"),
      require("../images/catalog/newtrend/essense/interiors/newtrend_essense_10142_855031_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/essense/tovars/newtrend_essense_84056_821923_300x0.jpg"),
      require("../images/catalog/newtrend/essense/tovars/newtrend_essense_84057_379969_300x0.jpg"),
      require("../images/catalog/newtrend/essense/tovars/newtrend_essense_86463_158842_300x0.jpg"),
      require("../images/catalog/newtrend/essense/tovars/newtrend_essense_86464_385592_300x0.jpg"),
      require("../images/catalog/newtrend/essense/tovars/newtrend_essense_86465_814972_300x0.jpg"),
      require("../images/catalog/newtrend/essense/tovars/newtrend_essense_86466_307606_300x0.jpg"),
    ],
  },
  {
    id: "651",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Firenze",
    interiors: [
      require("../images/catalog/newtrend/firenze/interiors/newtrend_firenze_14277_236657_0x300.jpg"),
      require("../images/catalog/newtrend/firenze/interiors/newtrend_firenze_14277_236657_0x500.jpg"),
      require("../images/catalog/newtrend/firenze/interiors/newtrend_firenze_14277_65432_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/firenze/tovars/newtrend_firenze_111020_750632_300x0.jpg"),
      require("../images/catalog/newtrend/firenze/tovars/newtrend_firenze_111021_276781_300x0.jpg"),
    ],
  },
  {
    id: "652",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Fly",
    interiors: [
      require("../images/catalog/newtrend/fly/interiors/newtrend_fly_10132_116448_0x300.jpg"),
      require("../images/catalog/newtrend/fly/interiors/newtrend_fly_10132_701743_0x300.jpg"),
      require("../images/catalog/newtrend/fly/interiors/newtrend_fly_10132_701743_0x500.jpg"),
      require("../images/catalog/newtrend/fly/interiors/newtrend_fly_10132_713501_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/fly/tovars/newtrend_fly_86418_444181_300x0.jpg"),
      require("../images/catalog/newtrend/fly/tovars/newtrend_fly_86421_609826_300x0.jpg"),
    ],
  },
  {
    id: "653",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Garret",
    interiors: [
      require("../images/catalog/newtrend/garret/interiors/newtrend_garret_10144_555697_0x300.jpg"),
      require("../images/catalog/newtrend/garret/interiors/newtrend_garret_10144_958671_0x300.jpg"),
      require("../images/catalog/newtrend/garret/interiors/newtrend_garret_10144_961480_0x300.jpg"),
      require("../images/catalog/newtrend/garret/interiors/newtrend_garret_10144_961480_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/garret/tovars/newtrend_garret_86469_377982_300x0.jpg"),
      require("../images/catalog/newtrend/garret/tovars/newtrend_garret_86470_150636_300x0.jpg"),
      require("../images/catalog/newtrend/garret/tovars/newtrend_garret_86471_762257_300x0.jpg"),
    ],
  },
  {
    id: "654",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Gemstone",
    interiors: [
      require("../images/catalog/newtrend/gemstone/interiors/newtrend_gemstone_10417_234244_0x300.jpg"),
      require("../images/catalog/newtrend/gemstone/interiors/newtrend_gemstone_10417_829951_0x300.jpg"),
      require("../images/catalog/newtrend/gemstone/interiors/newtrend_gemstone_10417_936044_0x300.jpg"),
      require("../images/catalog/newtrend/gemstone/interiors/newtrend_gemstone_10417_936044_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/gemstone/tovars/newtrend_gemstone_88908_749587_300x0.jpg"),
      require("../images/catalog/newtrend/gemstone/tovars/newtrend_gemstone_88909_815995_300x0.jpg"),
      require("../images/catalog/newtrend/gemstone/tovars/newtrend_gemstone_88910_178828_300x0.jpg"),
      require("../images/catalog/newtrend/gemstone/tovars/newtrend_gemstone_88911_265921_300x0.jpg"),
    ],
  },
  {
    id: "655",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Invisible",
    interiors: [
      require("../images/catalog/newtrend/invisible/interiors/newtrend_invisible_14278_435201_0x300.jpg"),
      require("../images/catalog/newtrend/invisible/interiors/newtrend_invisible_14278_435201_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/invisible/tovars/newtrend_invisible_111022_515708_300x0.jpg"),
    ],
  },
  {
    id: "656",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Konor",
    interiors: [
      require("../images/catalog/newtrend/konor/interiors/newtrend_konor_10708_312580_0x300.jpg"),
      require("../images/catalog/newtrend/konor/interiors/newtrend_konor_10708_552506_0x300.jpg"),
      require("../images/catalog/newtrend/konor/interiors/newtrend_konor_10708_899602_0x300.jpg"),
      require("../images/catalog/newtrend/konor/interiors/newtrend_konor_10708_899602_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/konor/tovars/newtrend_konor_84043_391781_300x0.jpg"),
      require("../images/catalog/newtrend/konor/tovars/newtrend_konor_90585_482815_300x0.jpg"),
      require("../images/catalog/newtrend/konor/tovars/newtrend_konor_90586_484046_300x0.jpg"),
      require("../images/catalog/newtrend/konor/tovars/newtrend_konor_90587_468848_300x0.jpg"),
    ],
  },
  {
    id: "657",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Laberta beige",
    interiors: [
      require("../images/catalog/newtrend/laberta_beige/interiors/newtrend_laberta_beige_9797_953466_0x300.jpg"),
      require("../images/catalog/newtrend/laberta_beige/interiors/newtrend_laberta_beige_9797_997906_0x300.jpg"),
      require("../images/catalog/newtrend/laberta_beige/interiors/newtrend_laberta_beige_9797_997906_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/laberta_beige/tovars/newtrend_laberta_beige_84063_484808_300x0.jpg"),
      require("../images/catalog/newtrend/laberta_beige/tovars/newtrend_laberta_beige_84064_316047_300x0.jpg"),
    ],
  },
  {
    id: "658",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Manhattan",
    interiors: [
      require("../images/catalog/newtrend/manhattan/interiors/newtrend_manhattan_10147_264002_0x300.jpg"),
      require("../images/catalog/newtrend/manhattan/interiors/newtrend_manhattan_10147_315595_0x300.jpg"),
      require("../images/catalog/newtrend/manhattan/interiors/newtrend_manhattan_10147_98089_0x300.jpg"),
      require("../images/catalog/newtrend/manhattan/interiors/newtrend_manhattan_10147_98089_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/manhattan/tovars/newtrend_manhattan_86480_452948_300x0.jpg"),
      require("../images/catalog/newtrend/manhattan/tovars/newtrend_manhattan_86481_968762_300x0.jpg"),
      require("../images/catalog/newtrend/manhattan/tovars/newtrend_manhattan_86482_645986_300x0.jpg"),
    ],
  },
  {
    id: "659",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Minori",
    interiors: [
      require("../images/catalog/newtrend/minori/interiors/newtrend_minori_10419_310592_0x300.jpg"),
      require("../images/catalog/newtrend/minori/interiors/newtrend_minori_10419_420702_0x300.jpg"),
      require("../images/catalog/newtrend/minori/interiors/newtrend_minori_10419_420702_0x500.jpg"),
      require("../images/catalog/newtrend/minori/interiors/newtrend_minori_10419_92202_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/minori/tovars/newtrend_minori_88916_212340_300x0.jpg"),
      require("../images/catalog/newtrend/minori/tovars/newtrend_minori_88918_31014_300x0.jpg"),
    ],
  },
  {
    id: "660",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Montana",
    interiors: [
      require("../images/catalog/newtrend/montana/interiors/newtrend_montana_9801_143854_0x300.jpg"),
      require("../images/catalog/newtrend/montana/interiors/newtrend_montana_9801_143854_0x500.jpg"),
      require("../images/catalog/newtrend/montana/interiors/newtrend_montana_9801_402032_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/montana/tovars/newtrend_montana_84071_791138_300x0.jpg"),
      require("../images/catalog/newtrend/montana/tovars/newtrend_montana_84072_969156_300x0.jpg"),
    ],
  },
  {
    id: "661",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Neil",
    interiors: [
      require("../images/catalog/newtrend/neil/interiors/newtrend_neil_15029_236986_0x300.jpg"),
      require("../images/catalog/newtrend/neil/interiors/newtrend_neil_15029_236986_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/neil/tovars/newtrend_neil_113833_76668_300x0.jpg"),
    ],
  },
  {
    id: "662",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Paintwood",
    interiors: [
      require("../images/catalog/newtrend/paintwood/interiors/newtrend_paintwood_9803_51205_0x300.jpg"),
      require("../images/catalog/newtrend/paintwood/interiors/newtrend_paintwood_9803_644234_0x300.jpg"),
      require("../images/catalog/newtrend/paintwood/interiors/newtrend_paintwood_9803_644234_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/paintwood/tovars/newtrend_paintwood_84075_527437_300x0.jpg"),
      require("../images/catalog/newtrend/paintwood/tovars/newtrend_paintwood_84076_478568_300x0.jpg"),
    ],
  },
  {
    id: "663",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Paintwood brown",
    interiors: [
      require("../images/catalog/newtrend/paintwood_brown/interiors/newtrend_paintwood_brown_9804_336696_0x300.jpg"),
      require("../images/catalog/newtrend/paintwood_brown/interiors/newtrend_paintwood_brown_9804_336696_0x500.jpg"),
      require("../images/catalog/newtrend/paintwood_brown/interiors/newtrend_paintwood_brown_9804_712823_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/paintwood_brown/tovars/newtrend_paintwood_brown_84077_37887_300x0.jpg"),
      require("../images/catalog/newtrend/paintwood_brown/tovars/newtrend_paintwood_brown_84078_576062_300x0.jpg"),
    ],
  },
  {
    id: "664",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Play",
    interiors: [
      require("../images/catalog/newtrend/play/interiors/newtrend_play_14296_724520_0x300.jpg"),
      require("../images/catalog/newtrend/play/interiors/newtrend_play_14296_724520_0x500.jpg"),
      require("../images/catalog/newtrend/play/interiors/newtrend_play_14296_796961_0x300.jpg"),
      require("../images/catalog/newtrend/play/interiors/newtrend_play_14296_918180_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/play/tovars/newtrend_play_111071_570150_300x0.jpg"),
      require("../images/catalog/newtrend/play/tovars/newtrend_play_111072_409210_300x0.jpg"),
      require("../images/catalog/newtrend/play/tovars/newtrend_play_111073_383071_300x0.jpg"),
      require("../images/catalog/newtrend/play/tovars/newtrend_play_111074_747192_300x0.jpg"),
      require("../images/catalog/newtrend/play/tovars/newtrend_play_111075_204753_300x0.jpg"),
      require("../images/catalog/newtrend/play/tovars/newtrend_play_111076_260937_300x0.jpg"),
    ],
  },
  {
    id: "665",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Prima",
    interiors: [
      require("../images/catalog/newtrend/prima/interiors/newtrend_prima_9805_462347_0x300.jpg"),
      require("../images/catalog/newtrend/prima/interiors/newtrend_prima_9805_462347_0x500.jpg"),
      require("../images/catalog/newtrend/prima/interiors/newtrend_prima_9805_937568_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/prima/tovars/newtrend_prima_84079_498952_300x0.jpg"),
      require("../images/catalog/newtrend/prima/tovars/newtrend_prima_84080_368968_300x0.jpg"),
    ],
  },
  {
    id: "666",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Ringwood brown",
    interiors: [
      require("../images/catalog/newtrend/ringwood_brown/interiors/newtrend_ringwood_brown_9806_537268_0x300.jpg"),
      require("../images/catalog/newtrend/ringwood_brown/interiors/newtrend_ringwood_brown_9806_537268_0x500.jpg"),
      require("../images/catalog/newtrend/ringwood_brown/interiors/newtrend_ringwood_brown_9806_665602_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/ringwood_brown/tovars/newtrend_ringwood_brown_84081_164338_300x0.jpg"),
      require("../images/catalog/newtrend/ringwood_brown/tovars/newtrend_ringwood_brown_84082_354093_300x0.jpg"),
    ],
  },
  {
    id: "667",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Ringwood crema",
    interiors: [
      require("../images/catalog/newtrend/ringwood_crema/interiors/newtrend_ringwood_crema_9807_192929_0x300.jpg"),
      require("../images/catalog/newtrend/ringwood_crema/interiors/newtrend_ringwood_crema_9807_192929_0x500.jpg"),
      require("../images/catalog/newtrend/ringwood_crema/interiors/newtrend_ringwood_crema_9807_287339_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/ringwood_crema/tovars/newtrend_ringwood_crema_84083_654725_300x0.jpg"),
      require("../images/catalog/newtrend/ringwood_crema/tovars/newtrend_ringwood_crema_84084_247670_300x0.jpg"),
    ],
  },
  {
    id: "668",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Statuario",
    interiors: [
      require("../images/catalog/newtrend/statuario/interiors/newtrend_statuario_14281_168398_0x300.jpg"),
      require("../images/catalog/newtrend/statuario/interiors/newtrend_statuario_14281_344737_0x300.jpg"),
      require("../images/catalog/newtrend/statuario/interiors/newtrend_statuario_14281_344737_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/statuario/tovars/newtrend_statuario_111028_986110_300x0.jpg"),
      require("../images/catalog/newtrend/statuario/tovars/newtrend_statuario_111029_518537_300x0.jpg"),
    ],
  },
  {
    id: "669",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Street",
    interiors: [
      require("../images/catalog/newtrend/street/interiors/newtrend_street_14282_615501_0x300.jpg"),
      require("../images/catalog/newtrend/street/interiors/newtrend_street_14282_615501_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/street/tovars/newtrend_street_111030_122418_300x0.jpg"),
    ],
  },
  {
    id: "670",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Teona",
    interiors: [
      require("../images/catalog/newtrend/teona/interiors/newtrend_teona_9809_310523_0x300.jpg"),
      require("../images/catalog/newtrend/teona/interiors/newtrend_teona_9809_933529_0x300.jpg"),
      require("../images/catalog/newtrend/teona/interiors/newtrend_teona_9809_933529_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/teona/tovars/newtrend_teona_84087_653267_300x0.jpg"),
      require("../images/catalog/newtrend/teona/tovars/newtrend_teona_84088_122823_300x0.jpg"),
    ],
  },
  {
    id: "671",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Venice",
    interiors: [
      require("../images/catalog/newtrend/venice/interiors/newtrend_venice_14297_829771_0x300.jpg"),
      require("../images/catalog/newtrend/venice/interiors/newtrend_venice_14297_829771_0x500.jpg"),
      require("../images/catalog/newtrend/venice/interiors/newtrend_venice_14297_855327_0x300.jpg"),
      require("../images/catalog/newtrend/venice/interiors/newtrend_venice_14297_961940_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/venice/tovars/newtrend_venice_111077_80216_300x0.jpg"),
      require("../images/catalog/newtrend/venice/tovars/newtrend_venice_111078_663139_300x0.jpg"),
      require("../images/catalog/newtrend/venice/tovars/newtrend_venice_111079_910234_300x0.jpg"),
      require("../images/catalog/newtrend/venice/tovars/newtrend_venice_111080_397745_300x0.jpg"),
    ],
  },
  {
    id: "672",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Vincenzo",
    interiors: [
      require("../images/catalog/newtrend/vincenzo/interiors/newtrend_vincenzo_10422_110799_0x300.jpg"),
      require("../images/catalog/newtrend/vincenzo/interiors/newtrend_vincenzo_10422_656513_0x300.jpg"),
      require("../images/catalog/newtrend/vincenzo/interiors/newtrend_vincenzo_10422_656513_0x500.jpg"),
      require("../images/catalog/newtrend/vincenzo/interiors/newtrend_vincenzo_10422_780947_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/vincenzo/tovars/newtrend_vincenzo_83759_996680_300x0.jpg"),
      require("../images/catalog/newtrend/vincenzo/tovars/newtrend_vincenzo_88927_518256_300x0.jpg"),
      require("../images/catalog/newtrend/vincenzo/tovars/newtrend_vincenzo_88928_101725_300x0.jpg"),
      require("../images/catalog/newtrend/vincenzo/tovars/newtrend_vincenzo_88929_891396_300x0.jpg"),
    ],
  },
  {
    id: "673",
    Сountry: "Россия",
    Category: "Plitka",
    Size: ["20x60"],
    Name: "NewTrend",
    Collection: "Volcano",
    interiors: [
      require("../images/catalog/newtrend/volcano/interiors/newtrend_volcano_14298_227035.jpg"),
      require("../images/catalog/newtrend/volcano/interiors/newtrend_volcano_14298_769660.jpg"),
      require("../images/catalog/newtrend/volcano/interiors/newtrend_volcano_14298_892318.jpg"),
    ],
    tovars: [
      require("../images/catalog/newtrend/volcano/tovars/newtrend_volcano_111081_271511_300x0.jpg"),
      require("../images/catalog/newtrend/volcano/tovars/newtrend_volcano_111084_783168_300x0.jpg"),
      require("../images/catalog/newtrend/volcano/tovars/newtrend_volcano_111082_293979_500x0.jpg"),
      require("../images/catalog/newtrend/volcano/tovars/newtrend_volcano_111083_828708_500x0.jpg"),
    ],
  },

  {
    id: "674",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Abside ice",
    interiors: [
      require("../images/catalog/primavera/abside_ice/interiors/primavera_abside_ice_18177_672073_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/abside_ice/tovars/primavera_abside_ice_126589_400386_500x0.jpg"),
    ],
  },
  {
    id: "675",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Acacia",
    interiors: [
      require("../images/catalog/primavera/acacia/interiors/primavera_acacia_15235_821305_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/acacia/tovars/primavera_acacia_114943_253175_500x0.jpg"),
    ],
  },
  {
    id: "676",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Acothly",
    interiors: [
      require("../images/catalog/primavera/acothly/interiors/primavera_acothly_18162_971516_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/acothly/tovars/primavera_acothly_126538_573843_500x0.jpg"),
      require("../images/catalog/primavera/acothly/tovars/primavera_acothly_126539_897482_500x0.jpg"),
      require("../images/catalog/primavera/acothly/tovars/primavera_acothly_126540_439758_500x0.jpg"),
      require("../images/catalog/primavera/acothly/tovars/primavera_acothly_126541_782254_500x0.jpg"),
    ],
  },
  {
    id: "677",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Alcor",
    interiors: [
      require("../images/catalog/primavera/alcor/interiors/primavera_alcor_15572_942293_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/alcor/tovars/primavera_alcor_116177_891483_500x0.jpg"),
      require("../images/catalog/primavera/alcor/tovars/primavera_alcor_116178_307588_500x0.jpg"),
      require("../images/catalog/primavera/alcor/tovars/tyan_shan_titaniya_114939_728412_500x0.jpg"),
      require("../images/catalog/primavera/alcor/tovars/tyan_shan_titaniya_114940_982506_500x0.jpg"),
      require("../images/catalog/primavera/alcor/tovars/tyan_shan_titaniya_114941_676319_500x0.jpg"),
      require("../images/catalog/primavera/alcor/tovars/tyan_shan_titaniya_114942_680201_500x0.jpg"),
    ],
  },
  {
    id: "678",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Allure",
    interiors: [
      require("../images/catalog/primavera/allure/interiors/primavera_allure_16052_48615_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/allure/tovars/primavera_allure_118886_377280_500x0.jpg"),
      require("../images/catalog/primavera/allure/tovars/primavera_allure_118887_409523_500x0.jpg"),
      require("../images/catalog/primavera/allure/tovars/primavera_allure_118888_526215_500x0.jpg"),
      require("../images/catalog/primavera/allure/tovars/primavera_allure_118889_787516_500x0.jpg"),
    ],
  },
  {
    id: "679",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Almond cascais",
    interiors: [
      require("../images/catalog/primavera/almond_cascais/interiors/primavera_almond_cascais_14962_32054.jpg"),
      require("../images/catalog/primavera/almond_cascais/interiors/primavera_almond_cascais_14962_462857_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/almond_cascais/tovars/primavera_almond_cascais_113673_977807_500x0.jpg"),
      require("../images/catalog/primavera/almond_cascais/tovars/primavera_almond_cascais_113674_213089_500x0.jpg"),
    ],
  },
  {
    id: "680",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Almond light grey",
    interiors: [
      require("../images/catalog/primavera/almond_light_grey/interiors/primavera_almond_light_grey_18178_618247_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/almond_light_grey/tovars/primavera_almond_light_grey_126591_480899_500x0.jpg"),
    ],
  },
  {
    id: "681",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Alzirr",
    interiors: [
      require("../images/catalog/primavera/alzirr/interiors/primavera_alzirr_15573_96882_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/alzirr/tovars/primavera_alzirr_116179_387645_500x0.jpg"),
      require("../images/catalog/primavera/alzirr/tovars/primavera_alzirr_116180_471781_500x0.jpg"),
      require("../images/catalog/primavera/alzirr/tovars/primavera_alzirr_116181_643497_500x0.jpg"),
      require("../images/catalog/primavera/alzirr/tovars/primavera_alzirr_116182_176031_500x0.jpg"),
    ],
  },
  {
    id: "682",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Amore beige",
    interiors: [
      require("../images/catalog/primavera/amore_beige/interiors/primavera_amore_beige_16053_386258_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/amore_beige/tovars/primavera_amore_beige_118890_490105_500x0.jpg"),
      require("../images/catalog/primavera/amore_beige/tovars/primavera_amore_beige_118891_818070_500x0.jpg"),
      require("../images/catalog/primavera/amore_beige/tovars/primavera_amore_beige_118892_768168_500x0.jpg"),
      require("../images/catalog/primavera/amore_beige/tovars/primavera_amore_beige_118893_84239_500x0.jpg"),
    ],
  },
  {
    id: "683",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Amore silver",
    interiors: [
      require("../images/catalog/primavera/amore_silver/interiors/primavera_amore_silver_16054_848518_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/amore_silver/tovars/primavera_amore_silver_118895_254704_500x0.jpg"),
      require("../images/catalog/primavera/amore_silver/tovars/primavera_amore_silver_118896_954037_500x0.jpg"),
      require("../images/catalog/primavera/amore_silver/tovars/primavera_amore_silver_118897_568370_500x0.jpg"),
      require("../images/catalog/primavera/amore_silver/tovars/primavera_amore_silver_118898_826733_500x0.jpg"),
    ],
  },
  {
    id: "684",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Antares",
    interiors: [
      require("../images/catalog/primavera/antares/interiors/primavera_antares_15574_230711_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/antares/tovars/primavera_antares_116183_800728_500x0.jpg"),
      require("../images/catalog/primavera/antares/tovars/primavera_antares_116184_928486_500x0.jpg"),
      require("../images/catalog/primavera/antares/tovars/primavera_antares_116185_13617_500x0.jpg"),
      require("../images/catalog/primavera/antares/tovars/primavera_antares_116186_550245_500x0.jpg"),
    ],
  },
  {
    id: "685",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Ardesia grafito",
    interiors: [
      require("../images/catalog/primavera/ardesia_grafito/interiors/primavera_ardesia_grafito_14917_557934.jpg"),
      require("../images/catalog/primavera/ardesia_grafito/interiors/primavera_ardesia_grafito_14917_608813_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/ardesia_grafito/tovars/primavera_ardesia_grafito_113602_932292_500x0.jpg"),
      require("../images/catalog/primavera/ardesia_grafito/tovars/primavera_ardesia_grafito_113603_56338_500x0.jpg"),
    ],
  },
  {
    id: "686",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Arena white",
    interiors: [
      require("../images/catalog/primavera/arena_white/interiors/primavera_arena_white_14916_470242_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/arena_white/tovars/primavera_arena_white_113601_216120_500x0.jpg"),
    ],
  },
  {
    id: "687",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Arseno",
    interiors: [
      require("../images/catalog/primavera/arseno/interiors/primavera_arseno_15236_885837_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/arseno/tovars/primavera_arseno_114944_712224_500x0.jpg"),
      require("../images/catalog/primavera/arseno/tovars/primavera_arseno_114945_897478_500x0.jpg"),
      require("../images/catalog/primavera/arseno/tovars/primavera_arseno_114946_487664_500x0.jpg"),
      require("../images/catalog/primavera/arseno/tovars/primavera_arseno_114947_417480_500x0.jpg"),
    ],
  },
  {
    id: "688",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Atago",
    interiors: [
      require("../images/catalog/primavera/atago/interiors/primavera_atago_14919_129304_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "689",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Ayton",
    interiors: [
      require("../images/catalog/primavera/ayton/interiors/primavera_ayton_15185_599621_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/ayton/tovars/primavera_ayton_114848_414190_500x0.jpg"),
      require("../images/catalog/primavera/ayton/tovars/primavera_ayton_114849_43136_500x0.jpg"),
      require("../images/catalog/primavera/ayton/tovars/primavera_ayton_114850_270246_500x0.jpg"),
      require("../images/catalog/primavera/ayton/tovars/primavera_ayton_114851_251035_500x0.jpg"),
    ],
  },
  {
    id: "690",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Balial",
    interiors: [
      require("../images/catalog/primavera/balial/interiors/primavera_balial_14925_415241_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "691",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Beira stone",
    interiors: [
      require("../images/catalog/primavera/beira_stone/interiors/primavera_beira_stone_17865_780910_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "692",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Belfast",
    interiors: [
      require("../images/catalog/primavera/belfast/interiors/primavera_belfast_15238_965730_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/belfast/tovars/primavera_belfast_114950_907211_500x0.jpg"),
    ],
  },
  {
    id: "693",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Bellevue",
    interiors: [
      require("../images/catalog/primavera/bellevue/interiors/primavera_bellevue_15239_307641_0x300.jpg"),
      require("../images/catalog/primavera/bellevue/interiors/primavera_bellevue_18151_987543.jpg"),
      require("../images/catalog/primavera/bellevue/interiors/primavera_bellevue_18151_987543_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/bellevue/tovars/primavera_bellevue_126514_445983_500x0.jpg"),
    ],
  },
  {
    id: "694",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Bellevue grit granula",
    interiors: [
      require("../images/catalog/primavera/bellevue_gold/interiors/primavera_bellevue_15239_307641.jpg"),
      require("../images/catalog/primavera/bellevue_gold/interiors/primavera_bellevue_15239_405390.jpg"),
      require("../images/catalog/primavera/bellevue_gold/interiors/primavera_bellevue_grit_granula_17889_907425_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/bellevue_gold/tovars/primavera_bellevue_114951_243095_500x0.jpg"),
      require("../images/catalog/primavera/bellevue_gold/tovars/primavera_bellevue_114952_404216_500x0.jpg"),
    ],
  },
  {
    id: "695",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Berat",
    interiors: [
      require("../images/catalog/primavera/berat/interiors/primavera_berat_18166_354008_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/berat/tovars/primavera_berat_126562_499580_500x0.jpg"),
      require("../images/catalog/primavera/berat/tovars/primavera_berat_126563_860174_500x0.jpg"),
    ],
  },
  {
    id: "696",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Bigium blue",
    interiors: [
      require("../images/catalog/primavera/bigium_blue/interiors/primavera_bigium_blue_14915_616052_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/bigium_blue/tovars/primavera_bigium_blue_113600_648240_500x0.jpg"),
    ],
  },
  {
    id: "697",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Black emperador",
    interiors: [
      require("../images/catalog/primavera/black_emperador/interiors/primavera_black_emperador_14966_871706_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/black_emperador/tovars/primavera_black_emperador_113681_205197_500x0.jpg"),
      require("../images/catalog/primavera/black_emperador/tovars/primavera_black_emperador_113682_800459_500x0.jpg"),
    ],
  },
  {
    id: "698",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Black modulo",
    interiors: [
      require("../images/catalog/primavera/black_modulo/interiors/primavera_black_modulo_15240_29347_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/black_modulo/tovars/primavera_black_modulo_114953_38795_500x0.jpg"),
      require("../images/catalog/primavera/black_modulo/tovars/primavera_black_modulo_114954_15527_500x0.jpg"),
    ],
  },
  {
    id: "699",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Black velvet",
    interiors: [
      require("../images/catalog/primavera/black_velvet/interiors/primavera_black_velvet_18176_618609_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/black_velvet/tovars/primavera_black_velvet_126587_861652_500x0.jpg"),
      require("../images/catalog/primavera/black_velvet/tovars/primavera_black_velvet_126588_876460_500x0.jpg"),
    ],
  },
  {
    id: "700",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Blanco tranco",
    interiors: [
      require("../images/catalog/primavera/blanco_tranco/interiors/primavera_blanco_tranco_15241_587580_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/blanco_tranco/tovars/primavera_blanco_tranco_114955_908670_500x0.jpg"),
      require("../images/catalog/primavera/blanco_tranco/tovars/primavera_blanco_tranco_114956_473506_500x0.jpg"),
    ],
  },
  {
    id: "701",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Bondford",
    interiors: [
      require("../images/catalog/primavera/bondford/interiors/primavera_bondford_15242_165535_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "702",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Borzonti",
    interiors: [
      require("../images/catalog/primavera/borzonti/interiors/primavera_borzonti_14934_338959_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "703",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Botein",
    interiors: [
      require("../images/catalog/primavera/botein/interiors/primavera_botein_15575_460323_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/botein/tovars/primavera_botein_116187_863117_500x0.jpg"),
      require("../images/catalog/primavera/botein/tovars/primavera_botein_116188_168028_500x0.jpg"),
      require("../images/catalog/primavera/botein/tovars/primavera_botein_116189_206754_500x0.jpg"),
      require("../images/catalog/primavera/botein/tovars/primavera_botein_116190_422729_500x0.jpg"),
    ],
  },
  {
    id: "704",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Branch",
    interiors: [
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_23406_0x300.jpg"),
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_348597_0x300.jpg"),
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_348597_0x500.jpg"),
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_461163_0x300.jpg"),
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_575901_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/branch/tovars/primavera_branch_116191_168419_300x0.jpg"),
      require("../images/catalog/primavera/branch/tovars/primavera_branch_116192_391012_300x0.jpg"),
      require("../images/catalog/primavera/branch/tovars/primavera_branch_116193_48939_300x0.jpg"),
      require("../images/catalog/primavera/branch/tovars/primavera_branch_116194_478006_300x0.jpg"),
    ],
  },
  {
    id: "705",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Brecia ivory",
    interiors: [
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_23406_0x300.jpg"),
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_348597_0x300.jpg"),
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_348597_0x500.jpg"),
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_461163_0x300.jpg"),
      require("../images/catalog/primavera/branch/interiors/primavera_branch_15576_575901_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/branch/tovars/primavera_branch_116191_168419_300x0.jpg"),
      require("../images/catalog/primavera/branch/tovars/primavera_branch_116192_391012_300x0.jpg"),
      require("../images/catalog/primavera/branch/tovars/primavera_branch_116193_48939_300x0.jpg"),
      require("../images/catalog/primavera/branch/tovars/primavera_branch_116194_478006_300x0.jpg"),
    ],
  },
  {
    id: "706",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Canta",
    interiors: [
      require("../images/catalog/primavera/canta/interiors/primavera_canta_14932_543196_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "707",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Canyon",
    interiors: [
      require("../images/catalog/primavera/canyon/interiors/primavera_canyon_15243_896475_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/canyon/tovars/primavera_canyon_114958_294017_500x0.jpg"),
    ],
  },
  {
    id: "708",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Caribbean",
    interiors: [
      require("../images/catalog/primavera/caribbean/interiors/primavera_caribbean_15244_596206_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/caribbean/tovars/primavera_caribbean_114959_712502_500x0.jpg"),
    ],
  },
  {
    id: "709",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Chalco",
    interiors: [
      require("../images/catalog/primavera/chalco/interiors/primavera_chalco_18167_649858_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/chalco/tovars/primavera_chalco_126564_299112_500x0.jpg"),
      require("../images/catalog/primavera/chalco/tovars/primavera_chalco_126565_110619_500x0.jpg"),
    ],
  },
  {
    id: "710",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Chance",
    interiors: [
      require("../images/catalog/primavera/chance/interiors/primavera_chance_16055_712199_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/chance/tovars/primavera_chance_118900_874463_500x0.jpg"),
      require("../images/catalog/primavera/chance/tovars/primavera_chance_118901_605554_500x0.jpg"),
      require("../images/catalog/primavera/chance/tovars/primavera_chance_118902_889436_500x0.jpg"),
    ],
  },
  {
    id: "711",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Chembra onyx",
    interiors: [
      require("../images/catalog/primavera/chembra_onyx/interiors/primavera_chembra_onyx_15245_157760_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/chembra_onyx/tovars/primavera_chembra_onyx_114960_973847_500x0.jpg"),
    ],
  },
  {
    id: "712",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Cobar",
    interiors: [
      require("../images/catalog/primavera/cobar/interiors/primavera_cobar_18155_938967_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "713",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Colonial white",
    interiors: [
      require("../images/catalog/primavera/colonial_white/interiors/primavera_colonial_white_14911_929456_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "714",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Cork",
    interiors: [
      require("../images/catalog/primavera/cork/interiors/primavera_cork_15246_198025_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "715",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Dalim",
    interiors: [
      require("../images/catalog/primavera/dalim/interiors/primavera_dalim_15577_66968_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "716",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Dario",
    interiors: [
      require("../images/catalog/primavera/dario/interiors/primavera_dario_14908_458774_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "717",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Dior white",
    interiors: [
      require("../images/catalog/primavera/dior_white/interiors/primavera_dior_white_17866_473978_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "718",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Duchess",
    interiors: [
      require("../images/catalog/primavera/duchess/interiors/primavera_duchess_16056_19649_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "719",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Dzhimara",
    interiors: [
      require("../images/catalog/primavera/dzhimara/interiors/primavera_dzhimara_14933_677451_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "720",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Ehlbrus",
    interiors: [
      require("../images/catalog/primavera/ehlbrus/interiors/primavera_ehlbrus_14920_195118_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "721",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Elgon",
    interiors: [
      require("../images/catalog/primavera/elgon/interiors/primavera_elgon_16393_753802_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "722",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Elise",
    interiors: [
      require("../images/catalog/primavera/elise/interiors/primavera_elise_16057_351479_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "723",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Empressa",
    interiors: [
      require("../images/catalog/primavera/empressa/interiors/primavera_empressa_16058_750450_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "724",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Essence grey",
    interiors: [
      require("../images/catalog/primavera/essence_grey/interiors/primavera_essence_grey_14907_673020_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "725",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Fantasy",
    interiors: [
      require("../images/catalog/primavera/fantasy/interiors/primavera_fantasy_15247_533384_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "726",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Favor",
    interiors: [
      require("../images/catalog/primavera/favor/interiors/primavera_favor_14935_678193_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "727",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Forest",
    interiors: [
      require("../images/catalog/primavera/forest/interiors/primavera_forest_15578_528966_0x300.jpg"),
      require("../images/catalog/primavera/forest/interiors/primavera_forest_15578_588703_0x300.jpg"),
      require("../images/catalog/primavera/forest/interiors/primavera_forest_15578_947215_0x300.jpg"),
      require("../images/catalog/primavera/forest/interiors/primavera_forest_15578_947215_0x500.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/forest/tovars/primavera_forest_116199_126587_300x0.jpg"),
      require("../images/catalog/primavera/forest/tovars/primavera_forest_116200_212695_300x0.jpg"),
      require("../images/catalog/primavera/forest/tovars/primavera_forest_116201_506774_300x0.jpg"),
      require("../images/catalog/primavera/forest/tovars/primavera_forest_116202_565336_300x0.jpg"),
    ],
  },
  {
    id: "728",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Gemba",
    interiors: [
      require("../images/catalog/primavera/gemba/interiors/primavera_gemba_14928_350555_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "885",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["60x120"],
    Name: "Primavera",
    Collection: "Golden Black Grit Granula",
    interiors: [
      require("../images/catalog/primavera/Golden Black Grit Granula/interiors/primavera_golden_black_grit_granula_17890_829828.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/Golden Black Grit Granula/tovars/primavera_golden_black_125276_906772.jpg"),
    ],
  },
  {
    id: "729",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Hangar",
    interiors: [
      require("../images/catalog/primavera/hangar/interiors/primavera_hangar_16394_361748_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "730",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Igman",
    interiors: [
      require("../images/catalog/primavera/igman/interiors/primavera_igman_14940_525999_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "731",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Kabru",
    interiors: [
      require("../images/catalog/primavera/kabru/interiors/primavera_kabru_14926_991214_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "732",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Kamatra",
    interiors: [
      require("../images/catalog/primavera/kamatra/interiors/primavera_kamatra_14931_16262_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "733",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Kazbek",
    interiors: [
      require("../images/catalog/primavera/kazbek/interiors/primavera_kazbek_14937_277816_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "734",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Ketil",
    interiors: [
      require("../images/catalog/primavera/ketil/interiors/primavera_ketil_14939_915166_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "735",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Khasiya",
    interiors: [
      require("../images/catalog/primavera/khasiya/interiors/primavera_khasiya_14923_792329_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "272",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Kongur",
    interiors: [
      require("../images/catalog/primavera/kongur/interiors/primavera_kongur_14921_247305_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "736",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Libekir",
    interiors: [
      require("../images/catalog/primavera/libekir/interiors/primavera_libekir_14938_783929_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "737",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Maderas",
    interiors: [
      require("../images/catalog/primavera/maderas/interiors/primavera_maderas_16395_983149_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "738",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Makalu",
    interiors: [
      require("../images/catalog/primavera/makalu/interiors/primavera_makalu_14922_50660_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "273",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Manaraga",
    interiors: [
      require("../images/catalog/primavera/manaraga/interiors/primavera_manaraga_14936_166320_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "274",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Milos",
    interiors: [
      require("../images/catalog/primavera/milos/interiors/primavera_milos_16396_840108_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "275",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Nemo",
    interiors: [
      require("../images/catalog/primavera/nemo/interiors/primavera_nemo_16397_648258_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "276",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Nordend",
    interiors: [
      require("../images/catalog/primavera/nordend/interiors/primavera_nordend_14941_913464_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "277",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Olimbos",
    interiors: [
      require("../images/catalog/primavera/olimbos/interiors/primavera_olimbos_14929_304671_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "278",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Pantir",
    interiors: [
      require("../images/catalog/primavera/pantir/interiors/primavera_pantir_14927_758982_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "279",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Ross",
    interiors: [
      require("../images/catalog/primavera/ross/interiors/primavera_ross_16398_369619_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "280",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Sion",
    interiors: [
      require("../images/catalog/primavera/sion/interiors/primavera_sion_14942_935834_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "281",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Taiga",
    interiors: [
      require("../images/catalog/primavera/taiga/interiors/primavera_taiga_15580_73611_0x300.jpg"),
      require("../images/catalog/primavera/taiga/interiors/primavera_taiga_15580_73611_0x500.jpg"),
      require("../images/catalog/primavera/taiga/interiors/primavera_taiga_15580_757909_0x300.jpg"),
      require("../images/catalog/primavera/taiga/interiors/primavera_taiga_15580_944701_0x300.jpg"),
    ],
    tovars: [
      require("../images/catalog/primavera/taiga/tovars/primavera_taiga_116207_754925_300x0.jpg"),
      require("../images/catalog/primavera/taiga/tovars/primavera_taiga_116208_786154_300x0.jpg"),
      require("../images/catalog/primavera/taiga/tovars/primavera_taiga_116209_355433_300x0.jpg"),
      require("../images/catalog/primavera/taiga/tovars/primavera_taiga_116210_142571_300x0.jpg"),
    ],
  },
  {
    id: "282",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Tajjshan",
    interiors: [
      require("../images/catalog/primavera/tajjshan/interiors/primavera_tajjshan_14930_995323_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "283",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Takora",
    interiors: [
      require("../images/catalog/primavera/takora/interiors/primavera_takora_16399_115631_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "284",
    Сountry: "Индия",
    Category: "Keramogranit",
    Size: ["20x60"],
    Name: "Primavera",
    Collection: "Veleta",
    interiors: [
      require("../images/catalog/primavera/veleta/interiors/primavera_veleta_14924_867032_0x300.jpg"),
    ],
    tovars: [require("../images/catalog/noFoto.jpg")],
  },
  {
    id: "1102",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №03Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/3t.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/3t.jpg"),
    ],
  },
  {
    id: "1103",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №49Тs",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5169-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5169-scaled.jpg"),
    ],
  },
  {
    id: "1104",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №48Тs",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5179-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5179-scaled.jpg"),
    ],
  },
  {
    id: "1104",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №55Тs-t006 Матовый",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5176-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5176-scaled.jpg"),
    ],
  },
  {
    id: "1105",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №60Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5180-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5180-scaled.jpg"),
    ],
  },
  {
    id: "1106",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №43К",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_4969-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_4969-scaled.jpg"),
    ],
  },
  {
    id: "1107",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №33Т-079A",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130706-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130706-scaled.jpg"),
    ],
  },
  {
    id: "1108",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №20Т-072",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130548-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130548-scaled.jpg"),
    ],
  },
  {
    id: "1109",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №19Тs-063",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130604-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130604-scaled.jpg"),
    ],
  },
  {
    id: "1111",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №20К",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130256-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130256-scaled.jpg"),
    ],
  },
  {
    id: "1112",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №01Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/1t.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/1t.jpg"),
    ],
  },
  {
    id: "1113",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №14K",
    interiors: [
      require("../images/catalog/MramorGibkiy/msg1303074017-1259-transformed.png"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/msg1303074017-1259-transformed.png"),
    ],
  },
  {
    id: "1114",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №12K",
    interiors: [
      require("../images/catalog/MramorGibkiy/msg1303074017-1260-transformed.png"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/msg1303074017-1260-transformed.png"),
    ],
  },
  {
    id: "1115",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №9K",
    interiors: [
      require("../images/catalog/MramorGibkiy/msg1303074017-1263-rotated.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/msg1303074017-1263-rotated.jpg"),
    ],
  },
  {
    id: "1116",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №56T",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5167-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5167-scaled.jpg"),
    ],
  },
  {
    id: "1117",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №57TS",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5168-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5168-scaled.jpg"),
    ],
  },
  {
    id: "1118",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №59TS",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5170-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5170-scaled.jpg"),
    ],
  },
  {
    id: "1119",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №58T",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5171-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5171-scaled.jpg"),
    ],
  },
  {
    id: "1120",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №46TS",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5173-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5173-scaled.jpg"),
    ],
  },
  {
    id: "1121",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №52Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5172-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5172-scaled.jpg"),
    ],
  },
  {
    id: "1122",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №54ТS",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5174-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5174-scaled.jpg"),
    ],
  },
  {
    id: "1123",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №44Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5175-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5175-scaled.jpg"),
    ],
  },
  {
    id: "1124",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №47Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5178-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5178-scaled.jpg"),
    ],
  },
  {
    id: "1125",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №50Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5177-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5177-scaled.jpg"),
    ],
  },
  {
    id: "1126",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №45Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5181-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5181-scaled.jpg"),
    ],
  },
  {
    id: "1127",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №51Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5181-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5181-scaled.jpg"),
    ],
  },
  {
    id: "1128",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №53Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_5183-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_5183-scaled.jpg"),
    ],
  },
  {
    id: "1129",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №10K",
    interiors: [
      require("../images/catalog/MramorGibkiy/sten-panel-010.png"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/sten-panel-010.png"),
    ],
  },
  {
    id: "1130",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №41K",
    interiors: [
      require("../images/catalog/MramorGibkiy/photo_2024-10-03_14-22-31.png"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/photo_2024-10-03_14-22-31.png"),
    ],
  },
  {
    id: "1131",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №35ТS",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_4972-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_4972-scaled.jpg"),
    ],
  },
  {
    id: "1132",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №42K",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_4970-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_4970-scaled.jpg"),
    ],
  },
  {
    id: "1133",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №37K",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_4968-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_4968-scaled.jpg"),
    ],
  },
  {
    id: "1134",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №36K",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_4967-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_4967-scaled.jpg"),
    ],
  },
  {
    id: "1135",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №39K",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_4966-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_4966-scaled.jpg"),
    ],
  },
  {
    id: "1136",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №40K",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_4965-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_4965-scaled.jpg"),
    ],
  },
  {
    id: "1137",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №38K",
    interiors: [
      require("../images/catalog/MramorGibkiy/img_4963-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/img_4963-scaled.jpg"),
    ],
  },
  {
    id: "1138",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №34Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130829-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130829-scaled.jpg"),
    ],
  },
  {
    id: "1139",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №31ТS",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130814-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130814-scaled.jpg"),
    ],
  },
  {
    id: "1140",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №30ТS",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131714-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131714-scaled.jpg"),
    ],
  },
  {
    id: "1141",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №29Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131826-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131826-scaled.jpg"),
    ],
  },
  {
    id: "1142",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №28Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131845-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131845-scaled.jpg"),
    ],
  },
  {
    id: "1143",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №27K",
    interiors: [
      require("../images/catalog/MramorGibkiy/photo_2024-07-29_17-16-22-e1722253938398.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/photo_2024-07-29_17-16-22-e1722253938398.jpg"),
    ],
  },
  {
    id: "1144",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №26T",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131614-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131614-scaled.jpg"),
    ],
  },
  {
    id: "1145",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №25Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131357-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131357-scaled.jpg"),
    ],
  },
  {
    id: "1146",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №24Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131416-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131416-scaled.jpg"),
    ],
  },
  {
    id: "1147",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №23Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131518-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131518-scaled.jpg"),
    ],
  },
  {
    id: "1148",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №22Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131533-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131533-scaled.jpg"),
    ],
  },
  {
    id: "1149",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №21Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130530-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130530-scaled.jpg"),
    ],
  },
  {
    id: "1150",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №18Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130621-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130621-scaled.jpg"),
    ],
  },
  {
    id: "1151",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №17ТS",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130133-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130133-scaled.jpg"),
    ],
  },
  {
    id: "1152",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №16TS",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130405-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130405-scaled.jpg"),
    ],
  },
  {
    id: "1153",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №15TS",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131454-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131454-scaled.jpg"),
    ],
  },
  {
    id: "1154",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №13K",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130333-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130333-scaled.jpg"),
    ],
  },
  {
    id: "1155",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №8K",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131236-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131236-scaled.jpg"),
    ],
  },
  {
    id: "1156",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №7K",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130233-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130233-scaled.jpg"),
    ],
  },
  {
    id: "1157",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №6K",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130641-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130641-scaled.jpg"),
    ],
  },
  {
    id: "1158",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №5K",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130508-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130508-scaled.jpg"),
    ],
  },
  {
    id: "1159",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №4K",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131236-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_131236-scaled.jpg"),
    ],
  },
  {
    id: "1160",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №3K",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130954-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130954-scaled.jpg"),
    ],
  },
  {
    id: "1161",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №1K",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130758-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130758-scaled.jpg"),
    ],
  },
  {
    id: "1162",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №32TS",
    interiors: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130911-scaled.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/IMG_20240410_130911-scaled.jpg"),
    ],
  },
  {
    id: "801",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Arabescato",
    interiors: [
      require("../images/catalog/GK_Elite/Arabescato/interiors/Arabescato-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Arabescato/interiors/Arabescato-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Arabescato/interiors/Arabescato-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Arabescato/interiors/Arabescato-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Arabescato/tovars/Arabescato.jpg"),
      require("../images/catalog/GK_Elite/Arabescato/tovars/variant-razmeshcheniya(27).jpg"),
    ],
  },
  {
    id: "802",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Arabescato Orobico",
    interiors: [
      require("../images/catalog/GK_Elite/Arabescato Orobico/interiors/Arabescato-Orobico--v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Arabescato Orobico/interiors/Arabescato-Orobico--v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Arabescato Orobico/interiors/Arabescato-Orobico--v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Arabescato Orobico/tovars/Arabescato-Orobico(1).jpg"),
      require("../images/catalog/GK_Elite/Arabescato Orobico/tovars/variant-razmeshcheniya(42).jpg"),
    ],
  },
  {
    id: "803",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Azul Ceilo",
    interiors: [
      require("../images/catalog/GK_Elite/Azul Ceilo/interiors/Azul-Ceilo-v-interere-1.png"),
      require("../images/catalog/GK_Elite/Azul Ceilo/interiors/Azul-Ceilo-v-interere-2.png"),
      require("../images/catalog/GK_Elite/Azul Ceilo/interiors/Azul-Ceilo-v-interere-3.png"),
      require("../images/catalog/GK_Elite/Azul Ceilo/interiors/Azul-Ceilo-v-interere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Azul Ceilo/tovars/Azul-Ceilo-v-interere.png"),
      require("../images/catalog/GK_Elite/Azul Ceilo/tovars/variant-razmeshcheniya.png"),
    ],
  },
  {
    id: "804",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Bianca Carrara",
    interiors: [
      require("../images/catalog/GK_Elite/Bianca Carrara/interiors/Bianca-Carrara-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Bianca Carrara/interiors/Bianca-Carrara-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Bianca Carrara/interiors/Bianca-Carrara-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Bianca Carrara/interiors/Bianca-Carrara-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Bianca Carrara/tovars/Bianca-Carrara.jpg"),
      require("../images/catalog/GK_Elite/Bianca Carrara/tovars/variant-razmeshcheniya(28).jpg"),
    ],
  },
  {
    id: "805",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Bianco Statuario",
    interiors: [
      require("../images/catalog/GK_Elite/Bianco Statuario/interiors/Bianco-Statuario-v-interrere-1.png"),
      require("../images/catalog/GK_Elite/Bianco Statuario/interiors/Bianco-Statuario-v-interrere-2.png"),
      require("../images/catalog/GK_Elite/Bianco Statuario/interiors/Bianco-Statuario-v-interrere-3.png"),
      require("../images/catalog/GK_Elite/Bianco Statuario/interiors/Bianco-Statuario-v-interrere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Bianco Statuario/tovars/Bianco-Statuario.png"),
      require("../images/catalog/GK_Elite/Bianco Statuario/tovars/variant-razmeshcheniya(1).png"),
    ],
  },
  {
    id: "806",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Bidasar Gold",
    interiors: [
      require("../images/catalog/GK_Elite/Bidasar Gold/interiors/Bidasar-Gold-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Bidasar Gold/interiors/Bidasar-Gold-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Bidasar Gold/interiors/Bidasar-Gold-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Bidasar Gold/interiors/Bidasar-Gold-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Bidasar Gold/tovars/Bidasar-Gold.jpg"),
      require("../images/catalog/GK_Elite/Bidasar Gold/tovars/variant-razmeshcheniya(29).jpg"),
    ],
  },
  {
    id: "807",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Bleu Fleuri",
    interiors: [
      require("../images/catalog/GK_Elite/Bleu Fleuri/interiors/Bleu-Fleuri-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Bleu Fleuri/interiors/Bleu-Fleuri-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Bleu Fleuri/interiors/Bleu-Fleuri-v-interere-3(1).jpg"),
      require("../images/catalog/GK_Elite/Bleu Fleuri/interiors/Bleu-Fleuri-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Bleu Fleuri/tovars/Bleu-Fleuri.jpg"),
      require("../images/catalog/GK_Elite/Bleu Fleuri/tovars/variant-razmeshcheniya(30).jpg"),
    ],
  },
  {
    id: "808",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Blue Cappella",
    interiors: [
      require("../images/catalog/GK_Elite/Blue Cappella/interiors/Blue-Cappella-v-interere-1.png"),
      require("../images/catalog/GK_Elite/Blue Cappella/interiors/Blue-Cappella-v-interere-2.png"),
      require("../images/catalog/GK_Elite/Blue Cappella/interiors/Blue-Cappella-v-interere-3.png"),
      require("../images/catalog/GK_Elite/Blue Cappella/interiors/Blue-Cappella-v-interere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Blue Cappella/tovars/Blue-Cappella.png"),
      require("../images/catalog/GK_Elite/Blue Cappella/tovars/variant-razmeshcheniya(5).png"),
    ],
  },
  {
    id: "809",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Blue Turquin",
    interiors: [
      require("../images/catalog/GK_Elite/Blue Turquin/interiors/Blue-Turquin-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Blue Turquin/interiors/Blue-Turquin-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Blue Turquin/interiors/Blue-Turquin-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Blue Turquin/interiors/Blue-Turquin-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Blue Turquin/tovars/Blue-Turquin.jpg"),
      require("../images/catalog/GK_Elite/Blue Turquin/tovars/variant-razmeshcheniya(31).jpg"),
    ],
  },
  {
    id: "810",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Breccia Damascata",
    interiors: [
      require("../images/catalog/GK_Elite/Breccia Damascata/interiors/Breccia-Damascata-v-interere-1.png"),
      require("../images/catalog/GK_Elite/Breccia Damascata/interiors/Breccia-Damascata-v-interere-2.png"),
      require("../images/catalog/GK_Elite/Breccia Damascata/interiors/Breccia-Damascata-v-interere-3.png"),
      require("../images/catalog/GK_Elite/Breccia Damascata/interiors/Breccia-Damascata-v-interere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Breccia Damascata/tovars/Breccia-Damascata.png"),
      require("../images/catalog/GK_Elite/Breccia Damascata/tovars/variant-razmeshcheniya(4).png"),
    ],
  },
  {
    id: "811",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Calcite Blue",
    interiors: [
      require("../images/catalog/GK_Elite/Calcite Bluе/interiors/Calcite-Blue-v-interere-1 (1).png"),
      require("../images/catalog/GK_Elite/Calcite Bluе/interiors/Calcite-Blue-v-interere-2.png"),
      require("../images/catalog/GK_Elite/Calcite Bluе/interiors/Calcite-Blue-v-interere-3.png"),
      require("../images/catalog/GK_Elite/Calcite Bluе/interiors/Calcite-Blue-v-interere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Calcite Bluе/tovars/Calcite-Blue.png"),
      require("../images/catalog/GK_Elite/Calcite Bluе/tovars/variant-razmeshcheniya(9).png"),
    ],
  },
  {
    id: "812",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Daino Reale",
    interiors: [
      require("../images/catalog/GK_Elite/Daino Reale/interiors/Daino-Reale-v-interere-1.png"),
      require("../images/catalog/GK_Elite/Daino Reale/interiors/Daino-Reale-v-interere-2.png"),
      require("../images/catalog/GK_Elite/Daino Reale/interiors/Daino-Reale-v-interere-3.png"),
      require("../images/catalog/GK_Elite/Daino Reale/interiors/Daino-Reale-v-interere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Daino Reale/tovars/Calcite-Blue-v-interere-1.png"),
      require("../images/catalog/GK_Elite/Daino Reale/tovars/Daino-Reale.png"),
      require("../images/catalog/GK_Elite/Daino Reale/tovars/variant-razmeshcheniya(8).png"),
    ],
  },
  {
    id: "813",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Diablo",
    interiors: [
      require("../images/catalog/GK_Elite/Diablo/interiors/Diablo-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Diablo/interiors/Diablo-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Diablo/interiors/Diablo-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Diablo/interiors/Diablo-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Diablo/tovars/Diablo.jpg"),
      require("../images/catalog/GK_Elite/Diablo/tovars/variant-razmeshcheniya(32).jpg"),
    ],
  },
  {
    id: "814",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Disette Basi",
    interiors: [
      require("../images/catalog/GK_Elite/Disette Basi/interiors/Disette-Basi-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Disette Basi/interiors/Disette-Basi-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Disette Basi/interiors/Disette-Basi-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Disette Basi/interiors/Disette-Basi-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Disette Basi/tovars/Disette-Basi.jpg"),
      require("../images/catalog/GK_Elite/Disette Basi/tovars/variant-razmeshcheniya(33).jpg"),
    ],
  },
  {
    id: "815",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Dorato",
    interiors: [
      require("../images/catalog/GK_Elite/Dorato/interiors/Dorato-v-intereere-1.jpg"),
      require("../images/catalog/GK_Elite/Dorato/interiors/Dorato-v-intereere-2.jpg"),
      require("../images/catalog/GK_Elite/Dorato/interiors/Dorato-v-intereere-3.jpg"),
      require("../images/catalog/GK_Elite/Dorato/interiors/Dorato-v-intereere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Dorato/tovars/Dorato.jpg"),
      require("../images/catalog/GK_Elite/Dorato/tovars/variant-razmeshcheniya(34).jpg"),
    ],
  },
  {
    id: "816",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Michelangelo",
    interiors: [
      require("../images/catalog/GK_Elite/Michelangelo/interiors/Michelangelo-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Michelangelo/interiors/Michelangelo-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Michelangelo/interiors/Michelangelo-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Michelangelo/interiors/Michelangelo-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Michelangelo/tovars/Michelangelo.jpg"),
      require("../images/catalog/GK_Elite/Michelangelo/tovars/variant-razmeshcheniya(35).jpg"),
    ],
  },
  {
    id: "817",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Nuvolato",
    interiors: [
      require("../images/catalog/GK_Elite/Nuvolato/interiors/Nuvolato-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Nuvolato/interiors/Nuvolato-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Nuvolato/interiors/Nuvolato-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Nuvolato/interiors/Nuvolato-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Nuvolato/tovars/Nuvolato.jpg"),
      require("../images/catalog/GK_Elite/Nuvolato/tovars/variant-razmeshcheniya(36).jpg"),
    ],
  },
  {
    id: "818",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Palissandro Blue Ocean",
    interiors: [
      require("../images/catalog/GK_Elite/Palissandro Blue Ocean/interiors/Palissandro-Blue-Ocean-v-interere-1.png"),
      require("../images/catalog/GK_Elite/Palissandro Blue Ocean/interiors/Palissandro-Blue-Ocean-v-interere-2.png"),
      require("../images/catalog/GK_Elite/Palissandro Blue Ocean/interiors/Palissandro-Blue-Ocean-v-interere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Palissandro Blue Ocean/tovars/Palissandro-Blue-Ocean.png"),
      require("../images/catalog/GK_Elite/Palissandro Blue Ocean/tovars/variant-razmeshcheniya(7).png"),
    ],
  },
  {
    id: "819",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Pietra Stellaria",
    interiors: [
      require("../images/catalog/GK_Elite/Pietra Stellaria/interiors/Pietra-Stellaria-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Pietra Stellaria/interiors/Pietra-Stellaria-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Pietra Stellaria/interiors/Pietra-Stellaria-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Pietra Stellaria/interiors/Pietra-Stellaria-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Pietra Stellaria/tovars/Pietra-Stellaria.jpg"),
      require("../images/catalog/GK_Elite/Pietra Stellaria/tovars/variant-razmeshcheniya(37).jpg"),
    ],
  },
  {
    id: "820",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Porto Santo",
    interiors: [
      require("../images/catalog/GK_Elite/Porto Santo/interiors/Porto-Santo-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Porto Santo/interiors/Porto-Santo-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Porto Santo/interiors/Porto-Santo-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Porto Santo/interiors/Porto-Santo-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Porto Santo/tovars/Porto-Santo.jpg"),
      require("../images/catalog/GK_Elite/Porto Santo/tovars/variant-razmeshcheniya(38).jpg"),
    ],
  },
  {
    id: "821",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Rojo Alicante",
    interiors: [
      require("../images/catalog/GK_Elite/Rojo Alicante/interiors/Rojo-Alicante-v-interere-1.png"),
      require("../images/catalog/GK_Elite/Rojo Alicante/interiors/Rojo-Alicante-v-interere-2.png"),
      require("../images/catalog/GK_Elite/Rojo Alicante/interiors/Rojo-Alicante-v-interere-3.png"),
      require("../images/catalog/GK_Elite/Rojo Alicante/interiors/Rojo-Alicante-v-interere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Rojo Alicante/tovars/Rojo-Alicante.png"),
      require("../images/catalog/GK_Elite/Rojo Alicante/tovars/variant-razmeshcheniya(2).png"),
    ],
  },
  {
    id: "822",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Rosso Verona",
    interiors: [
      require("../images/catalog/GK_Elite/Rosso Verona/interiors/Rosso-Verona-v-interere-1.png"),
      require("../images/catalog/GK_Elite/Rosso Verona/interiors/Rosso-Verona-v-interere-2.png"),
      require("../images/catalog/GK_Elite/Rosso Verona/interiors/Rosso-Verona-v-interere-3.png"),
      require("../images/catalog/GK_Elite/Rosso Verona/interiors/Rosso-Verona-v-interere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Rosso Verona/tovars/Rosso-Verona.png"),
      require("../images/catalog/GK_Elite/Rosso Verona/tovars/variant-razmeshcheniya(3).png"),
    ],
  },
  {
    id: "823",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Sainte Anne",
    interiors: [
      require("../images/catalog/GK_Elite/Sainte Anne/interiors/Sainte-Anne-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Sainte Anne/interiors/Sainte-Anne-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Sainte Anne/interiors/Sainte-Anne-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Sainte Anne/interiors/Sainte-Anne-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Sainte Anne/tovars/Sainte-Anne.jpg"),
      require("../images/catalog/GK_Elite/Sainte Anne/tovars/variant-razmeshcheniya(39).jpg"),
    ],
  },
  {
    id: "824",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Sandy Grey",
    interiors: [
      require("../images/catalog/GK_Elite/Sandy Grey/interiors/Sandy-Grey-v-interere-1.png"),
      require("../images/catalog/GK_Elite/Sandy Grey/interiors/Sandy-Grey-v-interere-2.png"),
      require("../images/catalog/GK_Elite/Sandy Grey/interiors/Sandy-Grey-v-interere-3.png"),
      require("../images/catalog/GK_Elite/Sandy Grey/interiors/Sandy-Grey-v-interere-4.png"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Sandy Grey/tovars/Sandy-Grey.png"),
      require("../images/catalog/GK_Elite/Sandy Grey/tovars/variant-razmeshcheniya(6).png"),
    ],
  },
  {
    id: "825",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Tobaco",
    interiors: [
      require("../images/catalog/GK_Elite/Tobaco/interiors/Tobaco-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Tobaco/interiors/Tobaco-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Tobaco/interiors/Tobaco-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Tobaco/interiors/Tobaco-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Tobaco/tovars/Tobaco.jpg"),
      require("../images/catalog/GK_Elite/Tobaco/tovars/variant-razmeshcheniya(40).jpg"),
    ],
  },
  {
    id: "826",
    Сountry: "Россия",
    Name: "Elite",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Verde Antico",
    interiors: [
      require("../images/catalog/GK_Elite/Verde Antico/interiors/Verde-Antico-v-interere-1.jpg"),
      require("../images/catalog/GK_Elite/Verde Antico/interiors/Verde-Antico-v-interere-2.jpg"),
      require("../images/catalog/GK_Elite/Verde Antico/interiors/Verde-Antico-v-interere-3.jpg"),
      require("../images/catalog/GK_Elite/Verde Antico/interiors/Verde-Antico-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_Elite/Verde Antico/tovars/variant-razmeshcheniya(41).jpg"),
      require("../images/catalog/GK_Elite/Verde Antico/tovars/Verde-Antico.jpg"),
    ],
  },
  {
    id: "830",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Cloudy Grey",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Cloudy Grey/interiors/Cloudy-Grey-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Cloudy Grey/interiors/Cloudy-Grey-v-interere-2(1).jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Cloudy Grey/interiors/Cloudy-Grey-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Cloudy Grey/interiors/Cloudy-Grey-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Cloudy Grey/tovars/Cloudy-Grey.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Cloudy Grey/tovars/variant-razmeshcheniya(16).jpg"),
    ],
  },
  {
    id: "831",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Crystal White",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Crystal White/interiors/Crystal-White-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Crystal White/interiors/Crystal-White-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Crystal White/interiors/Crystal-White-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Crystal White/interiors/Crystal-White-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Crystal White/tovars/Crystal-White.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Crystal White/tovars/variant-razmeshcheniya(17).jpg"),
    ],
  },
  {
    id: "832",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Estremoz",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Estremoz/interiors/Estremoz-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Estremoz/interiors/Estremoz-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Estremoz/interiors/Estremoz-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Estremoz/interiors/Estremoz-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Estremoz/tovars/Estremoz.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Estremoz/tovars/variant-razmeshcheniya.jpg"),
    ],
  },
  {
    id: "833",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Golden Cream",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Golden Cream/interiors/Golden-Cream-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Cream/interiors/Golden-Cream-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Cream/interiors/Golden-Cream-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Cream/interiors/Golden-Cream-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Golden Cream/tovars/Golden-Cream.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Cream/tovars/variant-razmeshcheniya(1).jpg"),
    ],
  },
  {
    id: "834",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Golden Jade",
    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Golden Jade/interiors/Golden-Jade-v-interere-1(1).jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Jade/interiors/Golden-Jade-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Jade/interiors/Golden-Jade-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Jade/interiors/Golden-Jade-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Golden Jade/tovars/Golden-Jade.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Jade/tovars/variant-razmeshcheniya(18).jpg"),
    ],
  },
  {
    id: "835",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Golden Spider",
    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Golden Spider/interiors/Golden-Spider-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Spider/interiors/Golden-Spider-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Spider/interiors/Golden-Spider-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Spider/interiors/Golden-Spider-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Golden Spider/tovars/Golden-Spider.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Golden Spider/tovars/variant-razmeshcheniya(2).jpg"),
    ],
  },
  {
    id: "836",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Grey Silk",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Grey Silk/interiors/Grey-Silk-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Grey Silk/interiors/Grey-Silk-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Grey Silk/interiors/Grey-Silk-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Grey Silk/interiors/Grey-Silk-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Grey Silk/tovars/Grey-Silk.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Grey Silk/tovars/variant-razmeshcheniya(19).jpg"),
    ],
  },
  {
    id: "837",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Grigio Carnico",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Grigio Carnico/interiors/Grigio-Carnico-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Grigio Carnico/interiors/Grigio-Carnico-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Grigio Carnico/interiors/Grigio-Carnico-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Grigio Carnico/interiors/Grigio-Carnico-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Grigio Carnico/tovars/Grigio-Carnico.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Grigio Carnico/tovars/variant-razmeshcheniya(3).jpg"),
    ],
  },
  {
    id: "838",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Imperador Desert",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Imperador Desert/interiors/Imperador-Desert-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Imperador Desert/interiors/Imperador-Desert-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Imperador Desert/interiors/Imperador-Desert-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Imperador Desert/interiors/Imperador-Desert-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Imperador Desert/tovars/Imperador-Desert.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Imperador Desert/tovars/variant-razmeshcheniya(4).jpg"),
    ],
  },
  {
    id: "839",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Imperador Gold",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Imperador Gold/interiors/Imperador-Gold-v-interere-1(1).jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Imperador Gold/interiors/Imperador-Gold-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Imperador Gold/interiors/Imperador-Gold-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Imperador Gold/interiors/Imperador-Gold-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Imperador Gold/tovars/Imperador-Gold.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Imperador Gold/tovars/variant-razmeshcheniya(20).jpg"),
    ],
  },
  {
    id: "840",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Marron Imperador",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Marron Imperador/interiors/Marron-Imperador-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Marron Imperador/interiors/Marron-Imperador-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Marron Imperador/interiors/Marron-Imperador-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Marron Imperador/interiors/Marron-Imperador-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Marron Imperador/tovars/Marron-Imperador.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Marron Imperador/tovars/variant-razmeshcheniya(5).jpg"),
    ],
  },
  {
    id: "841",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Misti Blue",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Misti Blue/interiors/Misti-Blue-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Misti Blue/interiors/Misti-Blue-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Misti Blue/interiors/Misti-Blue-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Misti Blue/interiors/Misti-Blue-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Misti Blue/tovars/Misti-Blue.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Misti Blue/tovars/variant-razmeshcheniya(6).jpg"),
    ],
  },
  {
    id: "842",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Misti Grey",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Misti Grey/interiors/Misti-Grey-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Misti Grey/interiors/Misti-Grey-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Misti Grey/interiors/Misti-Grey-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Misti Grey/interiors/Misti-Grey-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Misti Grey/tovars/Misti-Grey.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Misti Grey/tovars/variant-razmeshcheniya(7).jpg"),
    ],
  },
  {
    id: "843",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Moca Classico",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Moca Classico/interiors/Moca-Classico-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Moca Classico/interiors/Moca-Classico-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Moca Classico/interiors/Moca-Classico-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Moca Classico/interiors/Moca-Classico-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Moca Classico/tovars/Moca-Classico.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Moca Classico/tovars/variant-razmeshcheniya(8).jpg"),
    ],
  },
  {
    id: "844",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Moca Cream",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Moca Cream/interiors/Moca-Cream-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Moca Cream/interiors/Moca-Cream-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Moca Cream/interiors/Moca-Cream-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Moca Cream/interiors/Moca-Cream-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Moca Cream/tovars/Moca-Cream.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Moca Cream/tovars/variant-razmeshcheniya(9).jpg"),
    ],
  },
  {
    id: "845",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Monaco Blue",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Monaco Blue/interiors/Monaco-Blue-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Monaco Blue/interiors/Monaco-Blue-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Monaco Blue/interiors/Monaco-Blue-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Monaco Blue/interiors/Monaco-Blue-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Monaco Blue/tovars/Monaco-Blue.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Monaco Blue/tovars/variant-razmeshcheniya(10).jpg"),
    ],
  },
  {
    id: "846",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Monaco Brown",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Monaco Brown/interiors/Monaco-Brown-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Monaco Brown/interiors/Monaco-Brown-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Monaco Brown/interiors/Monaco-Brown-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Monaco Brown/interiors/Monaco-Brown-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Monaco Brown/tovars/Monaco-Brown.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Monaco Brown/tovars/variant-razmeshcheniya(11).jpg"),
    ],
  },
  {
    id: "847",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Opera Fantastico",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Opera Fantastico/interiors/Opera-Fantastico-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Opera Fantastico/interiors/Opera-Fantastico-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Opera Fantastico/interiors/Opera-Fantastico-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Opera Fantastico/interiors/Opera-Fantastico-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Opera Fantastico/tovars/Opera-Fantastico.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Opera Fantastico/tovars/variant-razmeshcheniya(15).jpg"),
    ],
  },
  {
    id: "848",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Pallisandro Blue",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Blue/interiors/Pallisandro-Blue-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Blue/interiors/Pallisandro-Blue-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Blue/interiors/Pallisandro-Blue-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Blue/interiors/Pallisandro-Blue-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Blue/tovars/Pallisandro-Blue.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Blue/tovars/variant-razmeshcheniya(12).jpg"),
    ],
  },
  {
    id: "849",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Pallisandro Classico",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Classico/interiors/Pallisandro-Classico-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Classico/interiors/Pallisandro-Classico-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Classico/interiors/Pallisandro-Classico-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Classico/interiors/Pallisandro-Classico-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Classico/tovars/Pallisandro-Classico.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Pallisandro Classico/tovars/variant-razmeshcheniya(13).jpg"),
    ],
  },
  {
    id: "850",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Saint Louis",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Saint Louis/interiors/Saint-Louis-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Saint Louis/interiors/Saint-Louis-v-interere-2(1).jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Saint Louis/interiors/Saint-Louis-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Saint Louis/interiors/Saint-Louis-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Saint Louis/tovars/Saint-Louis.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Saint Louis/tovars/variant-razmeshcheniya(21).jpg"),
    ],
  },
  {
    id: "851",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Skyline",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Skyline/interiors/Skyline-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Skyline/interiors/Skyline-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Skyline/interiors/Skyline-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Skyline/interiors/Skyline-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Skyline/tovars/Skyline.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Skyline/tovars/variant-razmeshcheniya(22).jpg"),
    ],
  },
  {
    id: "852",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Sunny Gold",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/Sunny Gold/interiors/Sunny-Gold-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Sunny Gold/interiors/Sunny-Gold-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Sunny Gold/interiors/Sunny-Gold-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Sunny Gold/interiors/Sunny-Gold-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/Sunny Gold/tovars/Sunny-Gold.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/Sunny Gold/tovars/variant-razmeshcheniya(23).jpg"),
    ],
  },
  {
    id: "853",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "White Cream",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/White Cream/interiors/White-Cream-v-interere-1.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/White Cream/interiors/White-Cream-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/White Cream/interiors/White-Cream-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/White Cream/interiors/White-Cream-v-interere-4(1).jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/White Cream/tovars/variant-razmeshcheniya(24).jpg"),
      require("../images/catalog/GK_EXCLUSIVE/White Cream/tovars/White-Cream.jpg"),
    ],
  },
  {
    id: "854",
    Сountry: "Россия",
    Name: "Exclusive",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "White Jade",

    interiors: [
      require("../images/catalog/GK_EXCLUSIVE/White Jade/interiors/White-Jade-v-interere-1(1).jpg"),
      require("../images/catalog/GK_EXCLUSIVE/White Jade/interiors/White-Jade-v-interere-2.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/White Jade/interiors/White-Jade-v-interere-3.jpg"),
      require("../images/catalog/GK_EXCLUSIVE/White Jade/interiors/White-Jade-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_EXCLUSIVE/White Jade/tovars/variant-razmeshcheniya(25).jpg"),
      require("../images/catalog/GK_EXCLUSIVE/White Jade/tovars/White-Jade.jpg"),
    ],
  },
  {
    id: "860",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Авантюрин",

    interiors: [
      require("../images/catalog/GK_VIP/Авантюрин/interiors/avantyurin-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Авантюрин/interiors/avantyurin-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Авантюрин/interiors/avantyurin-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Авантюрин/interiors/avantyurin-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Авантюрин/tovars/avantyurin-1.jpg"),
      require("../images/catalog/GK_VIP/Авантюрин/tovars/avantyurin-2.jpg"),
      require("../images/catalog/GK_VIP/Авантюрин/tovars/avantyurin-3.jpg"),
      require("../images/catalog/GK_VIP/Авантюрин/tovars/avantyurin-4.jpg"),
      require("../images/catalog/GK_VIP/Авантюрин/tovars/avantyurin.jpg"),
    ],
  },
  {
    id: "861",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Аквамарин",

    interiors: [
      require("../images/catalog/GK_VIP/Аквамарин/interiors/akvamarin-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Аквамарин/interiors/akvamarin-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Аквамарин/interiors/akvamarin-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Аквамарин/interiors/akvamarin-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Аквамарин/tovars/akvamarin-1.jpg"),
      require("../images/catalog/GK_VIP/Аквамарин/tovars/akvamarin-2.jpg"),
      require("../images/catalog/GK_VIP/Аквамарин/tovars/akvamarin-3.jpg"),
      require("../images/catalog/GK_VIP/Аквамарин/tovars/akvamarin-4.jpg"),
      require("../images/catalog/GK_VIP/Аквамарин/tovars/akvamarin.jpg"),
    ],
  },
  {
    id: "862",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Алмаз",

    interiors: [
      require("../images/catalog/GK_VIP/Алмаз/interiors/almaz-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Алмаз/interiors/almaz-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Алмаз/interiors/almaz-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Алмаз/interiors/almaz-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Алмаз/tovars/almaz-1.jpg"),
      require("../images/catalog/GK_VIP/Алмаз/tovars/almaz-2.jpg"),
      require("../images/catalog/GK_VIP/Алмаз/tovars/almaz-3.jpg"),
      require("../images/catalog/GK_VIP/Алмаз/tovars/almaz-4.jpg"),
      require("../images/catalog/GK_VIP/Алмаз/tovars/almaz.jpg"),
    ],
  },
  {
    id: "863",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Альмандин",

    interiors: [
      require("../images/catalog/GK_VIP/Альмандин/interiors/almandin-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Альмандин/interiors/almandin-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Альмандин/interiors/almandin-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Альмандин/interiors/almandin-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Альмандин/tovars/almandin-1.jpg"),
      require("../images/catalog/GK_VIP/Альмандин/tovars/almandin-2.jpg"),
      require("../images/catalog/GK_VIP/Альмандин/tovars/almandin-3.jpeg"),
      require("../images/catalog/GK_VIP/Альмандин/tovars/almandin-4.jpg"),
      require("../images/catalog/GK_VIP/Альмандин/tovars/almandin.jpg"),
    ],
  },
  {
    id: "864",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Аметист",

    interiors: [
      require("../images/catalog/GK_VIP/Аметист/interiors/ametist-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Аметист/interiors/ametist-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Аметист/interiors/ametist-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Аметист/interiors/ametist-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Аметист/tovars/ametist-1.jpg"),
      require("../images/catalog/GK_VIP/Аметист/tovars/ametist-2.jpg"),
      require("../images/catalog/GK_VIP/Аметист/tovars/ametist-3.jpg"),
      require("../images/catalog/GK_VIP/Аметист/tovars/ametist-4.jpg"),
      require("../images/catalog/GK_VIP/Аметист/tovars/ametist.jpg"),
    ],
  },
  {
    id: "865",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Апатит",

    interiors: [
      require("../images/catalog/GK_VIP/Апатит/interiors/apatit-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Апатит/interiors/apatit-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Апатит/interiors/apatit-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Апатит/interiors/apatit-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Апатит/tovars/apatit-1.jpg"),
      require("../images/catalog/GK_VIP/Апатит/tovars/apatit-2.jpg"),
      require("../images/catalog/GK_VIP/Апатит/tovars/apatit-3.jpg"),
      require("../images/catalog/GK_VIP/Апатит/tovars/apatit-4.jpg"),
      require("../images/catalog/GK_VIP/Апатит/tovars/apatit.jpg"),
    ],
  },
  {
    id: "866",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Верделит",

    interiors: [
      require("../images/catalog/GK_VIP/Верделит/interiors/verdelit-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Верделит/interiors/verdelit-v-interere-2(1).jpg"),
      require("../images/catalog/GK_VIP/Верделит/interiors/verdelit-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Верделит/interiors/verdelit-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Верделит/tovars/verdelit-1.jpg"),
      require("../images/catalog/GK_VIP/Верделит/tovars/verdelit-2.jpg"),
      require("../images/catalog/GK_VIP/Верделит/tovars/verdelit-3.jpg"),
      require("../images/catalog/GK_VIP/Верделит/tovars/verdelit-4.jpg"),
      require("../images/catalog/GK_VIP/Верделит/tovars/verdelit.jpg"),
    ],
  },
  {
    id: "867",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Волокас",

    interiors: [
      require("../images/catalog/GK_VIP/Волокас/interiors/volokas-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Волокас/interiors/volokas-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Волокас/interiors/volokas-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Волокас/interiors/volokas-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Волокас/tovars/variant-razmeshcheniya(14).jpg"),
      require("../images/catalog/GK_VIP/Волокас/tovars/volokas.jpg"),
    ],
  },
  {
    id: "868",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Гелиодор",

    interiors: [
      require("../images/catalog/GK_VIP/Гелиодор/interiors/geliodor-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Гелиодор/interiors/geliodor-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Гелиодор/interiors/geliodor-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Гелиодор/interiors/geliodor-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Гелиодор/tovars/geliodor-1.jpg"),
      require("../images/catalog/GK_VIP/Гелиодор/tovars/geliodor-2.jpg"),
      require("../images/catalog/GK_VIP/Гелиодор/tovars/geliodor-3.jpg"),
      require("../images/catalog/GK_VIP/Гелиодор/tovars/geliodor-4.jpg"),
      require("../images/catalog/GK_VIP/Гелиодор/tovars/geliodor.jpg"),
    ],
  },
  {
    id: "869",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Гессонит",

    interiors: [
      require("../images/catalog/GK_VIP/Гессонит/interiors/gessonit-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Гессонит/interiors/gessonit-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Гессонит/interiors/gessonit-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Гессонит/interiors/gessonit-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Гессонит/tovars/gessonit-1.jpg"),
      require("../images/catalog/GK_VIP/Гессонит/tovars/gessonit-2.jpg"),
      require("../images/catalog/GK_VIP/Гессонит/tovars/gessonit-3.jpg"),
      require("../images/catalog/GK_VIP/Гессонит/tovars/gessonit-4.jpg"),
      require("../images/catalog/GK_VIP/Гессонит/tovars/gessonit.jpg"),
    ],
  },
  {
    id: "870",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Гранат",

    interiors: [
      require("../images/catalog/GK_VIP/Гранат/interiors/granat-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Гранат/interiors/granat-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Гранат/interiors/granat-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Гранат/interiors/granat-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Гранат/tovars/granat-1.jpg"),
      require("../images/catalog/GK_VIP/Гранат/tovars/granat-2.jpg"),
      require("../images/catalog/GK_VIP/Гранат/tovars/granat-3.jpg"),
      require("../images/catalog/GK_VIP/Гранат/tovars/granat-4.jpg"),
      require("../images/catalog/GK_VIP/Гранат/tovars/granat.jpg"),
    ],
  },
  {
    id: "871",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Доломит",

    interiors: [
      require("../images/catalog/GK_VIP/Доломит/interiors/dolomit-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Доломит/interiors/dolomit-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Доломит/interiors/dolomit-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Доломит/interiors/dolomit-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Доломит/tovars/dolomit(1).jpg"),
      require("../images/catalog/GK_VIP/Доломит/tovars/dolomit.jpg"),
    ],
  },
  {
    id: "872",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Дымчатый кварц",

    interiors: [
      require("../images/catalog/GK_VIP/Дымчатый кварц/interiors/dimchatiy-kvarts-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Дымчатый кварц/interiors/dimchatiy-kvarts-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Дымчатый кварц/interiors/dimchatiy-kvarts-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Дымчатый кварц/interiors/dimchatiy-kvarts-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Дымчатый кварц/tovars/dimchatiy-kvarts-1.jpg"),
      require("../images/catalog/GK_VIP/Дымчатый кварц/tovars/dimchatiy-kvarts-2.jpg"),
      require("../images/catalog/GK_VIP/Дымчатый кварц/tovars/dimchatiy-kvarts-3.jpg"),
      require("../images/catalog/GK_VIP/Дымчатый кварц/tovars/dimchatiy-kvarts-4.jpg"),
      require("../images/catalog/GK_VIP/Дымчатый кварц/tovars/dimchatiy-kvarts.jpg"),
    ],
  },
  {
    id: "873",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Имперадор",

    interiors: [
      require("../images/catalog/GK_VIP/Имперадор/interiors/imperador-v-interere-1.jpeg"),
      require("../images/catalog/GK_VIP/Имперадор/interiors/imperador-v-interere-2.jpeg"),
      require("../images/catalog/GK_VIP/Имперадор/interiors/imperador-v-interere-3.jpeg"),
      require("../images/catalog/GK_VIP/Имперадор/interiors/imperador-v-interere-4.jpeg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Имперадор/tovars/imperador.jpeg"),
      require("../images/catalog/GK_VIP/Имперадор/tovars/imperator.jpg"),
    ],
  },
  {
    id: "874",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Лазурит",

    interiors: [
      require("../images/catalog/GK_VIP/Лазурит/interiors/lazurit-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Лазурит/interiors/lazurit-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Лазурит/interiors/lazurit-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Лазурит/interiors/lazurit-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Лазурит/tovars/lazurit-1.jpg"),
      require("../images/catalog/GK_VIP/Лазурит/tovars/lazurit-2.jpg"),
      require("../images/catalog/GK_VIP/Лазурит/tovars/lazurit-3.jpg"),
      require("../images/catalog/GK_VIP/Лазурит/tovars/lazurit-4.jpg"),
      require("../images/catalog/GK_VIP/Лазурит/tovars/lazurit.jpg"),
    ],
  },
  {
    id: "875",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Малахит",

    interiors: [
      require("../images/catalog/GK_VIP/Малахит/interiors/malahit-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Малахит/interiors/malahit-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Малахит/interiors/malahit-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Малахит/interiors/malahit-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Малахит/tovars/malahit-1.jpg"),
      require("../images/catalog/GK_VIP/Малахит/tovars/malahit-2.jpg"),
      require("../images/catalog/GK_VIP/Малахит/tovars/malahit-3.jpg"),
      require("../images/catalog/GK_VIP/Малахит/tovars/malahit-4.jpg"),
      require("../images/catalog/GK_VIP/Малахит/tovars/malahit.jpg"),
    ],
  },
  {
    id: "876",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Неро",

    interiors: [
      require("../images/catalog/GK_VIP/Неро/interiors/nero-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Неро/interiors/nero-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Неро/interiors/nero-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Неро/interiors/nero-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Неро/tovars/nero.jpg"),
      require("../images/catalog/GK_VIP/Неро/tovars/variant-razmeshcheniya(26).jpg"),
    ],
  },
  {
    id: "877",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Нефрит",

    interiors: [
      require("../images/catalog/GK_VIP/Нефрит/interiors/nefrit-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Нефрит/interiors/nefrit-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Нефрит/interiors/nefrit-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Нефрит/interiors/nefrit-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Нефрит/tovars/nefrit-1.jpg"),
      require("../images/catalog/GK_VIP/Нефрит/tovars/nefrit-2.jpg"),
      require("../images/catalog/GK_VIP/Нефрит/tovars/nefrit-3.jpg"),
      require("../images/catalog/GK_VIP/Нефрит/tovars/nefrit-4.jpg"),
      require("../images/catalog/GK_VIP/Нефрит/tovars/nefrit.jpg"),
    ],
  },
  {
    id: "878",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Оникс",

    interiors: [
      require("../images/catalog/GK_VIP/Оникс/interiors/oniks-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Оникс/interiors/oniks-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Оникс/interiors/oniks-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Оникс/interiors/oniks-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Оникс/tovars/oniks-1.jpg"),
      require("../images/catalog/GK_VIP/Оникс/tovars/oniks-2.jpg"),
      require("../images/catalog/GK_VIP/Оникс/tovars/oniks-3.jpg"),
      require("../images/catalog/GK_VIP/Оникс/tovars/oniks-4.jpg"),
      require("../images/catalog/GK_VIP/Оникс/tovars/oniks.jpg"),
    ],
  },
  {
    id: "879",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Перламутр",

    interiors: [
      require("../images/catalog/GK_VIP/Перламутр/interiors/perlamutr-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Перламутр/interiors/perlamutr-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Перламутр/interiors/perlamutr-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Перламутр/interiors/perlamutr-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Перламутр/tovars/perlamutr-1.jpg"),
      require("../images/catalog/GK_VIP/Перламутр/tovars/perlamutr-2.jpg"),
      require("../images/catalog/GK_VIP/Перламутр/tovars/perlamutr-3.jpg"),
      require("../images/catalog/GK_VIP/Перламутр/tovars/perlamutr-4.jpg"),
      require("../images/catalog/GK_VIP/Перламутр/tovars/perlamutr.jpg"),
    ],
  },
  {
    id: "880",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Сапфир",

    interiors: [
      require("../images/catalog/GK_VIP/Сапфир/interiors/sapfir-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Сапфир/interiors/sapfir-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Сапфир/interiors/sapfir-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Сапфир/interiors/sapfir-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Сапфир/tovars/sapfir-1.jpg"),
      require("../images/catalog/GK_VIP/Сапфир/tovars/sapfir-2.jpg"),
      require("../images/catalog/GK_VIP/Сапфир/tovars/sapfir-3.jpg"),
      require("../images/catalog/GK_VIP/Сапфир/tovars/sapfir-4.jpg"),
      require("../images/catalog/GK_VIP/Сапфир/tovars/sapfir.jpg"),
    ],
  },
  {
    id: "881",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Сердолик",

    interiors: [
      require("../images/catalog/GK_VIP/Сердолик/interiors/serdolik-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Сердолик/interiors/serdolik-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Сердолик/interiors/serdolik-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Сердолик/interiors/serdolik-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Сердолик/tovars/serdolik-1.jpg"),
      require("../images/catalog/GK_VIP/Сердолик/tovars/serdolik-2.jpg"),
      require("../images/catalog/GK_VIP/Сердолик/tovars/serdolik-3.jpg"),
      require("../images/catalog/GK_VIP/Сердолик/tovars/serdolik-4.jpg"),
      require("../images/catalog/GK_VIP/Сердолик/tovars/serdolik.jpg"),
    ],
  },
  {
    id: "882",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Танзанит",

    interiors: [
      require("../images/catalog/GK_VIP/Танзанит/interiors/tanzanit-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Танзанит/interiors/tanzanit-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Танзанит/interiors/tanzanit-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Танзанит/interiors/tanzanit-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Танзанит/tovars/tanzanit-1.jpg"),
      require("../images/catalog/GK_VIP/Танзанит/tovars/tanzanit-2.jpg"),
      require("../images/catalog/GK_VIP/Танзанит/tovars/tanzanit-3.jpg"),
      require("../images/catalog/GK_VIP/Танзанит/tovars/tanzanit-4.jpg"),
      require("../images/catalog/GK_VIP/Танзанит/tovars/tanzanit.jpg"),
    ],
  },
  {
    id: "883",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Хризолит",

    interiors: [
      require("../images/catalog/GK_VIP/Хризолит/interiors/hrizolit-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Хризолит/interiors/hrizolit-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Хризолит/interiors/hrizolit-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Хризолит/interiors/hrizolit-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Хризолит/tovars/hrizolit-1.jpg"),
      require("../images/catalog/GK_VIP/Хризолит/tovars/hrizolit-2.jpg"),
      require("../images/catalog/GK_VIP/Хризолит/tovars/hrizolit-3.jpg"),
      require("../images/catalog/GK_VIP/Хризолит/tovars/hrizolit-4.jpg"),
      require("../images/catalog/GK_VIP/Хризолит/tovars/hrizolit.jpg"),
    ],
  },
  {
    id: "884",
    Сountry: "Россия",
    Name: "VIP",
    Category: "GibkyMramor",
    Size: "1200x2400",
    Collection: "Цитрин",

    interiors: [
      require("../images/catalog/GK_VIP/Цитрин/interiors/tsitrin-v-interere-1.jpg"),
      require("../images/catalog/GK_VIP/Цитрин/interiors/tsitrin-v-interere-2.jpg"),
      require("../images/catalog/GK_VIP/Цитрин/interiors/tsitrin-v-interere-3.jpg"),
      require("../images/catalog/GK_VIP/Цитрин/interiors/tsitrin-v-interere-4.jpg"),
    ],
    tovars: [
      require("../images/catalog/GK_VIP/Цитрин/tovars/tsitrin-1.jpg"),
      require("../images/catalog/GK_VIP/Цитрин/tovars/tsitrin-2.jpg"),
      require("../images/catalog/GK_VIP/Цитрин/tovars/tsitrin-3.jpg"),
      require("../images/catalog/GK_VIP/Цитрин/tovars/tsitrin-4.jpg"),
      require("../images/catalog/GK_VIP/Цитрин/tovars/tsitrin.jpg"),
    ],
  },
  {
    id: "1101",
    Сountry: "Россия",
    Name: "Мрамор Гибкий",
    Category: "GibkyMramor",
    Size: "1220x2800",
    Collection: "Панель №02Т",
    interiors: [
      require("../images/catalog/MramorGibkiy/2t.jpg"),
    ],
    tovars: [
      require("../images/catalog/MramorGibkiy/2t.jpg"),
    ],
  },
  

];
export default initialCards;
